import {
    SERVICE_NOTE_DELETE,
    SERVICE_NOTE_DETAIL,
    SERVICE_NOTE_INSERT,
    SERVICE_NOTE_LIST,
    SERVICE_NOTE_UPDATE,
    SERVICE_NOTE_GET_ALL,
    SERVICE_NOTE_DETAIL_GET_ALL,
    SERVICE_NOTE_GET_CODE,
    SERVICE_NOTE_SELECT,
    SERVICE_NOTE_DETAIL_RESET,
    SAVE_EDITING_WORKDAY,
    GET_EDITING_WORKDAY
} from './action-types';

export function resetDetail() {
    return {
        type: SERVICE_NOTE_DETAIL_RESET
    };
}

export function select(payload) {
    return {
        type: SERVICE_NOTE_SELECT,
        payload
    };
}

export function list(payload) {
    const copyPayload = Object.assign({}, payload);

    copyPayload.data = copyPayload.data ? copyPayload.data : [];

    return {
        type: SERVICE_NOTE_LIST,
        payload: copyPayload
    };
}

export function getCode(payload) {
    return {
        type: SERVICE_NOTE_GET_CODE,
        payload
    };
}

export function detail(payload) {
    return {
        type: SERVICE_NOTE_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: SERVICE_NOTE_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: SERVICE_NOTE_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: SERVICE_NOTE_INSERT,
        payload
    };
}

export function suggestion(payload) {
    return {
        type: SERVICE_NOTE_GET_ALL,
        payload
    };
}

export function suggestionForServiceNoteDetail(payload) {
    return {
        type: SERVICE_NOTE_DETAIL_GET_ALL,
        payload
    };
}

export function saveEditingWorkDay(payload) {
    return {
        type: SAVE_EDITING_WORKDAY,
        payload
    };
}

export function getEditingWorkDay() {
    return {
        type: GET_EDITING_WORKDAY
    };
}
