import {
    WEBSITE_SETTING_DETAIL,
    WEBSITE_SETTING_UPDATE
} from "./action-types";

const initialState = {
    data: {}
}

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case WEBSITE_SETTING_DETAIL:
            return detail(state, payload)
        case WEBSITE_SETTING_UPDATE:
            return update(state, payload)
        default:
            return state
    }
}

function detail(state, payload) {
    state = Object.assign({}, state, { data: payload })
    return state
}

function update(state, payload) {
    state = Object.assign({}, state, { data: payload })
    return state
}

export default reducer
