import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import FormData from '../../../base/form/FormData';
import {
    getDetailServiceNoteDetail,
    getAllWorkListServiceNoteDetail
} from '../../serviceNoteDetail/service';
import {
    serviceNoteUpdateTimesRequest
} from '../../serviceNote/service';
import { BirthdayBox } from '../../../components/index';
import moment from 'moment';

class TimeEditForm extends FormData {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            disableEnterKey: true,
            pagePermisions: {
                add: true,
                view: true,
                edit: true,
                delete: true
            },
            model: {
                ...this.state.model,
                userId: this.props.value,
                branchId: this.props.branchId,
                serviceNoteDetailId: this.props.serviceNoteDetail.serviceNoteDetailId,
                startTime: this.props.serviceNoteDetail.startTime,
                totalAmount: 0,
                discountPrice: 0,
                discountByPercent: false
            },
            isShowContinue: false
        };
        this.initTxt = {
            insertTitle: 'Phân công công việc',
            updateTitle: 'Phân công công việc'
        };
        this.validateField = 'name';
    }

    componentDidMount() {
        // const { currentUser } = this.props;
        // if (!currentUser.isAdmin) {
        //     this.checkPermission();
        // }

        this.initHandler();
    }

    initHandler = () => {
        const { id } = this.props;

        if (id) {
            this.initData();
        } else {
            this.setState({
                initModel: this.state.model,
                renderDone: true,
                viewInfo: false
            });
        }
    };

    initData() {
        const { serviceNoteDetail, branchId } = this.props;
        const payload = {
            id: serviceNoteDetail.serviceNoteDetailId,
            branchId,
            serviceNoteId: serviceNoteDetail.serviceNoteId
        };
        this.getDetail(payload);
    }

    updateData(data) {
        // if (Object.keys(data)[0] === 'formValidate') {
        //     data = {
        //         formValidate: {
        //             ...this.state.model.formValidate,
        //             ...data.formValidate
        //         }
        //     };
        // }

        let model = {};
        model = { ...this.state.model, ...data };
        this.setState({
            model
        }, () => {
            this.calculateWorkTime();
        });
    }

    calculateWorkTime = () => {
        const { model } = this.state;
        const workTime = moment(model.endTime).diff(model.startTime, 'hour', true);
        const newModel = {
            ...model,
            workTime
        };
        this.setState({
            model: newModel
        });
    }

    formatTimeData = (data) => {
        if (typeof data.startTime !== 'string') data.startTime = moment(data.startTime).format('YYYY-MM-DD[T]HH:mm:ss');
        if (typeof data.endTime !== 'string') data.endTime = moment(data.endTime).format('YYYY-MM-DD[T]HH:mm:ss');

        return data;
    }

    handleSubmit() {
        const { id } = this.props;
        const { model } = this.state;
        if (id) {
            const serviceNoteDetailExtrasId = (model.serviceNoteDetailExtras.length > 0) ? model.serviceNoteDetailExtras.map(item => item.serviceId) : [];
            model.serviceNoteDetailExtras = [...serviceNoteDetailExtrasId];
            model.id = this.props.serviceNoteDetail.serviceNoteId;
            this.updateAction();
        } else {
            const { weekDay } = this.props;
            let payload = {
                code: weekDay,
                startTime: model.startTime,
                endTime: model.endTime
            };
            payload = this.formatTimeData(payload);
            this.props.changeWorkTime({ workTime: payload });
            this.props.hideForm();
        }
    }

    closeAction() {
        this.props.reloadPage();
    }

    renderFormData() {
        const { model } = this.state;
        const { serviceNoteDetail } = this.props;
        const startTimeMin = moment(serviceNoteDetail.startTime).set({ hour: 8 });
        const startTimeMax = moment(serviceNoteDetail.startTime).set({ hour: 20, minute: 30 });
        const endTimeMax = moment(serviceNoteDetail.startTime).add(3, 'hours').isAfter(startTimeMax) ? null : startTimeMax;
        return (
            <div className='row'>
                <div className='col-12 mb-3'>
                    <BirthdayBox
                        type={'time'}
                        i18nLabel={'customer.booking.startTime'}
                        updateData={this.updateData}
                        field={'startTime'}
                        interval={30}
                        showClearButton={false}
                        value={model.startTime}
                        validatorValue={'required'}
                        min={startTimeMin}
                        max={startTimeMax}
                        displayFormat={'HH:mm'}
                    />
                </div>
                <div className='col-12 mb-3'>
                    <BirthdayBox
                        type={'time'}
                        i18nLabel={'customer.booking.endTime'}
                        updateData={this.updateData}
                        field={'endTime'}
                        interval={30}
                        showClearButton={false}
                        value={model.endTime}
                        validatorValue={'required'}
                        min={new Date(moment(model.startTime).add(2, 'hours')) || new Date(2016, 1, 1, 8, 30)}
                        max={endTimeMax}
                        displayFormat={'HH:mm'}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, router) => {
    const { userSettings, languages, currentUser, permissions, pages } = state.appSettings.data;
    const { item } = state.serviceNoteDetail;
    const currentLanguage = _.find(userSettings, (item) => item.key === 'LanguageId');
    const currentBranchId = _.find(state.appSettings.data.userSettings, (item) => {
        return item.key === 'BranchId';
    });
    const languageDefaultIndex = _.findIndex(languages, lang => lang.languageId === currentLanguage.value);
    const { shiftPricesByService, byBranch } = state.coreShift;
    return {
        languages: languages,
        languageDefaultIndex,
        currentLanguage: currentLanguage.value,
        detailData: item,
        branchId: currentBranchId.value,
        currentUser,
        permissions: {
            pages: pages,
            permissions: permissions
        },
        shiftPricesByService,
        allShiftsByBranch: byBranch
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetail: (payload) => {
            return dispatch(getDetailServiceNoteDetail(payload));
        },
        update: (payload) => {
            return dispatch(serviceNoteUpdateTimesRequest(payload));
        },
        getAllServiceNoteDetail: (payload) => {
            return dispatch(getAllWorkListServiceNoteDetail(payload));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeEditForm);
