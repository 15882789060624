import { NAILSPA_URL } from "../../../constants/Config";
import Http from "../../../utils/Http";
import * as nationActions from "./store/actions";
import { spinnerLoading } from "../../../common/spinner/store/action";

export function nationSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            let url = `${NAILSPA_URL}nations?keyword=${payload.keyword}`;
            url +=
                payload.isActive !== null
                    ? `&isActive=${payload.isActive}`
                    : "";
            url += payload.page ? `&page=${payload.page}` : "";
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : "";

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(nationActions.list(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function nationSearchValidNameRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            let url = `${NAILSPA_URL}nations?keyword=${payload.keyword}`;
            url +=
                payload.countryId !== null
                    ? `&countryId=${payload.countryId}`
                    : "";

            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function nationInsertRequest(nation) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}nations`;
            let nationUpdate = nation;
            nationUpdate.name = JSON.stringify(nation.name);
            nationUpdate.description = JSON.stringify(nation.description);

            Http.post(url, nationUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    nation.id = res.data.data;
                    dispatch(nationActions.insert(nation));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function nationUpdateRequest(nation) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}nations/${nation.id}`;
            let nationUpdate = nation;
            nationUpdate.name = JSON.stringify(nation.name);
            nationUpdate.description = JSON.stringify(nation.description);

            Http.put(url, nationUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    nation.concurrencyStamp = res.data.data;
                    dispatch(nationActions.update(nation));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function nationDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            //dispatch(spinnerLoading(true))
            const url = `${NAILSPA_URL}nations/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    // dispatch(spinnerLoading(false))
                    dispatch(nationActions.detail(res.data.data));
                    resolve();
                })
                .catch(() => {
                    dispatch(spinnerLoading(false));
                    reject();
                });
        });
}

export function nationDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}nations/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(nationActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function nationGetAll(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}nations/get-all/${payload.countryId}`;

            Http.get(url)
                .then((res) => {
                    dispatch(nationActions.listByCountry(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
