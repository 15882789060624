import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/other-income',
        exact: true,
        component: Loadable({
            loader: () => import('../../pages/OtherIncomeContainer'),
            loading: LoadingComponent
        })
    }
];
