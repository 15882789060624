import { SelectBox } from 'devextreme-react';
import React, { Component } from 'react';
import { TYPESTAFF_COMMISSIONNE_PRODUCT } from '../../constants/Config';
import i18n from '../../i18n';

class TypeProductReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: {}
        };

        this.onDataChanged = this.onDataChanged.bind(this);
    }

    componentDidMount() {
        this.handleLanguage();
    }

    handleLanguage = () => {
        const { languages } = this.props;

        let datas = [];

        languages.forEach(element => {
            const newArray = [];

            for (const item of TYPESTAFF_COMMISSIONNE_PRODUCT) {
                const i = Object.assign({}, item);
                const nameParse = (i.name ? JSON.parse(i.name) : '');

                i.name = nameParse[element.languageId];
                newArray.push(i);
            }

            datas = { ...datas, [element.languageId]: newArray };
        });

        this.setState({
            datas
        });
    }

    /**
     * Handle Data Change.
     *
     * @inheritdoc
     */
    onDataChanged(e) {
        if (e.value != null) {
            const { field } = this.props;

            this.props.updateData({
                [field]: e.value
            });
        }
    }

    render() {
        const { currentLanguageActive, value, i18nLabel } = this.props;
        const { datas } = this.state;

        return (
            <div className="form-group cm-mgr-5">
                <div className={'dx-field'}>
                    <SelectBox
                        searchEnabled={true}
                        items={datas[currentLanguageActive]}
                        value={value}
                        valueExpr={'id'}
                        displayExpr={'name'}
                        showClearButton={true}
                        placeholder={i18n.t(i18nLabel)}
                        onValueChanged={this.onDataChanged}
                    />
                </div>
            </div>
        );
    }
}

export default TypeProductReport;
