export const WEBSITE_PRODUCT_CATEGORIES_SEARCH = "WEBSITE_PRODUCT_CATEGORIES_SEARCH";
export const WEBSITE_PRODUCT_CATEGORIES_INSERT = "WEBSITE_PRODUCT_CATEGORIES_INSERT";
export const WEBSITE_PRODUCT_CATEGORIES_DETAIL = "WEBSITE_PRODUCT_CATEGORIES_DETAIL";
export const WEBSITE_PRODUCT_CATEGORIES_UPDATE = "WEBSITE_PRODUCT_CATEGORIES_UPDATE";
export const WEBSITE_PRODUCT_CATEGORIES_TREE = "WEBSITE_PRODUCT_CATEGORIES_TREE";

export default {
    WEBSITE_PRODUCT_CATEGORIES_SEARCH,
    WEBSITE_PRODUCT_CATEGORIES_INSERT,
    WEBSITE_PRODUCT_CATEGORIES_DETAIL,
    WEBSITE_PRODUCT_CATEGORIES_UPDATE,
    WEBSITE_PRODUCT_CATEGORIES_TREE,
};
