import {
    USER_DETAIL,
    USER_SETTING,
} from "./action-types";

export function detail(payload) {
    return {
        type: USER_DETAIL,
        payload
    }
}

export function setting(payload) {
    return {
        type: USER_SETTING,
        payload
    }
}