import React, { Component } from "react";
import PrivateHeader from "./PrivateHeader";
import { isMobile } from "react-device-detect";
import { logout, initNewAppRequest } from "../../modules/auth/service.js";
import { connect } from "react-redux";
import Drawer from "devextreme-react/drawer";
import SideNavigationMenu from "./SideNavigationMenu.js";
import { Template } from "devextreme-react/core/template";
import {
  initAppService,
  updateLanguageRequest,
  getAppSettings,
  configSearchRequest,
  insertUserTokenPostRequest,
} from "../../modules/core/appSetting/service";
import ChangePasswordForm from "../../modules/core/account/change-password/index";
import {
  branchGetAll,
  changeBranch,
  branchGetAllByUser,
} from "../../modules/category/branch/service";
import { userSettingsChangePageSizeRequest } from "../../modules/config/usercustom/service";
import { officeGetAll } from "../../modules/user/department/service";
import { userGetAllRequest } from "../../modules/user/service";
import { paymentGetAll } from "../../modules/category/methodOfPayment/service";
import { servicePackageGetAllRequest } from "../../modules/service/servicePackage/service";
import i18n from "../../i18n";
import Http from "../../utils/Http";
import { changeTheme } from "../../modules/core/theme/service";
import { notificationDisconnectRequest } from "../../modules/notification/service";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Transformer from "../../utils/Transformer";
import ToastMessage from "../../common/toastMessage";
import ScrollView from "devextreme-react/scroll-view";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: null,
      id: null,
      showNavigation: false,
      showDropdown: false,
      isShowForm: false,
      opened: !isMobile,
      openedStateMode: "shrink",
      revealMode: "slide",
      position: "left",
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleNavigation = this.toggleNavigation.bind(this);
    this.toggleShowForm = this.toggleShowForm.bind(this);
    this.hideForm = this.hideForm.bind(this);

    console.log("isMobile", isMobile);
  }

  componentDidMount() {
    const { appSetting } = this.props;
    if (appSetting === null) {
      this.initAppSetting();
    } else {
      const { currentUser } = appSetting;
      import("../../push-notification")
        .then(({ getToken }) => {
          getToken((token) => {
            console.log("token", token);
            this.props.insertFireBaseToken({
              token,
              isActive: true,
              userId: currentUser?.id,
            });
          });
        })
        .catch((err) => {
          console.log("err", err);
        });

      this.changeDisplayLanguage();
    }

    this.initNewApp();
    this.toolbarItems = [
      {
        widget: "dxButton",
        location: "before",
        options: {
          icon: "menu",
          onClick: () => {
            console.log(this.state.opened);
            this.setState({ opened: !this.state.opened });
          },
        },
      },
    ];

    // handle receive firebase message
    if (Transformer.checkHttpsHost()) {
      import("../../push-notification").then(({ onMessageListener }) => {
        onMessageListener((payload) => {
          // eslint-disable-next-line camelcase
          const { title, body, click_action } = payload.notification;
          // eslint-disable-next-line camelcase
          toast.info(
            <ToastMessage
              title={title}
              body={body}
              handleOnClick={() => this.handleNotifyClicked(click_action)}
            />
          );
          // ToastsStore.info(`Has new notify: ${title}-${body}`);
        });
      });
    }
  }

  handleNotifyClicked = (url) => {
    window.location.href = url;
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const { isAuthenticated, appSetting } = this.props;

    if (isAuthenticated && appSetting === null) {
      this.initAppSetting();
    }
  }

  initNewApp() {
    this.props.initNewApp().then(() => {});
  }

  changeDisplayLanguage() {
    const { appSetting } = this.props;

    let languageId = appSetting.userSettings.find(
      (x) => x.key === "LanguageId"
    );
    if (!languageId) {
      languageId = appSetting.languages.find((x) => x.isDefault);
      if (!languageId) {
        languageId = appSetting.languages[0];
      }

      this.props.changeLanguage(languageId.languageId);
      i18n.changeLanguage(languageId.languageId);
      Http.defaults.headers["Accept-Language"] = languageId.languageId;
    }

    i18n.changeLanguage(languageId.value);
    Http.defaults.headers["Accept-Language"] = languageId.value;
  }

  initAppSetting() {
    console.log("initAppSetting");
    const data = {
      pages: localStorage.getItem("_si")
        ? JSON.parse(localStorage.getItem("_si"))
        : null,
      userSettings: localStorage.getItem("_s")
        ? JSON.parse(localStorage.getItem("_s"))
        : null,
      languages: localStorage.getItem("_langs")
        ? JSON.parse(localStorage.getItem("_langs"))
        : null,
      currentUser: localStorage.getItem("_u")
        ? JSON.parse(localStorage.getItem("_u"))
        : null,
      permissions: localStorage.getItem("_ps")
        ? JSON.parse(localStorage.getItem("_ps"))
        : null,
    };

    if (
      data.pages === null ||
      data.userSettings === null ||
      data.userSettings.length === 0 ||
      data.permissions === null ||
      data.languages === null ||
      data.currentUser === null
    ) {
      this.props.initAppService().then(() => {
        const { userSettings, currentUser, languages } = this.props.appSetting;
        if (Transformer.checkHttpsHost()) {
          import("../../push-notification").then(({ getToken }) => {
            getToken((token) => {
              console.log("token", token);
              this.props.insertFireBaseToken({
                token,
                isActive: true,
                userId: currentUser.id,
              });
            });
          });
        }

        const pageSize = userSettings.find((x) => x.key === "PageSize");
        if (!pageSize) {
          this.props.changePageSize(20);
        }

        let languageId = userSettings.find((x) => x.key === "LanguageId");
        if (!languageId) {
          languageId = languages.find((x) => x.isDefault);
          if (!languageId) {
            languageId = languages[0];
          }

          this.props.changeLanguage(languageId.languageId);
          i18n.changeLanguage(languageId.languageId);
          Http.defaults.headers["Accept-Language"] = languageId.languageId;
        }

        if (currentUser.isAdmin) {
          this.props.branchGetAll().then(() => {
            let branch = userSettings.find((x) => x.key === "BranchId");
            if (!branch || branch.value === "null") {
              branch = this.props.listBranch.find((x) => x.isDefault);
              if (!branch) {
                branch = this.props.listBranch[0];
              }
              this.props.changeBranch(branch.id);
            }
          });
        } else {
          this.props.branchGetAllByUser().then(() => {
            let branch = userSettings.find((x) => x.key === "BranchId");
            if (!branch || !branch.value) {
              branch = this.props.listBranch.find((x) => x.isDefault);
              if (!branch) {
                branch = this.props.listBranch[0];
              }
              this.props.changeBranch(branch.id);
            }
          });
        }
      });
    } else {
      this.props.getApp().then(() => {
        this.changeDisplayLanguage();
      });
    }
  }

  toggleNavbar() {
    this.setState({
      showNavigation: !this.state.showNavigation,
    });
  }

  toggleShowForm() {
    this.setState({
      isShowForm: !this.state.isShowForm,
      id: null,
    });
  }

  toggleNavigation() {
    this.setState({
      showNavigation: !this.state.showNavigation,
    });
  }

  hideForm() {
    this.setState({
      isShowForm: !this.state.isShowForm,
    });
  }

  logout = () => {
    this.props.disconnect().then(() => {
      this.props.logout();
    });
  };

  onOutsideClick = () => {
    if (isMobile) {
      this.setState({ opened: false });
    }
  };

  render() {
    const {
      appSetting,
      isAuthenticated,
      selectedWebsite,
      websitesList,
      currentLanguage,
      pages,
    } = this.props;
    const { opened, openedStateMode, position, revealMode } = this.state;

    if (
      isAuthenticated &&
      appSetting !== null &&
      appSetting !== undefined &&
      appSetting.userSettings !== null
    ) {
      const currentUser = appSetting.currentUser;

      const showChildren =
        websitesList.length > 0 ? selectedWebsite !== null : true;

      return (
        <div
          className={
            "wrap-content dx-theme-text-color dx-theme-background-color sidebar-mini header-fixed " +
            (this.state.showNavigation && !isMobile
              ? " sidebar-collapse fixed"
              : " fixed") +
            (this.state.showNavigation && isMobile
              ? " sidebar-collapse sidebar-open"
              : "")
          }
        >
          <PrivateHeader
            history={this.props.history}
            currentLanguage={currentLanguage}
            currentUser={currentUser}
            showNavigation={this.state.showNavigation}
            toggleNavigation={this.toggleNavigation}
            isShowForm={this.state.isShowForm}
            toggleShowForm={this.toggleShowForm}
            showSideBar={() => {
              this.setState({ opened: !this.state.opened });
            }}
            logout={this.logout}
          />

          {this.state.isShowForm ? (
            <ChangePasswordForm
              isShowForm={this.state.isShowForm}
              hideForm={this.hideForm}
            />
          ) : (
            ""
          )}
          <Drawer
            openedStateMode={openedStateMode}
            position={position}
            revealMode={revealMode}
            maxSize={250}
            opened={opened}
            closeOnOutsideClick={this.onOutsideClick}
            template={"menu"}
          >
            <ScrollView>
              {showChildren && (
                <div
                  id="content"
                  className="content-wrapper ml-0 dx-theme-background-color"
                >
                  <div className="container-fluid">
                    <div className="dashboard">{this.props.children}</div>
                  </div>
                </div>
              )}
            </ScrollView>
            <Template name={"menu"}>
              <SideNavigationMenu
                compactMode={true}
                openMenu={() => console.log("open menu")}
                pages={pages}
                currentLanguage={currentLanguage}
              ></SideNavigationMenu>
            </Template>
          </Drawer>
          <ToastContainer position="top-right" closeOnClick="true" />
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  const appSettings = state.appSettings.data;
  const { selected, data } = state.website;

  return {
    isAuthenticated: state.auth.isAuthenticated,
    appSetting: appSettings,
    listBranch: state.branch.suggestion,
    selectedWebsite: selected,
    websitesList: data,
    currentLanguage: state.appSettings.currentLanguage,
    pages: state.appSettings.data.pages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    initAppService: () => {
      return dispatch(initAppService());
    },
    getApp: () => {
      return dispatch(getAppSettings());
    },
    branchGetAll: () => {
      return dispatch(branchGetAll());
    },
    branchGetAllByUser: () => {
      return dispatch(branchGetAllByUser());
    },
    changeBranch: (branchid) => {
      return dispatch(changeBranch(branchid));
    },
    changePageSize: (pageSize) => {
      return dispatch(userSettingsChangePageSizeRequest(pageSize));
    },
    changeLanguage: (languageId) => {
      return dispatch(updateLanguageRequest(languageId));
    },
    detailConfig: () => {
      return dispatch(configSearchRequest());
    },
    initNewApp: () => {
      return dispatch(initNewAppRequest());
    },
    getAllOffice: () => {
      return dispatch(officeGetAll());
    },
    getAllUser: () => {
      return dispatch(userGetAllRequest());
    },
    getAllPayment: (payload) => {
      return dispatch(paymentGetAll(payload));
    },
    changeTheme: (themeId) => {
      return dispatch(changeTheme(themeId));
    },
    disconnect: () => {
      return dispatch(notificationDisconnectRequest());
    },
    insertFireBaseToken: (payload) => {
      return dispatch(insertUserTokenPostRequest(payload));
    },
    getAllServicePackage: () => {
      return dispatch(servicePackageGetAllRequest());
    },
  };
};

Navigation.propTypes = {
  appSetting: PropTypes.object,
  initNewApp: PropTypes.func,
  branchGetAll: PropTypes.func,
  changeLanguage: PropTypes.func,
  changeBranch: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  getApp: PropTypes.func,
  logout: PropTypes.func,
  children: PropTypes.array,
  listBranch: PropTypes.array,
  selectedWebsite: PropTypes.object,
  changePageSize: PropTypes.func,
  disconnect: PropTypes.func,
  detailConfig: PropTypes.func,
  branchGetAllByUser: PropTypes.func,
  initAppService: PropTypes.func,
  websitesList: PropTypes.any,
  insertFireBaseToken: PropTypes.func,
  history: PropTypes.any,
  getAllServicePackage: PropTypes.func,
  currentLanguage: PropTypes.string,
  pages: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
