import {
    AUTH_CHECK, AUTH_LOGIN, AUTH_INIT_NEW_APP,
    AUTH_LOGOUT, CHECK_USER_NAME, SENT_MAIL_CODE, CONFIRM_CODE
} from './action-types';

export function authInitNewApp(payLoad) {
    return {
        type: AUTH_INIT_NEW_APP,
        payLoad
    };
}

export function authLogout() {
    return {
        type: AUTH_LOGOUT
    };
}

export function authCheck() {
    return {
        type: AUTH_CHECK
    };
}

export function authLogin(payLoad) {
    return {
        type: AUTH_LOGIN,
        payLoad
    };
}

export function checkUserName(payLoad) {
    return {
        type: CHECK_USER_NAME,
        payLoad
    };
}

export function sentCode(payLoad) {
    return {
        type: SENT_MAIL_CODE,
        payLoad
    };
}

export function confirmCode(payLoad) {
    return {
        type: CONFIRM_CODE,
        payLoad
    };
}
