import {
    APP_GET,
    APP_INIT,
    APP_UPDATE_USERSETTING,
    SETTING_CONFIG,
    APP_SETTING_CHECK
} from './action-types';

export function checkAppSetting() {
    return {
        type: APP_SETTING_CHECK
    };
}

export function coreInitApp(payload) {
    return {
        type: APP_INIT,
        payload
    };
}
export function getApp() {
    return {
        type: APP_GET
    };
}

export function settingConfig(payload) {
    return {
        type: SETTING_CONFIG,
        payload
    };
}

export function updateUserSettings(payload) {
    return {
        type: APP_UPDATE_USERSETTING,
        payload
    };
}
