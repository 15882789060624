import React, { Component } from 'react';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import FileManagement from './';
import PropTypes from 'prop-types';

class FileChoosePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            isShowPopup: this.props.isShowPopup
        };

        this.onSelectFile = this.onSelectFile.bind(this);
        this.onHidden = this.onHidden.bind(this);
    }

    onSelectFile(fileInfo) {
        this.props.onFileSelected(fileInfo);
    }

    onHidden() {
        this.props.hidePopup();
    }

    render() {
        const { selectionMode, allowedExt } = this.props;
        return (
            <Popup
                position={'center'}
                visible={this.state.isShowPopup}
                onHiding={() =>
                    this.setState({
                        isShowPopup: false
                    })
                }
                onHidden={this.onHidden}
                shading={true}
                shadingColor={'rgba(0, 0, 0, 0.5)'}
                title={'Chọn file'}
            >
                <ScrollView>
                    <div>
                        <FileManagement
                            allowedExt={allowedExt}
                            insidePopup={true}
                            onSelectFile={this.onSelectFile}
                            selectionMode={selectionMode}
                            
                        />
                    </div>
                </ScrollView>
            </Popup>
        );
    }
}

FileChoosePopup.propTypes = {
    isShowPopup: PropTypes.bool,
    selectionMode: PropTypes.string,
    allowedExt: PropTypes.array,
    onFileSelected: PropTypes.func,
    hidePopup: PropTypes.func
};

export default FileChoosePopup;
