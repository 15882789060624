export const viO = {
    otherIncome: {
        insertTitle: 'Thêm mới thu nhập khác',
        updateTitle: 'Chỉnh sửa thu nhập khác',
        listTitle: 'Danh sách thu nhập khác',
        pageUnit: 'bản ghi',
        list: {
            reason: 'Lý do',
            amount: 'Số tiền',
            userId: 'Trợ lý',
            collectionDate: 'Ngày',
            isActive: 'Hoạt động',
            status: 'Trạng thái phê duyệt',
            info: 'Thông tin thu nhập'
        },
        form: {
            reason: 'Lý do',
            amount: 'Số tiền',
            userId: 'Trợ lý',
            collectionDate: 'Ngày',
            isActive: 'Hoạt động'
        }
    }
};
