// banner
export const BANNER_LIST = 'BANNER_LIST'
export const BANNER_GETALL = 'BANNER_GETALL'
export const BANNER_DETAIL = 'BANNER_DETAIL'
export const BANNER_INSERT = 'BANNER_INSERT'
export const BANNER_UPDATE = 'BANNER_UPDATE'
export const BANNER_DELETE = 'BANNER_DELETE'
// banner item
export const BANNER_ITEM_GETALL = 'BANNER_ITEM_GETALL'
export const BANNER_ITEM_DETAIL = 'BANNER_ITEM_DETAIL'
export const BANNER_ITEM_INSERT = 'BANNER_ITEM_INSERT'
export const BANNER_ITEM_UPDATE = 'BANNER_ITEM_UPDATE'
export const BANNER_ITEM_DELETE = 'BANNER_ITEM_DELETE'

export default {
    BANNER_LIST,
    BANNER_GETALL,
    BANNER_DETAIL,
    BANNER_INSERT,
    BANNER_UPDATE,
    BANNER_DELETE,
    BANNER_ITEM_GETALL,
    BANNER_ITEM_DETAIL,
    BANNER_ITEM_INSERT,
    BANNER_ITEM_UPDATE,
    BANNER_ITEM_DELETE,
}
