import React, { Component } from 'react';
import { CheckBox } from 'devextreme-react';
import i18n from '../../../i18n';
import PropTypes from 'prop-types';

class ReadOnlyCheckBox extends Component {
    render() {
        const { value, i18nLabel } = this.props;

        return (
            <div className={'dx-field'}>
                <CheckBox
                    readOnly={true}
                    text={i18n.t(i18nLabel)}
                    value={value} />
            </div>
        );
    }
}

ReadOnlyCheckBox.propTypes = {
    i18nLabel: PropTypes.string,
    value: PropTypes.bool
};

export default ReadOnlyCheckBox;
