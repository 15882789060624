import {PAGE_SIZE} from "../../../../constants/Config";
import {
    NATION_DELETE,
    NATION_DETAIL,
    NATION_INSERT,
    NATION_LIST,
    NATION_UPDATE,
    NATION_GETALL, NATION_BY_COUNTRY
} from "./action-types";


const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    items: [],
    item: null,
    getAll: null,
    suggestion: null
}

const reducer = (state = initialState, {type, payload = null})  => {
    switch (type) {
        case NATION_LIST:
            return list(state, payload)
        case NATION_DETAIL:
            return detail(state, payload)
        case NATION_UPDATE:
            return update(state, payload)
        case NATION_INSERT:
            return insert(state, payload)
        case NATION_DELETE:
            return deletes(state, payload)
        case NATION_GETALL:
            return getAll(state, payload)
        case NATION_BY_COUNTRY:
            return suggestion(state, payload)
        default:
            return state;
    }
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    })
    return state
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload)

    return Object.assign({}, state, {data})
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if(obj.id === payload.id) {
            return {...obj, ...payload}
        }
        return obj
    })

    return Object.assign({}, state, {data})
}

function insert(state, payload) {
    const data = [...state.data, payload]
    return Object.assign({},state, {data})
}
function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    })
    return state;
}
function getAll(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    })
    return state
}
export default reducer
