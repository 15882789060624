import configRoutes from "../modules/config/routes";
import userRoutes from "../modules/user/routes";
import customerRoutes from "../modules/customer/routes";
import bookingRoutes from "../modules/booking/routes";
import serviceRoutes from "../modules/service/routes";
import fileRoutes from "../modules/filemanagement/routes";
import productRoutes from "../modules/product/routes";
import promotionRoutes from "../modules/promotion/routes";
import serviceCardRoutes from "../modules/serviceCard/routes";
import promotionCardRoutes from "../modules/promotionCard/routes";
import promotionServiceCardRoutes from "../modules/promotionServiceCard/routes";
import websiteRoutes from "../features/nailspa/website/routes";
import systemRoutes from "../features/nailspa/system/routes";
import authRoutes from "../features/nailspa/auth/routes";
import coreRoutes from "../modules/core/routes";
import testRoutes from "../test/routes";
import notifyRoutes from "../modules/notification/routes";
import otherIncomeRoutes from "../modules/otherIncome/routes";
import holidayRoutes from "../modules/holiday/routes";
import specialRequest from "../modules/specialRequest/routes";
import reportRoutes from "../pages/report/router";

import moriRoutes from "../pages/router";

export default [
  ...authRoutes,
  ...moriRoutes,
  ...configRoutes,
  ...userRoutes,
  ...bookingRoutes,
  ...customerRoutes,
  ...notifyRoutes,
  ...serviceRoutes,
  ...productRoutes,
  ...fileRoutes,
  ...promotionRoutes,
  ...serviceCardRoutes,
  ...websiteRoutes,
  ...coreRoutes,
  ...systemRoutes,
  ...promotionCardRoutes,
  ...promotionServiceCardRoutes,
  ...testRoutes,
  ...otherIncomeRoutes,
  ...holidayRoutes,
  ...specialRequest,
  ...reportRoutes,
];
