import React, { Component } from 'react';
import { Popup } from 'devextreme-react/popup';
import i18n from '../../i18n';
import ScrollView from 'devextreme-react/scroll-view';
import PropTypes from 'prop-types';

class FormPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupVisible: true,
            renderData: false
        };

        this.hidePopup = this.hidePopup.bind(this);
        this.isHiddenForm = this.isHiddenForm.bind(this);
    }

    hidePopup() {
        this.setState({
            popupVisible: false
        });
    }

    isHiddenForm() {
        this.props.hideForm();
    }

    onShownHandler = () => {
        this.setState({ renderData: true });
        if (this.props.onShown) this.props.onShown();
    };

    render() {
        const { i18nLabel, otherProps } = this.props;
        const { popupVisible } = this.state;

        return (
            <Popup
                className={'popup'}
                visible={popupVisible}
                // onHiding={() => this.hidePopup()}
                onHidden={() => this.isHiddenForm()}
                dragEnabled={false}
                onShown={this.onShownHandler}
                closeOnOutsideClick={true}
                showTitle={true}
                maxHeight="700px"
                title={i18n.t(i18nLabel)}
                {...otherProps}
            >
                <ScrollView>
                    <div className="pr-3 pl-3">
                        {this.state.renderData ? (
                            this.props.children
                        ) : (<div>Loading...</div>)}
                    </div>
                </ScrollView>
            </Popup>
        );
    }
}

FormPopup.propTypes = {
    onShown: PropTypes.any,
    children: PropTypes.any,
    i18nLabel: PropTypes.string,
    otherProps: PropTypes.any,
    hideForm: PropTypes.func
};

export default FormPopup;
