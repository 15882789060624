import { NAILSPA_URL } from "../../../constants/Config";
import Http from "../../../utils/Http";
import * as districtActions from "./store/actions";
import { spinnerLoading } from "../../../common/spinner/store/action";

export function districtSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            let url = `${NAILSPA_URL}districts?keyword=${payload.keyword}`;
            url +=
                payload.isActive !== null
                    ? `&isActive=${payload.isActive}`
                    : "";
            url += payload.page ? `&page=${payload.page}` : "";
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : "";

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(districtActions.list(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function districtSearchCheckValidNameRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            let url = `${NAILSPA_URL}districts?keyword=${payload.keyword}`;
            url += payload.countryId ? `&countryId=${payload.countryId}` : "";
            url += payload.provinceId
                ? `&provinceId=${payload.provinceId}`
                : "";

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function districtInsertRequest(district) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}districts`;
            let districtUpdate = district;
            districtUpdate.name = JSON.stringify(district.name);
            districtUpdate.description = JSON.stringify(district.description);

            Http.post(url, districtUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    district.id = res.data.data;
                    dispatch(districtActions.insert(district));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
}

export function districtUpdateRequest(district) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}districts/${district.id}`;
            let districtUpdate = district;
            districtUpdate.name = JSON.stringify(district.name);
            districtUpdate.description = JSON.stringify(district.description);

            Http.put(url, districtUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    district.concurrencyStamp = res.data.data;
                    dispatch(districtActions.update(district));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
}

export function districtDetailRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}districts/${payload.id}`;
            Http.get(url)
                .then((res) => {
                    // dispatch(spinnerLoading(false));
                    dispatch(districtActions.detail(res.data.data));
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    };
}

export function districtDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}districts/${payload.id}`;
            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(districtActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
    };
}

export function districtGetAll(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}districts/get-all/${payload.provinceId}`;

            Http.get(url)
                .then((res) => {
                    dispatch(districtActions.suggestion(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
