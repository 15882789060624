export const viG = {
    goodNoteDetail: {
        form: {
            insertTitle: 'Thêm mới Sản Phẩm',
            updateTitle: 'Chỉnh sửa Sản Phẩm',
            quantity: 'Số lượng',
            unit: 'Đơn vị',
            barcode: 'Mã vạch',
            unitPrice: 'Đơn giá',
            lotId: 'Lô SX',
            expiredDate: 'Hạn sử dụng',
            inStock: 'SL Tồn'
        },
        table: {
            supplier: 'Nhà cung cấp',
            warehouse: 'Kho',
            deliver: 'Người giao hàng',
            invoiceDate: 'Ngày chứng từ',
            invoiceNo: 'Số CT'
        }
    },
    groupService: {
        list: { title: 'Danh sách Nhóm Dịch Vụ', order: 'Order' },
        form: {
            name: 'Tên Nhóm Dịch Vụ',
            parent: 'Chọn Nhóm Dịch Vụ Cha'
        }
    },
    groupProduct: {
        list: {
            title: 'Danh sách Nhóm Sản Phẩm',
            placeholder: 'Chọn Nhóm Sản Phẩm'
        }
    },
    goodsNote: {
        code: 'Số phiếu',
        entryDate: 'Ngày',
        customerCode: 'Mã KH',
        customerName: 'Khách hàng',
        objectName: 'Đối tượng',
        reason: 'Lý do',
        address: 'Địa chỉ',
        deliver: 'Người giao hàng',
        staff: 'Nhân viên',
        totalAmount: 'Thành tiền',
        totalTax: 'Thuế(%)',
        discount: 'Discounts',
        totalPrice: 'Tổng tiền',
        invoiceDate: 'Ngày CT',
        invoiceNo: 'Số CT',
        ct: 'Chứng từ',
        note: 'Ghi chú',
        title: 'Danh sách sản phẩm',
        unit: 'phiếu',
        titleInsert: 'Thêm mới sản phẩm',
        titleUpdate: 'Chỉnh sửa sản phẩm',
        supplier: 'Nhà cung cấp',
        placeOfUse: 'Nơi sử dụng',
        info: 'Xem thông tin',
        delete: 'Xóa',
        print: 'In',
        canceler: 'Người hủy',
        deliverySupply: { title: 'Danh sách xuất tặng', insertTitle: 'Thêm mới xuất tặng', updateTitle: 'Chỉnh sửa xuất tặng', printTitle: 'Phiếu xuất tặng' },
        deliveryOther: { title: 'Danh sách xuất khác', insertTitle: 'Thêm mới xuất khác', updateTitle: 'Chỉnh sửa xuất khác', printTitle: 'Phiếu xuất khác' },
        deliveryTransfer: { title: 'Danh sách xuất chuyển kho', insertTitle: 'Thêm mới xuất chuyển kho', updateTitle: 'Chỉnh sửa xuất chuyển kho', printTitle: 'Phiếu xuất chuyển kho' },
        deliverySupplier: { title: 'Danh sách nhà cung cấp', insertTitle: 'Thêm mới nhà cung cấp', updateTitle: 'Chỉnh sửa nhà cung cấp', printTitle: 'Phiếu xuất trả nhà cung cấp' },
        deliverySale: { title: 'Danh sách xuất bán lẻ', insertTitle: 'Thêm mới xuất bán lẻ', updateTitle: 'Chỉnh sửa xuất bán lẻ', printTitle: 'Phiếu xuất bán lẻ' },
        deliveryUse: { title: 'Danh sách xuất sử dụng', insertTitle: 'Thêm mới xuất sử dụng', updateTitle: 'Chỉnh sửa xuất sử dụng', printTitle: 'Phiếu xuất sử dụng' },
        deliveryCancel: { title: 'Danh sách xuất hủy', insertTitle: 'Thêm mới xuất hủy', updateTitle: 'Chỉnh sửa xuất hủy', printTitle: 'Phiếu xuất hủy' },
        receiptOther: { title: 'Danh sách Nhập khác', insertTitle: 'Thêm mới nhập khác', updateTitle: 'Chỉnh sửa nhập khác', printTitle: 'Phiếu nhập khác' },
        receiptReturn: { title: 'Danh sách Nhập khách trả', insertTitle: 'Thêm mới nhập khách trả', updateTitle: 'Chỉnh sửa nhập khách trả', printTitle: 'Phiếu nhập khách trả' },
        receiptNew: { title: 'Danh sách Nhập mua mới', insertTitle: 'Thêm mới nhập mua mới', updateTitle: 'Chỉnh sửa nhập mua mới', printTitle: 'Phiếu nhập mua mới' },
        transfer: {
            warehouseExportId: 'Mã kho xuất',
            warehouseExportName: 'Kho xuất',
            warehouseExportObject: 'Người xuất',
            warehouseImportId: 'Mã kho nhập',
            warehouseImportName: 'Kho nhập',
            warehouseImportObject: 'Người nhập'
        },
        errorAlert: {
            errWarehouseConfig: 'Chưa chọn cấu hình kho',
            errUpdateWarehouse: 'Không được chọn kho nhập trùng với kho xuất'
        }
    }
};
