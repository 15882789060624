export const NEWS_LIST = 'NEWS_LIST'
export const NEWS_GETALL = 'NEWS_GETALL'
export const NEWS_DETAIL = 'NEWS_DETAIL'
export const NEWS_INSERT = 'NEWS_INSERT'
export const NEWS_UPDATE = 'NEWS_UPDATE'
export const NEWS_DELETE = 'NEWS_DELETE'
export const CURRENT_PAGE = 'CURRENT_PAGE'

export const NEWS_APPROVE_STATUS = 'NEWS_APPROVE_STATUS'
export const NEWS_UPDATE_HOT = 'NEWS_UPDATE_HOT'
export const NEWS_UPDATE_HOMEPAGE = 'NEWS_UPDATE_HOMEPAGE'
export const NEWS_UPDATE_VIEW_COUNT = 'NEWS_UPDATE_VIEW_COUNT'
export const NEWS_GETALL_TRANSLATION = 'NEWS_GETALL_TRANSLATION'
export const NEWS_INSERT_TRANSLATION = 'NEWS_INSERT_TRANSLATION'
export const NEWS_DETAIL_TRANSLATION = 'NEWS_DETAIL_TRANSLATION'
export const NEWS_UPDATE_TRANSLATION = 'NEWS_UPDATE_TRANSLATION'
export const NEWS_DELETE_TRANSLATION = 'NEWS_DELETE_TRANSLATION'

export const CATEGORY_GETALL = 'CATEGORY_GETALL'

