import { NAILSPA_URL, CORE_URL } from '../../../constants/Config';
import Http from '../../../utils/Http';
import * as branchsActions from './store/actions';
import { spinnerLoading } from '../../../common/spinner/store/action';
import { updateUserSettings } from '../../core/appSetting/store/actions';

export function branchSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}branchs?keyword=${payload.keyword}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(branchsActions.list(res.data));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function branchSearchNameValid(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}branchs?keyword=${payload.keyword}`;

            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function branchDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            // dispatch(spinnerLoading(true))
            const url = `${NAILSPA_URL}branchs/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    // dispatch(spinnerLoading(false))
                    dispatch(branchsActions.detail(res.data.data));
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                    // dispatch(spinnerLoading(false))
                });
        });
}

export function branchDetailRequestWithoutLoading(id) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}branchs/${id}`;
            Http.get(url)
                .then((res) => {
                    dispatch(branchsActions.detail(res.data.data));
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
}

export function branchUpdateRequest(branch) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}branchs/${branch.id}`;
            const branchUpdate = Object.assign({}, branch);
            branchUpdate.name = JSON.stringify(branch.name);
            branchUpdate.address = JSON.stringify(branch.address);
            branchUpdate.note = JSON.stringify(branch.note);
            branchUpdate.noteCard = JSON.stringify(branch.noteCard);
            branchUpdate.noteProduct = JSON.stringify(branch.noteProduct);
            branchUpdate.noteService = JSON.stringify(branch.noteService);

            Http.put(url, branchUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    branchUpdate.concurrencyStamp = res.data.data;
                    dispatch(branchsActions.update(branchUpdate));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.respose.data.message);
                });
        });
}

export function branchInsertRequest(branch) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}branchs`;
            const branchUpdate = Object.assign({}, branch);
            branchUpdate.name = JSON.stringify(branch.name);
            branchUpdate.address = JSON.stringify(branch.address);
            branchUpdate.note = JSON.stringify(branch.note);
            branchUpdate.noteCard = JSON.stringify(branch.noteCard);
            branchUpdate.noteProduct = JSON.stringify(branch.noteProduct);
            branchUpdate.noteService = JSON.stringify(branch.noteService);

            branch.name = branchUpdate.name;
            branch.address = branchUpdate.address;
            branch.note = branchUpdate.note;
            branch.noteCard = branchUpdate.noteCard;
            branch.noteProduct = branchUpdate.noteProduct;
            branch.noteService = branchUpdate.noteService;
            Http.post(url, branchUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    branch.id = res.data.data;
                    dispatch(branchsActions.insert(branch));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function branchDeleteRequest(id) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}branchs/${id}`;
            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(branchsActions.deletes(id));
                    return resolve(res);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    return reject(err);
                });
        });
}

export function branchGetAll() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}branchs/get-all`;

            Http.get(url)
                .then((res) => {
                    dispatch(branchsActions.suggestion(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function branchGetAllByUser() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}branchs/get-user`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(branchsActions.suggestion(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

// branchusers
export function userGetAllByBranch(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            // dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}branchUsers/${payload.branchId}?keyword=${payload.keyword}`;
            url += payload.page ? `&page=${payload.page}` : '';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '';

            Http.get(url)
                .then((res) => {
                    // dispatch(spinnerLoading(false));
                    dispatch(branchsActions.userList(res.data));
                    resolve(res.data);
                })
                .catch((err) => reject(err));
        });
    };
}

export function userBranchPost(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}branchUsers/${payload.branchId}`;

            Http.post(url, payload.data)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function userBranchDelete(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}branchUsers/${payload.branchId}/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function changeBranch(branchid) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}usersettings/change-branch/${branchid}`;

            Http.get(url)
                .then(() => {
                    dispatch(spinnerLoading(false));
                    dispatch(
                        updateUserSettings({
                            key: 'BranchId',
                            value: branchid
                        })
                    );
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
