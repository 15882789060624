import {
    NOTIFICATION_LIST, NOTIFICATION_UPDATE_READ,
    NOTIFICATION_DELETE, NOTIFICATION_RELIST,
    NOTIFICATION_UPDATE_ALL_READ, NOTIFICATION_COUNT_UNREAD,
    NOTIFICATION_INSERT,
    OFFICE_TREES_USERS_GETALL
} from './action-types';

export function list(payload) {
    return {
        type: NOTIFICATION_LIST,
        payload
    };
}

export function insert(payload) {
    return {
        type: NOTIFICATION_INSERT,
        payload
    };
}

export function updateRead(payload) {
    return {
        type: NOTIFICATION_UPDATE_READ,
        payload
    };
}

export function updateAllRead() {
    return {
        type: NOTIFICATION_UPDATE_ALL_READ
    };
}

export function countUnRead(payload) {
    return {
        type: NOTIFICATION_COUNT_UNREAD,
        payload
    };
}

export function deleteRead(payload) {
    return {
        type: NOTIFICATION_DELETE,
        payload
    };
}

export function reList(payload) {
    return {
        type: NOTIFICATION_RELIST,
        payload
    };
}

export function officeTreeUser(payload) {
    return {
        type: OFFICE_TREES_USERS_GETALL,
        payload
    };
}
