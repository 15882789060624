export const viP = {
    // P
    paymentMethod: {
        list: { title: 'Danh sách Phương thức Thanh toán' },
        form: { methods: 'Phương thức thanh toán' }
    },
    product: {
        form: {
            insertTitle: 'Thêm mới Sản Phẩm',
            updateTitle: 'Chỉnh sửa Sản Phẩm',
            selectImg: 'Chọn ảnh',
            lotId: 'Số lô',
            printAmout: 'Số lượng in'
        },
        list: {
            price: 'Giá bán',
            order: 'Thứ tự',
            description: 'Mô tả',
            capacity: 'Dung tích',
            license: 'Số đăng ký',
            source: 'Nguồn gốc',
            directions: 'Công dụng',
            element: 'Thành Phần',
            label: 'Nhãn',
            uses: 'Cách sử dụng'
        }
    },
    program: {
        info: 'Thông tin chương trình'
    },
    page: {
        list: { title: 'Danh sách trang' },
        form: {
            code: 'Mã trang',
            icon: 'Icon',
            titleUpdate: 'Chỉnh sửa trang',
            titleInsert: 'Thêm mới trang',
            used: 'Trang sử dụng'
        },
        action: {
            listTitle: 'Danh sách hành động trên Trang',
            code: 'Mã hành động',
            bitwise: 'Giá trị Bitwise',
            insertTitle: 'Thêm mới hành động',
            updateTitle: 'Chỉnh sửa hành động',
            actionCode: 'Mã hành động',
            actionName: 'Tên hành động',
            edit: 'Chỉnh sửa'
        }
    },
    province: {
        list: { title: 'Danh sách Tỉnh/Thành Phố' },
        form: { active: 'Kích hoạt' }
    },
    position: {
        list: {
            multiple: 'Cho phép chọn nhiều',
            department: 'Sử dụng cho phòng ban'
        }
    },
    promotion: {
        list: {
            title: 'Danh sách chương trình ưu đãi ',
            promotion: 'Chương trình ưu đãi'
        },
        form: {
            promotionCard: 'Chương trình ưu đãi',
            promotionCardDetail: 'Ưu đãi của thẻ',
            note: 'Ghi chú',
            insertTitle: 'Thêm mới chương trình ưu đãi',
            info: 'Thông tin chương trình ưu đãi',
            updateTitle: 'Chỉnh sửa chương trình ưu đãi',
            servicePromotion: 'Dịch vụ thuộc chương trình ưu đãi',
            productPromotion: 'Sản phẩm thuộc chương trình ưu đãi',
            listSelect: 'Danh sách được chọn',
            selectServices: 'Chọn dịch vụ',
            selectProducts: 'Chọn sản phẩm',
            promoTitle: 'Khuyến mãi',
            days: 'Thời hạn (ngày)',
            customerType: 'Loại khách',
            customer: 'Khách hàng sử dụng',
            servicePackage: 'Gói DV áp dụng',
            discountPrice: 'Khuyến mại',
            discountPriceMax: 'Khuyến mại tối đa',
            isMoney: 'Loại điều kiện',
            condition: 'Điều kiện KM',
            conditionTime: 'Điều kiện KM (thời gian)',
            conditionMoney: 'Điều kiện KM (đơn giá)',
            code: 'Mã'
        }
    },
    promotionCard: {
        list: {
            title: 'Danh sách chương trình ưu đãi của thẻ ',
            promotionCard: 'Chương trình ưu đãi',
            promotionCardName: 'Tên ưu đãi của thẻ',
            titleDetail: 'Chi tiết chương trình ưu đãi của thẻ',
            info: 'Thông tin chương trình ưu đãi của thẻ',
            promotionCardDetailName: 'Tên chi tiết ưu đãi của thẻ'
        }
    },
    promotionServiceCard: {
        list: {
            title: 'Danh sách chương trình khuyến mại của thẻ dịch vụ',
            promotionCard: 'Chương trình ưu đãi',
            promotionCardName: 'Tên ưu đãi',
            titleDetail: 'Chi tiết chương trình ưu đãi',
            info: 'Thông tin chương trình ưu đãi',
            promotionCardDetailName: 'Tên chi tiết ưu đãi của thẻ',
            promotionServiceCardDetailDetail: 'Danh sách ưu đãi của thẻ',
            promotionServiceCardDetail: 'Danh sách thẻ dịch vụ của CTKM',
            note: 'Thêm mới Chương trình khuyến mại trước sau đó thêm thẻ dịch vụ',
            serviceCardDetailNote: 'Thêm mới thẻ dịch vụ trước sau đó thêm chương trình ưu đãi'
        },
        form: {
            insertTitle: 'Thêm mới chương trình khuyến mại',
            updateTitle: 'Chỉnh sửa chương trình khuyến mại',
            serviceCardInsertTitle: 'Thêm mới thẻ dịch vụ của CTKM',
            serviceCardUpdateTitle: 'Chỉnh sửa thẻ dịch vụ của CTKM'
        },
        error: {
            endTime: 'Thời gian kết thúc phải sau thời gian bắt đầu. Vui lòng kiểm tra lại.'
        }
    }
};
