export const viN = {
    nation: {
        list: { title: 'Danh sách Dân tộc' }
    },
    notify: {
        title: 'Tiêu đề',
        content: 'Nội dung',
        isRead: 'Đã đọc',
        createTime: 'Thời gian tạo',
        senderFullName: 'Người gửi',
        titleList: 'Danh sách thông báo',
        unit: 'thông báo',
        insertTitle: 'Thêm mới thông báo',
        infoTitle: 'Chi tiết thông báo',
        type: 'Loại',
        subContent: 'Nội dung phụ',
        message: 'Thông báo mới!',
        sendTime: 'Hẹn giờ gửi',
        endTime: 'Hẹn giờ kết thúc',
        isTimer: 'Hẹn giờ gửi',
        timerTime: 'Giờ gửi'
    }
};
