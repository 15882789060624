import { PAGE_SIZE } from '../../../constants/Config';
import {
    HOLIDAY_DETAIL,
    HOLIDAY_GETTALL,
    HOLIDAY_LIST,
    HOLIDAY_SHIFT_GETALL,
    HOLIDAY_SHIFT_BY_DATE
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: null,
    suggestion: [],
    shiftSuggestion: [],
    shiftByDate: [],
    code: ''
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case HOLIDAY_LIST:
            return list(state, payload);
        case HOLIDAY_DETAIL:
            return detail(state, payload);
        case HOLIDAY_GETTALL:
            return getAll(state, payload);
        case HOLIDAY_SHIFT_GETALL:
            return shiftGetAll(state, payload);
        case HOLIDAY_SHIFT_BY_DATE:
            return getByDate(state, payload);

        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function getAll(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

function shiftGetAll(state, payload) {
    state = Object.assign({}, state, {
        shiftSuggestion: payload
    });
    return state;
}

function getByDate(state, payload) {
    state = Object.assign({}, state, {
        shiftByDate: payload
    });
    return state;
}

export default reducer;
