export const ALBUM_LIST = 'ALBUM_LIST';
export const ALBUM_DETAIL = 'ALBUM_DETAIL';
export const ALBUM_UPDATE = 'ALBUM_UPDATE';
export const ALBUM_INSERT = 'ALBUM_INSERT';
export const ALBUM_DELETE = 'ALBUM_DELETE';
export const ALBUM_GETALL = 'ALBUM_GETALL';
export const ALBUM_SELECT = 'ALBUM_SELECT';

export const ALBUM_PHOTO_GETALL = 'ALBUM_PHOTO_GETALL';
export const ALBUM_PHOTO_DETAIL = 'ALBUM_PHOTO_DETAIL';
export const ALBUM_PHOTO_INSERT = 'ALBUM_PHOTO_INSERT';
export const ALBUM_PHOTO_UPDATE = 'ALBUM_PHOTO_UPDATE';
export const ALBUM_PHOTO_DELETE = 'ALBUM_PHOTO_DELETE';

export const ALBUM_VIDEO_GETALL = 'ALBUM_VIDEO_GETALL';
export const ALBUM_VIDEO_DETAIL = 'ALBUM_VIDEO_DETAIL';
export const ALBUM_VIDEO_INSERT = 'ALBUM_VIDEO_INSERT';
export const ALBUM_VIDEO_UPDATE = 'ALBUM_VIDEO_UPDATE';
export const ALBUM_VIDEO_DELETE = 'ALBUM_VIDEO_DELETE';

export default {
    ALBUM_DELETE,
    ALBUM_DETAIL,
    ALBUM_INSERT,
    ALBUM_LIST,
    ALBUM_UPDATE,
    ALBUM_GETALL,
    ALBUM_SELECT,

    ALBUM_PHOTO_GETALL,
    ALBUM_PHOTO_DETAIL,
    ALBUM_PHOTO_INSERT,
    ALBUM_PHOTO_UPDATE,
    ALBUM_PHOTO_DELETE,

    ALBUM_VIDEO_GETALL,
    ALBUM_VIDEO_DETAIL,
    ALBUM_VIDEO_INSERT,
    ALBUM_VIDEO_UPDATE,
    ALBUM_VIDEO_DELETE
}
