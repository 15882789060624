import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/personnel',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/admin/list',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/UserContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/user/:userId/:isAdmin',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/UserDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/user-add/:isAdmin',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/UserDetailContainer'),
            loading: LoadingComponent
        })
    },
    // {
    //     path: '/admin/list',
    //     exact: true,
    //     auth: true,
    //     component: Loadable({
    //         loader: () => import('./list/index'),
    //         loading: LoadingComponent
    //     })
    // },
    {
        path: '/personnel/list',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/UserContainer'),
            loading: LoadingComponent
        })
    }, {
        path: '/personnel/department',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/personnel/OfficeContainer'),
            loading: LoadingComponent
        })
    }, {
        path: '/personnel/department1',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./department/list/index'),
            loading: LoadingComponent
        })
    }, {
        path: '/personnel/position',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/personnel/PositionContainer'),
            loading: LoadingComponent
        })
    }, {
        path: '/personnel/title',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/personnel/TitleContainer'),
            loading: LoadingComponent
        })
    }

];
