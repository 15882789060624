import React, { Component } from 'react';
import { TextArea } from 'devextreme-react';
import i18n from '../../i18n';
import ReeValidate from 'ree-validate';
import vi from 'ree-validate/dist/locale/vi';
import en from 'ree-validate/dist/locale/en';
import PropTypes from 'prop-types';

class MultiLangDescriptionBox extends Component {
    constructor(props) {
        super(props);

        this.validatorTranslation = {};
        this.props.languages.forEach((item) => {
            this.validatorTranslation[item.languageId] = new ReeValidate({
                description: this.props.validatorValue
            });

            if (this.props.currentLanguage === 'vi-VN') {
                this.validatorTranslation[item.languageId].localize('vi', vi);
            } else {
                this.validatorTranslation[item.languageId].localize('en', en);
            }
            if ('vi-VN' in this.props.value) {
                this.validatorTranslation[item.languageId].validate('description', this.props.value[item.languageId])
                    .then(() => {
                        this.checkValidate();
                    });
            }
        });

        this.state = {
            errorsTranslation: this.validatorTranslation,
            maximum: 0,
            currentLength: 0
        };

        this.valueChangedTranslation = this.valueChangedTranslation.bind(this);
    }

    componentDidMount() {
        this.getMaxCharactersAllow();
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        // if (nextProps.value !== this.props.value || nextProps.languages !== this.props.languages || this.props.validatorValue !== nextProps.validatorValue) {
        //     nextProps.languages.forEach(item => {
        //         this.validatorTranslation[item.languageId] = new ReeValidate({
        //             description: this.props.validatorValue
        //         });

        //         if (this.props.currentLanguage === 'vi-VN') {
        //             this.validatorTranslation[item.languageId].localize('vi', vi);
        //         } else {
        //             this.validatorTranslation[item.languageId].localize('en', en);
        //         }

        //         this.validatorTranslation[item.languageId].validate('description', nextProps.value[item.languageId])
        //             .then(() => {
        //                 this.checkValidate();
        //             });

        //         this.setState({
        //             errorsTranslation: this.validatorTranslation
        //         });
        //     });
        // }

        // if (nextProps.validatorValue !== this.props.validatorValue) {
        //     this.validatorTranslation = {};
        //     this.props.languages.forEach((item) => {
        //         this.validatorTranslation[item.languageId] = new ReeValidate({
        //             description: nextProps.validatorValue
        //         });
        //         this.validatorTranslation[item.languageId].validate('description', nextProps.value[item.languageId])
        //             .then(() => {
        //                 this.checkValidate();
        //             });

        //         this.setState({
        //             errorsTranslation: this.validatorTranslation
        //         });
        //     });
        // }
    }

    checkValidate() {
        const { languages, field } = this.props;

        let formValidate = true;
        languages.forEach(element => {
            if (this.validatorTranslation[element.languageId].errors.has('description')) {
                formValidate = false;
            }
        });

        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    valueChangedTranslation(e) {
        if (e.event !== undefined && e.event !== null) {
            const { currentLanguageActive } = this.props;
            const value = e.event.target.value.trim();
            this.setState({
                currentLength: e.event.target.value.trim().length
            });
            this.props.updateData({
                [this.props.field]: { ...this.props.value, [currentLanguageActive]: value }
            });
            this.validatorTranslation[currentLanguageActive].validate(e.event.target.name, value)
                .then(() => {
                    this.checkValidate();
                });
        }
    }

    getMaxCharactersAllow = () => {
        const { validatorValue, value, currentLanguageActive } = this.props;
        let currentLength = 0;
        if (value[currentLanguageActive]) currentLength = value[currentLanguageActive].length;
        if (validatorValue && validatorValue.includes('max')) {
            let maximum = 0;
            validatorValue.split('|').forEach(item => {
                if (item.includes('max')) maximum = item.split(':')[1];
            });

            this.setState({
                maximum,
                currentLength
            });
        }
    }

    getCurrentValueLength = (e) => {
        this.setState({
            currentLength: e.event.target.value.length
        });
    }

    render() {
        const { currentLanguageActive, value, i18nLabel, readOnly, validatorValue, placeholder, height, showLimitation } = this.props;
        const { maximum, currentLength } = this.state;
        return (
            // <div className={!readOnly && this.validatorTranslation[currentLanguageActive].errors.has('description') ? ' has-error' : ''}>
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>{i18n.t(i18nLabel) + ':'}{validatorValue && validatorValue.includes('require') && <span className="color-red"> *</span>}</div>
                <div className={'dx-field-value'}>
                    <TextArea
                        className={'multi-language'}
                        name={'description'}
                        value={value[currentLanguageActive]}
                        readOnly={readOnly || false}
                        onValueChanged={this.valueChangedTranslation}
                        placeholder={placeholder || ''}
                        height={height || null}
                        onKeyUp={this.getCurrentValueLength}
                    />
                    {showLimitation &&
                        <div style={{ textAlign: 'right' }}><i>{`${currentLength} / ${maximum}`}</i></div>}
                    {!readOnly && this.validatorTranslation[currentLanguageActive].errors.has('description') && (
                        <div className="help-block">
                            {this.validatorTranslation[currentLanguageActive].errors
                                .first('description')
                                .replace('description', i18n.t(i18nLabel))}
                        </div>
                    )}
                </div>
            </div>
            // </div>
        );
    }
}

MultiLangDescriptionBox.propTypes = {
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    value: PropTypes.any,
    validatorValue: PropTypes.string,
    field: PropTypes.string,
    updateData: PropTypes.func,
    currentLanguageActive: PropTypes.string,
    i18nLabel: PropTypes.string,
    readOnly: PropTypes.bool,
    showLimitation: PropTypes.bool,
    placeholder: PropTypes.string,
    height: PropTypes.any
};

export default MultiLangDescriptionBox;
