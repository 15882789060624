import React, { Component } from 'react';
import { FILE_URL, DEFAULT_IMG } from '../../constants/Config';
import i18n from '../../i18n';
import { Button } from 'devextreme-react';
import FileChoosePopup from '../../modules/filemanagement/FileChoosePopup';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import Transformer from '../../utils/Transformer';

class ImageBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowImgPicker: false
        };

        this.handleUploadImg = this.handleUploadImg.bind(this);
        this.onSetAvatar = this.onSetAvatar.bind(this);
    }

    handleUploadImg() {
        this.setState({
            isShowImgPicker: true
        });
    }

    handleRemoveImg = () => {
        const { field } = this.props;
        this.props.updateData({
            [field]: ''
        });
        // this.props.removeImage()
    }

    onSetAvatar(e) {
        if (_.isArray(e)) {
            this.setState({
                isShowImgPicker: false
            });

            this.props.updateData({
                [this.props.field]: e
            });

            return;
        }

        this.setState({
            currentUrl: e.dataItem.url,
            isShowImgPicker: false
        });

        this.props.updateData({
            [this.props.field]: e.dataItem.url,
            imageName: e.dataItem.name
        });
    }

    hideFileChoosePopup = () => {
        this.setState({
            isShowImgPicker: false
        });
    };

    render() {
        const { value, editable, selectionMode, i18nLabel, isShowLabel, validatorValue } = this.props;
        const { isShowImgPicker } = this.state;
        return (
            <div className="wrapper-button-center">
                {isShowLabel && <div className={'dx-field-label position-absolute'}>
                    {i18n.t(i18nLabel) + ':'}
                    {validatorValue && validatorValue.includes('require') && (
                        <span style={{ color: 'red' }}> *</span>
                    )}
                </div>}
                {selectionMode !== 'multiple' &&
                    <React.Fragment>
                        <div className={'mb-3 text-center'}>

                            {value ? (
                                <img
                                    src={`${FILE_URL}/${value}`}
                                    alt="Copyright Of GHMSoft"
                                    height="200"
                                    onError={Transformer.ImageOnError}
                                />
                            ) : (<img
                                src={`${DEFAULT_IMG}`}
                                alt="Copyright Of GHMSoft"
                                height="200"
                                onError={Transformer.ImageOnError}
                            />)}

                        </div>
                    </React.Fragment>
                }

                {editable && (
                    <div className="d-flex justify-content-center mb-2">
                        <Button
                            text={i18n.t('base.selectImg')}
                            onClick={this.handleUploadImg}
                        />
                        {
                            <Button
                                className='ml-3'
                                text={i18n.t('base.removeImg')}
                                onClick={this.handleRemoveImg}
                            />
                        }

                    </div>
                )}

                {isShowImgPicker && (
                    <FileChoosePopup
                        hidePopup={this.hideFileChoosePopup}
                        isShowPopup={this.state.isShowImgPicker}
                        allowedExt={['.jpg', '.png']}
                        onFileSelected={this.onSetAvatar}
                        selectionMode={selectionMode}
                    />
                )}
            </div>
        );
    }
}

ImageBox.propTypes = {
    field: PropTypes.string,
    updateData: PropTypes.func,
    value: PropTypes.string,
    editable: PropTypes.bool,
    selectionMode: PropTypes.string,
    validatorValue: PropTypes.bool,
    isShowLabel: PropTypes.bool,
    i18nLabel: PropTypes.string
};

export default ImageBox;
