export const WEBSITE_PRODUCT_SEARCH = "WEBSITE_PRODUCT_SEARCH";
export const WEBSITE_PRODUCT_INSERT = "WEBSITE_PRODUCT_INSERT";
export const WEBSITE_PRODUCT_UPDATE = "WEBSITE_PRODUCT_UPDATE";
export const WEBSITE_PRODUCT_DETAIL = "WEBSITE_PRODUCT_DETAIL";
export const WEBSITE_PRODUCT_GETALL = "WEBSITE_PRODUCT_GETALL";

export const WEBSITE_PRODUCT_TRANSLATION_GETALL = "WEBSITE_PRODUCT_TRANSLATION_GETALL";
export const WEBSITE_PRODUCT_TRANSLATION_DETAIL = "WEBSITE_PRODUCT_TRANSLATION_DETAIL";
export const WEBSITE_PRODUCT_TRANSLATION_UPDATE = "WEBSITE_PRODUCT_TRANSLATION_UPDATE";

export default {
    WEBSITE_PRODUCT_SEARCH,
    WEBSITE_PRODUCT_INSERT,
    WEBSITE_PRODUCT_DETAIL,
    WEBSITE_PRODUCT_UPDATE,
    WEBSITE_PRODUCT_GETALL,

    WEBSITE_PRODUCT_TRANSLATION_GETALL,
    WEBSITE_PRODUCT_TRANSLATION_DETAIL,
    WEBSITE_PRODUCT_TRANSLATION_UPDATE
};
