import React, { Component } from 'react';
import { TextArea } from 'devextreme-react';
import i18n from '../../../i18n';
import PropTypes from 'prop-types';

class ReadOnlyTextArea extends Component {
    render() {
        const { value, i18nLabel } = this.props;

        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>
                    {i18n.t(i18nLabel) + ':'}
                </div>

                <div className={'dx-field-value'}>
                    <TextArea
                        className={'multi-language'}
                        name={'name'}
                        value={value}
                        readOnly={true}
                    />
                </div>
            </div>
        );
    }
}

ReadOnlyTextArea.propTypes = {
    i18nLabel: PropTypes.string,
    value: PropTypes.string
};

export default ReadOnlyTextArea;
