import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/config/core',
        component: Loadable({
            loader: () => import('./core/index'),
            loading: LoadingComponent
        })
    }, {
        path: '/config/usercustom',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./usercustom/index'),
            loading: LoadingComponent
        })
    }

];
