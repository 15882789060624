import { PAGE_SIZE } from '../../../constants/Config';
import {
    SERVICE_NOTE_DELETE,
    SERVICE_NOTE_DETAIL,
    SERVICE_NOTE_INSERT,
    SERVICE_NOTE_LIST,
    SERVICE_NOTE_UPDATE,
    SERVICE_NOTE_GET_ALL,
    SERVICE_NOTE_DETAIL_GET_ALL,
    SERVICE_NOTE_GET_CODE,
    SERVICE_NOTE_SELECT,
    SERVICE_NOTE_DETAIL_RESET,
    SAVE_EDITING_WORKDAY,
    GET_EDITING_WORKDAY
} from './action-types';
import * as _ from 'lodash';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {
        id: null,
        code: null
    },
    suggestion: [],
    serviceNoteDetailGetAll: [],
    customerId: null,
    editingData: {}
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case SERVICE_NOTE_SELECT:
            return select(state, payload);
        case SERVICE_NOTE_DETAIL_RESET:
            return resetDetail(state);
        case SERVICE_NOTE_LIST:
            return list(state, payload);
        case SERVICE_NOTE_DETAIL:
            return detail(state, payload);
        case SERVICE_NOTE_UPDATE:
            return update(state, payload);
        case SERVICE_NOTE_INSERT:
            return insert(state, payload);
        case SERVICE_NOTE_DELETE:
            return deletes(state, payload);
        case SERVICE_NOTE_GET_ALL:
            return suggestion(state, payload);
        case SERVICE_NOTE_DETAIL_GET_ALL:
            return getAll(state, payload);
        case SERVICE_NOTE_GET_CODE:
            return getCode(state, payload);
        case SAVE_EDITING_WORKDAY:
            return saveEditingWorkDay(state, payload);
        case GET_EDITING_WORKDAY:
            return getEditingWorkDay(state);
        default:
            return state;
    }
};
function resetDetail(state) {
    state = Object.assign({}, state, {
        item: {
        }
    });
    return { ...state, item: {} };
}

function select(state, payload) {
    const item = _.find(state.data, (x) => x.id === payload);
    return { ...state, item };
}

function getCode(state, payload) {
    state = Object.assign({}, state, {
        item: { code: payload }
    });
    return state;
}

function list(state, payload) {
    state = Object.assign({}, state, { ...payload });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    return { ...state, data: [...state.data, payload], item: payload };
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

function getAll(state, payload) {
    state = Object.assign({}, state, {
        serviceNoteDetailGetAll: payload
    });
    return state;
}

function saveEditingWorkDay(state, payload) {
    state = Object.assign({}, state, {
        editingData: { ...state.editingData, ...payload }
    });
    return state;
}

export const getServiceNotes = state => state.serviceNote.data;
export const getServiceNoteItem = state => state.serviceNote.item;
export const getEditingWorkDay = state => state.serviceNote.editingData;
export default reducer;
