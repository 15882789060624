export const enC = {
    customer: {
        list: {
            title: 'List of Customers',
            appointment: 'Appointment',
            delete: 'Delete',
            placeOrder: 'Place order',
            serviceHistory: 'Used services history',
            canceledList: 'Canceled services list'
        },
        form: {
            insertTitle: 'Add New Customer',
            updateTitle: 'Edit Customer',
            registerServiceProduct: 'Register Service/Product',
            addPoint: 'History of accumulation of points',
            registerAppointment: 'Register Appointment'
        },
        registerServiceProduct: {
            userSale: 'Sale Staff',
            userAdvisory: 'Advisory Staff'
        },
        booking: {
            startTime: 'Start Time',
            endTime: 'End Time'
        },
        pregnant: 'Pregnant Note',
        error: {
            genderRequired: 'Gender must be required'
        }
    },
    country: {
        list: { title: 'List of Countries' },
        name: 'Country Name',
        description: 'Country Description',
        order: 'Order',
        isActive: 'Active',
        insertTitle: 'Add New Country',
        updateTitle: 'Update Country'
    },
    customerServiceCard: {
        title: 'List of Customer Service Cards',
        price: 'Price',
        promotion: 'Promotion',
        notice:
            '*Each customer will be provided with a prepaid card, which is used to top up and pay for all spa services (Including buying additional service cards)',
        formtabs: {
            cardInfo: 'Card Info',
            serviceCard: 'Service Cards List',
            billHistory: 'Bill History',
            rechargeHistory: 'Recharge History'
        },
        noteCard: 'Card Note',
        form: {
            gender: 'Gender',
            fullname: 'Fullname',
            phone: 'Phone Number',
            dob: 'Date of Birth',
            branch: 'Branch'
        },
        list: {
            info: 'Info of Card',
            buyServiceCard: 'Buy Service Card',
            payCustomerCard: 'Pay Customer Card',
            payServiceCard: 'Pay Service Card',
            changeStatus: 'Change Status',
            withdrawPrepaid: 'Withdraw to Prepaid Card',
            usageHistory: 'Usage History',
            paymentHistory: 'Payment History',
            buyPreCard: 'Buy prepaid card',
            customerCard: 'Prepaid Card',
            editCustomerCard: 'Edit customer card',
            transferBalance: 'Transfer Prepaid Card Balance'
        },
        totalBalanceUsed: 'Total Balance Used',
        totalBalance: 'Total Balance',
        totalAmount: 'Total Amount',
        totalPaid: 'Total Paid',
        totalUnPaid: 'Total UnPaid',
        totalUse: 'Total Use',
        approveExpiryDate: 'Approve expiry date'
    },
    calendar: {
        home: {
            shift: 'Working shift',
            workingday: 'Working day',
            calendar: 'Calendar',
            homepage: 'Homepage',
            titlePopupCopy: 'Copy schedule',
            timeCopy: 'Copy time',
            startCopydate: 'Copy start date',
            destinationCopy: 'Copy destination',
            titlePopupAdd: 'Add new schedule',
            titlePopupEdit: 'Edit schedule',
            titlePopupList: 'List of employees working',
            deleteTitle: 'Are sure want to delete this calendar?',
            shiftRequired: 'Shift is required'
        },
        menu: {
            delete: 'Cancel Work Shift',
            edit: 'Edit Shift',
            note: 'Note'
        }
    },
    category: {
        supplier: {
            title: 'List of Supplier',
            unit: 'suppliers',
            name: 'Supplier'
        },
        branch: { title: 'Branch list', choose: 'Select branch' },
        country: { title: 'List of countries' },
        province: { title: 'List of provinces / cities' },
        district: { title: 'List of districts' },
        nation: { title: 'Lists of ethnicity' },
        religion: { title: 'List of religions' },
        position: { title: 'List of positions' },
        title: { title: 'List of titles' },
        office: { title: 'List of departments' },
        groupservice: { title: 'List of service groups' },
        productgroup: { title: 'Product group list' },
        service: { title: 'Service list' },
        unit: { title: 'List of units' },
        warehouse: { title: 'Warehouse list' },
        resource: { title: 'Guest source list' },
        job: { title: 'Job listings' },
        occupation: { title: 'Job listings' },
        methodOfPayment: { title: 'Lists of payment methods' }
    },
    customerCare: {
        customerFeedback: {
            insertCustomerCare: 'Insert Customer Care Information',
            updateCustomerCare: 'Update Customer Care Information',
            newPromotionInfo: 'New promotion information',
            reviewsFeedback: 'Review Feedback',
            consultationInfo: 'Consultation Information',
            exchangeTime: 'Exchange time',
            salesConsultant: 'Sale Consultant',
            careNote: 'Care note',
            workmanship: 'Workmanship',
            productReview: 'Product Review',
            attitude: 'Attitude',
            title: 'Customer Feedback',
            requestStatus: 'Request Status',
            serviceNoteProfile: 'Service Note Status',
            serviceNoteDetailList: 'Service Note Detail List',
            newCustomerReport: 'New Customer Report',
            customerBirthdayReport: 'Customer Birthday Report'
        },
        customerCardStatus: {
            title: 'List card Customer Care'
        }
    }
};
