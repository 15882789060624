import React from 'react';
import i18n from '../../../i18n';

function renderViewItem() {
    return (
        <React.Fragment>
            <i
                className="dx-icon dx-icon-search"
                style={{ color: '#888888' }}
            ></i>
            <span
                className="dx-menu-item-text"
                style={{ padding: '0 5px 5px 5px' }}
            >
                {i18n.t('fileManagement.list.view')}
            </span>
        </React.Fragment>
    );
}

function renderEditItem() {
    return (
        <React.Fragment>
            <i
                className="dx-icon dx-icon-edit"
                style={{ color: '#888888' }}
            ></i>
            <span
                className="dx-menu-item-text"
                style={{ padding: '0 5px 5px 5px' }}
            >
                {i18n.t('fileManagement.list.edit')}
            </span>
        </React.Fragment>
    );
}

function renderRenameItem() {
    return (
        <React.Fragment>
            <i
                className="dx-icon dx-icon-rename"
                style={{ color: '#888888' }}
            ></i>
            <span
                className="dx-menu-item-text"
                style={{ padding: '0 5px 5px 5px' }}
            >
                {i18n.t('fileManagement.list.rename')}
            </span>
        </React.Fragment>
    );
}

function renderDeleteItem() {
    return (
        <React.Fragment>
            <i
                className="dx-icon dx-icon-close"
                style={{ color: '#888888' }}
            ></i>
            <span
                className="dx-menu-item-text"
                style={{ padding: '0 5px 5px 5px' }}
            >
                {i18n.t('fileManagement.list.delete')}
            </span>
        </React.Fragment>
    );
}

function renderRefreshItem() {
    return (
        <React.Fragment>
            <i
                className="dx-icon dx-icon-refresh"
                style={{ color: '#888888' }}
            ></i>
            <span
                className="dx-menu-item-text"
                style={{ padding: '0 5px 5px 5px' }}
            >
                {i18n.t('fileManagement.list.refresh')}
            </span>
        </React.Fragment>
    );
}

function renderDownloadItem(fileMgr) {
    const selectedItems = fileMgr.getSelectedItems();
    const contextMenuEl2 = document.querySelectorAll(
        '.dx-filemanager-context-menu.dx-context-menu.dx-menu-base'
    );

    if (contextMenuEl2.length > 0) {
        contextMenuEl2.forEach((item) => {
            item.classList.add('no-render');
        });
    }

    if (selectedItems.length > 0) {
        const contextMenuEl = document.querySelector(
            '.dx-context-menu.dx-menu-base'
        );

        if (selectedItems[0].dataItem === undefined) {
            contextMenuEl.classList.add('to-parent-folder');
            return <span></span>;
        } else if (selectedItems[0].dataItem.isDirectory) {
            contextMenuEl.classList.add('no-render');
            return <span></span>;
        } else if (!selectedItems[0].dataItem.isDirectory) {
            return (
                <React.Fragment>
                    <i
                        className="dx-icon dx-icon-download"
                        style={{ color: '#888888' }}
                    ></i>
                    <span
                        className="dx-menu-item-text"
                        style={{ padding: '0 5px 5px 5px' }}
                    >
                        {i18n.t('general.button.download')}
                    </span>
                </React.Fragment>
            );
        }
    }
}

export {
    renderViewItem,
    renderEditItem,
    renderRenameItem,
    renderDeleteItem,
    renderRefreshItem,
    renderDownloadItem
};
