import Loadable from 'react-loadable';
import LoadingComponent from '../../../common/loader/index';

export default [
    {
        path: '/website',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteListContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/website/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/website/:websiteId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/news',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/NewsContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/news/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/NewsDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/news/category',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/NewsCategoryContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/news/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/NewsDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/news/category/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/NewsCategoryDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/news/category/:categoryId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/NewsCategoryDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/notifications',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./notifications/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/banner',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/BannerContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/banner/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/BannerDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/banner/:bannerId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/BannerDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/faq',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/FAQContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/faq/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/FAQDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/faq/:faqId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/FAQDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/faq-group',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/FAQGroupContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/faq-group/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/FAQGroupDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/faq-group/:faqGroupId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/FAQGroupDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/menu',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/MenuContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/menu/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/MenuDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/menu/:menuId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/MenuDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/info-website',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/SettingContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/hardContent/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/HardContentDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/hardContent/:hardContentId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/HardContentDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/branchContact/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/BranchContactDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/branchContact/:branchContactId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/BranchContactDetailContainer'),
            loading: LoadingComponent
        })
    },

    {
        path: '/socialNetwork/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/SocialNetworkDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/socialNetwork/:socialNetworkId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/SocialNetworkDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/service-website',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteServicesContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/service-website/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteServiceDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/service-website/:serviceId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteServiceDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/album',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./album/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/album/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./album/detail'),
            loading: LoadingComponent
        })
    },
    {
        path: '/album/:albumId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./album/detail'),
            loading: LoadingComponent
        })
    },
    {
        path: '/product-website',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./product'),
            loading: LoadingComponent
        })
    },
    {
        path: '/product-website/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./product/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/product-categories',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./productCategories'),
            loading: LoadingComponent
        })
    },
    {
        path: '/partner',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/PartnerContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/partner/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/PartnerDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/partner/:partnerId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/PartnerDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/feedback',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/FeedbackContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/feedback/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/FeedbackDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/feedback/:feedbackId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/FeedbackDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customerFeedback',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/CustomerFeedbackContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customerFeedback/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/CustomerFeedbackDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customerFeedback/:customerFeedbackId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/CustomerFeedbackDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/subscribe',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./subscribe/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/subscribe/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./subscribe/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/subscribe/:subscribeId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./subscribe/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/booking',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteBookingContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/booking/edit/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteBookingDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./project/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./project/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./project/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/job',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteJobContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/job/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteJobDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/job/:jobId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/WebsiteJobDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/recruitment',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/RecruitmentContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/recruitment/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/RecruitmentDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/recruitment/:recruitmentId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/website/RecruitmentDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project-category',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./projectCategories/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project-category/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./projectCategories/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project-category/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./projectCategories/add'),
            loading: LoadingComponent
        })
    }
];
