import axios from "axios";
import {
  CORE_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  AUTH_URL,
} from "../constants/Config";
import qs from "qs";
import Transformer from "./Transformer";

axios.defaults.baseURL = CORE_URL;
var refreshTokenRunning = false;

axios.interceptors.request.use((request) => {
  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !refreshTokenRunning) {
      refreshTokenRunning = true;

      // if (localStorage.getItem('refresh_token') === null) {
      //     store.dispatch(authLogout());
      // } else {
      const input = qs.stringify({
        grant_type: "refresh_token",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        refresh_token: localStorage.getItem("refresh_token"),
      });

      axios
        .post(`${AUTH_URL}/connect/token`, input)
        .then((res) => {
          const data = Transformer.fetch(res.data);

          localStorage.setItem("refresh_token", data.refreshToken);
          localStorage.setItem("access_token", data.accessToken);

          window.location.reload();
        })
        .catch((err) => {
          console.log("err", err);
          // store.dispatch(authLogout());
          refreshTokenRunning = false;
        });
      // }
    }

    return Promise.reject(error);
  }
);

export default axios;
