import { spinnerLoading } from '../../../common/spinner/store/action';
import { CORE_URL, DEFAULT_USERSETTING, FIREBASE_URL } from '../../../constants/Config';
import Http from '../../../utils/Http';
import Transformer from '../../../utils/Transformer';
import { coreInitApp, getApp, settingConfig, updateUserSettings } from './store/actions';

export function initAppService() {
    return (dispatch) => (
        new Promise((resolve, reject) => {
            Http.get(`${CORE_URL}appsettings`)
                .then((res) => {
                    const data = Transformer.fetch(res.data);
                    const userSettings = data.userSettings;

                    if (data.userSettings.findIndex(x => x.key === 'BranchId') === -1) {
                        Http.get(`${CORE_URL}UserSettings/change-branch/${DEFAULT_USERSETTING.BRANCH}`);
                        userSettings.push({
                            key: 'BranchId',
                            value: 'dae13df6-6720-4bda-a61c-61e5e948017e'
                        });
                    }
                    if (data.userSettings.findIndex(x => x.key === 'LanguageId') === -1) {
                        Http.get(`${CORE_URL}UserSettings/change-language/${DEFAULT_USERSETTING.LANGUAGE}`);
                        userSettings.push({
                            key: 'LanguageId',
                            value: 'vi-VN'
                        });
                    }
                    if (data.userSettings.findIndex(x => x.key === 'PageSize') === -1) {
                        Http.get(`${CORE_URL}UserSettings/change-pagesize/${DEFAULT_USERSETTING.PAGESIZE}`);
                        userSettings.push({
                            key: 'PageSize',
                            value: 20
                        });
                    }
                    if (data.userSettings.findIndex(x => x.key === 'ThemeId') === -1) {
                        Http.get(`${CORE_URL}UserSettings/change-theme/${DEFAULT_USERSETTING.THEME}`);
                        userSettings.push({
                            key: 'ThemeId',
                            value: '14998e5c-27a3-44ff-92d8-19dfd1366ba1'
                        });
                    }

                    data.userSettings = userSettings;
                    dispatch(coreInitApp(data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        })
    );
}

export function insertUserTokenPostRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${FIREBASE_URL}UserTokens`;

            Http.post(url, payload)
                .then((res) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        })

    );
}

export function getAppSettings() {
    return (dispatch) => (
        new Promise((resolve, reject) => {
            dispatch(getApp());
            resolve();
        })
    );
}

export function configSearchRequest() {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}AppSettings/settings`;
            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false));
                dispatch(settingConfig(res.data.data));
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    );
}

export function configInsertRequest(configSystem) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}AppSettings/settings`;
            Http.post(url, configSystem).then(res => {
                dispatch(spinnerLoading(false));
                resolve(res.data);
            }).catch(err => {
                dispatch(spinnerLoading(false));
                reject(err);
            });
        })
    );
}

export function updateLanguageRequest(languageId) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(updateUserSettings({
                key: 'LanguageId',
                value: languageId
            }));
            resolve();
        })
    );
}
