export const enO = {
    otherIncome: {
        insertTitle: 'Add other income',
        updateTitle: 'Update other income',
        listTitle: 'List of angent other incomes',
        pageUnit: 'record(s)',
        list: {
            reason: 'Reason',
            amount: 'Money amount',
            userId: 'Agent',
            collectionDate: 'Date',
            isActive: 'Active',
            status: 'Approve status',
            info: 'Incomme info'
        },
        form: {
            reason: 'Reason',
            amount: 'Money amount',
            userId: 'Agent',
            collectionDate: 'Date',
            isActive: 'Active'
        }
    }
};
