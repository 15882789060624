import {
    SERVICE_CARD_LIST,
    SERVICE_CARD_TREE,
    SERVICE_CARD_DETAIL,
    SERVICE_CARD_INSERT,
    SERVICE_CARD_UPDATE,
    SERVICE_CARD_DELETE,
    SERVICE_CARD_GET_CODE
} from './action-types';

import { PAGE_SIZE } from '../../../constants/Config';
const initialState = {
    data: [],
    totalRows: 0,
    currentPAGE: 0,
    pageSize: PAGE_SIZE,
    action: null,
    item: {},
    getAll: [],
    code: ''
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case SERVICE_CARD_GET_CODE:
            return getCode(state, payload);
        case SERVICE_CARD_LIST:
            return list(state, payload);
        case SERVICE_CARD_TREE:
            return tree(state, payload);
        case SERVICE_CARD_DETAIL:
            return detail(state, payload);
        case SERVICE_CARD_UPDATE:
            return update(state, payload);
        case SERVICE_CARD_INSERT:
            return insert(state, payload);
        case SERVICE_CARD_DELETE:
            return deletes(state, payload);
        default:
            return state;
    }
};

function getCode(state, payload) {
    state = Object.assign({}, state, {
        code: payload
    });
    return state;
}

function list(state, payload) {
    return { ...state, ...payload };
}

function tree(state, payload) {
    state = Object.assign({}, state, {
        getAll: payload
    });
    return state;
}

function detail(state, payload) {
    return { ...state, item: payload };
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return payload;
        }
        return obj;
    });
    return Object.assign({}, state, { data });
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload);

    return Object.assign({}, state, { data });
}

export const getServiceCardsList = state => state.serviceCard.data;

export default reducer;
