import React from 'react';
import { connect } from 'react-redux';
import './spinner.scss';
import i18n from '../../i18n';

class SpinnerService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: props.isLoading
        };
    }

    render() {
        if (this.props.isLoading) {
            return (
                <div>
                    <div className={this.props.isLoading ? 'spinner-backdrop' : ''}>
                    </div>
                    <div className="m-page-loader m-page-loader--non-block">
                        <div className="m-blockui">
                            <span>{i18n.t('message.loading')}</span>
                            <span>
                                <div className="m-loader m-loader--brand"></div>
                            </span>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.spinner.isLoading
    };
};

export default connect(mapStateToProps)(SpinnerService);
