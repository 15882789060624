import {
    WEBSITE_PRODUCT_DETAIL,
    WEBSITE_PRODUCT_GETALL, WEBSITE_PRODUCT_SEARCH, WEBSITE_PRODUCT_TRANSLATION_DETAIL, WEBSITE_PRODUCT_TRANSLATION_GETALL
} from "./action-types";

const initialState = {
    data: [],
    totalRows: 0,
    item: null,
    suggestion: [],
    translation: {
        data: [],
        totalRows: 0,
        item: {}
    }
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case WEBSITE_PRODUCT_SEARCH:
            return search(state, payload);
        case WEBSITE_PRODUCT_DETAIL:
            return detail(state, payload);
        case WEBSITE_PRODUCT_GETALL:
            return getAll(state, payload);

        case WEBSITE_PRODUCT_TRANSLATION_GETALL:
            return translationGetAll(state, payload);
        case WEBSITE_PRODUCT_TRANSLATION_DETAIL:
            return translationDetail(state, payload);
        default:
            return state;
    }
};

function translationDetail(state, payload) {
    state = Object.assign({}, state, {
        translation: {
            ...state.translation,
            item: payload
        }
    })
    return state
}

function translationGetAll(state, payload) {
    state = Object.assign({}, state, {
        translation: {
            data: payload,
            totalRows: payload.length,
            item: {}
        }
    })
    return state
}

const search = (state, payload) => {
    state = { ...state, data: payload.data, totalRows: payload.totalRows };

    return state;
};

const detail = (state, payload) => {
    state = { ...state, item: payload };

    return state;
};

const getAll = (state, payload) => {
    state = Object.assign({}, state, {
        suggestion: payload
    })
    return state
}

export default reducer;
