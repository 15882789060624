export const FAQ_LIST = 'FAQ_LIST'
export const FAQ_DETAIL = 'FAQ_DETAIL'
export const FAQ_UPDATE = 'FAQ_UPDATE'
export const FAQ_INSERT = 'FAQ_INSERT'
export const FAQ_DELETE = 'FAQ_DELETE'
export const FAQ_RESPONSE = 'FAQ_RESPONSE'
export const FAQ_GETALL = 'FAQ_GETALL'

export default {
    FAQ_DELETE,
    FAQ_DETAIL,
    FAQ_INSERT,
    FAQ_LIST,
    FAQ_UPDATE,
    FAQ_GETALL
}
