import { ToastsStore } from 'react-toasts';
import i18n from '../../../i18n';

var moment = require('moment');

function deleteFolder(fileMgrClass) {
    const { editFoldersTreeView } = fileMgrClass.state;

    if (editFoldersTreeView) {
        const { selectedItem } = fileMgrClass.state;
        fileMgrClass.props
            .deleteFolder(selectedItem.id)
            .then((res) => {
                fileMgrClass.setState({
                    editFoldersTreeView: false
                });

                fileMgrClass.fileMgr.refresh();
                fileMgrClass.getFolderTree();
                fileMgrClass.updateFileProvider();

                ToastsStore.success(res.message);
            })
            .catch((e) => {
                ToastsStore.error(
                    i18n.t('fileManagement.error.folderContainFiles')
                );
            });
    } else {
        const selectedItems = fileMgrClass.fileMgr.getSelectedItems();
        const currentFolder = fileMgrClass.fileMgr.getCurrentDirectory();

        selectedItems.forEach((element) => {
            if (element.dataItem.isDirectory) {
                fileMgrClass.props
                    .deleteFolder(element.dataItem.id)
                    .then((res) => {
                        if (currentFolder.dataItem) {
                            const removeIndex = currentFolder.dataItem.items.indexOf(
                                element.dataItem
                            );
                            currentFolder.dataItem.items.splice(
                                removeIndex,
                                1
                            );
                            fileMgrClass.fileMgr.refresh();
                        } else {
                            fileMgrClass.updateFileProvider();
                        }
                        fileMgrClass.getFolderTree();
                        ToastsStore.success(res.message);
                    })
                    .catch((e) => {
                        ToastsStore.error(
                            i18n.t(
                                'fileManagement.error.folderContainFiles'
                            )
                        );
                    });
            } else {
                fileMgrClass.props.deleteFile(element.dataItem.id)
                    .then(() => {
                        if (currentFolder.dataItem) {
                            const removeIndex = currentFolder.dataItem.items.indexOf(
                                element.dataItem
                            );
                            currentFolder.dataItem.items.splice(removeIndex, 1);
                            fileMgrClass.fileMgr.refresh();
                        } else {
                            fileMgrClass.updateFileProvider();
                        }
                    });
            }
        });
    }
}

function addFolder(fileMgrClass, newFolderName) {
    const currentFolder = fileMgrClass.fileMgr.getCurrentDirectory();
    const parentId = currentFolder.isRoot ? null : currentFolder.dataItem.id;

    const folderInfo = {
        name: newFolderName,
        order: 0,
        parentId: parentId,
        description: 'string',
        concurrencyStamp: 'string'
    };

    fileMgrClass.props
        .createFolder(folderInfo)
        .then((data) => {
            ToastsStore.success(data.message);
            if (currentFolder.dataItem) {
                currentFolder.dataItem.items.push({
                    id: data.data,
                    name: newFolderName,
                    parentId: parentId,
                    isDirectory: true
                });

                fileMgrClass.fileMgr.refresh();
            } else {
                fileMgrClass.updateFileProvider();
            }

            fileMgrClass.getFolderTree();
            fileMgrClass.updateFileProvider();
            fileMgrClass.hideAddFolderPopup();
        })
        .catch((err) => {
            ToastsStore.error(err.response.data.message);
        });
}

function renameFolder(fileMgrClass, newFolderName) {
    const { selectedItem, editFoldersTreeView } = fileMgrClass.state;

    const currentFolder = fileMgrClass.fileMgr.getCurrentDirectory();

    if (selectedItem.isDirectory) {
        const folderMeta = {
            name: newFolderName,
            order: 0,
            parentId: selectedItem.parentId,
            description: 'string',
            concurrencyStamp: selectedItem.concurrencyStamp
        };

        fileMgrClass.props.editFolder(selectedItem.id, folderMeta)
            .then((data) => {
                if (editFoldersTreeView) {
                    currentFolder.dataItem.name = newFolderName;
                    currentFolder.dataItem.concurrencyStamp = data.data;
                    fileMgrClass.setState({
                        editFoldersTreeView: false
                    });
                    fileMgrClass.fileMgr.refresh();
                } else {
                    if (currentFolder.dataItem) {
                        const renameIndex = currentFolder.dataItem.items.indexOf(
                            selectedItem
                        );
                        currentFolder.dataItem.items[
                            renameIndex
                        ].name = newFolderName;
                        currentFolder.dataItem.items[
                            renameIndex
                        ].concurrencyStamp = data.data;

                        fileMgrClass.fileMgr.refresh();
                    } else {
                        fileMgrClass.updateFileProvider();
                    }
                }

                ToastsStore.success(data.message);
                fileMgrClass.getFolderTree();
                fileMgrClass.hideRenamePopup();
            })
            .catch((err) => {
                ToastsStore.error(err.response.data.message);
            });
    } else {
        const fileMeta = {
            name: newFolderName,
            folderId: selectedItem.folderId,
            concurrencyStamp: selectedItem.concurrencyStamp
        };

        fileMgrClass.props.editFile(selectedItem.id, fileMeta)
            .then((data) => {
                if (currentFolder.dataItem) {
                    const renameIndex = currentFolder.dataItem.items.indexOf(
                        selectedItem
                    );
                    currentFolder.dataItem.items[
                        renameIndex
                    ].name = newFolderName;
                    currentFolder.dataItem.items[renameIndex].concurrencyStamp =
                        data.data;

                    fileMgrClass.fileMgr.refresh();
                } else {
                    fileMgrClass.updateFileProvider();
                }
                ToastsStore.success(data.message);
                fileMgrClass.hideRenamePopup();
            })
            .catch((err) => {
                ToastsStore.error(err.response.data.message);
            });
    }
}

function getChildrenFoldersList(fileMgrClass, folderId) {
    fileMgrClass.props.getChildFolder(folderId)
        .then((data) => {
            if (folderId === 0) {
                fileMgrClass.updateFileProvider();
            } else {
                const currentFolder = fileMgrClass.fileMgr.getCurrentDirectory();

                data.files.forEach((element) => {
                    const searchFile = currentFolder.dataItem.items.find(
                        (x) => x.id === element.id
                    );
                    if (searchFile === undefined) {
                        currentFolder.dataItem.items.push({
                            id: element.id,
                            name: element.name,
                            folderId: element.folderId,
                            type: element.type,
                            url: element.url,
                            size: element.size,
                            concurrencyStamp: element.concurrencyStamp,
                            dateModified: moment(element.createTime),
                            isDirectory: false
                        });
                    } else {
                        searchFile.name = element.name;
                        searchFile.concurrencyStamp = element.concurrencyStamp;
                    }
                });

                data.folders.forEach((element) => {
                    const searchFolder = currentFolder.dataItem.items.find(
                        (x) => x.id === element.id
                    );
                    if (searchFolder === undefined) {
                        currentFolder.dataItem.items.push({
                            id: element.id,
                            name: element.name,
                            parentId: element.parentId,
                            isDirectory: true,
                            concurrencyStamp: element.concurrencyStamp
                        });
                    } else {
                        searchFolder.name = element.name;
                        searchFolder.concurrencyStamp =
                            element.concurrencyStamp;
                    }
                });

                fileMgrClass.fileMgr.refresh();
            }
        });
}

function editFolder(fileMgrClass, newFolderName, folderId) {
    const { selectedItem, editFoldersTreeView } = fileMgrClass.state;
    const currentFolder = fileMgrClass.fileMgr.getCurrentDirectory();

    const oldParentFolderId = selectedItem.isDirectory
        ? selectedItem.parentId
        : selectedItem.folderId;

    if (folderId === oldParentFolderId) {
        fileMgrClass.renameFolder(newFolderName);

        fileMgrClass.hideEditPopup();
    } else {
        if (selectedItem.isDirectory) {
            const folderMeta = {
                name: newFolderName,
                order: 0,
                parentId: folderId,
                description: 'string',
                concurrencyStamp: selectedItem.concurrencyStamp
            };

            fileMgrClass.props
                .editFolder(selectedItem.id, folderMeta)
                .then((data) => {
                    if (editFoldersTreeView) {
                        fileMgrClass.setState({
                            editFoldersTreeView: false
                        });

                        fileMgrClass.fileMgr.refresh();
                        fileMgrClass.getFolderTree();
                        fileMgrClass.updateFileProvider();
                    } else {
                        if (currentFolder.dataItem) {
                            const removeIndex = currentFolder.dataItem.items.indexOf(
                                selectedItem
                            );
                            currentFolder.dataItem.items.splice(
                                removeIndex,
                                1
                            );

                            fileMgrClass.fileMgr.refresh();
                        } else {
                            fileMgrClass.updateFileProvider();
                        }
                    }

                    if (folderId === null) {
                        fileMgrClass.updateFileProvider();
                    }
                    ToastsStore.success(data.message);
                    fileMgrClass.getFolderTree();
                    fileMgrClass.updateFileProvider();
                    fileMgrClass.hideEditPopup();
                })
                .catch((err) => {
                    ToastsStore.error(err.response.data.message);
                });
        } else {
            const fileMeta = {
                name: newFolderName,
                folderId: folderId,
                concurrencyStamp: selectedItem.concurrencyStamp
            };

            fileMgrClass.props.editFile(selectedItem.id, fileMeta)
                .then((data) => {
                    if (currentFolder.dataItem) {
                        const removeIndex = currentFolder.dataItem.items.indexOf(
                            selectedItem
                        );
                        currentFolder.dataItem.items.splice(removeIndex, 1);

                        fileMgrClass.fileMgr.refresh();
                    } else {
                        fileMgrClass.updateFileProvider();
                    }

                    ToastsStore.success(data.message);
                    fileMgrClass.hideEditPopup();
                });
        }
    }
}

function searchFolder(fileMgrClass) {
    if (fileMgrClass.state.searchValue) {
        fileMgrClass.props.searchFolder(fileMgrClass.state.searchValue)
            .then(() => {
                fileMgrClass.updateFileProvider();
            });
    } else {
        const currentFolder = fileMgrClass.fileMgr.getCurrentDirectory();
        const folderId = currentFolder.isRoot ? 0 : currentFolder.dataItem.id;

        fileMgrClass.setState({
            folderID: folderId,
            parentFolderIdRequestedList: []
        });

        fileMgrClass.props.resetFolder()
            .then(() => {
                const { folderID } = fileMgrClass.state;
                fileMgrClass.getChildrenFoldersList(folderID);
                fileMgrClass.getFolderTree();
            });
    }
}

function onCurrentDirectoryChanged(fileMgrClass) {
    const currentFolder = fileMgrClass.fileMgr.getCurrentDirectory();

    const folderId = currentFolder.isRoot ? 0 : currentFolder.dataItem.id;
    fileMgrClass.folderID = folderId;

    fileMgrClass.setState({
        folderID: folderId
    });

    getChildrenFoldersList(fileMgrClass, folderId);
}

export {
    deleteFolder,
    addFolder,
    renameFolder,
    getChildrenFoldersList,
    editFolder,
    searchFolder,
    onCurrentDirectoryChanged
};
