export const SERVICE_LIST = 'SERVICE_LIST';
export const SERVICE_DETAIL = 'SERVICE_DETAIL';
export const SERVICE_UPDATE = 'SERVICE_UPDATE';
export const SERVICE_INSERT = 'SERVICE_INSERT';
export const SERVICE_DELETE = 'SERVICE_DELETE';
export const SERVICE_GET_ALL = 'SERVICE_GET_ALL';
export const SERVICE_GET_ALL_MAIN = 'SERVICE_GET_ALL_MAIN';
export const SERVICE_SELECT = 'SERVICE_SELECT';
export const SERVICE_USER_LIST = 'SERVICE_USER_LIST';
export const SERVICE_USER_RESET = 'SERVICE_USER_RESET';
export const SERVICE_DETAIL_RESET = 'SERVICE_DETAIL_RESET';
export const SERVICE_GET_CODE = 'SERVICE_GET_CODE';

export default {
    SERVICE_DELETE,
    SERVICE_DETAIL,
    SERVICE_INSERT,
    SERVICE_LIST,
    SERVICE_UPDATE,
    SERVICE_GET_ALL,
    SERVICE_SELECT,
    SERVICE_USER_LIST,
    SERVICE_USER_RESET,
    SERVICE_DETAIL_RESET,
    SERVICE_GET_CODE,
    SERVICE_GET_ALL_MAIN
};
