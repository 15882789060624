import {
    SERVICE_NOTE_DETAIL_DETAIL,
    SERVICE_NOTE_DETAIL_INSERT,
    SERVICE_NOTE_DETAIL_LIST,
    SERVICE_NOTE_DETAIL_SELECT,
    SERVICE_NOTE_DETAIL_RESET,
    SERVICE_NOTE_DETAIL_UPDATE,
    SERVICE_NOTE_DETAIL_BY_CUSTOMER_LIST,
    SERVICE_NOTE_DETAIL_LIST_RESET,
    SERVICE_NOTE_STATUS,
    SERVICE_NOTE_DETAIL_GETALL,
    SERVICE_NOTE_DETAIL_GETALL_BYAGENT
} from './action-types';

export function resetList() {
    return {
        type: SERVICE_NOTE_DETAIL_LIST_RESET
    };
}

export function reset() {
    return {
        type: SERVICE_NOTE_DETAIL_RESET
    };
}

export function select(payload) {
    return {
        type: SERVICE_NOTE_DETAIL_SELECT,
        payload
    };
}

export function insertList(payload) {
    return {
        type: SERVICE_NOTE_DETAIL_LIST_RESET,
        payload
    };
}

export function list(payload) {
    return {
        type: SERVICE_NOTE_DETAIL_LIST,
        payload
    };
}

export function listByCustomer(payload) {
    return {
        type: SERVICE_NOTE_DETAIL_BY_CUSTOMER_LIST,
        payload
    };
}

export function insert(payload) {
    return {
        type: SERVICE_NOTE_DETAIL_INSERT,
        payload
    };
}

export function update(payload) {
    return {
        type: SERVICE_NOTE_DETAIL_UPDATE,
        payload
    };
}

export function detail(payload) {
    return {
        type: SERVICE_NOTE_DETAIL_DETAIL,
        payload
    };
}

export function storeStatus(payload) {
    return {
        type: SERVICE_NOTE_STATUS,
        payload
    };
}

export function getAll(payload) {
    return {
        type: SERVICE_NOTE_DETAIL_GETALL,
        payload
    };
}

export function getAllWorkShifts(payload) {
    return {
        type: SERVICE_NOTE_DETAIL_GETALL_BYAGENT,
        payload
    };
}
