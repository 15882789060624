export const TENANT_LIST = 'TENANT_LIST'
export const TENANT_SUGGESTION = 'TENANT_SUGGESTION'
export const TENANT_UPDATE  = 'TENANT_UPDATE'
export const TENANT_INSERT = 'TENANT_INSERT'
export const TENANT_DELETE = 'TENANT_DELETE'
export const TENANT_DETAIL = 'TENANT_DETAIL'
export const TENANT_LANGUAGE_INSERT = 'TENANT_LANGUAGE_INSERT'
export const TENANT_LANGUAGE_UPDATE = 'TENANT_LANGUAGE_UPDATE'
export const TENANT_LANGUAGE_DELETE = 'TENANT_LANGUAGE_DELETE'
export const TENANT_LANGUAGE_UPDATE_ACTIVE = 'TENANT_LANGUAGE_UPDATE_ACTIVE'
export const TENANT_LANGUAGE_UPDATE_DEFAULT = 'TENANT_LANGUAGE_UPDATE_DEFAULT'
export const TENANT_ACTIVE_ID = 'TENANT_ACTIVE_ID'
export const TENANT_PAGE_UPDATE = 'TENANT_PAGE_UPDATE'

export default {
    TENANT_LIST,
    TENANT_SUGGESTION,
    TENANT_INSERT,
    TENANT_UPDATE,
    TENANT_DELETE,
    TENANT_LANGUAGE_UPDATE,
    TENANT_LANGUAGE_INSERT,
    TENANT_LANGUAGE_DELETE,
    TENANT_LANGUAGE_UPDATE_ACTIVE,
    TENANT_LANGUAGE_UPDATE_DEFAULT,
    TENANT_DETAIL,
    TENANT_ACTIVE_ID,
    TENANT_PAGE_UPDATE
}
