export const GROUPS_SERVICE_LIST = 'GROUPS_SERVICE_LIST'
export const GROUPS_SERVICE_TREE = 'GROUPS_SERVICE_TREE'
export const GROUPS_SERVICE_DETAIL = 'GROUPS_SERVICE_DETAIL'
export const GROUPS_SERVICE_INSERT = 'GROUPS_SERVICE_INSERT'
export const GROUPS_SERVICE_UPDATE = 'GROUPS_SERVICE_UPDATE'
export const GROUPS_SERVICE_DELETE = 'GROUPS_SERVICE_DELETE'
export const GROUPS_SERVICE_GET_ALL = 'GROUPS_SERVICE_GET_ALL'

export default {
    GROUPS_SERVICE_LIST,
    GROUPS_SERVICE_TREE,
    GROUPS_SERVICE_DETAIL,
    GROUPS_SERVICE_INSERT,
    GROUPS_SERVICE_UPDATE,
    GROUPS_SERVICE_DELETE,
    GROUPS_SERVICE_GET_ALL
}
