import Loadable from "react-loadable";
import LoadingComponent from "../common/loader/index";

export default [
  {
    path: "/serviceNote",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./serviceNote"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/serviceNote/add",
    exact: true,
    auth: true,
    component: Loadable({
      // loader: () => import('./ServiceNoteDetail'),
      loader: () => import("./serviceNote/ServiceNoteDetailContainer"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/serviceNote/:id",
    exact: true,
    auth: true,
    component: Loadable({
      // loader: () => import('./ServiceNoteDetail'),
      loader: () => import("./serviceNote/ServiceNoteDetailContainer"),
      loading: LoadingComponent,
    }),
  },
];
