import React, { Component } from 'react';
import i18n from '../../i18n';
import { Button } from 'devextreme-react';
import FileChoosePopup from '../../modules/filemanagement/FileChoosePopup';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

class CVBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowImgPicker: false,
            nameFile: ''
        };

        this.handleUploadImg = this.handleUploadImg.bind(this);
        this.onSetAvatar = this.onSetAvatar.bind(this);
    }

    componentDidMount() {
        const { nameFile } = this.props;
        this.setState({
            nameFile
        });
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { nameFile } = this.props;

        if (nextProps.nameFile !== nameFile) {
            this.setState({
                nameFile: nextProps.nameFile
            });
        }
    }

    handleUploadImg() {
        this.setState({
            isShowImgPicker: true
        });
    }

    handleRemoveImg = () => {
        const { field, nameField } = this.props;
        this.setState({
            nameFile: ''
        });
        this.props.updateData({
            [field]: '',
            [nameField]: ''
        });
        // this.props.removeImage()
    }

    onSetAvatar(e) {
        const { field, nameField } = this.props;
        if (_.isArray(e)) {
            this.setState({
                isShowImgPicker: false
            });

            this.props.updateData({
                [field]: e
            });

            return;
        }
        this.setState({
            currentUrl: e.dataItem.url,
            isShowImgPicker: false,
            nameFile: e.dataItem.name
        });

        this.props.updateData({
            [field]: e.dataItem.url,
            [nameField]: e.dataItem.name
        });
    }

    hideFileChoosePopup = () => {
        this.setState({
            isShowImgPicker: false
        });
    };

    render() {
        const { value, editable, selectionMode, showRemoveBtn, btnLabel } = this.props;
        const { isShowImgPicker, nameFile } = this.state;
        return (
            <div className="wrapper-button-center">
                {selectionMode !== 'multiple' &&
                    <React.Fragment>
                        <div className={'mb-3 text-center'}>
                            {value !== '' ? (
                                <div><i className="dx-icon-card" style={{ fontSize: '10rem', color: '#1CB12C' }}></i></div>
                            ) : (<div><i className="dx-icon-warning" style={{ fontSize: '10rem', color: '#DC2A32' }}></i></div>)}
                        </div>
                        <p style={{ textAlign: 'center', marginBottom: '10px' }}>{nameFile}</p>
                    </React.Fragment>
                }

                {editable && (
                    <div className="d-flex justify-content-center mb-2">
                        <Button
                            text={i18n.t(btnLabel)}
                            onClick={this.handleUploadImg}
                        />
                        {
                            showRemoveBtn &&
                            <Button
                                className='ml-3'
                                text={i18n.t('base.remove')}
                                onClick={this.handleRemoveImg}
                            />
                        }
                    </div>
                )}
                {isShowImgPicker && (
                    <FileChoosePopup
                        hidePopup={this.hideFileChoosePopup}
                        isShowPopup={this.state.isShowImgPicker}
                        allowedExt={['.pdf', '.doc', '.docx', 'xls', 'xlsx']}
                        onFileSelected={this.onSetAvatar}
                        selectionMode={selectionMode}
                    />
                )}
            </div>
        );
    }
}

CVBox.propTypes = {
    field: PropTypes.string,
    updateData: PropTypes.func,
    value: PropTypes.string,
    editable: PropTypes.bool,
    selectionMode: PropTypes.string,
    showRemoveBtn: PropTypes.bool
};

export default CVBox;
