import React, { Component } from 'react';
import { WEEKDAYS, MONTHS_OF_CONTRACT } from '../../../constants/Config';
import { SelectBox } from 'devextreme-react';
import WeekdayTimeForm from '../../../modules/workAssignment/components/WeekdayTimeForm';
import Transformer from '../../../utils/Transformer';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import * as _ from 'lodash';
import moment from 'moment';

class WeekdaysGroupCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: [],
            selectedDays: [],
            listMonths: {},
            months: 0,
            showUpdateTimeForm: false,
            workTimes: [],
            weekDay: '',
            startTime: moment(new Date()).set({ hour: 8, minute: 0 }).format('YYYY-MM-DD[T]HH:mm:ss'),
            endTime: moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss')
        };
    }

    componentDidMount() {
        this.initData();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.serviceNote.workTimes !== state.workTimes) {
            return {
                workTimes: props.serviceNote.workTimes
            };
        } else return false;
    }

    initData = () => {
        const { currentLanguage, value, id, months, languages, serviceNote } = this.props;
        const datas = WEEKDAYS.map(item => {
            item.convertName = Transformer.convertJSONString(item.name, currentLanguage);
            item.value = id ? (!!value.includes(item.code)) : false;
            return item;
        });

        let listMonths = [];
        languages.forEach((element) => {
            const newArray = [];

            for (const item of MONTHS_OF_CONTRACT) {
                const i = Object.assign({}, item);
                const nameParse = Transformer.IsJsonString(i.name)
                    ? Transformer.convertJSONString(i.name, element.languageId)
                    : i.name;

                i.name = nameParse;
                newArray.push(i);
            }

            listMonths = { ...listMonths, [element.languageId]: newArray };
        });

        this.setState({
            datas,
            months,
            listMonths,
            workTimes: serviceNote.workTimes,
            selectedDays: value ? value.split('-') : []
        });
    }

    handleValueChanged = (data) => {
        const { field } = this.props;
        let newSelectedDays = [...this.state.selectedDays];
        // const workTimes = [...this.state.workTimes];
        let months = this.state.months;
        if (_.has(data, 'months')) {
            months = data.months;
        } else if (_.has(data, 'weekDays')) {
            if (!this.state.selectedDays.includes(data.weekDays)) {
                newSelectedDays.push(data.weekDays);
            } else {
                newSelectedDays = _.filter(newSelectedDays, item => item !== data.weekDays);
            }
        } else if (_.has(data, 'workTime')) {
            this.props.updateData({
                wdWorkTime: { ...data.workTime }
            });
        }
        this.setState({
            selectedDays: newSelectedDays,
            months
        }, () => {
            if (this.state.months > 0 && this.state.selectedDays.length > 0) {
                if ((_.has(data, 'months'))) {
                    this.props.updateData({
                        months: this.state.months
                    });
                } else if ((_.has(data, 'weekDays'))) {
                    this.props.updateData({
                        [field]: newSelectedDays.join('-'),
                        months: this.state.months
                    });
                }
                this.props.updateData({
                    formValidate: {
                        [field]: true
                    }
                });
            }

            if (this.state.selectedDays.length === 0) {
                if (_.has(data, 'weekDays')) {
                    this.props.updateData({
                        [field]: '',
                        months: this.state.months
                    });
                }
                this.props.updateData({
                    formValidate: {
                        [field]: false
                    }
                });
            }
        });
    }

    handleChangeWorkTime = (data) => {
        this.props.updateData({
            wdWorkTime: { ...data }
        });
    }

    showTimeEditForm = (day) => {
        this.setState({
            showUpdateTimeForm: true,
            weekDay: day
        });
    }

    hideTimeEditForm = () => {
        this.setState({
            showUpdateTimeForm: false,
            weekDay: ''
        });
    }

    render() {
        const { datas, months, listMonths, selectedDays, showUpdateTimeForm, startTime, endTime, weekDay, workTimes } = this.state;
        const { validatorValue, i18nLabel, currentLanguage, readOnly } = this.props;
        return (
            <React.Fragment>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>
                        {i18n.t('serviceNote.form.selectMonths') + ':'}
                        {validatorValue &&
                            validatorValue.includes('require') && (<span style={{ color: 'red' }}> *</span>)}
                    </div>
                    <div className={'dx-field-value'}>
                        <SelectBox
                            dataSource={listMonths[currentLanguage]}
                            valueExpr={'value'}
                            displayExpr={'name'}
                            value={months}
                            disabled={readOnly}
                            onValueChanged={(e) => this.handleValueChanged({ months: e.value })}
                        />
                    </div>
                </div>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel) + ':'}
                        {validatorValue &&
                            validatorValue.includes('require') && (<span style={{ color: 'red' }}> *</span>)}
                    </div>
                    <div className={'dx-field-value'}>
                        <div className='weekdays-container'>
                            {
                                !!months && datas.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`weekdays-item ${selectedDays.includes(item.code) ? 'dx-theme-accent-as-background-color' : 'grayBG'} ${readOnly ? 'disabled-click' : ''}`}
                                        >
                                            <div
                                                className='info-container'
                                                onClick={() => this.handleValueChanged({ weekDays: item.code })}>
                                                <p className='item-name'><b>{item.convertName}</b></p>
                                                <p className='item-time'>
                                                    <i id={`${item.code}S`}>--:--</i><br />
                                                    <i id={`${item.code}E`}>--:--</i>
                                                    {
                                                        workTimes.forEach(wTime => {
                                                            if (item.code === wTime.code) {
                                                                const startWorkTime = document.getElementById(`${item.code}S`);
                                                                const endWorkTime = document.getElementById(`${item.code}E`);
                                                                if (startWorkTime) { startWorkTime.innerHTML = `${moment(wTime.startTime).format('HH:mm')}`; }
                                                                if (endWorkTime) { endWorkTime.innerHTML = `${moment(wTime.endTime).format('HH:mm')}`; }
                                                            }
                                                        })
                                                    }
                                                </p>
                                            </div>
                                            {
                                                selectedDays.includes(item.code) &&
                                                <div className='btn-container'>
                                                    <i
                                                        className='dx-icon dx-icon-clock'
                                                        onClick={() => this.showTimeEditForm(item.code)}
                                                    ></i>
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                {
                    showUpdateTimeForm &&
                    <WeekdayTimeForm
                        otherProps={{ maxWidth: '650px', maxHeight: '350px' }}
                        hideForm={this.hideTimeEditForm}
                        changeWorkTime={this.handleValueChanged}
                        weekDay={weekDay}
                        reloadPage={this.hideTimeEditForm}
                        // serviceNoteId={serviceNoteId}
                        serviceNoteDetail={{ startTime, endTime }} />
                }
            </React.Fragment>

        );
    }
}

WeekdaysGroupCheckbox.propTypes = {
    currentLanguage: PropTypes.string,
    value: PropTypes.any,
    validatorValue: PropTypes.any,
    i18nLabel: PropTypes.string,
    field: PropTypes.string,
    updateData: PropTypes.func,
    id: PropTypes.any,
    languages: PropTypes.any,
    serviceNote: PropTypes.any,
    months: PropTypes.number,
    readOnly: PropTypes.bool
};

export default WeekdaysGroupCheckbox;
