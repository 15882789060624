export const GROUPS_AREA_LIST = 'GROUPS_AREA_LIST'
export const GROUPS_AREA_TREE = 'GROUPS_AREA_TREE'
export const GROUPS_AREA_DETAIL = 'GROUPS_AREA_DETAIL'
export const GROUPS_AREA_INSERT = 'GROUPS_AREA_INSERT'
export const GROUPS_AREA_UPDATE = 'GROUPS_AREA_UPDATE'
export const GROUPS_AREA_DELETE = 'GROUPS_AREA_DELETE'

export default {
    GROUPS_AREA_LIST,
    GROUPS_AREA_TREE,
    GROUPS_AREA_DETAIL,
    GROUPS_AREA_INSERT,
    GROUPS_AREA_UPDATE,
    GROUPS_AREA_DELETE

}
