import { PAGE_SIZE } from '../../../constants/Config';
import {
    SHIFT_DELETE,
    SHIFT_DETAIL,
    SHIFT_INSERT,
    SHIFT_LIST,
    SHIFT_UPDATE,
    SHIFT_GET_ALL,
    SHIFT_PRICES_GET_ALL,
    SHIFT_PRICES_BY_DATE,
    SHIFT_PRICES_RESET
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: null,
    byBranch: [],
    shiftPricesByService: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case SHIFT_LIST:
            return list(state, payload);
        case SHIFT_DETAIL:
            return detail(state, payload);
        case SHIFT_UPDATE:
            return update(state, payload);
        case SHIFT_INSERT:
            return insert(state, payload);
        case SHIFT_DELETE:
            return deletes(state, payload);
        case SHIFT_GET_ALL:
            return getAll(state, payload);
        case SHIFT_PRICES_GET_ALL:
            return getAllShiftPrices(state, payload);
        case SHIFT_PRICES_BY_DATE:
            return getAllShiftByDate(state, payload);
        case SHIFT_PRICES_RESET:
            return reset(state);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function getAll(state, payload) {
    state = Object.assign({}, state, { byBranch: payload });
    return state;
}
function getAllShiftPrices(state, payload) {
    state = Object.assign({}, state, { shiftPricesByService: payload });
    return state;
}

function getAllShiftByDate(state, payload) {
    state = Object.assign({}, state, { shiftPricesByService: payload });
    return state;
}

function reset(state) {
    state = Object.assign({}, state, { shiftPricesByService: [] });
    return state;
}
export default reducer;
