export const viH = {
    holiday: {
        serviceId: 'Dịch vụ chính',
        price: 'Giá cơ bản',
        priceWeekend: 'Giá cuối tuần',
        description: 'Mô tả',
        form: {
            insertTitle: 'Thêm mới ngày lễ',
            updateTitle: 'Chỉnh sửa thông tin ngày lễ'
        }
    }
};
