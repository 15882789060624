export const enW = {
    // W
    website: {
        webService: {
            title: 'Website management',
            infoTitle: 'Service Website Information',
            titleName: 'Title',
            name: 'Name',
            seoLink: 'Seo Link',
            serviceTitle: 'Service Title Detail',
            videoUrl: 'VideoUrl',
            alt: 'Alt',
            description: 'Description',
            metaTitle: 'Meta title',
            metaDescription: 'Meta description',
            metaKeyword: 'Meta keyword',
            icon: 'Icon',
            image: 'Image',
            thumbnail: 'Thumbnail',
            order: 'Order',
            addNew: 'Add new',
            insertTitle: 'Add new website service',
            updateTitle: 'Edit website service',
            insertServiceTitle: 'Add new website title service',
            updateServiceTitle: 'Edit website title service',
            webServiceTitle: 'Title',
            insertServiceItemMetaTitle: 'Add New Service Detail Title',
            updateServiceItemMetaTitle: 'Edit Service Detail Title',
            tags: 'Tags',
            edit: 'Edit',
            isActive: 'Active',
            pageUnit: 'services',
            serviceTitleMeta: {
                title: 'List of Service Detail Titles',
                pageUnit: 'titles',
                insertTitle: 'Add New Service Detail Title',
                updateTitle: 'Edit Service Detail Title',
                isActive: 'Active',
                item: {
                    title: 'List of Title Items',
                    pageUnit: 'items'
                }
            },
            serviceFaqMeta: {
                title: 'List of Service Detail Faqs',
                pageUnit: 'faqs',
                image: 'Image'
            }
        },
        webProduct: {
            insertTitle: 'Add new product',
            updateTitle: 'Edit product',
            title: 'Product website manager',
            isHomePage: 'Home page',
            isHot: 'Hot',
            isActive: 'Active',
            detail: 'Detail',
            prodName: 'Product name',
            productTranslation: 'Product translations',
            productImages: 'Product images',
            element: 'Element',
            label: 'Label',
            uses: 'Uses',
            userManual: 'User manual',
            prodCode: 'Code',
            unit: 'Product',
            updateMeta: 'Add new meta',
            insertMeta: 'Edit meta',
            content: 'Content',
            capacity: 'Capacity',
            category: {
                title: 'List of Website Product Categories',
                pageUnit: 'categories',
                insertTitle: 'Add New Website Product Category',
                updateTitle: 'Edit Website Product Category',
                name: 'Name',
                seoLink: 'Seo Link',
                description: 'Description',
                metaTitle: 'Meta Title',
                metaDescription: 'Meta Description',
                altImages: 'Alt Image',
                bannerImages: 'Banner Image',
                bannerImagesMobile: 'Mobile Banner Image',
                isActive: 'Active',
                isHot: 'Hot',
                isHomepage: 'HomePage'
            },
            translation: {
                pageUnit: 'translations',
                insertTitle: 'Add New Product Translation',
                updateTitle: 'Edit Product Translation',
                title: 'List of Product Translation'
            },
            likeCount: 'Like count',
            viewCount: 'View count',
            commentCount: 'Comment Count',
            metaDescription: 'Meta Description',
            metaTitle: 'Meta Title',
            salePrice: 'Sale Price'
        },
        productCategories: {
            title: 'Product categories',
            insertTitle: 'Add new product categories',
            updateTitle: 'Edit product categories',
            name: 'Name',
            banner: 'Banner',
            mobileBanner: 'Mobile banner'
        },
        album: {
            listTitle: 'List of Photo and Video Albums',
            pageUnit: 'albums',
            name: 'Name',
            description: 'Description',
            seoLink: 'SEOLink',
            metaTitle: 'Meta Title',
            metaDescription: 'Meta Description',
            metaKeyword: 'Meta Keyword',
            type: 'Type',
            thumbnail: 'Thumbnail',
            alt: 'Alt',
            order: 'Order',
            isActive: 'Active',
            isHomePage: 'HomePage',
            isHot: 'Hot',
            isDefault: 'Default',
            chooseAlbum: 'Choose Album',
            photo: {
                listTitle: 'List of Photos',
                pageUnit: 'photos',
                title: 'Title',
                description: 'Description',
                alt: 'Alt',
                order: 'Order',
                url: 'Url',
                insertTitle: 'Add New Image',
                updateTitle: 'Edit Image'
            },
            video: {
                listTitle: 'List of Videos',
                pageUnit: 'video',
                title: 'Title',
                description: 'Description',
                alt: 'Alt',
                type: 'Video Type',
                url: 'Url',
                lastUpdateHomePage: 'Last Update',
                thumbnail: 'Thumbnail',
                videoLinkId: 'Video Link Id',
                order: 'Order',
                isActive: 'Active',
                isDefault: 'Default',
                isHomePage: 'HomePage',
                insertTitle: 'Add New Video',
                updateTitle: 'Edit Video'
            }
        },
        list: {
            title: 'List of Websites',
            unit: 'websites',
            name: 'Website Name',
            phone: 'Phone Number',
            address: 'Address',
            note: 'Note',
            isDefault: 'Default',
            isActive: 'Active',
            languagesList: 'Languages'
        },
        form: {
            insertTitle: 'Add New Website',
            updateTitle: 'Edit Website',
            name: 'Website Name',
            phone: 'Phone Number',
            address: 'Address',
            note: 'Note',
            isDefault: 'Default',
            isActive: 'Active',
            title: 'Title',
            subTitle: 'Sub-Title'
        },
        faq: {
            list: {
                title: 'List of FAQs',
                unit: 'questions',
                question: 'Question',
                answer: 'Answer',
                website: 'Website',
                faqGroup: 'Faq Group',
                photo: 'Photo',
                alt: 'Alt',
                isActive: 'Active',
                group: 'FAQ Group',
                order: 'Order'
            },
            form: {
                insertTitle: 'Add New FAQ',
                updateTitle: 'Edit FAQ',
                question: 'Question',
                answer: 'Answer',
                website: 'Website',
                faqGroup: 'Faq Group',
                photo: 'Photo',
                alt: 'Alt',
                isActive: 'Active',
                title: 'Title'
            }
        },
        faqGroup: {
            list: {
                title: 'List of FAQ Groups',
                unit: 'groups',
                name: 'FAQ Group Name',
                description: 'Description',
                thumbnail: 'Thumbnail',
                alt: 'Alt',
                seoLink: 'SEO Link',
                order: 'Order',
                metaTitle: 'meta Title',
                metaKeyword: 'meta Keyword',
                metaDescription: 'meta Description',
                isActive: 'Active'
            },
            form: {
                name: 'FAQ Group Name',
                description: 'Description',
                thumbnail: 'Thumbnail',
                isDefault: 'Default',
                alt: 'Alt',
                seoLink: 'SEO Link',
                order: 'Order',
                metaTitle: 'meta Title',
                metaKeyword: 'meta Keyword',
                metaDescription: 'meta Description',
                isActive: 'Active'
            }
        },
        banner: {
            list: {
                title: 'List of banners',
                unit: 'banners',
                bannerItemsTitle: 'List of Banner-items',
                bannerItemsUnit: 'banner-items',
                name: 'Name',
                image: 'Image',
                imageMobile: 'Image for mobile',
                url: 'URL',
                alt: 'Alternative name',
                note: 'Description',
                status: 'Status',
                position: 'Position',
                type: 'Type',
                isActive: 'Active'
            },
            form: {
                insertTitle: 'Add new banner',
                updateTitle: 'Edit banner',
                bannerPosition: 'Banner position',
                bannerType: 'Banner type',
                insertBannerItem: 'Add banner item',
                updateBannerItem: 'Edit banner item'
            },
            error: {
                bannerItemsRequired: 'Please insert banner item',
                imageRequired: 'Please choose picture',
                urlPattern: 'Please enter a valid url',
                urlRequired: 'Please enter url',
                invalidName: 'is already taken',
                positionRequired: 'Please select banner position',
                typeRequired: 'Please select banner type'
            }
        },
        news: {
            list: {
                title: 'List of news',
                unit: 'news',
                newsTitle: 'Title',
                category: 'Category',
                createDate: 'Created at',
                viewCount: 'View',
                status: 'Status',
                isHot: 'Hot',
                isHomepage: 'Home Page',
                userCreate: 'Creator',
                seoLink: 'SEO Link',
                editNews: 'Edit news',
                isActive: 'Active',
                description: 'Description',
                type: 'Type',
                note: 'Note',
                startTime: 'Start time',
                declineReasons: 'Decline Reasons',
                selectType: 'Select type'
            },
            form: {
                insertTitle: 'Add new news',
                updateTitle: 'Edit news',
                description: 'Description',
                source: 'Source',
                content: 'Content',
                altImage: 'Alternative name',
                seoLink: 'SEO Link',
                metaKeyword: 'Meta keyword',
                metaTitle: 'Meta title',
                metaDescription: 'Meta description'
            },
            error: {
                urlRequired: 'Please enter url',
                newsRequired: 'Please select news',
                categoryRequired: 'Please select news category',
                seoLinkRequired: 'Please enter SEOlink',
                typeRequired: 'Please select type',
                startTimeRequired: 'Please select start time'
            }
        },
        info: {
            logo: 'Logo',
            logoFooter: 'Logo Footer',
            favicon: 'Favicon',
            zaloId: 'Zalo Id',
            brand: 'Brand',
            instruction: 'Instruction',
            metaTitle: 'Meta Title',
            metaDescription: 'Meta Description',
            ipBlocking: 'Ip Blocking',
            metaKeyword: 'MetaKeyword',
            fanpage: 'Fanpage',
            googleMap: 'GoogleMap',
            hotline: 'Hotline',
            logoMobile: 'Logo Mobile',
            trackingHeader: 'Tracking Header',
            trackingBody: 'Tracking Body',
            googleAnalytics: 'Google Analytics',
            commentNotAllow: 'Comment Not Allow',
            fanPageFacebookId: 'Fanpage Facebook Id'
        },
        socialNetwork: {
            list: {
                title: 'List of Social network',
                unit: 'social network',
                image: 'Image',
                socialNetwork: 'Social Network',
                url: 'URL',
                icon: 'Icon',
                order: 'Order',
                description: 'Description',
                isActive: 'Active'
            },
            form: {
                insertTitle: 'Insert Social Network',
                updateTitle: 'Update Social Network'
            }
        },
        languages: {
            list: {
                title: 'List of Website Languages',
                unit: 'Languages',
                languageId: 'Language ID',
                name: 'Name',
                default: 'Default',
                active: 'Active'
            }
        },
        menus: {
            list: {
                title: 'List of Menus',
                unit: 'menus',
                name: 'Name',
                position: 'Position',
                description: 'Description',
                isActive: 'Active'
            },
            form: {
                insertMenuItem: 'Add new menu-item',
                updateMenuItem: 'Edit menu-item',
                objectType: 'Select object Type',
                objectId: 'Select object',
                parentId: 'Select parent menu',
                icon: 'Icon',
                news: 'Select news'
            },
            error: {
                objectTypeRequired: 'Please select object type',
                urlRequired: 'Please enter url',
                newsRequired: 'Please select news',
                categoryRequired: 'Please select news category',
                seoLinkRequired: 'Please enter SEOlink',
                productRequired: 'Please select product',
                productCategoryRequired: 'Please select product category',
                serviceRequired: 'Please select service',
                faqRequired: 'Please select FAQ group',
                feedbackRequired: 'Please select customer feedback',
                albumRequired: 'Please select album'
            }
        },
        hardContent: {
            name: 'Name',
            code: 'Code',
            description: 'Description',
            url: 'Url',
            image: 'Image',
            alt: 'Alt',
            order: 'Order',
            isActive: 'Active',
            listTitle: 'List of Hard Contents',
            pageUnit: 'contents',
            insertTitle: 'Insert Hard Content',
            updateTitle: 'Edit Hard Content',
            detail: {
                insertTitle: 'Add New Hard Content Detail',
                updateTitle: 'Edit Hard Content Detail',
                listTitle: 'List of Hard Content Details',
                pageUnit: 'hard content details'
            }
        },
        branchContact: {
            name: 'Name',
            workTime: 'Working Time',
            logo: 'Logo',
            link: 'Link',
            googleMap: 'Google Map',
            address: 'Address',
            description: 'Description',
            order: 'Order',
            isActive: 'Active',
            isDefault: 'Default',
            emailList: 'Email',
            phoneList: 'Phone Number',
            isOffice: 'Office',
            website: 'Website',
            listTitle: 'List of Branch Contacts',
            pageUnit: 'contacts',
            insertTitle: 'Add New Branch Contact',
            updateTitle: 'Edit Branch Contact',
            images: 'List of Images',
            detail: {
                insertTitle: 'Add New Branch Contact Detail',
                updateTitle: 'Edit Branch Contact Detail',
                listTitle: 'List of Branch Contact Details',
                pageUnit: 'contact details',
                contactType: 'Contact Type',
                contactValue: 'Contact Value'
            }
        },
        newsCategory: {
            list: {
                title: 'List of News categories',
                unit: 'categories',
                metaTitle: 'Meta title',
                metaKeyword: 'Meta keyword',
                metaDescription: 'Meta description',
                order: 'Order'
            },
            form: {
                insertTitle: 'Add new category',
                updateTitle: 'Edit category',
                parentCategory: 'Parent Category',
                images: 'Image',
                imagesMobile: 'Mobile Image'
            }
        },
        partner: {
            listTitle: 'List of Partners',
            pageUnit: 'partners',
            name: 'Name',
            description: 'Description',
            thumbnail: 'Thumbnail',
            alt: 'Alt',
            url: 'Url',
            order: 'Order',
            isActive: 'Active'
        },
        customerFeedback: {
            listTitle: 'List of Customer Feedbacks',
            pageUnit: 'feedbacks',
            name: 'Name',
            avata: 'Avatar',
            description: 'Description',
            position: 'Position',
            thumbnail: 'Thumbnail',
            alt: 'Alt',
            rate: 'Rate',
            url: 'Url',
            order: 'Order',
            isActive: 'Active',
            seoLink: 'SEO Link'
        },
        feedback: {
            listTitle: 'List of Feebacks',
            pageUnit: 'feedbacks',
            email: 'Email',
            fullName: 'Fullname',
            phoneNumber: 'Phone Number',
            title: 'Title',
            content: 'Content',
            isView: 'View',
            description: 'Description'
        },
        subscribe: {
            listTitle: 'List of Subscribes',
            pageUnit: 'subscribes',
            email: 'Email'
        },
        booking: {
            title: 'Booking manager',
            name: 'Customer Name',
            email: 'Email',
            address: 'Address',
            phone: 'Phone Number',
            description: 'Description',
            appointmentDate: 'Appointment Date',
            appointmentTime: 'Appointment Time',
            editTitle: 'Edit booking',
            approvedStatus: 'Status',
            appointmentDateError: 'Appointment time must be a future time'
        },
        project: {
            title: 'Project Manager',
            infoTitle: 'Project Information',
            projectCategory: 'Project category',
            project: 'Project',
            projectTitle: 'Title',
            description: 'Description',
            image: 'Image',
            logo: 'Logo',
            classify: 'Field',
            isHomePage: 'Is Home Page',
            isActive: 'Is Active',
            year: 'Year',
            client: 'Client',
            url: 'url',
            albumType: 'Album Type',
            services: 'Services',
            videoLinkId: 'Video Id',
            titleColor: "Tilte's Color",
            updateTitle: 'Edit Project detail',
            insertTitle: 'Add new Project detail',
            albumUpdateTitle: 'Edit Project album item',
            albumInsertTitle: 'Add new Project album item',
            detailList: 'Project details list',
            albumList: 'Project album list',
            pageUnit: 'details',
            projectCategoryInfoTitle: 'Project Category Information',
            projectCategoryTitle: 'Project Category Management',
            categoryRequired: 'Please select project category',
            videoLinkRequired: 'Please enter video link',
            imageRequired: 'Please choose image',
            videoTypeRequired: 'Please select video type',
            typeRequired: 'Please select item type'
        },
        job: {
            name: 'Name',
            description: 'Description',
            type: 'Job type',
            order: 'Order',
            salaryRange: 'Salary Range',
            isActive: 'Is Active',
            title: 'Job management',
            infoTitle: 'Job Information',
            job: 'Job',
            titlee: 'Title',
            image: 'Image',
            pageUnit: 'vị trí tuyển dụng',
            seoLink: 'SEO Link',
            requirement: 'Requirements',
            location: 'Location',
            position: 'Position',
            quantity: 'Quantity',
            benefit: 'Benefit',
            workingTime: 'Working Time'
        },
        recruitment: {
            title: 'Recruitment Information',
            fullName: 'Full Name',
            jobsRequired: 'Please select job to apply',
            cvRequired: 'Please select candidate cv',
            recruitment: 'Recruitment',
            editRecruitment: 'Edit Recruitment',
            email: 'Email',
            jobsDetail: 'Job Detail',
            phoneNumber: 'Phone number',
            isView: 'Viewed',
            description: 'description',
            nameInvalid: 'Name can not contain number or special character',
            phoneInvalid: 'Invalid phone number',
            isViewStatus: 'Status',
            job: 'Job'
        }
    },
    warehouse: {
        list: {
            title: 'List of warehouse ',
            warehouseName: 'Warehouse name',
            manageconfig: 'Manage config',
            warehouse: 'Warehouse',
            receiptWarehouse: 'Receipt warehouse',
            deliveryWarehouse: 'Delivery warehouse'
        },
        form: {
            insertTitle: 'Add new warehouse',
            info: 'Warehouse info',
            supplier: 'Supplier',
            address: 'Address',
            staff: 'Staff',
            receiptNumber: 'Receipt number',
            date: 'Date',
            invoice: 'Invoice',
            invoiceDate: 'Invoice date',
            note: 'Note',
            deliever: 'Deliever'
        },
        menu: {
            manageConfig: {
                title: 'List warehouse manage config',
                insertTitle: 'Add new warehouse manage config',
                updateTitle: 'Edit warehouse manage config',
                warehouseManageConfig: 'Warehouse manage config',
                selectTitle: 'Select permissions'
            },
            productConfig: {
                title: 'List warehouse manage config',
                insertTitle: 'Add new warehouse product config',
                updateTitle: 'Edit warehouse product config',
                warehouseProductConfig: 'Warehouse product config',
                selectTitle: 'Select product'
            }
        },
        error: {
            title: 'You do not have warehouse access permission'
        },
        title: {
            listNewReceipt: 'List new receipt',
            receiptDetail: 'Receipt detail',
            updateReceipt: 'Update receipt detail'
        },
        print: {
            noteCreator: 'Creator',
            deliver: 'Deliver',
            stockKeeper: 'Stock keeper',
            chiefAccountant: 'Chief accountant',
            receiver: 'Receiver',
            exporter: 'Exporter',
            importer: 'Importer',
            departmentOfGoods: 'Member of department of goods'
        }
    },
    workAssignment: {
        list: {
            startTime: 'Start time',
            endTime: 'End time',
            agent: 'Agent',
            cashier: 'Cashier',
            checkinTime: 'Check-in time',
            checkoutTime: 'Check-out time'
        }
    }
};
