import {
    GROUPS_SERVICE_DETAIL, GROUPS_SERVICE_INSERT, GROUPS_SERVICE_LIST, GROUPS_SERVICE_TREE,
    GROUPS_SERVICE_DELETE, GROUPS_SERVICE_UPDATE,
    GROUPS_SERVICE_GET_ALL
} from './action-types';
import { PAGE_SIZE } from '../../../../constants/Config';

const initialState = {
    data: [],
    totalRows: 0,
    currentPAGE: 0,
    pageSize: PAGE_SIZE,
    tree: [],
    action: null,
    item: null,
    suggestion: null
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GROUPS_SERVICE_LIST:
            return list(state, payload);
        case GROUPS_SERVICE_TREE:
            return tree(state, payload);
        case GROUPS_SERVICE_DETAIL:
            return detail(state, payload);
        case GROUPS_SERVICE_UPDATE:
            return update(state, payload);
        case GROUPS_SERVICE_INSERT:
            return insert(state, payload);
        case GROUPS_SERVICE_DELETE:
            return deletes(state, payload);
        case GROUPS_SERVICE_GET_ALL:
            return getAll(state, payload);
        default:
            return state;
    }
};

function getAll(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

function list(state, payload) {
    return { ...state, ...payload };
}

function tree(state, payload) {
    state = Object.assign({}, state, {
        tree: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
    const item = { ...state.item, ...payload };
    return Object.assign({}, state, { data, item });
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload);

    return Object.assign({}, state, { data });
}

export const getServiceGroupsList = state => state.groupServices.data;

export default reducer;
