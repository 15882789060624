import {
    RELIGION_DELETE, RELIGION_DETAIL, RELIGION_INSERT, RELIGION_LIST, RELIGION_RESPONSE,
    RELIGION_UPDATE, RELIGION_GETALL, RELIGION_BY_COUNTRY
} from "./action-types";


export function list(payload) {
    return {
        type: RELIGION_LIST,
        payload
    }
}

export function detail(payload) {
    return {
        type: RELIGION_DETAIL,
        payload
    }
}

export function update(payload) {
    return {
        type: RELIGION_UPDATE,
        payload
    }
}

export function deletes(payload) {
    return {
        type: RELIGION_DELETE,
        payload
    }
}

export function insert(payload) {
    return {
        type: RELIGION_INSERT,
        payload
    }
}

export function responseError(payload) {
    return {
        type: RELIGION_RESPONSE,
        payload
    }
}
export function getAll(payload) {
    return {
        type: RELIGION_GETALL,
        payload
    }
}

export function listByCountry(payload) {
    return {
        type: RELIGION_BY_COUNTRY,
        payload
    }
}


