import { NAILSPA_URL } from '../../constants/Config';
import Http from '../../utils/Http';
import { spinnerLoading } from '../../common/spinner/store/action';
import * as serviceNoteDetailActions from './store/actions';
import Transformer from '../../utils/Transformer';
import ServiceNoteDetailViewModel from './ServiceNoteDetailViewModel';

export function resetServiveNoteDetail() {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(serviceNoteDetailActions.reset());
            resolve();
        }
        ));
}

export function resetServiceNoteDetailList() {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(serviceNoteDetailActions.resetList());
            resolve();
        }
        ));
}

export function selectServiveNoteDetail(serviceNoteDetailId) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(serviceNoteDetailActions.select(serviceNoteDetailId));
            resolve();
        }
        ));
}

export function serviceNoteDetailDeleteRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/${payload.branchId}/${payload.serviceNoteId}/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.repsonse.data.message);
                });
        })
    );
}

export function getDetailServiceNoteDetail(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/${payload.branchId}/${payload.serviceNoteId}/${payload.id}`;

            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false));
                dispatch(serviceNoteDetailActions.detail(res.data.data));
                resolve(res.data.data);
            }).catch((error) => {
                dispatch(spinnerLoading(false));
                reject(error);
            });
        })
    );
}

export function serviceNoteDetailInsertRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/${payload.branchId}/${payload.serviceNoteId}`;

            const serviceNoteDetailUpdate = Object.assign({}, payload);
            if (serviceNoteDetailUpdate.discountByPercent) {
                serviceNoteDetailUpdate.discountPrice = payload.discountPrice;
            }
            serviceNoteDetailUpdate.workTime = payload.workTime * 60;
            serviceNoteDetailUpdate.discountNote = JSON.stringify(payload.discountNote);
            serviceNoteDetailUpdate.note = JSON.stringify(payload.note);
            serviceNoteDetailUpdate.statusBefore = (typeof payload.statusBefore === 'object') ? JSON.stringify(payload.statusBefore) : payload.statusBefore;
            serviceNoteDetailUpdate.statusAfter = (typeof payload.statusAfter === 'object') ? JSON.stringify(payload.statusAfter) : payload.statusAfter;

            Http.post(url, serviceNoteDetailUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(serviceNoteDetailActions.insert(new ServiceNoteDetailViewModel({ ...serviceNoteDetailUpdate, id: res.data.data }, payload.languageId)));
                    resolve(res.data);
                })
                .catch((error) => {
                    console.log('error', error);
                    // dispatch(spinnerLoading(false));
                    // if (error.response.data.code === -99) {
                    //     reject(error.response.data.code);
                    // }
                    // if (error.response.data.code === -15) {
                    //     const returnErr = { code: '-15', message: error.response.data.message };
                    //     reject(returnErr);
                    // }

                    // reject(error.response.data.message);
                });
        })
    );
}

export function insertListServiceNoteDetail(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/list/${payload.branchId}/${payload.idServiceNote}`;

            Http.post(url, payload.serviceNoteDetails)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                }).catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        })
    );
}

export function serviceNoteDetailUpdateRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/${payload.branchId}/${payload.serviceNoteId}/${payload.id}`;

            payload.totalAmount = payload.totalPrice;

            const serviceNoteDetailData = Object.assign({}, payload);
            serviceNoteDetailData.workTime = payload.workTime * 60;
            serviceNoteDetailData.discountNote = (typeof payload.discountNote === 'object') ? JSON.stringify(payload.discountNote) : payload.discountNote;
            serviceNoteDetailData.note = (typeof payload.note === 'object') ? JSON.stringify(payload.note) : payload.note;
            // serviceNoteDetailData.statusBefore = (typeof payload.statusBefore === 'object') ? JSON.stringify(payload.statusBefore) : payload.statusBefore;
            // serviceNoteDetailData.statusAfter = (typeof payload.statusAfter === 'object') ? JSON.stringify(payload.statusAfter) : payload.statusAfter;
            if (payload.serviceNoteDetailExtras.length > 0) {
                serviceNoteDetailData.serviceNoteDetailExtras = payload.serviceNoteDetailExtras.map(item => {
                    return item.serviceId;
                });
            }
            if (payload.serviceNoteDetailStaffs.length > 0) {
                serviceNoteDetailData.serviceNoteDetailStaffs = payload.serviceNoteDetailStaffs.map(staff => staff.userId);
            }

            Http.put(url, serviceNoteDetailData)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    // dispatch(serviceNoteDetailActions.update(payload))
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        })
    );
}

export function serviceNoteDetailCanccelRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/update-status-cancel/${payload.branchId}/${payload.serviceNoteId}/${payload.id}/${payload.isCancel}`;

            Http.put(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    // dispatch(serviceNoteDetailActions.update(payload))
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        })
    );
}

export function serviceNoteDetailStatusUpdateRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/update-status/${payload.branchId}/${payload.serviceNoteId}/${payload.id}`;

            const serviceNoteDetailData = Object.assign({}, payload.statusData);

            serviceNoteDetailData.comment = JSON.stringify(serviceNoteDetailData.comment);
            serviceNoteDetailData.note = JSON.stringify(serviceNoteDetailData.note);
            Http.put(url, serviceNoteDetailData)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    // dispatch(serviceNoteDetailActions.update(payload))
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        })
    );
}

export function serviceNoteDetailStatusGetDetailRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/detail-comment/${payload.branchId}/${payload.serviceNoteId}/${payload.id}/${payload.type}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    // dispatch(serviceNoteDetailActions.update(payload))
                    resolve(res.data.data);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        })
    );
}

export function serviceNoteDetailByCustomerGetAll(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const data = {
                branchId: payload.branchId,
                keyword: payload.keyword,
                page: payload.page,
                pageSize: payload.pageSize,
                customerId: payload.customerId,
                isPayment: payload.isPayment
            };
            if (payload.startDate) data.startDate = payload.startDate;
            if (payload.endDate) data.endDate = payload.endDate;

            const url = `${NAILSPA_URL}serviceNoteDetails?${Transformer.convertToPramsURI(data)}`;

            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false));
                const serviceNoteDetailList = Object.assign({}, {
                    data: res.data.data,
                    customerId: payload.customerId,
                    totalRows: res.data.totalRows
                });
                dispatch(serviceNoteDetailActions.listByCustomer(serviceNoteDetailList));
                resolve(res.data);
            }).catch((error) => {
                dispatch(spinnerLoading(false));
                reject(error);
            });
        })
    );
}

export function serviceNoteDetailMultipleStaffs(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNoteDetailStaffs/${payload.branchId}/${payload.serviceNoteDetailId}`;
            const serviceNoteDetailStaffs = payload.userId.map(item => ({ userId: item, concurrencyStamp: 'string' }));
            Http.post(url, { serviceNoteDetailStaffs })
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        })
    );
}

export function serviceNoteDetailStaff(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNoteDetailStaffs/by-service-note/${payload.branchId}/${payload.serviceNoteId}/${payload.userId}`;
            Http.post(url)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        })
    );
}

export function serviceNoteDetailStaffSingle(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNoteDetailStaffs/single/${payload.branchId}/${payload.serviceNoteDetailId}/${payload.userId}`;
            Http.post(url)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        })
    );
}

export function serviceNoteDetailStaffUpdate(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNoteDetailStaffs/user/${payload.branchId}/${payload.serviceNoteDetailId}/${payload.id}/${payload.userId}`;
            Http.put(url)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        })
    );
}

export function serviceNoteDetailStaffSingleInsert(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNoteDetailStaffs/single/${payload.branchId}/${payload.serviceNoteDetailId}/${payload.userId}`;
            Http.post(url)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        })
    );
}

export function deleteServiceNoteDetailStaff(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNoteDetailStaffs/${payload.branchId}/${payload.serviceNoteDetailId}/${payload.userId}`;

            Http.delete(url)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        })
    );
}

export function insertServiceNoteDetailStaff(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNoteDetailStaffs/${payload.branchId}/${payload.serviceNoteDetailId}`;

            Http.post(url, payload.data)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        })
    );
}

export function serviceNoteDetailStaffInsertSingleRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNoteDetailStaffs/single/${payload.branchId}/${payload.serviceNoteDetailId}`;

            Http.post(url, payload.data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        })
    );
}

export function serviceNoteDetailGetAll1(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNoteDetails/${payload.branchId}/${payload.serviceNoteId}`;

            Http.get(url)
                .then((res) => {
                    dispatch(serviceNoteDetailActions.list(res.data.map((detail) => new ServiceNoteDetailViewModel(detail, payload.languageId))));
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    );
}

export function getAllServiceNoteDetail(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/${payload.branchId}/${payload.serviceNoteId}`;

            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false));
                dispatch(serviceNoteDetailActions.getAll(res.data));
                resolve(res.data);
            }).catch((error) => {
                dispatch(spinnerLoading(false));
                reject(error);
            });
        })
    );
}

export function getAllWorkListServiceNoteDetail(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/work-list/${payload.branchId}/${payload.serviceNoteId}`;

            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false));
                dispatch(serviceNoteDetailActions.getAllWorkShifts(res.data));
                resolve(res.data);
            }).catch((error) => {
                dispatch(spinnerLoading(false));
                reject(error);
            });
        })
    );
}

export function updateServiceNoteDetailRemind(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}ServiceNoteDetails/update-remind/${payload.branchId}/${payload.serviceNoteId}/${payload.id}/${payload.isRemind}?remindedNote=${payload.remindedNote}`;

            Http.put(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        })
    );
}

export function storeStatus(data) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(serviceNoteDetailActions.storeStatus(data));
            resolve();
        });
}
