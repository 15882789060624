export const FEEDBACK_LIST = 'FEEDBACK_LIST'
export const FEEDBACK_DETAIL = 'FEEDBACK_DETAIL'
export const FEEDBACK_UPDATE = 'FEEDBACK_UPDATE'
export const FEEDBACK_INSERT = 'FEEDBACK_INSERT'
export const FEEDBACK_DELETE = 'FEEDBACK_DELETE'
export const FEEDBACK_RESPONSE = 'FEEDBACK_RESPONSE'
export const FEEDBACK_GETALL = 'FEEDBACK_GETALL'

export default {
    FEEDBACK_DELETE,
    FEEDBACK_DETAIL,
    FEEDBACK_INSERT,
    FEEDBACK_LIST,
    FEEDBACK_UPDATE,
    FEEDBACK_GETALL
}
