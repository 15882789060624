import React, { Component } from 'react';
import { TextBox } from 'devextreme-react';
import i18n from '../../../i18n';
import { GENDER } from '../../../constants/Config';
import PropTypes from 'prop-types';

class ReadOnlyGender extends Component {
    render() {
        const { value, i18nLabel, currentLanguageActive } = this.props;

        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>
                    {i18n.t(i18nLabel) + ':'}
                </div>

                <div className={'dx-field-value'}>
                    <TextBox
                        className={'multi-language'}
                        name={'name'}
                        value={Number.isInteger(value) ? JSON.parse(GENDER[value].name)[currentLanguageActive] : value}
                        readOnly={true}
                    />
                </div>
            </div>
        );
    }
}

ReadOnlyGender.propTypes = {
    i18nLabel: PropTypes.string,
    value: PropTypes.any,
    currentLanguageActive: PropTypes.string
};

export default ReadOnlyGender;
