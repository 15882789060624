
import { NAILSPA_URL } from '../../../constants/Config';
import Http from '../../../utils/Http';
import * as servicePkgActions from './store/actions';
import { spinnerLoading } from '../../../common/spinner/store/action';
import ServicePackageViewModel from './ServicePackageViewModel';

// Service package
export function setServicePackageDetails(id) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(servicePkgActions.setDetailsList(id));
            resolve();
        });
}

export function getServicePackageDetails() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(servicePkgActions.getDetailsList());
            resolve();
        });
}

export function servicePackageSearchRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}ServicePackages?keyword=${payload.keyword}`;
            url += payload.isActive !== null ? `&isActive=${payload.isActive}` : '';
            url += payload.page ? `&page=${payload.page}` : '';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '';
            url += payload.groupServiceId ? `&groupServiceId=${payload.groupServiceId}` : '';

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(servicePkgActions.list({
                        ...res.data,
                        data: res.data.data ? res.data.data.map((service) => new ServicePackageViewModel(service, payload.languageId)) : []
                    }));
                    resolve(res.data.data);
                })
                .catch(error => {
                    reject(error);
                    dispatch(spinnerLoading(false));
                });
        })
    );
}

export function servicePackageDeleteRequest(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}ServicePackages/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(servicePkgActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function servicePackageInsertRequest(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}ServicePackages`;
            const servicePackageData = Object.assign({}, payload);
            servicePackageData.name = (typeof payload.name === 'object') ? JSON.stringify(payload.name) : payload.name;
            servicePackageData.note = (typeof payload.note === 'object') ? JSON.stringify(payload.note) : payload.note;

            Http.post(url, servicePackageData)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function servicePackageDetailRequest(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}ServicePackages/${payload.id}`;
            // const servicePackageData = Object.assign({}, payload);
            // servicePackageData.name = (typeof payload.name === 'object') ? JSON.stringify(payload.name) : payload.name;
            // servicePackageData.note = (typeof payload.note === 'object') ? JSON.stringify(payload.note) : payload.note;

            Http.get(url)
                .then((res) => {
                    dispatch(servicePkgActions.detail(res.data.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
    };
}

export function servicePackageUpdateRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}ServicePackages/${payload.id}`;
            const servicePackageData = Object.assign({}, payload);
            servicePackageData.name = (typeof payload.name === 'object') ? JSON.stringify(payload.name) : payload.name;
            servicePackageData.note = (typeof payload.note === 'object') ? JSON.stringify(payload.note) : payload.note;

            Http.put(url, servicePackageData)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    servicePackageData.concurrencyStamp = res.data.data;
                    dispatch(servicePkgActions.update(servicePackageData));
                    resolve(res.data.message);
                })
                .catch(err => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        })
    );
}

export function servicePackageGetAllRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}ServicePackages/get-all`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(servicePkgActions.getAll(res.data));
                    resolve(res.data);
                })
                .catch(err => {
                    dispatch(spinnerLoading(false));
                    reject(err);
                });
        })
    );
}
