// core action types
export const APP_INIT = 'APP_INIT';
export const APP_GET = 'APP_GET';
export const APP_UPDATE_USERSETTING = 'APP_UPDATE_USERSETTING';
export const SETTING_CONFIG = 'SETTING_CONFIG';
export const APP_SETTING_CHECK = 'APP_SETTING_CHECK';

export default {
    APP_INIT,
    APP_GET,
    APP_UPDATE_USERSETTING,
    SETTING_CONFIG
};
