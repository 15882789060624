export const defaultAllowExt = [
    ".txt",
    ".rtf",
    ".doc",
    ".docx",
    ".odt",
    ".xls",
    ".xlsx",
    ".ods",
    ".ppt",
    ".pptx",
    ".odp",
    ".pdf",
    ".xml",
    ".png",
    ".svg",
    ".gif",
    ".jpg",
    ".jpeg",
    ".ico",
    ".bmp",
    ".avi",
    ".mpeg",
    ".mkv",
    "",
];

export const popupImageStyle = {
    display: "block",
    margin: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
};


export function customizeIcon(fileSystemItem) {
    // .txt, .pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .gif, .csv, .swf
    if (fileSystemItem.isDirectory) {
        return `/assets/icons/folder.svg`;
    }

    const fileExtension = fileSystemItem.getExtension();

    switch (fileExtension) {
        case ".txt":
            return "/assets/icons/txt_extension_2.svg";
        case ".docx":
        case ".doc":
            return "/assets/icons/doc_extension_2.svg";
        case ".xlsx":
        case ".xls":
            return "/assets/icons/xls_extension_2.svg";
        case ".pptx":
        case ".ppt":
            return "/assets/icons/ppt_extension_2.svg";
        case ".pdf":
            return "/assets/icons/pdf_extension_2.svg";
        case ".csv":
            return "/assets/icons/csv_extension_2.svg";
        case ".swf":
            return "/assets/icons/swf_extension.svg";
        case ".odt":
            return "/assets/icons/odt_extension_2.svg";
        case ".rtf":
            return "/assets/icons/rtf_extension.svg";
        case ".png":
            return "/assets/icons/picture.svg";
        case ".jpg":
        case ".jpeg":
        case ".gif":
            return "/assets/icons/picture.svg";
        case ".avi":
        case ".mpeg":
        case ".mkv":
            return "/assets/icons/video.svg";
        case ".svg":
            return "/assets/icons/svg_extension_2.svg";
        default:
            return "/assets/icons/document.svg";
    }
};