import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';

export default function ToastMessage(props) {
    return (
        <div onClick={props.handleOnClick}>
            {`${i18n.t('notify.message')}: ${props.title}`}
        </div>
    );
}

ToastMessage.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    clickAction: PropTypes.string,
    handleOnClick: PropTypes.func
};
