import PropTypes from 'prop-types';
import React from 'react';
import Navigation from './navigation/index';

function PrivateLayout({ children }) {
    return (
        <Navigation>
            {children}
        </Navigation>
    );
}

PrivateLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default PrivateLayout;
