import { PAGE_SIZE } from '../../../constants/Config';
import {
    PROMOTION_DELETE,
    PROMOTION_DETAIL,
    PROMOTION_INSERT,
    PROMOTION_LIST,
    PROMOTION_UPDATE,
    PROMOTION_GET_ALL,
    PROMOTION_GET_ALL_BY_SERVICE_NOTE,
    PROMOTION_CONDITIONS_GETALL,
    PROMOTION_CUSTOMER_GETALL,
    PROMOTION_GETALL_BYTIME
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: null,
    suggestion: [],
    getAllByServiceNote: [],
    conditions: [],
    promoCustomers: [],
    promoByTime: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PROMOTION_LIST:
            return list(state, payload);
        case PROMOTION_DETAIL:
            return detail(state, payload);
        case PROMOTION_UPDATE:
            return update(state, payload);
        case PROMOTION_INSERT:
            return insert(state, payload);
        case PROMOTION_DELETE:
            return deletes(state, payload);
        case PROMOTION_GET_ALL:
            return getAll(state, payload);
        case PROMOTION_GET_ALL_BY_SERVICE_NOTE:
            return getAllByServiceNote(state, payload);
        case PROMOTION_CONDITIONS_GETALL:
            return conditionGetAll(state, payload);
        case PROMOTION_CUSTOMER_GETALL:
            return promoCustomersGetAll(state, payload);
        case PROMOTION_GETALL_BYTIME:
            return promoByTimeGetAll(state, payload);
        default:
            return state;
    }
};

const getAllByServiceNote = (state, payload) => {
    state = Object.assign({}, state, { getAllByServiceNote: payload });
    return state;
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.UserId !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}
function getAll(state, payload) {
    state = Object.assign({}, state, { suggestion: payload });
    return state;
}

function conditionGetAll(state, payload) {
    state = Object.assign({}, state, { conditions: payload });
    return state;
}

function promoCustomersGetAll(state, payload) {
    state = Object.assign({}, state, { promoCustomers: payload });
    return state;
}

function promoByTimeGetAll(state, payload) {
    state = Object.assign({}, state, { promoByTime: payload });
    return state;
}
export default reducer;
