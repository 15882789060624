import { NAILSPA_URL } from "../../../constants/Config";
import Http from "../../../utils/Http";
import * as provinceActions from "./store/actions";
import { spinnerLoading } from "../../../common/spinner/store/action";

export function provinceSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}provinces?keyword=${payload.keyword}`;
            url +=
                payload.isActive !== null
                    ? `&isActive=${payload.isActive}`
                    : "";
            url += payload.page ? `&page=${payload.page}` : "";
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : "";

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(provinceActions.list(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function provinceSearchNameValidRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}provinces?keyword=${payload.keyword}`;
            url += payload.countryId ? `&countryId=${payload.countryId}` : "";

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function provinceInsertRequest(province) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}provinces`;
            let provinceUpdate = province;
            provinceUpdate.name = JSON.stringify(province.name);
            provinceUpdate.description = JSON.stringify(province.description);

            Http.post(url, provinceUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    province.id = res.data.data;
                    dispatch(provinceActions.insert(province));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function provinceUpdateRequest(province) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}provinces/${province.id}`;
            let provinceUpdate = province;
            provinceUpdate.name = JSON.stringify(province.name);
            provinceUpdate.description = JSON.stringify(province.description);
            provinceUpdate.unsignName = JSON.stringify(province.unsignName);

            Http.put(url, provinceUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    province.concurrencyStamp = res.data.data;
                    dispatch(provinceActions.update(province));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function provinceDetailRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            // dispatch(spinnerLoading(true))
            const url = `${NAILSPA_URL}provinces/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    // dispatch(spinnerLoading(false))
                    dispatch(provinceActions.detail(res.data.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function provinceDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}provinces/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(provinceActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
    };
}

export function provinceGetAll(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}provinces/get-all/${payload.countryId}`;

            Http.get(url)
                .then((res) => {
                    dispatch(provinceActions.listByCountry(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
