export const enS = {
    system: {
        shift: {
            unit: 'shifts',
            shiftName: 'Shift Name',
            insertTitle: 'Add New Shift',
            updateTitle: 'Edit Shift',
            hoursOfWork: 'Hours of Work',
            price: 'Price',
            priceWeekend: 'Weekend price'
        }
    },
    serviceProduct: {
        error: { required: 'Product is required' },
        list: {
            title: 'List of Service Products',
            code: 'Service Product Code',
            name: 'Service Product Name',
            amount: 'Amount',
            unit: 'Unit Name'
        },
        form: {
            insertTitle: 'Add New Service Product',
            updateTitle: 'Edit Service Product',
            product: 'Service Product',
            quantity: 'Amount',
            code: 'Service Product Code',
            unit: 'Unit'
        }
    },
    serviceNote: {
        info: { code: 'Service Note Code' },
        list: {
            title: 'List of customer order',
            code: 'Service note code',
            info: 'Service note info',
            updater: 'Updater',
            joinedDate: 'Work date',
            canceled: 'Cancel',
            pay: 'Checkout',
            approveCheckout: 'Approve checkout',
            cancelMsg: 'Are you sure to cancel this order',
            cancelCfm: 'After canceled, agents can not be assign to this order'
        },
        form: {
            showCusInfo: 'Customer info',
            showServiceDetail: 'Services detail',
            servicePackage: 'Service package',
            extraService: 'Extra services',
            status: 'Service note status',
            updateStatus: 'Update service note status',
            paymentStatus: 'Service note payment',
            weekdays: 'Choose weekdays',
            selectMonths: 'Number of months',
            workDetail: 'Work detail',
            specialRequest: 'Special request',
            promotion: 'Promotion',
            noteAddress: 'Note address',
            surveyDate: 'Survey date'
        },
        error: {
            required: 'Enter discount reason',
            serviceRequired: 'Service is required',
            placeRequired: 'the Area is required',
            serviceStaffRequire: 'Booking Service Staff required',
            discountNoteRequired: 'Discount Reason Required'
        }
    },
    systemInfo: {
        basicInfo: {
            title: 'Basic information',
            hotline: 'Hotline',
            name: 'Spa Name',
            address: 'Address',
            slogan: 'Slogan',
            logo: 'Logo'
        },
        shiftInfo: {
            title: 'Shift information',
            list: 'List of Shifts'
        },
        systemConfig: {
            title: 'System configuration',
            selectImg: 'Select image',
            job: 'Occupation'
        }
    },
    service: {
        list: {
            title: 'List of Services',
            unit: 'service',
            commissioneRate: 'Commission Rate'
        },
        form: {
            info: 'Service information',
            material: 'Consumables',
            staff: 'Employee',
            titleUpdate: 'Edit service',
            titleInsert: 'Add new service',
            active: 'Active',
            time: 'Time taken',
            selectImg: 'Choose photo',
            price: 'Price',
            isMain: 'Main service',
            isMoney: 'Using price',
            mainService: 'Main service',
            additionalService: 'Additional service'
        }
    },
    servicePackage: {
        list: {
            title: 'List of service packages',
            unit: 'service packages'
        },
        form: {
            info: 'Service package info',
            type: 'Service package type (number)',
            isLanguage: 'Agent uses foreign languages',
            joinedDate: 'Date'
        }
    },
    serviceCard: {
        list: { commissioneRate: 'Comission Rate' },
        form: {
            infoTitle: 'Service Card Info',
            insertTitle: 'Add New Service Card',
            updateTitle: 'Edit Service Card'
        }
    },
    serviceNoteDetail: {
        form: {
            insertTitle: 'Add New Service',
            updateTitle: 'Edit Service',
            serviceName: 'Service Name',
            serviceCode: 'Service Code',
            placeName: 'Area Name',
            staff: 'Staff'
        }
    },
    supplier: {
        titlePopup: {
            Addnewsupplier: 'Add new supplier',
            Editsupplier: 'Edit new supplier'
        }
    },
    specialRequest: {
        form: {
            name: 'Special request name'
        },
        list: {
            title: 'Special request list',
            pageUnit: 'special request'
        }
    }
};
