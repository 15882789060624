import {
    SERVICE_PACKAGE_DETAIL,
    SERVICE_PACKAGE_GET_ALL,
    SERVICE_PACKAGE_LIST,
    SERVICE_PACKAGE_DELETE,
    SERVICE_PACKAGE_UPDATE,
    SERVICE_PACKAGE_INSERT,
    SERVICE_PACKAGE_DETAILS_GET_ALL,
    SERVICE_PACKAGE_DETAILS_SET_ALL
} from './action-types';

export function list(payload) {
    return {
        type: SERVICE_PACKAGE_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: SERVICE_PACKAGE_DETAIL,
        payload
    };
}

export function getAll(payload) {
    return {
        type: SERVICE_PACKAGE_GET_ALL,
        payload
    };
}

export function update(payload) {
    return {
        type: SERVICE_PACKAGE_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: SERVICE_PACKAGE_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: SERVICE_PACKAGE_INSERT,
        payload
    };
}

export function setDetailsList(payload) {
    return {
        type: SERVICE_PACKAGE_DETAILS_SET_ALL,
        payload
    };
}

export function getDetailsList(payload) {
    return {
        type: SERVICE_PACKAGE_DETAILS_GET_ALL,
        payload
    };
}
