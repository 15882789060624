export const FOLDER_LIST = 'FOLDER_LIST'
export const FOLDER_INSERT = 'FOLDER_INSERT'
export const FOLDER_UPDATE = 'FOLDER_UPDATE'
export const FOLDER_DELETE = 'FOLDER_DELETE'
export const FOLDER_SEARCH = 'FOLDER_SEARCH'
export const FOLDER_SHARE_LIST = 'FOLDER_SHARE_LIST'
export const FOLDER_TREE = 'FOLDER_TREE'
export const FOLDER_CHILDREN = 'FOLDER_CHILDREN'
export const FOLDER_DETAIL = 'FOLDER_DETAIL'
export const FOLDER_ALL = 'FOLDER_ALL'
export const FOLDER_EDIT = 'FOLDER_EDIT'
export const FOLDER_RESET = 'FOLDER_RESET'

export const FILE_UPLOAD = 'FILE_UPLOAD'
export const FILE_DELETE = 'FILE_DELETE'
export const FILE_EDIT = 'FILE_EDIT'

export default {
    FOLDER_DELETE,
    FOLDER_INSERT,
    FOLDER_LIST,
    FOLDER_UPDATE,
    FOLDER_SEARCH,
    FOLDER_SHARE_LIST,
    FOLDER_CHILDREN,
    FOLDER_DETAIL,
    FOLDER_EDIT,
    FOLDER_ALL,
    FOLDER_TREE,
    FOLDER_RESET,

    FILE_UPLOAD,
    FILE_DELETE,
    FILE_EDIT
}
