import { PAGE_SIZE } from '../../../constants/Config';
import {
    CUSTOMER_DELETE, CUSTOMER_DETAIL, CUSTOMER_INSERT,
    CUSTOMER_LIST, CUSTOMER_UPDATE, CUSTOMER_GETALL,
    CUSTOMER_SELECT, CUSTOMER_GETCODE, CUSTOMER_FILTER,
    CUSTOMER_DETAIL_RESET, CUSTOMER_HISTORY_ADD, CUSTOMER_HISTORY_PAYMENT,
    CUSTOMER_GETALL_BALANCE, CUSTOMER_GETALL_SERVICECARD, CUSTOMER_HAVE_CARD
} from './action-types';
import * as _ from 'lodash';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {},
    suggestion: [],
    filter: [],
    code: null,
    selectedCustomerId: null,
    historyPayment: [],
    historyAdd: [],
    totalRowsHistoryAdd: 0,
    totalRowsPaymentHistory: 0,
    tttOther: [],
    tdvOther: [],
    customerHaveCardsList: []
};

const customer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case CUSTOMER_FILTER:
            return filter(state, payload);
        case CUSTOMER_DETAIL_RESET:
            return resetDetail(state);
        case CUSTOMER_GETCODE:
            return getCode(state, payload);
        case CUSTOMER_SELECT:
            return select(state, payload);
        case CUSTOMER_LIST:
            return list(state, payload);
        case CUSTOMER_HAVE_CARD:
            return listCard(state, payload);
        case CUSTOMER_DETAIL:
            return detail(state, payload);
        case CUSTOMER_UPDATE:
            return update(state, payload);
        case CUSTOMER_INSERT:
            return insert(state, payload);
        case CUSTOMER_DELETE:
            return deletes(state, payload);
        case CUSTOMER_GETALL:
            return suggestion(state, payload);
        case CUSTOMER_HISTORY_ADD:
            return historyAdd(state, payload);
        case CUSTOMER_HISTORY_PAYMENT:
            return historyPayment(state, payload);
        case CUSTOMER_GETALL_BALANCE:
            return getAllBalance(state, payload);
        case CUSTOMER_GETALL_SERVICECARD:
            return getAllServiceCard(state, payload);
        default:
            return state;
    }
};

function getAllServiceCard(state, payload) {
    state = Object.assign({}, state, {
        tdvOther: payload
    });
    return state;
}

function getAllBalance(state, payload) {
    state = Object.assign({}, state, {
        tttOther: payload
    });
    return state;
}

function resetDetail(state) {
    return { ...state, item: {} };
}

function filter(state, payload) {
    state = Object.assign({}, state, { filter: payload });
    return state;
}

function getCode(state, payload) {
    state = Object.assign({}, state, { code: payload });
    return state;
}

function select(state, payload) {
    return { ...state, item: _.find(state.data, (x) => x.id === payload) };
}

function list(state, payload) {
    return { ...state, ...payload };
}

function listCard(state, payload) {
    return { ...state, customerHaveCardsList: payload.data, totalRows: payload.totalRows };
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}
function detail(state, payload) {
    return { ...state, item: payload };
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function historyPayment(state, payload) {
    state = Object.assign({}, state, { historyPayment: payload.data, totalRowsPaymentHistory: payload.totalRows });
    return state;
}

function historyAdd(state, payload) {
    state = Object.assign({}, state, { historyAdd: payload.data, totalRowsHistoryAdd: payload.totalRows });
    return state;
}

export const getCustomerHaveCardsList = state => state.customer.customerHaveCardsList;
export const getDetailCustomer = state => state.customer.item;
export const getCustomersList = state => state.customer.data;
// export const getCustomersList = state => state.customer.data;

export default customer;
