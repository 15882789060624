export const PROMOTION_CARD_DETAIL_LIST = 'PROMOTION_CARD_DETAIL_LIST'
export const PROMOTION_CARD_DETAIL_SEARCH = 'PROMOTION_CARD_DETAIL_SEARCH'
export const PROMOTION_CARD_DETAIL_DETAIL = 'PROMOTION_CARD_DETAIL_DETAIL'
export const PROMOTION_CARD_DETAIL_UPDATE = 'PROMOTION_CARD_DETAIL_UPDATE'
export const PROMOTION_CARD_DETAIL_INSERT = 'PROMOTION_CARD_DETAIL_INSERT'
export const PROMOTION_CARD_DETAIL_DELETE = 'PROMOTION_CARD_DETAIL_DELETE'
export const PROMOTION_CARD_DETAIL_GET_ALL = 'PROMOTION_CARD_DETAIL_GET_ALL'
export const PROMOTION_CARD_DETAIL_SELECT = 'PROMOTION_CARD_DETAIL_SELECT'
export const PROMOTION_CARD_DETAIL_LIST_RESET = 'PROMOTION_CARD_DETAIL_LIST_RESET'

export default {
    PROMOTION_CARD_DETAIL_DELETE,
    PROMOTION_CARD_DETAIL_DETAIL,
    PROMOTION_CARD_DETAIL_INSERT,
    PROMOTION_CARD_DETAIL_LIST,
    PROMOTION_CARD_DETAIL_UPDATE,
    PROMOTION_CARD_DETAIL_GET_ALL,
    PROMOTION_CARD_DETAIL_SELECT,
    PROMOTION_CARD_DETAIL_LIST_RESET,
    PROMOTION_CARD_DETAIL_SEARCH
}
