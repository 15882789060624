
import { THEME_LIST, CHANGE_THEME } from './action-types'

const initialState = {
    data: [],
}

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case THEME_LIST:
            return list(state, payload)
        case CHANGE_THEME:
            return update(state, payload)
        default:
            return state
    }
}

function list(state, payload) {
    state = Object.assign({}, payload)
    return state
}

function update(state, payload) {

    return state
}

export default reducer
