import {
    CUSTOMER_DELETE, CUSTOMER_DETAIL, CUSTOMER_INSERT, CUSTOMER_LIST, CUSTOMER_RESPONSE,
    CUSTOMER_UPDATE, CUSTOMER_GETALL, CUSTOMER_SELECT, CUSTOMER_GETCODE, CUSTOMER_FILTER,
    CUSTOMER_DETAIL_RESET, CUSTOMER_HISTORY_ADD, CUSTOMER_HISTORY_PAYMENT, CUSTOMER_GETALL_BALANCE,
    CUSTOMER_GETALL_SERVICECARD, CUSTOMER_HAVE_CARD
} from './action-types';

export function getAllServiceCard(payload) {
    return {
        type: CUSTOMER_GETALL_SERVICECARD,
        payload
    };
}

export function getAllBalance(payload) {
    return {
        type: CUSTOMER_GETALL_BALANCE,
        payload
    };
}

export function resetDetail() {
    return {
        type: CUSTOMER_DETAIL_RESET
    };
}

export function filter(payload) {
    return {
        type: CUSTOMER_FILTER,
        payload
    };
}

export function getCode(payload) {
    return {
        type: CUSTOMER_GETCODE,
        payload
    };
}

export function select(payload) {
    return {
        type: CUSTOMER_SELECT,
        payload
    };
}

export function list(payload) {
    return {
        type: CUSTOMER_LIST,
        payload
    };
}

export function listCard(payload) {
    return {
        type: CUSTOMER_HAVE_CARD,
        payload
    };
}

export function detail(payload) {
    return {
        type: CUSTOMER_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: CUSTOMER_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: CUSTOMER_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: CUSTOMER_INSERT,
        payload
    };
}

export function responseError(payload) {
    return {
        type: CUSTOMER_RESPONSE,
        payload
    };
}
export function suggestion(payload) {
    return {
        type: CUSTOMER_GETALL,
        payload
    };
}

export function historyAdd(payload) {
    return {
        type: CUSTOMER_HISTORY_ADD,
        payload
    };
}
export function historyPayment(payload) {
    return {
        type: CUSTOMER_HISTORY_PAYMENT,
        payload
    };
}
