import {
    NEWS_CATEGORY_SEARCH,
    NEWS_CATEGORY_DETAIL,
    NEWS_CATEGORY_GETALL,
    NEWS_CATEGORY_TREE,
} from './action-types'

const initialState = {
    data: [],
    item: null,
    suggestion: [],
    totalRows: 0,
    tree: null,
}

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case NEWS_CATEGORY_SEARCH:
            return search(state, payload)
        case NEWS_CATEGORY_DETAIL:
            return detail(state, payload)
        case NEWS_CATEGORY_TREE:
            return tree(state, payload)
        case NEWS_CATEGORY_GETALL:
            return getAll(state, payload)
    
        default:
            return state
    }
}

function search(state, payload) {
    state = Object.assign({}, state, {data: payload.data ? payload.data : [], totalRows: payload.totalRows})
    return state
}

function detail(state, payload) {
    state = Object.assign({}, state, {item: payload.data})
    return state
}

function tree(state, payload) {
    state = Object.assign({}, state, {tree: payload})
    return state
}

function getAll(state, payload) {
    state = Object.assign({}, state, {suggestion: payload})
    return state
}

export default reducer