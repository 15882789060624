export const SERVICE_NOTE_LIST = 'SERVICE_NOTE_LIST';
export const SERVICE_NOTE_DETAIL = 'SERVICE_NOTE_DETAIL';
export const SERVICE_NOTE_UPDATE = 'SERVICE_NOTE_UPDATE';
export const SERVICE_NOTE_INSERT = 'SERVICE_NOTE_INSERT';
export const SERVICE_NOTE_DELETE = 'SERVICE_NOTE_DELETE';
export const SERVICE_NOTE_GET_ALL = 'SERVICE_NOTE_GET_ALL';
export const SERVICE_NOTE_DETAIL_GET_ALL = 'SERVICE_NOTE_DETAIL_GET_ALL';
export const SERVICE_NOTE_GET_CODE = 'SERVICE_NOTE_GET_CODE';
export const SERVICE_NOTE_SELECT = 'SERVICE_NOTE_SELECT';
export const SERVICE_NOTE_DETAIL_RESET = 'SERVICE_NOTE_DETAIL_RESET';
export const SAVE_EDITING_WORKDAY = 'SAVE_EDITING_WORKDAY';
export const GET_EDITING_WORKDAY = 'GET_EDITING_WORKDAY';
export default {
    SERVICE_NOTE_DELETE,
    SERVICE_NOTE_DETAIL,
    SERVICE_NOTE_INSERT,
    SERVICE_NOTE_LIST,
    SERVICE_NOTE_UPDATE,
    SERVICE_NOTE_GET_ALL,
    SERVICE_NOTE_DETAIL_GET_ALL,
    SERVICE_NOTE_GET_CODE,
    SERVICE_NOTE_SELECT,
    SERVICE_NOTE_DETAIL_RESET,
    SAVE_EDITING_WORKDAY,
    GET_EDITING_WORKDAY
};
