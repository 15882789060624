export const ROLE_LIST = 'ROLE_LIST'
export const ROLE_SUGGESTION = 'ROLE_SUGGESTION'
export const ROLE_UPDATE  = 'ROLE_UPDATE'
export const ROLE_INSERT = 'ROLE_INSERT'
export const ROLE_DELETE = 'ROLE_DELETE'
export const ROLE_DETAIL = 'ROLE_DETAIL'
export const ROLE_GROUP_INSERT = 'ROLE_GROUP_INSERT'
export const ROLE_GROUP_UPDATE = 'ROLE_GROUP_UPDATE'
export const ROLE_GROUP_DELETE = 'ROLE_GROUP_DELETE'
export const ROLE_GROUP_LIST = 'ROLE_GROUP_LIST'
export const ROLE_GROUP_DETAIL = 'ROLE_GROUP_DETAIL'
export default {
    ROLE_LIST,
    ROLE_SUGGESTION,
    ROLE_INSERT,
    ROLE_UPDATE,
    ROLE_DELETE,
    ROLE_GROUP_INSERT,
    ROLE_DETAIL,
    ROLE_GROUP_DELETE,
    ROLE_GROUP_UPDATE,
    ROLE_GROUP_DETAIL
}
