import React, { Component } from 'react';
import FileManager from 'devextreme-react/file-manager';
import { Popup } from 'devextreme-react/popup';
import { Button, Template } from 'devextreme-react';
// import ArrayFileProvider from 'devextreme/ui/file_manager/file_provider/array';
import { connect } from 'react-redux';
import {
    folderSearchRequest,
    folderInsertRequest,
    folderDeleteRequest,
    folderChildrenRequest,
    folderEditRequest,
    folderTreeRequest,
    folderResetRequest,
    fileUploadRequest,
    fileDeleteRequest,
    fileEditRequest,
    folderNameEditRequest,
    fileDownloadRequest
} from './service';
import * as _ from 'lodash';
import FolderViewModel from './FolderViewModel';
import AddNewFolderPopup from './component/AddNewFolderPopup';
import RenamePopup from './component/RenamePopup';
import EditPopup from './component/EditPopup';
import { encodeHtml } from 'devextreme/core/utils/string';
import { custom } from 'devextreme/ui/dialog';
import { ToastsStore } from 'react-toasts';
import i18n from '../../i18n';
import * as contextMenuItem from './component/renderContextMenuItem';
import { defaultAllowExt, popupImageStyle, customizeIcon } from './component/fileConfig';
import * as folderActions from './component/folderAction';
import * as fileActions from './component/fileActions';
import PropTypes from 'prop-types';

class FileManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupVisible: false,
            popupDownloadVisible: false,
            imageItemToDisplay: {},
            fileProvider: null,
            isShowAddFolderPopup: false,
            isShowRenamePopup: false,
            isShowEditPopup: false,
            parentFolderIdRequestedList: [],
            foldersData: null,
            selectedItem: null,
            folderTrees: [],
            searchValue: '',
            filesNum: 0,
            foldersNum: 0,
            imgDataURL: '',
            imgName: '',
            folderID: 0,
            editFoldersTreeView: false
        };

        this.fileMgrRef = React.createRef();

        this.hideImagePopup = this.hideImagePopup.bind(this);
        this.hideDownloadPopup = this.hideDownloadPopup.bind(this);
        this.showAddFolderPopup = this.showAddFolderPopup.bind(this);
        this.hideAddFolderPopup = this.hideAddFolderPopup.bind(this);
        this.showRenamePopup = this.showRenamePopup.bind(this);
        this.hideRenamePopup = this.hideRenamePopup.bind(this);
        this.deleteAlert = this.deleteAlert.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.hideEditPopup = this.hideEditPopup.bind(this);
        this.onSearchValueChanged = this.onSearchValueChanged.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.showEditPopup = this.showEditPopup.bind(this);
    }

    componentDidMount() {
        this.getFolderTree();
        folderActions.getChildrenFoldersList(this, 0);
    }

    updateFileProvider() {
        const { totalFolders, totalFiles } = this.props;
        const _fileProvider = this.props.foldersData;

        this.setState({
            fileProvider: _fileProvider,
            foldersNum: totalFolders,
            filesNum: totalFiles
        });
    }

    hideImagePopup() {
        this.setState({
            popupVisible: false
        });
    }

    hideDownloadPopup() {
        this.setState({
            popupDownloadVisible: false
        });
    }

    get fileMgr() {
        return this.fileMgrRef.current.instance;
    }

    showAddFolderPopup() {
        this.setState({
            isShowAddFolderPopup: true
        });
    }

    hideAddFolderPopup() {
        this.setState({
            isShowAddFolderPopup: false
        });
    }

    showRenamePopup(e) {
        const selectedItems = this.fileMgr.getSelectedItems();
        const currentDirectory = this.fileMgr.getCurrentDirectory();

        let err = 0;
        if (selectedItems === null || selectedItems.length === 0) {
            if (!currentDirectory.isRoot) {
                this.setState({
                    selectedItem: currentDirectory.dataItem,
                    isShowRenamePopup: true,
                    editFoldersTreeView: true
                });
            } else {
                ToastsStore.warning(
                    i18n.t('fileManagement.error.selectFileFolder')
                );
            }
        } else {
            selectedItems.forEach((element) => {
                if (!_.has(element, 'dataItem')) {
                    err = 1;
                }
                this.setState({
                    selectedItem: element.dataItem
                });
            });

            if (err === 0) {
                this.setState({
                    isShowRenamePopup: true
                });
            }
        }
    }

    hideRenamePopup() {
        this.setState({
            isShowRenamePopup: false
        });
    }

    showEditPopup() {
        const selectedItems = this.fileMgr.getSelectedItems();
        const currentDirectory = this.fileMgr.getCurrentDirectory();
        let err = 0;

        if (selectedItems === null || selectedItems.length === 0) {
            if (!currentDirectory.isRoot) {
                this.setState({
                    selectedItem: currentDirectory.dataItem,
                    isShowEditPopup: true,
                    editFoldersTreeView: true
                });
            } else {
                ToastsStore.warning(
                    i18n.t('fileManagement.error.selectFileFolder')
                );
            }
        } else {
            selectedItems.forEach((element) => {
                if (!_.has(element, 'dataItem')) {
                    err = 1;
                }
                this.setState({
                    selectedItem: element.dataItem
                });
            });

            if (err === 0) {
                this.setState({
                    isShowEditPopup: true
                });
            }
        }
    }

    hideEditPopup() {
        this.setState({
            isShowEditPopup: false
        });
    }

    showDeleteAlert = () => {
        const encodedMessage = encodeHtml(
            i18n.t('fileManagement.popup.deleteNotice')
        );
        const myDialog = custom({
            title: i18n.t('fileManagement.popup.deleteTitle'),
            width: 500,
            messageHtml: encodedMessage,
            buttons: [
                {
                    type: 'success',
                    text: i18n.t('message.accept'),
                    onClick: (e) => folderActions.deleteFolder(this)
                },
                {
                    text: i18n.t('message.decline'),
                    onClick: () => {
                        return { data: false };
                    }
                }
            ]
        });
        myDialog.show().then((dialogResult) => { });
    };

    deleteAlert() {
        const selectedItems = this.fileMgr.getSelectedItems();
        const currentDirectory = this.fileMgr.getCurrentDirectory();
        let err = 0;

        if (selectedItems === null || selectedItems.length === 0) {
            if (!currentDirectory.isRoot) {
                this.setState(
                    {
                        selectedItem: currentDirectory.dataItem,
                        editFoldersTreeView: true
                    },
                    () => this.showDeleteAlert()
                );
            } else {
                ToastsStore.warning(i18n.t('fileManagement.error.selectFileFolder'));
            }
        } else {
            selectedItems.forEach((element) => {
                if (!_.has(element, 'dataItem')) {
                    err = 1;
                }
            });

            if (err === 0) {
                this.showDeleteAlert();
            }
        }
    }

    uploadFile() {
    }

    getFolderTree() {
        this.props.getFolderTree().then(() => {
            this.setState({
                folderTrees: this.props.folderTrees
            });
        });
    }

    onSearchValueChanged(e) {
        this.setState({
            searchValue: e.value
        });

        if (e.value) {
            this.props.searchFolder(e.value).then(() => {
                this.updateFileProvider();
            });
        } else {
            const currentFolder = this.fileMgr.getCurrentDirectory();
            const folderId = currentFolder.isRoot ? 0 : currentFolder.dataItem.id;

            this.setState({
                folderID: folderId,
                parentFolderIdRequestedList: []
            });
            this.props.resetFolder()
                .then(() => {
                    const { folderID } = this.state;
                    folderActions.getChildrenFoldersList(this, folderID);
                    this.getFolderTree();
                    this.updateFileProvider();
                });
        }
    }

    selectFile() {
        const { selectionMode } = this.props;
        const selectedItems = this.fileMgr.getSelectedItems();
        if (selectedItems && selectedItems.length > 0) {
            selectionMode === 'multiple'
                ? this.props.onSelectFile(selectedItems)
                : this.props.onSelectFile(selectedItems[0]);
        } else {
            ToastsStore.warning('Please select file');
        }
    }

    renderDownloadItem = () => {
        return contextMenuItem.renderDownloadItem(this.fileMgr);
    };

    render() {
        const {
            isShowAddFolderPopup, isShowRenamePopup, isShowEditPopup, popupDownloadVisible,
            selectedItem, folderTrees, imgDataURL, imgName
        } = this.state;
        const { insidePopup, allowedExt, selectionMode, filesList } = this.props;

        return (
            <div>
                {!insidePopup ? (
                    <h1 className="page-title">
                        <span className="cm-mgr-5">
                            {i18n.t('titlePage.FileManagement')}
                        </span>
                    </h1>
                ) : null}
                <input
                    type="file"
                    id="file"
                    style={{ display: 'none' }}
                    accept=".txt, .pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .gif, .csv, .swf"
                    onChange={(event) => fileActions.handleFileUpload(this, event, filesList)}
                    multiple
                />
                <FileManager
                    ref={this.fileMgrRef}
                    rootFolderName={'Home'}
                    customizeThumbnail={customizeIcon}
                    // fileProvider={fileProvider}
                    height="600"
                    allowedFileExtensions={
                        allowedExt || defaultAllowExt
                    }
                    onCurrentDirectoryChanged={() => folderActions.onCurrentDirectoryChanged(this)}
                    selectionMode={selectionMode || 'single'}
                    onSelectedFileOpened={
                        this.props.insidePopup ? this.selectFile : () => fileActions.download(this)
                    }
                    contextMenu={{
                        items: [
                            // 'delete',
                            {
                                name: 'View',
                                text: i18n.t('fileManagement.list.view'), // custom item with sub items
                                icon: 'search',
                                onClick: () => fileActions.download(this),
                                template: 'renderViewItem'
                            },
                            {
                                name: 'Edit',
                                text: i18n.t('fileManagement.list.edit'), // custom item with sub items
                                icon: 'edit',
                                onClick: this.showEditPopup,
                                template: 'renderEditItem'
                            },
                            // 'rename',
                            {
                                name: 'Rename',
                                text: i18n.t('fileManagement.list.rename'), // custom item with sub items
                                icon: 'rename',
                                onClick: this.showRenamePopup,
                                template: 'renderRenameItem'
                            },
                            {
                                name: 'Delete',
                                text: i18n.t('fileManagement.list.delete'), // custom item with sub items
                                icon: 'close',
                                onClick: this.deleteAlert,
                                template: 'renderDeleteItem'
                            },
                            {
                                name: 'Refresh',
                                text: i18n.t('fileManagement.list.refresh'),
                                icon: 'refresh',
                                onClick: () => folderActions.searchFolder(this),
                                template: 'renderRefreshItem'
                            },
                            {
                                name: 'Download',
                                text: i18n.t('general.button.download'),
                                icon: 'download',
                                onClick: () => fileActions.downloadFile(this),
                                template: 'renderDownloadItem'
                            }
                        ]
                    }}
                    toolbar={{
                        items: [
                            {
                                widget: 'dxButton',
                                location: 'before',
                                options: {
                                    name: 'Add Folder',
                                    text: i18n.t(
                                        'fileManagement.toolbar.addFolder'
                                    ),
                                    icon: 'newfolder'
                                },
                                onClick: this.showAddFolderPopup
                            },
                            {
                                widget: 'dxButton',
                                location: 'before',
                                options: {
                                    name: 'Choose file',
                                    text: i18n.t(
                                        'fileManagement.toolbar.chooseFile'
                                    ),
                                    icon: 'upload'
                                },
                                onClick: this.uploadFile
                            },
                            'separator',
                            {
                                widget: 'dxTextBox',
                                location: 'after',
                                options: {
                                    placeholder: i18n.t(
                                        'fileManagement.toolbar.searchPlaceholder'
                                    ),
                                    onValueChanged: this.onSearchValueChanged,
                                    value: this.state.searchValue,
                                    showClearButton: true,
                                    onEnterKey: this.searchFolder
                                }
                            },
                            {
                                widget: 'dxButton',
                                location: 'after',
                                options: {
                                    icon: 'search'
                                },
                                onClick: this.searchFolder
                            },
                            {
                                name: 'separator',
                                location: 'after'
                            },
                            {
                                name: 'viewSwitcher',
                                visible: true
                            }
                        ]
                    }}
                >
                    <Template
                        name={'renderDownloadItem'}
                        render={this.renderDownloadItem}
                    />
                    <Template
                        name={'renderViewItem'}
                        render={contextMenuItem.renderViewItem}
                    />
                    <Template
                        name={'renderEditItem'}
                        render={contextMenuItem.renderEditItem}
                    />
                    <Template
                        name={'renderRenameItem'}
                        render={contextMenuItem.renderRenameItem}
                    />
                    <Template
                        name={'renderDeleteItem'}
                        render={contextMenuItem.renderDeleteItem}
                    />
                    <Template
                        name={'renderRefreshItem'}
                        render={contextMenuItem.renderRefreshItem}
                    />
                </FileManager>
                {this.props.insidePopup ? (
                    <div className="text-right">
                        <Button
                            text={i18n.t('fileManagement.toolbar.chooseFile')}
                            className="mt-2"
                            onClick={this.selectFile}
                        />
                    </div>
                ) : null}

                <Popup
                    maxHeight={600}
                    closeOnOutsideClick={true}
                    title={this.state.imageItemToDisplay.name}
                    visible={this.state.popupVisible}
                    onHiding={this.hideImagePopup}
                    className={'photo-popup-content'}
                >
                    <img
                        alt="Copyright Of GHMSoft"
                        src={this.state.imageItemToDisplay.url}
                        className={'photo-popup-image'}
                        style={popupImageStyle}
                    />
                </Popup>

                <Popup
                    maxHeight={150}
                    maxWidth={400}
                    closeOnOutsideClick={true}
                    title={i18n.t('general.button.download')}
                    visible={popupDownloadVisible}
                    onHiding={this.hideDownloadPopup}
                >
                    <p>{i18n.t('general.button.download') + ' ' + imgName}</p>
                    <div style={{ display: 'flex' }}>
                        <a
                            className="download-link"
                            href={imgDataURL}
                            download={imgName}
                            style={{ margin: 'auto' }}
                        >
                            <Button
                                text={i18n.t('general.button.download')}
                                icon={'download'}
                                onClick={this.hideDownloadPopup}
                            />
                        </a>
                    </div>
                </Popup>

                {isShowAddFolderPopup ? (
                    <AddNewFolderPopup
                        addFolder={(newFolderName) => folderActions.addFolder(this, newFolderName)}
                        hideAddFolderPopup={this.hideAddFolderPopup}
                        isShowAddFolderPopup={isShowAddFolderPopup}
                    />
                ) : null}

                {isShowRenamePopup ? (
                    <RenamePopup
                        selectedItem={selectedItem}
                        renameFolder={(newFolderName) => folderActions.renameFolder(this, newFolderName)}
                        hideRenamePopup={this.hideRenamePopup}
                        isShowRenamePopup={isShowRenamePopup}
                    />
                ) : null}

                {isShowEditPopup ? (
                    <EditPopup
                        folderTrees={folderTrees}
                        selectedItem={selectedItem}
                        editFolder={(newFolderName, folderId) => folderActions.editFolder(this, newFolderName, folderId)}
                        hideEditPopup={this.hideEditPopup}
                        isShowEditPopup={isShowEditPopup}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { files, folders, tree } = state.filemanagement;
    const languages = state.appSettings.data.languages;
    const currentLanguage = _.find(languages, (item) => {
        return item.isDefault === true;
    });

    return {
        folderTrees: tree,
        foldersData: new FolderViewModel(folders, files),
        totalFolders: folders.length,
        filesList: files,
        totalFiles: files.length,
        currentLanguage: currentLanguage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        searchFolder: (keyword) => {
            return dispatch(folderSearchRequest(keyword));
        },
        createFolder: (folderInfo) => {
            return dispatch(folderInsertRequest(folderInfo));
        },
        deleteFolder: (folderId) => {
            return dispatch(folderDeleteRequest(folderId));
        },
        getChildFolder: (folderId) => {
            return dispatch(folderChildrenRequest(folderId));
        },
        uploadFile: (folderId, file) => {
            return dispatch(fileUploadRequest(folderId, file));
        },
        deleteFile: (fileId) => {
            return dispatch(fileDeleteRequest(fileId));
        },
        editFile: (fileId, fileMeta) => {
            return dispatch(fileEditRequest(fileId, fileMeta));
        },
        downloadFile: (fileId) => {
            return dispatch(fileDownloadRequest(fileId));
        },
        editFolder: (folderId, folderMeta) => {
            return dispatch(folderEditRequest(folderId, folderMeta));
        },
        editFolderName: (folderId, folderMeta) => {
            return dispatch(folderNameEditRequest(folderId, folderMeta));
        },
        getFolderTree: () => {
            return dispatch(folderTreeRequest());
        },
        resetFolder: () => {
            return dispatch(folderResetRequest());
        }
    };
};

FileManagement.propTypes = {
    searchFolder: PropTypes.func.isRequired,
    createFolder: PropTypes.func.isRequired,
    deleteFolder: PropTypes.func.isRequired,
    getChildFolder: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    editFile: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    editFolder: PropTypes.func.isRequired,
    editFolderName: PropTypes.func.isRequired,
    getFolderTree: PropTypes.func.isRequired,
    resetFolder: PropTypes.func.isRequired,
    folderTrees: PropTypes.array,
    foldersData: PropTypes.array,
    totalFolders: PropTypes.number,
    filesList: PropTypes.array,
    totalFiles: PropTypes.number,
    currentLanguage: PropTypes.string,
    onSelectFile: PropTypes.func,
    insidePopup: PropTypes.any,
    allowedExt: PropTypes.array,
    selectionMode: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(FileManagement);
