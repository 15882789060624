import { PAGE_SIZE } from '../../../../constants/Config';
import * as _ from 'lodash';
import {
    SERVICE_PACKAGE_LIST,
    SERVICE_PACKAGE_GET_ALL,
    SERVICE_PACKAGE_DETAIL,
    SERVICE_PACKAGE_INSERT,
    SERVICE_PACKAGE_UPDATE,
    SERVICE_PACKAGE_DELETE,
    SERVICE_PACKAGE_DETAILS_GET_ALL,
    SERVICE_PACKAGE_DETAILS_SET_ALL
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: null,
    selectedServicePkg: null,
    suggestion: [],
    servicePkgDetails: [],
    code: ''
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case SERVICE_PACKAGE_LIST:
            return list(state, payload);
        case SERVICE_PACKAGE_DETAIL:
            return detail(state, payload);
        case SERVICE_PACKAGE_UPDATE:
            return update(state, payload);
        case SERVICE_PACKAGE_INSERT:
            return insert(state, payload);
        case SERVICE_PACKAGE_DELETE:
            return deletes(state, payload);
        case SERVICE_PACKAGE_GET_ALL:
            return getAll(state, payload);
        case SERVICE_PACKAGE_DETAILS_SET_ALL:
            return setServicePackageDetails(state, payload);
        case SERVICE_PACKAGE_DETAILS_GET_ALL:
            return getServicePackageDetails(state);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.UserId !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    // const data = state.data.map(obj => {
    //     if (obj.id === payload.id) {
    //         return { ...obj, ...payload };
    //     }
    //     return obj;
    // });

    return { ...state, item: payload };
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function getAll(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

export const getServicesPkgList = state => state.servicePkg.data;

export const setServicePackageDetails = (state, id) => {
    const selectedServicePackage = _.find(state.suggestion, item => item.id === id);
    if (selectedServicePackage) state.servicePkgDetails = selectedServicePackage.servicePkgDetails;

    return state;
};

export const getServicePackageDetails = (state) => state.servicePkgDetails;
export default reducer;
