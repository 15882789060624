import React, { Component } from 'react';
import { SubmitButton, CloseButton } from '../actionbox';
import LangTabs from './LangTabs';
import * as _ from 'lodash';
import BaseViewModel from '../BaseViewModel';
import { ToastsStore } from 'react-toasts';
import i18n from '../../i18n';
import Transformer from '../../utils/Transformer';
import { ScrollView, Button } from 'devextreme-react';
import PropTypes from 'prop-types';

class TabFormData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {
                formValidate: {}
            },
            initModel: {},
            currentLanguageActive: this.props.currentLanguage,
            currentLanguage: this.props.currentLanguage,
            selectedIndex: this.props.languageDefaultIndex,
            isContinue: false,
            langTabShow: true,
            isShowActionBox: true,
            renderDone: false,
            reloadForm: false,
            jsonField: [],
            isShowContinue: true,
            disableEnterKey: false,
            viewInfo: false,
            editable: true,
            showEditButton: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateLanguage = this.updateLanguage.bind(this);
        this.updateData = this.updateData.bind(this);
        this.onChangeIsContinue = this.onChangeIsContinue.bind(this);
        this.closeAction = this.closeAction.bind(this);

        this.ViewModel = BaseViewModel;
        this.validateField = null;
    }

    componentDidMount() {
        const { currentUser, match } = this.props;

        if (match && !_.isEmpty(match.params)) {
            if (!currentUser.isAdmin) {
                this.checkPermission();
            }

            this.initData();
        } else {
            this.setState({
                initModel: this.state.model,
                renderDone: true,
                viewInfo: false
            });
        }
    }

    checkPermission() {
        const { permissions, match } = this.props;
        const pagePermisions = Transformer.checkPermissionDetail(permissions, match);
        this.setState({
            pagePermisions
        });
    }

    /**
     * Init Data.
     *
     * @inheritdoc
     */
    initHandler = () => {
        const { id } = this.props;

        if (id) {
            this.initData();
        } else {
            this.setState({
                initModel: this.state.model,
                renderDone: true,
                viewInfo: false
            });
        }
    };

    initData() {
        const { id } = this.props;
        const payload = { id };

        this.getDetail(payload);
    }

    getDetail(payload) {
        const { langTabShow } = this.state;
        this.props.getDetail(payload)
            .then(() => {
                let model = {};
                if (langTabShow) {
                    model = new this.ViewModel(this.props.detailData);
                } else {
                    model = new this.ViewModel(
                        this.props.detailData,
                        this.props.currentLanguage
                    );
                }

                model = this.createJsonData(model);
                this.setState({
                    model: _.mergeWith({}, this.state.model, model, (o, s) =>
                        _.isNull(s) ? o : s
                    ),
                    renderDone: true
                });
            })
            .catch((err) => {
                console.log('err', err);
                if (typeof err === 'string') { ToastsStore.error(err); } else {
                    ToastsStore.error('has error happen!');
                }
                this.getDetailFail();
            });
    }

    /**
     * do anything if error happen when getDetail
     *
     * @inheritdoc
     */
    getDetailFail() {

    }

    createJsonData(data) {
        const updateData = Object.assign({}, data);
        const { languages } = this.props;
        const { jsonField } = this.state;

        jsonField.forEach((field) => {
            if (typeof data[field] !== 'object') {
                updateData[field] = {};
                languages.forEach((element) => {
                    updateData[field] = {
                        ...updateData[field],
                        [element.languageId]: data[field]
                    };
                });
            }
        });

        return updateData;
    }

    handleSubmit() {
        this.checkValidate()
            .then(() => {
                if (!this.props.id) {
                    this.addNewAction();
                } else {
                    this.updateAction();
                }
            })
            .catch((errMes) => {
                ToastsStore.error(errMes);
            });
    }

    checkValidate() {
        return new Promise((resolve, reject) => {
            if (!this.validateField) {
                return resolve();
            }

            const { languages } = this.props;
            const { model } = this.state;

            const promises = [];

            languages.forEach((language) => {
                const payload = {
                    keyword: `"${language.languageId}":"${model[this.validateField][language.languageId]
                        }"`
                };

                promises.push(
                    this.props.checkValidate(payload).then((searchResult) => {
                        searchResult.data.forEach((element) => {
                            const elementTxt = JSON.parse(
                                element[this.validateField]
                            )[language.languageId];

                            if (
                                Transformer.compareString(
                                    elementTxt,
                                    model[this.validateField][language.languageId]
                                ) &&
                                element.id !== model.id
                            ) {
                                throw new Error(
                                    i18n.t('general.form.error.nameExisted') +
                                    `${language.languageId}`
                                );
                            }
                        });
                    })
                );
            });

            Promise.all(promises)
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err.message);
                });
        });
    }

    /**
     * Add New Form Data.
     *
     * @inheritdoc
     */
    addNewAction() {
        const { model } = this.state;
        this.props
            .insert(model)
            .then((message) => {
                this.setState({ reloadForm: true });
                if (!this.state.isContinue) {
                    this.closeAction();
                    this.props.search();
                } else {
                    this.props.search();
                    const formValidate = this.state.model.formValidate;
                    Object.keys(formValidate).forEach((element) => {
                        formValidate[element] = false;
                    });

                    this.setState(
                        {
                            model: { ...this.state.initModel, formValidate }
                        },
                        () => {
                            this.setState({ reloadForm: false });
                        }
                    );
                }
                ToastsStore.success(message);
            })
            .catch((message) => {
                ToastsStore.error(message);
            });
    }

    /**
     * Update Form Data.
     *
     * @inheritdoc
     */
    updateAction() {
        const { model } = this.state;
        this.props
            .update(model)
            .then((message) => {
                ToastsStore.success(message);
                this.props.search();
                this.props.hideForm();
                this.closeAction();
            })
            .catch((message) => {
                ToastsStore.error(message);
            });
    }

    /**
     * Render Form Data.
     *
     * @inheritdoc
     */
    renderFormData() {
        return <div>No Form Data to render</div>;
    }

    renderViewInfoButton = () => {
        const { viewInfo, pagePermisions } = this.state;
        return (
            <div className="text-right my-2">
                {
                    pagePermisions['edit'] &&
                    <Button
                        className={'button-info mx-1 dx-theme-accent-as-background-color'}
                        icon={`${viewInfo ? 'edit' : 'info'}`}
                        text={i18n.t(`${viewInfo ? 'general.button.edit' : 'general.button.viewInfo'}`)}
                        onClick={this.toggleEdit}
                    />
                }
            </div>
        );
    }

    toggleEdit = () => {
        this.setState({
            viewInfo: !this.state.viewInfo
        });
    }

    updateLanguage(data) {
        this.setState(data);
    }

    updateData(data) {
        if (Object.keys(data)[0] === 'formValidate') {
            data = {
                formValidate: {
                    ...this.state.model.formValidate,
                    ...data.formValidate
                }
            };
        }

        let model = {};
        if (data.countryId) {
            model = {
                ...this.state.model,
                ...data,
                provinceId: '',
                districtId: '',
                nationId: '',
                religionId: ''
            };
        } else model = { ...this.state.model, ...data };
        this.setState({
            model
        });
    }

    renderActionBox() {
        const { model, viewInfo } = this.state;

        return (
            <div className={'mt-3 dx-theme-background-color action_form'} style={{ textAlign: 'right' }}>
                {
                    !viewInfo &&
                    <SubmitButton
                        formValidate={model.formValidate}
                        handleSubmit={() => this.handleSubmit()}
                    />
                }

                <CloseButton hideForm={this.closeAction} />

            </div>
        );
    }

    closeAction() {
        const { hideForm } = this.props;
        return hideForm();
    }

    onChangeIsContinue(data) {
        this.setState(data);
    }

    render() {
        const { languages, id, title } = this.props;
        const { selectedIndex, isShowActionBox, renderDone, langTabShow, model, reloadForm, disableEnterKey, showEditButton } = this.state;
        if ((id && !renderDone) || reloadForm) {
            return <div>Loading...</div>;
        }

        return (
            <div>
                {title && <div>
                    <h3>{title}</h3>
                    <hr />
                </div>}
                <ScrollView>
                    <div>
                        {langTabShow && languages.length > 1 && (
                            <LangTabs
                                languages={languages}
                                selectedIndex={selectedIndex}
                                updateLanguage={this.updateLanguage}
                            />
                        )}
                    </div>

                    <div>
                        {showEditButton && model.id && this.renderViewInfoButton()}
                    </div>
                    <div
                        className={'content mt-1 mb-5 mx-0'}
                        onKeyPress={(event) => {
                            if (!disableEnterKey && event.key === 'Enter' && !_.includes(Object.values(model.formValidate), false)) {
                                this.handleSubmit();
                            }
                        }}
                    >
                        {this.renderFormData()}
                    </div>

                    {isShowActionBox && this.renderActionBox()}
                </ScrollView>
            </div>
        );
    }
}

TabFormData.propTypes = {
    hideForm: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    title: PropTypes.string,
    currentLanguage: PropTypes.string,
    getDetail: PropTypes.func,
    languages: PropTypes.array,
    checkValidate: PropTypes.func,
    insert: PropTypes.func,
    id: PropTypes.any,
    update: PropTypes.func,
    detailData: PropTypes.object,
    languageDefaultIndex: PropTypes.number,
    currentUser: PropTypes.object,
    match: PropTypes.any,
    permissions: PropTypes.any,
    location: PropTypes.any
};

TabFormData.defaultProps = {
    hideForm: () => { console.log('hideForm'); },
    search: () => { console.log('search'); },
    title: null
};

export default TabFormData;
