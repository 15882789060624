export const viS = {
    service: {
        list: {
            title: 'Danh sách Dịch vụ',
            unit: 'dịch vụ',
            commissioneRate: '% Hoa Hồng'
        },
        form: {
            info: 'Thông tin dịch vụ',
            material: 'Vật tư tiêu hao',
            staff: 'Nhân viên thực hiện',
            titleUpdate: 'Chỉnh sửa Dịch Vụ',
            titleInsert: 'Thêm mới dịch vụ',
            active: 'Hoạt động',
            time: 'Thời gian thực hiện',
            selectImg: 'Chọn ảnh',
            price: 'Giá',
            isMain: 'Dịch vụ chính',
            isMoney: 'Tính theo giá tiền',
            mainService: 'Dịch vụ chính',
            additionalService: 'Dịch vụ thêm'
        }
    },
    servicePackage: {
        list: {
            title: 'Danh sách gói dịch vụ',
            unit: 'Gói dịch vụ'
        },
        form: {
            info: 'Thông tin gói dịch vụ',
            type: 'Loại gói (điền số)',
            isLanguage: 'Trợ lý biết ngoại ngữ',
            joinedDate: 'Ngày làm'
        }
    },
    serviceCard: {
        list: { commissioneRate: 'Hoa Hồng' },
        form: {
            infoTitle: 'Thông tin Thẻ Dịch Vụ',
            insertTitle: 'Thêm mới Thẻ Dịch Vụ',
            updateTitle: 'Chỉnh sửa Thẻ Dịch Vụ'
        }
    },
    systemInfo: {
        basicInfo: {
            title: 'Thông tin cơ bản',
            hotline: 'Hotline',
            name: 'Tên Spa',
            address: 'Địa chỉ',
            slogan: 'Slogan',
            logo: 'Logo'
        },
        shiftInfo: {
            title: 'Thông tin ca làm việc',
            list: 'Danh sách Ca làm việc'
        },
        systemConfig: {
            title: 'Cấu hình hệ thống',
            selectImg: 'Chọn ảnh',
            job: 'Nghề nghiệp'
        }
    },
    system: {
        shift: {
            unit: 'ca',
            shiftName: 'Tên ca làm việc',
            insertTitle: 'Thêm mới Ca làm việc',
            updateTitle: 'Chỉnh sửa Ca làm việc',
            hoursOfWork: 'Số giờ làm việc',
            price: 'Giá cơ bản',
            priceWeekend: 'Giá ngày nghỉ'
        }
    },
    serviceProduct: {
        error: { required: 'Sản phẩm không được bỏ trống' },
        list: {
            title: 'Danh sách Vật tư tiêu hao',
            code: 'Mã Vật tư',
            name: 'Tên Vật tư',
            amount: 'Số lượng',
            unit: 'Đơn vị'
        },
        form: {
            insertTitle: 'Thêm mới Vật tư tiêu hao',
            updateTitle: 'Chỉnh sửa Vật tư tiêu hao',
            product: 'Vật tư',
            quantity: 'Số lượng',
            code: 'Mã vật tư',
            unit: 'Đơn vị'
        }
    },
    serviceNote: {
        info: { code: 'Mã phiếu' },
        list: {
            title: 'Danh sách đơn hàng',
            code: 'Mã hóa đơn',
            info: 'Thông tin đơn hàng',
            updater: 'Người cập nhật',
            joinedDate: 'Ngày làm việc',
            canceled: 'Hủy đơn',
            pay: 'Thanh toán',
            approveCheckout: 'Xác nhận thanh toán',
            cancelMsg: 'Bạn có chắc muốn hủy đơn này',
            cancelCfm: 'Sau khi hủy, trợ lý sẽ không nhận được đơn hàng nữa'
        },
        form: {
            showCusInfo: 'Thông tin khách hàng',
            showServiceDetail: 'Thông tin dịch vụ',
            servicePackage: 'Gói dịch vụ',
            extraService: 'Dịch vụ thêm',
            status: 'Trạng thái đơn',
            updateStatus: 'Cập nhật trạng thái đơn',
            paymentStatus: 'Trạng thái thanh toán',
            weekdays: 'Chọn ngày',
            selectMonths: 'Số tháng sử dụng',
            workDetail: 'Ghi chú của khách',
            specialRequest: 'Yêu cầu đặc biệt',
            promotion: 'Khuyến mại',
            noteAddress: 'Ghi chú địa chỉ',
            surveyDate: 'Ngày khảo sát'
        },
        error: {
            required: 'Lý do giảm giá không được bỏ trống',
            serviceRequired: 'Dịch vụ không được bỏ trống',
            placeRequired: 'Khu vực không được bỏ trống',
            serviceStaffRequire: 'Nhân viên đặt hẹn không được để trống',
            discountNoteRequired: 'Lí do giảm giá không được để trống'
        }
    },
    serviceNoteDetail: {
        form: {
            insertTitle: 'Thêm mới Dịch Vụ',
            updateTitle: 'Chỉnh sửa Dịch Vụ',
            serviceName: 'Tên Dịch Vụ',
            serviceCode: 'Mã Dịch vụ',
            placeName: 'Khu Vực',
            staff: 'Nhân Viên làm'
        }
    },
    setting: {
        title: { label: 'Cấu hình' },
        account: {
            title: 'Danh sách tài khoản',
            role: 'Quyền người dùng'
        },
        page: { title: 'Danh sách trang' },
        name: { label: 'Tên' },
        role: { title: 'Danh sách quyền', info: 'Thông tin quyền' },
        area: { title: 'Danh sách khu vực' },
        customer: { title: 'Danh sách khách hàng' },
        branch: { title: 'Danh sách chi nhánh' }
    },
    supplier: {
        titlePopup: {
            Addnewsupplier: 'Thêm mới nhà cung cấp',
            Editsupplier: 'Chỉnh sửa nhà cung cấp'
        }
    },
    specialRequest: {
        form: {
            name: 'Tên yêu cầu'
        },
        list: {
            title: 'Danh sách yêu cầu đặc biệt',
            pageUnit: 'yêu cầu'
        }
    }
};
