export const viU = {
    // U
    user: {
        list: {
            title: 'Danh sách Nhân viên',
            adminTitle: 'Danh sách admin',
            selectTitle: 'Danh sách được chọn',
            deleteAll: 'Xóa tất cả',
            unit: 'nhân viên',
            adminUnit: 'admin',
            code: 'Mã NV'
        },
        form: {
            insertTitle: 'Thêm mới Nhân viên',
            info: 'Thông tin cá nhân',
            enrollDate: 'Ngày vào làm',
            email: 'Email',
            facebook: 'Facebook',
            infoTitle: 'Thông tin Nhân viên',
            joinedDate: 'Ngày bắt đầu làm việc',
            fullname: 'Họ và Tên',
            gender: 'Giới tính',
            account: 'Tài khoản',
            identify: 'CMND',
            identifyDate: 'Ngày cấp CMND',
            passport: 'Hộ chiếu',
            passportDate: 'Ngày cấp Hộ chiếu',
            passportPlace: 'Nơi cấp Hộ chiếu',
            permanentResidence: 'Hộ khẩu thường trú',
            taxIdentificationNumber: 'Mã số TTNCN',
            nationality: 'Quốc tịch',
            bankAccountNumber: 'Số TKNH',
            serviceTitle: 'Dịch vụ làm được',
            idCardFront: 'Mặt trước CMND',
            idCardBack: 'Mặt sau CMND'
        }
    },
    userService: {
        list: {
            title: 'Danh sách Nhân viên thực hiện Dịch vụ',
            unit: 'nhân viên'
        },
        serviceList: {
            insertTitle: 'Thêm mới Dịch vụ làm được',
            updateTitle: 'Chỉnh sửa Dịch Vụ làm được',
            title: 'Danh sách Dịch vụ làm được',
            unit: 'dịch vụ',
            commissionRate: '% Hoa Hồng',
            executeTime: 'Số phút thực hiện',
            price: 'Đơn giá',
            code: 'Mã Dịch Vụ',
            name: 'Tên Dịch Vụ'
        },
        form: { selectTitle: 'Chọn nhân viên' }
    },
    userCustom: {
        member: {
            fullname: 'Họ và tên',
            identify: 'CMND',
            passport: 'Hộ chiếu',
            email: 'Email',
            address: 'Địa chỉ'
        }
    },
    usercustom: {
        custom: 'Tùy chỉnh',
        personalinformation: 'Thông tin cá nhân',
        usercustom: 'Tùy chỉnh người dùng',
        Defaultcolor: 'Màu mặc định',
        Displaylanguage: 'Ngôn ngữ hiển thị',
        usedlanguage: 'Ngôn ngữ sử dụng',
        language: 'Ngôn ngữ ',
        Defaultbranch: 'Chi nhánh mặc định',
        numberpage: 'Số bản ghi hiển thị trang',
        historyService: 'Dịch vụ đã làm',
        ProductBought: 'Sản phẩm đã mua',
        accumulatePoints: 'Tích điểm',
        bill: 'Hóa đơn'
    }
};
