import React, { Component } from 'react';
import { Button, TextBox } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';
import i18n from '../../../i18n';
class RenamePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newFolderName: this.props.selectedItem.name,
            extension: ''
        };

        this.onFolderNameValueChanged = this.onFolderNameValueChanged.bind(this);
        this.renameFolder = this.renameFolder.bind(this);
        this.hideRenamePopup = this.hideRenamePopup.bind(this);
    }

    componentDidMount() {
        const { selectedItem } = this.props;
        if (!selectedItem.isDirectory) this.getNameWithoutExtension();
    }

    getNameWithoutExtension = () => {
        const { selectedItem } = this.props;
        const extension = selectedItem.name.split('.').pop();
        const newName = selectedItem.name.split('.').slice(0, -1).join('.');

        this.setState({
            newFolderName: newName,
            extension
        });
    }

    joinName = () => {
        const { newFolderName, extension } = this.state;
        const nameArr = [newFolderName, extension];
        const newJoinedName = nameArr.join('.');
        return newJoinedName;
    }

    onFolderNameValueChanged(e) {
        if (e.event !== undefined && e.event !== null) {
            this.setState({
                newFolderName: e.value
            });
        }
    }

    renameFolder() {
        const { selectedItem } = this.props;
        let changedName = '';
        if (!selectedItem.isDirectory) {
            changedName = this.joinName();
        } else {
            changedName = this.state.newFolderName;
        }

        this.props.renameFolder(changedName);
    }

    hideRenamePopup() {
        this.props.hideRenamePopup();
    }

    render() {
        const { isShowRenamePopup } = this.props;
        const { newFolderName } = this.state;
        const { currentLanguage } = this.props;
        return (
            <Popup
                maxHeight={200}
                maxWidth={500}
                closeOnOutsideClick={true}
                title={i18n.t('fileManagement.popup.renameTitle')}
                visible={isShowRenamePopup}
                onHiding={this.hideRenamePopup}
                onHidden={() => console.log('onHidden')}
                className={'photo-popup-content'}>

                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>{i18n.t('general.form.Folderfilename', { currentLanguage })}:<span className="color-red"> *</span></div>
                    <div className={'dx-field-value'}>
                        <TextBox
                            className={'multi-language'}
                            name={'name'}
                            valueChangeEvent={'keyup'}
                            value={newFolderName || ''}
                            onValueChanged={this.onFolderNameValueChanged}
                        />
                    </div>
                </div>

                <div className={'mt-3 '} style={{ textAlign: 'right' }}>
                    <Button
                        className={'button-info mx-1 dx-theme-accent-as-background-color'}
                        text={i18n.t('general.button.save', { currentLanguage })} width={'120'}
                        onClick={this.renameFolder} />
                    <Button
                        className={'button-info'}
                        text={i18n.t('general.button.cancel', { currentLanguage })} width={'120'}
                        onClick={this.hideRenamePopup} />
                </div>
            </Popup>
        );
    }
}
export default RenamePopup;
