import {
    FOLDER_DELETE,
    FOLDER_LIST,
    FOLDER_SEARCH,
    FOLDER_SHARE_LIST,
    FOLDER_UPDATE,
    FOLDER_INSERT,
    FOLDER_EDIT,
    FOLDER_TREE,
    FOLDER_RESET,

    FILE_UPLOAD,
    FILE_DELETE,
    FILE_EDIT
} from "./action-types";

export function reset(payload) {
    return {
        type: FOLDER_RESET,
        payload
    }
}

export function list(payload) {
    return {
        type: FOLDER_LIST,
        payload
    }
}

export function update(payload) {
    return {
        type: FOLDER_UPDATE,
        payload
    }
}

export function deletes(payload) {
    return {
        type: FOLDER_DELETE,
        payload
    }
}

export function folderSearch(payload) {
    return {
        type: FOLDER_SEARCH,
        payload
    }
}

export function folderShare(payload) {
    return {
        type: FOLDER_SHARE_LIST,
        payload
    }
}

export function folderInsert(payload) {
    return {
        type: FOLDER_INSERT,
        payload
    }
}

export function folderDelete(payload) {
    return {
        type: FOLDER_DELETE,
        payload
    }
}

export function folderEdit(payload) {
    return {
        type: FOLDER_EDIT,
        payload
    }
}

export function folderTree(payload) {
    return {
        type: FOLDER_TREE,
        payload
    }
}

export function fileUpload(payload) {
    return {
        type: FILE_UPLOAD,
        payload
    }
}

export function fileDelete(payload) {
    return {
        type: FILE_DELETE,
        payload
    }
}

export function fileEdit(payload) {
    return {
        type: FILE_EDIT,
        payload
    }
}