import {
    WEBSITE_SERVICE_SEARCH,
    WEBSITE_SERVICE_DETAIL,
    WEBSITE_SERVICE_META,
    WEBSITE_SERVICE_RESET,
    WEBSITE_SERVICE_GETALL,
    WEBSITE_SERVICE_DELETE,
    WEBSITE_SERVICE_TITLE_GETALL,
    WEBSITE_SERVICE_TITLE_INSERT,
    WEBSITE_SERVICE_TITLE_ITEM_GETALL,
    WEBSITE_SERVICE_FAQ_GETALL,
    WEBSITE_SERVICE_FAQ_INSERT,
    WEBSITE_SERVICE_FAQ_UPDATE,
    WEBSITE_SERVICE_FAQ_DETAIL
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    item: {},
    currentMetaData: {
        title: {},
        metaTitle: '',
        description: {},
        metaDescription: '',
        metaKeyword: '',
        seoLink: '',
        image: '',
        alt: '',
        videoUrl: '',
        thumbnail: '',
        isActive: true,
        concurrencyStamp: '',
        tags: [],
        serviceTitleMeta: [],
        serviceFaqMeta: []
    },
    title: {
        data: [],
        totalRows: 0,
        item: {
            data: [],
            totalRows: 0
        }
    },
    faq: {
        data: [],
        totalRows: 0,
        detail: {}
    },
    suggestion: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case WEBSITE_SERVICE_SEARCH:
            return search(state, payload);
        case WEBSITE_SERVICE_META:
            return updateCurrentMetaData(state, payload);
        case WEBSITE_SERVICE_RESET:
            return reset(state);
        case WEBSITE_SERVICE_DELETE:
            return deletes(state, payload);
        case WEBSITE_SERVICE_DETAIL:
            return detail(state, payload);
        case WEBSITE_SERVICE_GETALL:
            return getAll(state, payload);

        case WEBSITE_SERVICE_TITLE_GETALL:
            return titleGetAll(state, payload);
        case WEBSITE_SERVICE_TITLE_INSERT:
            return titleInsert(state, payload);

        case WEBSITE_SERVICE_TITLE_ITEM_GETALL:
            return titleItemGetAll(state, payload);

        case WEBSITE_SERVICE_FAQ_GETALL:
            return faqGetAll(state, payload);
        case WEBSITE_SERVICE_FAQ_INSERT:
            return faqInsert(state, payload);
        case WEBSITE_SERVICE_FAQ_UPDATE:
            return faqUpdate(state, payload);
        case WEBSITE_SERVICE_FAQ_DETAIL:
            return faqDetail(state, payload);
        default:
            return state;
    }
};

// faq
function faqGetAll(state, payload) {
    state = Object.assign({}, state, {
        faq: {
            data: payload,
            totalRows: payload.length,
            detail: {}
        }
    });
    return state;
}

function faqInsert(state, payload) {
    return state;
}

function faqUpdate(state, payload) {
    return state;
}

function faqDetail(state, payload) {
    state = Object.assign({}, state, {
        faq: {
            ...state.faq,
            detail: payload
        }
    });

    return state;
}

// title
function titleGetAll(state, payload) {
    state = Object.assign({}, state, {
        title: {
            data: payload,
            totalRows: payload.length,
            item: {
                data: [],
                totalRows: 0
            }
        }
    });
    return state;
}

function titleInsert(state, payload) {
    return state;
}

function titleItemGetAll(state, payload) {
    const title = Object.assign({}, state.title, {
        item: {
            data: payload,
            totalRows: payload.length
        }
    });

    state = Object.assign({}, state, {
        title
    });

    return state;
}

const detail = (state, payload) => {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
};

const search = (state, payload) => {
    state = { ...state, data: payload.data, totalRows: payload.totalRows };

    return state;
};

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload);

    return Object.assign({}, state, { data });
}

const updateCurrentMetaData = (state, payload) => {
    if (!payload) {
        state = {
            ...state,
            currentMetaData: {
                title: {},
                metaTitle: '',
                description: {},
                metaDescription: '',
                metaKeyword: '',
                seoLink: '',
                image: '',
                alt: '',
                videoUrl: '',
                thumbnail: '',
                isActive: true,
                concurrencyStamp: '',
                tags: [],
                serviceTitleMeta: [],
                serviceFaqMeta: []
            }
        };
    } else {
        state = { ...state, currentMetaData: payload };
    }
    return state;
};

const reset = (state) => {
    state = { ...state, currentMetaData: initialState.currentMetaData };

    return state;
};

const getAll = (state, payload) => {
    state = { ...state, suggestion: payload };
    return state;
};

export default reducer;
