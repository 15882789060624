import BaseViewModel from '../../base/BaseViewModel';
import { SERVICE_NOTE_STATUS, FORMAT_DAY_BYLANG, PAYMENT_STATUS } from '../../constants/Config';
import * as _ from 'lodash';
import moment from 'moment';
import Transformer from '../../utils/Transformer';

export default class ServiceNoteViewModel extends BaseViewModel {
    initialize(props) {
        super.initialize(props);
    }

    initializeWithLanguage(props, languageId) {
        super.initializeWithLanguage(props, languageId);

        // if (_.isNumber(props.type)) {
        //     this.typeName = JSON.parse(props.servicePackageName)[languageId];
        // }
        if (_.isNumber(props.status)) {
            this.statusName = JSON.parse(SERVICE_NOTE_STATUS[props.status].name)[languageId];
        }
        this.joinedDate = moment(props.joinedDate).isValid() ? moment(props.joinedDate).format(FORMAT_DAY_BYLANG[languageId]) : props.joinedDate;
        if (props.serviceNoteDetails && props.serviceNoteDetails.length > 0) {
            this.startTime = props.serviceNoteDetails[0].startTime;
            this.endTime = props.serviceNoteDetails[0].endTime;
            this.finalEndTime = props.serviceNoteDetails[0].endTime;
            this.serviceNoteDetailExtras = props.serviceNoteDetails[0].serviceNoteDetailExtras;
            this.serviceNoteDetailSpecials = props.serviceNoteDetails[0].serviceNoteDetailSpecials;
            this.specialIds = _.map(props.serviceNoteDetails[0].serviceNoteDetailSpecials, item => item.specialRequestId);
            this.joinedDate = props.serviceNoteDetails.length === 1 ? props.serviceNoteDetails[0].joinedDate : props.joinedDate;
            this.numberOfShifts = props.serviceNoteDetails.length;
            this.quantityStaff = props.serviceNoteDetails[0].quantityStaff;
            this.workTimes = [];

            const weekDaysArr = props.weekDays.split('-');
            props.serviceNoteDetails.forEach(item => {
                weekDaysArr.forEach(day => {
                    if (day === moment(item.joinedDate).locale('en').format('dddd').toLowerCase()) {
                        const filterArr = _.filter(this.workTimes, fItem => fItem.code === day);
                        if (filterArr.length === 0) {
                            this.workTimes.push({
                                code: day,
                                startTime: item.startTime,
                                endTime: item.endTime
                            });
                        }
                    }
                });
                item.serviceNoteDetailExtras = this.serviceNoteDetailExtras.map(xItem => xItem.serviceId);
            });
            if (props.type === 1 || props.type === 0) {
                this.workTime = props.serviceNoteDetails[0].workTime >= 60 ? props.serviceNoteDetails[0].workTime / 60 : props.serviceNoteDetails[0].workTime;
                this.oldWorkTime = props.serviceNoteDetails[0].oldWorkTime >= 60 ? props.serviceNoteDetails[0].oldWorkTime / 60 : props.serviceNoteDetails[0].oldWorkTime;
            }
        }
        if (props.promotionName) {
            const promoName = Transformer.IsJsonString(props.promotionName) ? Transformer.convertJSONString(props.promotionName) : props.promotionName;
            const discountPriceTxt = props.discountByPercent ? (props.discountPrice + '%') : Transformer.convertCurrency(props.discountPrice);
            this.displayPromoName = `[${props.promotionCode}] - ${promoName} - ${discountPriceTxt}`;

            if (props.discountByPercent && ((props.discountPrice / 100 * props.totalPrice) > (props.totalPrice - props.totalAmount))) {
                this.discountPriceMax = props.totalPrice - props.totalAmount;
            }
        }

        this.paymentStatusName = '';
        if (props.paymentStatus !== undefined) {
            const statusName = _.find(PAYMENT_STATUS, item => item.id === props.paymentStatus);
            if (statusName) {
                const paymentStatusName = Transformer.convertJSONString(statusName.name, languageId);
                this.paymentStatusName = paymentStatusName;
            }
        }
    }
}
