import { WEBSITE_URL } from "../../../constants/Config";
import Http from "../../../utils/Http";
import * as categoryActions from './store/actions';
import Transformer from '../../../utils/Transformer';
import { spinnerLoading } from "../../../common/spinner/store/action";

export function newsCategorySearchRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            let data = {}

            if(payload.keyword) data.keyword = payload.keyword
            if(payload.page) data.page = payload.page
            if(payload.pageSize) data.pageSize = payload.pageSize
            if(payload.isActive !== null && payload.isActive !== undefined) data.isActive = payload.isActive ? true : false

            let url = `${WEBSITE_URL}categorys/${payload.websiteId}?${Transformer.convertToPramsURI(data)}`

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(categoryActions.search(res.data));
                    resolve()
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error)
                })
        })
    )
}

export function newsCategoryInsertRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            let url = `${WEBSITE_URL}categorys/${payload.websiteId}`

            let categoryMeta = {...payload}

            categoryMeta.name = Transformer.IsJsonString(payload.name) ? payload.name : JSON.stringify(payload.name)
            categoryMeta.description = Transformer.IsJsonString(payload.description) ? payload.description : JSON.stringify(payload.description)
            categoryMeta.metaTitle = Transformer.IsJsonString(payload.metaTitle) ? payload.metaTitle : JSON.stringify(payload.metaTitle)
            categoryMeta.metaDescription = Transformer.IsJsonString(payload.metaDescription) ? payload.metaDescription : JSON.stringify(payload.metaDescription)
            // categoryMeta.seoLink = Transformer.IsJsonString(payload.seoLink) ? payload.seoLink : JSON.stringify(payload.seoLink)

            Http.post(url, categoryMeta)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.response.data.message)
                })
        })
    )
}

export function newsCategoryUpdateRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            let url = `${WEBSITE_URL}categorys/${payload.websiteId}/${payload.id}`

            let categoryMeta = {...payload}

            categoryMeta.name = Transformer.IsJsonString(payload.name) ? payload.name : JSON.stringify(payload.name)
            categoryMeta.description = Transformer.IsJsonString(payload.description) ? payload.description : JSON.stringify(payload.description)
            categoryMeta.metaTitle = Transformer.IsJsonString(payload.metaTitle) ? payload.metaTitle : JSON.stringify(payload.metaTitle)
            categoryMeta.metaDescription = Transformer.IsJsonString(payload.metaDescription) ? payload.metaDescription : JSON.stringify(payload.metaDescription)
            // categoryMeta.seoLink = Transformer.IsJsonString(payload.seoLink) ? payload.seoLink : JSON.stringify(payload.seoLink)

            Http.put(url, categoryMeta)
                .then(res => {
                    resolve(res.data.message)
                })
                .catch(err => {
                    reject(err.response.data.message)
                })
        })
    )
}
export function newsCategoryGetDetailRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            let url = `${WEBSITE_URL}categorys/${payload.websiteId}/${payload.id}`

            Http.get(url)
                .then(res => {
                    dispatch(categoryActions.detail(res.data))
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    )
}

export function newsCategoryGetAllRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            let url = `${WEBSITE_URL}Categorys/get-all/${payload.websiteId}`

            Http.get(url)
                .then((res) => {
                    dispatch(categoryActions.getAll(res.data))
                    resolve()
                })
                .catch((err) => {
                    reject(err)
                })
        })
    )
}

export function newsCategoryDeleteRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            let url = `${WEBSITE_URL}categorys/${payload.websiteId}/${payload.id}`

            Http.delete(url)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err.response.data.message)
                })
        })
    )
}

export function newsCategoryTreeRequest(payload) {
    return dispatch =>(
        new Promise((resolve, reject) => {
            let url = `${WEBSITE_URL}categorys/trees?websiteId=${payload.websiteId}`

            Http.get(url)
                .then(res => {
                    dispatch(categoryActions.tree(res.data))
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    )
}

export function newsCategoryIsHotUpdateRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true))
            let url = `${WEBSITE_URL}categorys/hot/${payload.websiteId}/${payload.id}/${payload.isHot}`

            Http.put(url)
                .then(res => {
                    dispatch(spinnerLoading(false))
                    resolve(res.data.message)
                })
                .catch(err => {
                    dispatch(spinnerLoading(false))
                    reject(err.response.data.message)
                })
        })
    )
}

export function newsCategoryIsHomePageUpdateRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true))
            let url = `${WEBSITE_URL}categorys/home-page/${payload.websiteId}/${payload.id}/${payload.isHomePage}`

            Http.put(url)
                .then(res => {
                    dispatch(spinnerLoading(false))
                    resolve(res.data.message)
                })
                .catch(err => {
                    dispatch(spinnerLoading(false))
                    reject(err.response.data.message)
                })
        })
    )
}