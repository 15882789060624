import {RESOURCE_DELETE, RESOURCE_DETAIL, RESOURCE_INSERT, RESOURCE_LIST, RESOURCE_RESPONSE,
    RESOURCE_UPDATE,RESOURCE_GETALL} from "./action-types";


export function list(payload) {
    return {
        type: RESOURCE_LIST,
        payload
    }
}

export function detail(payload) {
    return {
        type: RESOURCE_DETAIL,
        payload
    }
}

export function update(payload) {
    return {
        type: RESOURCE_UPDATE,
        payload
    }
}

export function deletes(payload) {
    return {
        type: RESOURCE_DELETE,
        payload
    }
}

export function insert(payload) {
    return {
        type: RESOURCE_INSERT,
        payload
    }
}

export function responseError(payload) {
    return {
        type: RESOURCE_RESPONSE,
        payload
    }
}
export function suggestion(payload) {
    return {
        type: RESOURCE_GETALL,
        payload
    }
}
