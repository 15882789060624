import React, { Component } from 'react';
import { Button, TextBox } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';
import i18n from '../../../i18n';
class AddNewFolderPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newFolderName: ''
        };

        this.onFolderNameValueChanged = this.onFolderNameValueChanged.bind(this);
        this.addFolder = this.addFolder.bind(this);
        this.hideAddFolderPopup = this.hideAddFolderPopup.bind(this);
    }

    onFolderNameValueChanged(e) {
        if (e.event !== undefined && e.event !== null) {
            this.setState({
                newFolderName: e.value
            });
        }
    }

    addFolder() {
        this.props.addFolder(this.state.newFolderName);
    }

    hideAddFolderPopup() {
        this.props.hideAddFolderPopup();
    }

    render() {
        const { isShowAddFolderPopup } = this.props;
        const { newFolderName } = this.state;
        const { currentLanguage } = this.props;
        return (
            <Popup
                maxHeight={200}
                maxWidth={'500'}
                closeOnOutsideClick={true}
                title={i18n.t('fileManagement.popup.addNewFolderTitle')}
                visible={isShowAddFolderPopup}
                onHiding={this.hideAddFolderPopup}
                onHidden={() => console.log('onHidden')}
                className={'photo-popup-content'}>

                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>{i18n.t('fileManagement.popup.folderLabel')}:<span className="color-red"> *</span></div>
                    <div className={'dx-field-value'}>
                        <TextBox
                            className={'multi-language'}
                            name={'name'}
                            valueChangeEvent={'keyup'}
                            value={newFolderName || ''}
                            onValueChanged={this.onFolderNameValueChanged}
                        />
                    </div>
                </div>

                <div className={'mt-3 '} style={{ textAlign: 'right' }}>
                    <Button
                        className={'button-info mx-1 dx-theme-accent-as-background-color'}
                        text={i18n.t('general.button.save', { currentLanguage })} width={'120'}
                        onClick={this.addFolder} />
                    <Button
                        className={'button-info'}
                        text={i18n.t('general.button.cancel', { currentLanguage })} width={'120'}
                        onClick={this.hideAddFolderPopup} />

                </div>
            </Popup>
        );
    }
}
export default AddNewFolderPopup;
