import Loadable from "react-loadable";
import LoadingComponent from "../../common/loader/index";

export default [
  {
    path: "/promotion",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./list/index"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/promotion/add",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./form/index"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/promotion/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./form/index"),
      loading: LoadingComponent,
    }),
  },
];
