import { NAILSPA_URL } from '../../constants/Config';
import Http from '../../utils/Http';
import * as customerActions from './store/actions';
import { spinnerLoading } from '../../common/spinner/store/action';
import Transformer from '../../utils/Transformer';
import CustomerViewModel from './CustomerViewModel';

var moment = require('moment');

export function selectCustomer(customerId) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(customerActions.select(customerId));
            resolve();
        });
}

export function resetCustomerDetail() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(customerActions.resetDetail());
            resolve();
        });
}

export function handleDispatchDetailCustomer(data) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(customerActions.detail(data));
            resolve();
        });
}

export function customerSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const data = {
                keyword: payload.keyword,
                page: payload.page,
                pageSize: payload.pageSize
            };
            if (payload.startDate) { data.startDate = moment(payload.startDate).format('YYYY-MM-DD'); }
            if (payload.endDate) { data.endDate = moment(payload.endDate).format('YYYY-MM-DD'); }

            const url = `${NAILSPA_URL}customers?${Transformer.convertToPramsURI(data)}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(customerActions.list({ ...res.data, data: res.data.data.map((customer) => new CustomerViewModel(customer, payload.languageId)) }));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function getAllCustomerDataRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const data = {
                page: payload.page,
                pageSize: payload.pageSize
            };

            const url = `${NAILSPA_URL}customers?${Transformer.convertToPramsURI(data)}`;

            Http.get(url)
                .then((res) => {
                    resolve(res.data.data.map((customer) => new CustomerViewModel(customer, payload.languageId)));
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function customerServiceCardSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const data = {
                keyword: payload.keyword,
                page: payload.page,
                pageSize: payload.pageSize
            };
            if (payload.startDate) { data.startDate = moment(payload.startDate).format('YYYY-MM-DD'); }
            if (payload.endDate) { data.endDate = moment(payload.endDate).format('YYYY-MM-DD'); }

            const url = `${NAILSPA_URL}customers/customer-have-card?${Transformer.convertToPramsURI(data)}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(customerActions.list({ ...res.data, data: res.data.data.map((customer) => new CustomerViewModel(customer, payload.languageId)) }));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function customerSearchWithFilterRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            let url = `${NAILSPA_URL}customers/filter?fullName=${payload.fullName}&phoneNumber=${payload.phoneNumber}`;
            url += payload.birthday
                ? `&birthday=${moment(payload.birthday).format('YYYY-MM-DD')}`
                : '';
            url += payload.email ? `&email=${payload.email}` : '';
            url += payload.userName ? `&userName=${payload.userName}` : '';
            url += payload.gender !== null ? `&gender=${payload.gender}` : '';

            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function customerSearchWithBookingRequest(key) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}customers?keyword=${key}`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function customerInsertRequest(customer) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}customers`;
            const customerUpdate = Object.assign({}, customer);

            customerUpdate.note = JSON.stringify(customer.note);
            customerUpdate.address = JSON.stringify(customer.address);
            customerUpdate.description = JSON.stringify(customer.description);

            Http.post(url, customerUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    customerUpdate.id = res.data.data;
                    customerUpdate.concurrencyStamp = res.data.data;

                    // dispatch(customerActions.detail(customerUpdate));

                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    const errMes = `Code: ${err.response.data.code} - ${err.response.data.message}`;
                    reject(errMes);
                });
        });
}

export function customerGenerationUserNameRequest(fullName) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}customers/generation-user-name?fullName=${fullName}`;

            Http.get(url)
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function customerUpdateRequest(customer) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}customers/${customer.id}`;
            const customerUpdate = Object.assign({}, customer);

            customerUpdate.note = JSON.stringify(customer.note);
            customerUpdate.address = JSON.stringify(customer.address);
            customerUpdate.description = JSON.stringify(customer.description);

            Http.put(url, customerUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    customerUpdate.concurrencyStamp = res.data.data;

                    customer.isFirstTime = true;
                    customerUpdate.description = JSON.parse(customerUpdate.description);
                    customerUpdate.address = JSON.parse(customerUpdate.address);
                    customerUpdate.note = JSON.parse(customerUpdate.note);

                    // dispatch(customerActions.detail(customerUpdate));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    if (err.response.data.code === -4) {
                        const errObj = {
                            message: err.response.data.message,
                            code: err.response.data.code
                        };
                        reject(errObj);
                    }

                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function customerCardNoteUpdateRequest(card) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}customers/note-card/${card.id}?noteCard=${JSON.stringify(card.noteCard)}`;

            Http.put(url)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
}

export function customerDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading({}.hasOwnProperty.call(payload, 'loading') ? payload.loading : false));

            const url = `${NAILSPA_URL}customers/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(customerActions.detail(new CustomerViewModel(res.data.data)));
                    resolve();
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err);
                });
        });
}

export function customerDetailRequestWithoutLoading(id) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}customers/${id}`;

            Http.get(url)
                .then((res) => {
                    dispatch(customerActions.detail(res.data.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function customerDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}customers/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(customerActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function customerGetAllRequest() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}customers/get-all`;

            Http.get(url)
                .then((res) => {
                    dispatch(customerActions.suggestion(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function customerGetAllBalanceRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}customers/get-all-balance/${payload.customerId}`;

            Http.get(url)
                .then((res) => {
                    dispatch(customerActions.getAllBalance(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function customerGetAllServiceCardRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}customers/get-all-customer-servicecard/${payload.customerId}?invoiceDate=${moment().format('YYYY-MM-DD')}`;

            Http.get(url)
                .then((res) => {
                    dispatch(customerActions.getAllServiceCard(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function customerGetCodeRequest() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            // dispatch(spinnerLoading(true))
            const url = `${NAILSPA_URL}customers/code`;
            Http.get(url)
                .then((res) => {
                    // dispatch(spinnerLoading(false))
                    dispatch(customerActions.getCode(res.data.data));
                    // debugger
                    // customerActions.getCode(res.data.data.code)
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                    // dispatch(spinnerLoading(false))
                });
        });
}
export function customerCheckUserName({ userName }) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}customers/check/${userName}`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                    dispatch(spinnerLoading(false));
                });
        });
}
export function customerFilterRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url =
                `${NAILSPA_URL}customers/filter` +
                `?userName=${payload.userName}` +
                `&fullName=${payload.fullName}` +
                `&phoneNumber=${payload.phoneNumber}` +
                `&email=${payload.email}` +
                `&gender=${payload.gender}`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(customerActions.filter(res.data));
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                    dispatch(spinnerLoading(false));
                });
        });
}

export function customerGetBalanceMoney(id) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}customers/detail-balance/${id}`;

            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function customerHistoryPaymentPointRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const data = {
                // branchId: payload.branchId,
            };

            if (payload.branchId) data.branchId = payload.branchId;
            if (payload.keyword) data.keyword = payload.keyword;
            if (payload.startDate) { data.startDate = moment(payload.startDate).format('YYYY-MM-DD'); }
            if (payload.endDate) { data.endDate = moment(payload.endDate).format('YYYY-MM-DD'); }
            if (payload.page) data.page = payload.page;
            if (payload.pageSize) data.pageSize = payload.pageSize;

            const url = `${NAILSPA_URL}customers/history-payment/${payload.customerId
                }?${Transformer.convertToPramsURI(data)}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(customerActions.historyPayment(res.data));
                    resolve();
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err);
                });
        });
}

export function customerHistoryAddPointRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const data = {
                // branchId: payload.branchId,
                // customerId: payload.customerId
            };

            if (payload.branchId) data.branchId = payload.branchId;
            if (payload.keyword) data.keyword = payload.keyword;
            if (payload.startDate) { data.startDate = moment(payload.startDate).format('YYYY-MM-DD'); }
            if (payload.endDate) { data.endDate = moment(payload.endDate).format('YYYY-MM-DD'); }
            if (payload.page) data.page = payload.page;
            if (payload.pageSize) data.pageSize = payload.pageSize;

            const url = `${NAILSPA_URL}customers/history-add/${payload.customerId
                }?${Transformer.convertToPramsURI(data)}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(customerActions.historyAdd(res.data));
                    resolve();
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err);
                });
        });
}

export function convertNumberToString(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}Invoices/conver/${payload.value}/${payload.currentLanguage}`;

            Http.get(url)
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}
