import { NAILSPA_URL } from '../../../constants/Config';
import Http from '../../../utils/Http';
import * as jobActions from './store/actions';
import { spinnerLoading } from '../../../common/spinner/store/action';

export function jobSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}categorys/job?keyword=${payload.keyword}`;
            url +=
                payload.isActive !== null
                    ? `&isActive=${payload.isActive}`
                    : '';
            url += payload.page ? `&page=${payload.page}` : '';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '';

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(jobActions.list(res.data));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function jobSearchValidNameRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}categorys/job?keyword=${payload.keyword}`;
            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function jobInsertRequest(job) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}categorys/job`;
            const jobUpdate = job;
            jobUpdate.name = JSON.stringify(job.name);
            jobUpdate.description = JSON.stringify(job.description);

            Http.post(url, jobUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    job.id = res.data.data;
                    dispatch(jobActions.insert(job));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function jobUpdateRequest(job) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}categorys/job/${job.id}`;
            const jobUpdate = job;
            jobUpdate.name = JSON.stringify(job.name);
            jobUpdate.description = JSON.stringify(job.description);

            Http.put(url, jobUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    job.concurrencyStamp = res.data.data;
                    dispatch(jobActions.update(job));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function jobDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            // dispatch(spinnerLoading(true))
            const url = `${NAILSPA_URL}categorys/job/${payload.id}`;
            Http.get(url)
                .then((res) => {
                    // dispatch(spinnerLoading(false))
                    dispatch(jobActions.detail(res.data.data));
                    resolve();
                })
                .catch((err) => {
                    // dispatch(spinnerLoading(false))
                    reject(err);
                });
        });
}

export function jobDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}categorys/job/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(jobActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function jobGetAll() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}categorys/get-all/job`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(jobActions.suggestion(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
