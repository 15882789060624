import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';
import i18n from '../../i18n';
import PropTypes from 'prop-types';

class DatePickerBox extends Component {
    constructor(props) {
        super(props);

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(e) {
        if (e.event !== undefined && e.event !== null) {
            this.props.updateData({
                [this.props.field]: e.value
            });
        }
    }

    render() {
        const { value, i18nLabel, width, currentLanguage, zoomLvl, displayFormat, min, max } = this.props;

        return (
            <div className="form-group cm-mgr-5">
                <div className={'dx-field'}>
                    {
                        zoomLvl === 'year'
                            ? <DateBox
                                maxZoomLevel={zoomLvl}
                                type={'date'}
                                useMaskBehavior={true}
                                // pickerType="calendar"
                                displayFormat={displayFormat}
                                onValueChanged={this.onValueChanged}
                                showClearButton={true}
                                placeholder={i18n.t(i18nLabel)}
                                value={value}
                                min={min || undefined}
                                max={max || undefined}
                                width={width || 160}
                            />
                            : <DateBox
                                type={'date'}
                                // pickerType="calendar"
                                useMaskBehavior={true}
                                displayFormat={currentLanguage === 'vi-VN' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                                onValueChanged={this.onValueChanged}
                                placeholder={i18n.t(i18nLabel)}
                                showClearButton={true}
                                value={value}
                                min={min || undefined}
                                max={max || undefined}
                                width={width || 160}
                            />
                    }
                </div>
            </div>
        );
    }
}

DatePickerBox.propTypes = {
    value: PropTypes.any,
    i18nLabel: PropTypes.string,
    width: PropTypes.string,
    currentLanguage: PropTypes.string,
    updateData: PropTypes.func,
    field: PropTypes.string,
    zoomLvl: PropTypes.string,
    displayFormat: PropTypes.string
};

export default DatePickerBox;
