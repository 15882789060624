export const PAYMENT_LIST = 'PAYMENT_LIST'
export const PAYMENT_DETAIL = 'PAYMENT_DETAIL'
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE'
export const PAYMENT_INSERT = 'PAYMENT_INSERT'
export const PAYMENT_DELETE = 'PAYMENT_DELETE'
export const PAYMENT_RESPONSE = 'PAYMENT_RESPONSE'
export const PAYMENT_GETALL = 'PAYMENT_GETALL'
export default {
    PAYMENT_DELETE,
    PAYMENT_DETAIL,
    PAYMENT_INSERT,
    PAYMENT_LIST,
    PAYMENT_UPDATE,
    PAYMENT_GETALL
}
