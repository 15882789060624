import React, { Component } from 'react';
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import i18n from '../../i18n';

class HTMLEditorBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valueContent: '',
            editorValueType: 'html',
            isMultiline: true
        };

        this.sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
        this.fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
        this.headerValues = [false, 1, 2, 3, 4, 5];
        this.enabled = {
            enabled: true
        };

        this.valueChanged = this.valueChanged.bind(this);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                valueContent: nextProps.value
            });
        }
    }

    valueChanged(e) {
        this.setState({
            valueContent: e.value
        });

        this.props.updateData({
            [this.props.field]: e.value
        });
    }

    render() {
        const { valueContent, editorValueType } = this.state;
        const { i18nLabel, validatorValue } = this.props;

        return (
            <div className="form-group mb-3 cm-mgr-5">
                <div className="form-group mb-3 cm-mgr-5">
                    <div className={'dx-field'}>
                        <div className={'dx-field-label'}>
                            {i18n.t(i18nLabel) + ':'}{validatorValue && validatorValue.includes('require') && <span className="color-red"> *</span>}
                        </div>

                        <div className={'dx-field-value'}>
                            <div className="form-group mb-3 cm-mgr-5">

                                <div className={'row m-0'}>
                                    <div className={'dx-field-value w100pc my-3'}>
                                        <HtmlEditor
                                            value={valueContent}
                                            valueType={editorValueType}
                                            onValueChanged={this.valueChanged}
                                            height="200px">
                                            <MediaResizing enabled={true} />
                                            <Toolbar multiline={true}>
                                                <Item formatName="undo" />
                                                <Item formatName="redo" />
                                                <Item formatName="separator" />
                                                <Item
                                                    formatName="size"
                                                    formatValues={this.sizeValues}
                                                />
                                                <Item
                                                    formatName="font"
                                                    formatValues={this.fontValues}
                                                />
                                                <Item formatName="separator" />
                                                <Item formatName="bold" />
                                                <Item formatName="italic" />
                                                <Item formatName="strike" />
                                                <Item formatName="underline" />
                                                <Item formatName="separator" />
                                                <Item formatName="alignLeft" />
                                                <Item formatName="alignCenter" />
                                                <Item formatName="alignRight" />
                                                <Item formatName="alignJustify" />
                                                <Item formatName="separator" />
                                                <Item formatName="orderedList" />
                                                <Item formatName="bulletList" />
                                                <Item formatName="separator" />
                                                <Item
                                                    formatName="header"
                                                    formatValues={this.headerValues}
                                                />
                                                <Item formatName="separator" />
                                                <Item formatName="color" />
                                                <Item formatName="background" />
                                                <Item formatName="separator" />
                                                <Item formatName="link" />
                                                <Item formatName="image" />
                                                <Item formatName="separator" />
                                                <Item formatName="clear" />
                                                <Item formatName="codeBlock" />
                                                <Item formatName="blockquote" />
                                            </Toolbar>
                                        </HtmlEditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HTMLEditorBox;
