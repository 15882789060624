import { PAGE_SIZE } from "../../../../constants/Config";
import {
    EMPLOYEE_POSITION_DELETE,
    EMPLOYEE_POSITION_DETAIL,
    EMPLOYEE_POSITION_INSERT,
    EMPLOYEE_POSITION_LIST,
    EMPLOYEE_POSITION_UPDATE,
    EMPLOYEE_POSITION_GET_ALL,
} from "./action-types";


const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    items: [],
    item: null,
    getAll: [],
    suggestion: []
}

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case EMPLOYEE_POSITION_LIST:
            return list(state, payload)
        case EMPLOYEE_POSITION_DETAIL:
            return detail(state, payload)
        case EMPLOYEE_POSITION_UPDATE:
            return update(state, payload)
        case EMPLOYEE_POSITION_INSERT:
            return insert(state, payload)
        case EMPLOYEE_POSITION_DELETE:
            return deletes(state, payload)
        case EMPLOYEE_POSITION_GET_ALL:
            return getAll(state, payload)
        default:
            return state;
    }
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    })
    return state
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload);
    return Object.assign({}, state, { data }, { totalRows: state.totalRows - 1 });
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload }
        }
        return obj
    })

    return Object.assign({}, state, { data })
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data })
}

function getAll(state, payload) {
    state = Object.assign({}, state, {
        getAll: payload
    })
    return state
}

export default reducer
