import { PAGE_SIZE } from "../../../../constants/Config";
import {
    UNIT_DELETE,
    UNIT_DETAIL,
    UNIT_INSERT,
    UNIT_LIST,
    UNIT_UPDATE,
    UNIT_GETALL,
    UNIT_BYPRODUCT_GETALL,
} from "./action-types";


const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    items: [],
    item: null,
    suggestion: [],
    suggestionByProduct: [],
}

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case UNIT_LIST:
            return list(state, payload)
        case UNIT_DETAIL:
            return detail(state, payload)
        case UNIT_UPDATE:
            return update(state, payload)
        case UNIT_INSERT:
            return insert(state, payload)
        case UNIT_DELETE:
            return deletes(state, payload)
        case UNIT_GETALL:
            return suggestion(state, payload)
        case UNIT_BYPRODUCT_GETALL:
            return suggestionByProduct(state, payload)
        default:
            return state;
    }
}

function list(state, payload) {
    state = Object.assign({}, state,  payload)
    return state
}
function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    })
    return state
}
function suggestionByProduct(state, payload) {
    state = Object.assign({}, state, {
        suggestionByProduct: payload
    })
    return state
}
function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    })
    return state
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload)

    return Object.assign({}, state, { data })
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload }
        }
        return obj
    })

    return Object.assign({}, state, { data })
}

function insert(state, payload) {
    const data = [...state.data, payload]
    return Object.assign({}, state, { data })
}
// function getAll(state, payload){
//     state = Object.assign({}, state, {data: payload})
//     return state
// }
export default reducer
