export const EMPLOYEE_POSITION_LIST = 'EMPLOYEE_POSITION_LIST'
export const EMPLOYEE_POSITION_DETAIL = 'EMPLOYEE_POSITION_DETAIL'
export const EMPLOYEE_POSITION_UPDATE = 'EMPLOYEE_POSITION_UPDATE'
export const EMPLOYEE_POSITION_INSERT = 'EMPLOYEE_POSITION_INSERT'
export const EMPLOYEE_POSITION_DELETE = 'EMPLOYEE_POSITION_DELETE'
export const EMPLOYEE_POSITION_GET_ALL = 'EMPLOYEE_POSITION_GET_ALL'
export default {
    EMPLOYEE_POSITION_DELETE,
    EMPLOYEE_POSITION_DETAIL,
    EMPLOYEE_POSITION_INSERT,
    EMPLOYEE_POSITION_LIST,
    EMPLOYEE_POSITION_UPDATE,
    EMPLOYEE_POSITION_GET_ALL,
}
