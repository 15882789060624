export const CUSTOMER_FEEDBACK_LIST = 'CUSTOMER_FEEDBACK_LIST'
export const CUSTOMER_FEEDBACK_DETAIL = 'CUSTOMER_FEEDBACK_DETAIL'
export const CUSTOMER_FEEDBACK_UPDATE = 'CUSTOMER_FEEDBACK_UPDATE'
export const CUSTOMER_FEEDBACK_INSERT = 'CUSTOMER_FEEDBACK_INSERT'
export const CUSTOMER_FEEDBACK_DELETE = 'CUSTOMER_FEEDBACK_DELETE'
export const CUSTOMER_FEEDBACK_RESPONSE = 'CUSTOMER_FEEDBACK_RESPONSE'
export const CUSTOMER_FEEDBACK_GETALL = 'CUSTOMER_FEEDBACK_GETALL'

export default {
    CUSTOMER_FEEDBACK_DELETE,
    CUSTOMER_FEEDBACK_DETAIL,
    CUSTOMER_FEEDBACK_INSERT,
    CUSTOMER_FEEDBACK_LIST,
    CUSTOMER_FEEDBACK_UPDATE,
    CUSTOMER_FEEDBACK_GETALL
}
