import React, { Component } from 'react';
import { DropDownBox } from 'devextreme-react';
import DataGrid, {
    Selection,
    Paging,
    FilterRow,
    Scrolling
} from 'devextreme-react/data-grid';
import { servicePackageGetAllRequest } from '../../modules/service/servicePackage/service';
import i18n from '../../i18n';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Transformer from '../../utils/Transformer';
import * as _ from 'lodash';
// import ServicePackageViewModel from '../../modules/service/servicePackage/ServicePackageViewModel';

class ServicePackageSelectBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            value: null
        };
        this.dataGridRef = React.createRef();
    }

    get dataGrid() {
        return this.dataGridRef.current.instance;
    }

    // eslint-disable-next-line camelcase
    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (nextProps.currentlanguageActive !== this.props.currentlanguageActive) {
    //         this.props.getAllData()
    //             .then((res) => {
    //                 // const listData = res.map(item => new ServicePackageViewModel(item, nextProps.currentlanguageActive));
    //                 const listData = [...res];
    //                 listData.forEach(item => {
    //                     item.formatedName = Transformer.convertJSONString(item.name, nextProps.currentlanguageActive);
    //                 });
    //                 this.setState({ listData });
    //             });
    //     }
    // }

    componentDidMount() {
        this.getAllData();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== null && props.value !== undefined) {
            return {
                value: props.value
            };
        } else return null;
    }

    // do side effect task after component update
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== null && prevProps.value !== undefined) {
            const { field } = this.props;
            this.props.updateData({
                formValidate: {
                    [field]: true
                }
            });
        }
    }

    getAllData = () => {
        const { currentlanguageActive } = this.props;
        this.props.getAllData()
            .then((res) => {
                const listData = [...res];
                listData.forEach(item => {
                    item.formatedName = Transformer.convertJSONString(item.name, currentlanguageActive);
                });
                this.setState({ listData });
            });
    }

    dataGridRender = () => {
        const { listData } = this.state;
        const { value, selectionMode } = this.props;
        let valueArr = [];
        if (selectionMode === 'single' && value) {
            valueArr.push(value);
        } else if (selectionMode === 'multiple' && value.length > 0) {
            valueArr = [...value];
        }
        return (
            <DataGrid
                hoverStateEnabled={true}
                dataSource={listData}
                columns={[{ dataField: 'formatedName', caption: 'Name', cssClass: 'px-2' }]}
                selectedRowKeys={valueArr}
                wordWrapEnabled={true}
                onSelectionChanged={this.onCustomItemCreating}
                keyExpr={'id'}
                height="100%"
            >
                <Selection mode={selectionMode || 'single'} />
                <Scrolling mode="infinite" />
                <Paging enabled={true} pageSize={10} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    };

    onCustomItemCreating = (e) => {
        const { field, selectionMode } = this.props;
        const { listData } = this.state;
        if (e.selectedRowKeys.length > 0) {
            if (field === 'type') {
                const selectedPackage = _.find(listData, item => item.id === e.selectedRowKeys[0]);
                if (selectedPackage) {
                    const mainService = _.find(selectedPackage.servicePackageDetails, item => item.isMain === true);

                    const data = {
                        [field]: selectedPackage.type,
                        servicePackageId: selectedPackage.id,
                        mainService: mainService || {}
                    };
                    this.props.updateData(data);
                }
            } else {
                this.props.updateData({ [field]: e.selectedRowKeys.length && e.selectedRowKeys });
            }
        }

        if (selectionMode === 'single') this.dataGrid.close();
    };

    syncDataGridSelection = (e) => {
        const { field } = this.props;

        this.props.updateData({
            [field]: e.value || []
        });
    };

    render() {
        const { listData } = this.state;
        const {
            i18nLabel, displayExpr, deferRendering, valueExpr,
            showClearButton, value, validatorValue,
            readOnly
        } = this.props;
        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>
                    {i18n.t(i18nLabel) + ':'}
                    {validatorValue &&
                        validatorValue.includes('require') && (<span style={{ color: 'red' }}> *</span>)}
                </div>
                <div className={'dx-field-value  '}>
                    <DropDownBox
                        ref={this.dataGridRef}
                        value={value}
                        valueExpr={valueExpr}
                        deferRendering={deferRendering}
                        displayExpr={displayExpr}
                        showClearButton={showClearButton}
                        dataSource={listData}
                        onValueChanged={this.syncDataGridSelection}
                        contentRender={this.dataGridRender}
                        readOnly={readOnly}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllData: () => {
            return dispatch(servicePackageGetAllRequest());
        }
    };
};

ServicePackageSelectBox.propTypes = {
    i18nLabel: PropTypes.any,
    displayExpr: PropTypes.any,
    deferRendering: PropTypes.any,
    valueExpr: PropTypes.any,
    showClearButton: PropTypes.any,
    disabled: PropTypes.any,
    value: PropTypes.any,
    updateData: PropTypes.any,
    field: PropTypes.any,
    serviceId: PropTypes.any,
    branchId: PropTypes.any,
    currentlanguageActive: PropTypes.any,
    getAllData: PropTypes.any,
    selectionMode: PropTypes.string,
    validatorValue: PropTypes.string,
    readOnly: PropTypes.bool
};

export default connect(null, mapDispatchToProps)(ServicePackageSelectBox);
