import {
    BRANCHS_DETAIL, BRANCHS_LIST, BRANCHS_INSERT, BRANCHS_UPDATE,
    BRANCHS_DELETE,BRANCHS_GETALL, BRANCHS_USER_LIST
} from "./action-types";

export function list(payload) {
    return {
        type: BRANCHS_LIST,
        payload
    }
}

export function userList(payload) {
    return {
        type: BRANCHS_USER_LIST,
        payload
    }
}

export function detail(payload) {
    return {
        type: BRANCHS_DETAIL,
        payload
    }
}

export function update(payload) {
    return {
        type: BRANCHS_UPDATE,
        payload
    }
}

export function insert(payload) {
    return {
        type: BRANCHS_INSERT,
        payload
    }
}

export function deletes(payload) {
    return {
        type: BRANCHS_DELETE,
        payload
    }
}
export function suggestion(payload) {
    return {
        type: BRANCHS_GETALL,
        payload
    }
}