export const PROMOTION_CARD_LIST = "PROMOTION_CARD_LIST";
export const PROMOTION_CARD_DETAIL = "PROMOTION_CARD_DETAIL";
export const PROMOTION_CARD_UPDATE = "PROMOTION_CARD_UPDATE";
export const PROMOTION_CARD_INSERT = "PROMOTION_CARD_INSERT";
export const PROMOTION_CARD_DELETE = "PROMOTION_CARD_DELETE";
export const PROMOTION_CARD_GET_ALL = "PROMOTION_CARD_GET_ALL";
export const PROMOTION_CARD_GET_ALL_BY_TIME = "PROMOTION_CARD_GET_ALL_BY_TIME";
export default {
    PROMOTION_CARD_DELETE,
    PROMOTION_CARD_DETAIL,
    PROMOTION_CARD_INSERT,
    PROMOTION_CARD_LIST,
    PROMOTION_CARD_UPDATE,
    PROMOTION_CARD_GET_ALL,
    PROMOTION_CARD_GET_ALL_BY_TIME,
};
