import {
    NEWS_CATEGORY_DETAIL,
    NEWS_CATEGORY_GETALL,
    NEWS_CATEGORY_SEARCH,
    NEWS_CATEGORY_TREE,
}from './action-types'

export function search(payload) {
    return {
        type: NEWS_CATEGORY_SEARCH,
        payload
    }
}

export function detail(payload) {
    return {
        type: NEWS_CATEGORY_DETAIL,
        payload
    }
}

export function getAll(payload) {
    return {
        type: NEWS_CATEGORY_GETALL,
        payload
    }
}

export function tree(payload) {
    return {
        type: NEWS_CATEGORY_TREE,
        payload
    }
}