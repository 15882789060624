import {
    WEBSITE_LIST,
    WEBSITE_DELETE,
    WEBSITE_DETAIL,
    WEBSITE_INSERT,
    WEBSITE_GETALL,
    WEBSITE_UPDATE,
    WEBSITE_GETALL_BYUSER,
    WEBSITE_SELECT,
    WEBSITE_GET_LANGUAGES,
    WEBSITE_GET_USERS,
    WEBSITE_INSERT_USER,
    WEBSITE_DELETE_USER,
    WEBSITE_INSERT_LANGUAGES,
    WEBSITE_UPDATE_LANGUAGES,
    WEBSITE_DELETE_LANGUAGES,
    WEBSITE_UPDATE_ACTIVE,
    WEBSITE_SETTING_CHECK
} from './action-types';

export function checkWebsite() {
    return {
        type: WEBSITE_SETTING_CHECK
    };
}

export function deleteUser(payload) {
    return {
        type: WEBSITE_DELETE_USER,
        payload
    };
}

export function insertUser(payload) {
    return {
        type: WEBSITE_INSERT_USER,
        payload
    };
}

export function getUser(payload) {
    return {
        type: WEBSITE_GET_USERS,
        payload
    };
}

export function getLanguage(payload) {
    return {
        type: WEBSITE_GET_LANGUAGES,
        payload
    };
}

export function insertLanguage(payload) {
    return {
        type: WEBSITE_INSERT_LANGUAGES,
        payload
    };
}

export function updateLanguage(payload) {
    return {
        type: WEBSITE_UPDATE_LANGUAGES,
        payload
    };
}

export function deleteLanguage(payload) {
    return {
        type: WEBSITE_DELETE_LANGUAGES,
        payload
    };
}

export function list(payload) {
    return {
        type: WEBSITE_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: WEBSITE_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: WEBSITE_UPDATE,
        payload
    };
}

export function updateActive(payload) {
    return {
        type: WEBSITE_UPDATE_ACTIVE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: WEBSITE_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: WEBSITE_INSERT,
        payload
    };
}

export function suggestion(payload) {
    return {
        type: WEBSITE_GETALL,
        payload
    };
}

export function getAllbyUser(payload) {
    return {
        type: WEBSITE_GETALL_BYUSER,
        payload
    };
}

export function select(payload) {
    return {
        type: WEBSITE_SELECT,
        payload
    };
}
