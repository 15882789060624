import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCogs, faBars, faBell, faCaretDown, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Notification from '../../modules/notification/pages';
import { DropDownButton } from 'devextreme-react';
// import ThemeColor from '../../modules/core/theme';
// import Branch from './branch/index';
// import WebsiteSelectHeader from '../../modules/website/website/components/WebsiteSelectHeader';
import { FILE_URL, DEFAULT_AVATAR, THEMES_LIST } from '../../constants/Config';
import i18n from '../../i18n';
import PropTypes from 'prop-types';
import Toolbar, { Item } from 'devextreme-react/toolbar';

const displaName = 'Private header';
library.add(faCogs, faBars, faBell, faCaretDown, faUser, faSignOutAlt);

const PrivateHeader = ({ currentUser, initNewData, toggleShowForm, logout, currentLanguage, showSideBar }) => {
    const [theme, setTheme] = useState(null);
    const history = useHistory();

    useEffect(() => {
        let themeId = localStorage.getItem('_theme');
        if (!themeId) {
            themeId = 'dx.dark.css';
            localStorage.setItem('_theme', themeId);
        }

        setTheme(themeId);
    }, []);

    const onItemClick = (e) => {
        if (e.itemData.event) {
            e.itemData.event(e);
        }
    };

    const changeTheme = (args) => {
        localStorage.setItem('_theme', args.value);
        window.location.reload();
    };

    const themesOptions = {
        items: THEMES_LIST,
        value: theme,
        displayExpr: 'name',
        valueExpr: 'value',
        onValueChanged: changeTheme
    };

    return (
        <Toolbar>
            <Item location="before" widget='dxButton' locateInMenu="auto" options={{
                icon: 'menu',
                onClick: showSideBar
            }} />
            <Item location="before" locateInMenu="auto" >
                <Link to="/"><img src={`${FILE_URL}/${initNewData ? initNewData.logo : ''}`} height={30} alt="Mori-Mori" /></Link>
            </Item>
            {/* <Item location="after" locateInMenu="auto" >
                    <WebsiteSelectHeader />
                </Item> */}
            {/* <Item location="after"
                    locateInMenu="auto" >
                    <Branch />
                </Item> */}
            {/* <Item location="after"
                    locateInMenu="auto" >
                    <ThemeColor />
                </Item> */}
            <Item location="after"
                widget="dxSelectBox"
                options={themesOptions}
                locateInMenu="auto" />
            <Item location="after"
                locateInMenu="never" >
                <DropDownButton
                    splitButton={true}
                    width={200}
                    useSelectMode={false}
                    text={currentUser.fullName}
                    icon={currentUser.avatar ? `${FILE_URL}/${currentUser.avatar}` : `${DEFAULT_AVATAR}`}
                    items={[
                        { id: 1, name: i18n.t('usercustom.personalinformation', { lng: currentLanguage }), icon: 'user', event: (e) => history.push('/config/usercustom') },
                        { id: 2, name: i18n.t('general.navbar.changepass', { lng: currentLanguage }), icon: 'pulldown', event: (e) => toggleShowForm(e) },
                        { id: 3, name: i18n.t('general.navbar.logout', { lng: currentLanguage }), icon: 'runner', event: (e) => logout(e) }
                    ]}
                    onItemClick={onItemClick}
                    displayExpr={'name'}
                    keyExpr={'id'}
                />
            </Item>
            <Item location="after"
                locateInMenu="never" >
                <Notification history={history} />
            </Item>
        </Toolbar>
    );
};
const mapStateToProps = (state) => {
    return {
        initNewData: state.auth.initNewApp
    };
};

PrivateHeader.displayName = displaName;

PrivateHeader.propTypes = {
    toggleNavigation: PropTypes.func,
    currentUser: PropTypes.object,
    initNewData: PropTypes.object,
    toggleShowForm: PropTypes.func,
    logout: PropTypes.func,
    currentLanguage: PropTypes.string,
    history: PropTypes.object,
    showSideBar: PropTypes.func
};

export default connect(mapStateToProps, null)(PrivateHeader);
