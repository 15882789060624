import {
    SPECIAL_REQUEST_DETAIL,
    SPECIAL_REQUEST_GETALL,
    SPECIAL_REQUEST_LIST
} from './action-types';
import { PAGE_SIZE } from '../../../constants/Config';
// import * as _ from 'lodash';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {
        id: null,
        code: null
    },
    suggestion: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case SPECIAL_REQUEST_LIST:
            return list(state, payload);
        case SPECIAL_REQUEST_DETAIL:
            return detail(state, payload);
        case SPECIAL_REQUEST_GETALL:
            return getAll(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, { ...payload });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function getAll(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

export default reducer;
