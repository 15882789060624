
import {
    NOTIFICATION_LIST, NOTIFICATION_UPDATE_READ,
    NOTIFICATION_DELETE, NOTIFICATION_RELIST, NOTIFICATION_INSERT,
    NOTIFICATION_UPDATE_ALL_READ, NOTIFICATION_COUNT_UNREAD,
    OFFICE_TREES_USERS_GETALL
} from './action-types';

const initialState = {
    data: [],
    currentPage: 1,
    totalRows: 0,
    pageSize: 10,
    unReadCount: 0,
    treeUsers: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case NOTIFICATION_INSERT:
            return insert(state, payload);
        case NOTIFICATION_LIST:
            return list(state, payload);
        case NOTIFICATION_RELIST:
            return reList(state, payload);
        case NOTIFICATION_UPDATE_READ:
            return updateNotificationRead(state, payload);
        case NOTIFICATION_DELETE:
            return deleteNotificationRead(state, payload);
        case NOTIFICATION_UPDATE_ALL_READ:
            return updateAllRead(state);
        case NOTIFICATION_COUNT_UNREAD:
            return countUnRead(state, payload);
        case OFFICE_TREES_USERS_GETALL:
            return treeUsers(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    if (payload.data.length === state.totalRows) {
        state = Object.assign(state, {
            totalRows: payload.totalRows,
            data: payload.data
        });
    } else {
        state = Object.assign(state, {
            totalRows: payload.totalRows,
            data: [...state.data, ...payload.data]
        });
    }

    return state;
}

function insert(state, payload) {
    const unReadCount = state.unReadCount + 1;
    return { ...state, data: [payload, ...state.data], unReadCount };
}

function reList(state, payload) {
    state = Object.assign(state, {
        totalRows: payload.totalRows,
        data: payload.data
    });

    return state;
}

function countUnRead(state, payload) {
    return { ...state, unReadCount: payload };
}

function updateAllRead(state) {
    return { ...state, unReadCount: 0, data: state.data.map(x => { return { ...x, isRead: true }; }) };
}

function deleteNotificationRead(state, payload) {
    state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
}
function updateNotificationRead(state, payload) {
    const data = state.data.map(object => {
        if (object.id === payload) {
            return {
                ...object, isRead: !object.isRead
            };
        }
        return object;
    });

    return { ...state, data };
}

function treeUsers(state, payload) {
    state = Object.assign(state, {
        treeUsers: payload
    });

    return state;
}

export default reducer;
