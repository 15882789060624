import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/service',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/ServiceContainer'),
            loading: LoadingComponent
        })
    }, {
        path: '/service/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/ServiceDetailContainer'),
            loading: LoadingComponent
        })
    }, {
        path: '/service/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/ServiceDetailContainer'),
            loading: LoadingComponent
        })
    }, {
        path: '/service-group',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./groupService/list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/service-package',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/ServicePackageContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/service-package/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/ServicePackageDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/service-package/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/ServicePackageDetailContainer'),
            loading: LoadingComponent
        })
    }
];
