export const PRODUCT_LIST = 'PRODUCT_LIST'
export const PRODUCT_DETAIL = 'PRODUCT_DETAIL'
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE'
export const PRODUCT_INSERT = 'PRODUCT_INSERT'
export const PRODUCT_DELETE = 'PRODUCT_DELETE'
export const PRODUCT_RESPONSE = 'PRODUCT_RESPONSE'
export const PRODUCT_GETALL = 'PRODUCT_GETALL'
export const PRODUCT_SELECT = 'PRODUCT_SELECT'
export const PRODUCT_GETCODE = 'PRODUCT_GETCODE'

export default {
    PRODUCT_DELETE,
    PRODUCT_DETAIL,
    PRODUCT_INSERT,
    PRODUCT_LIST,
    PRODUCT_UPDATE,
    PRODUCT_RESPONSE,
    PRODUCT_GETALL,
    PRODUCT_SELECT,
    PRODUCT_GETCODE
}
