import { connect } from 'react-redux';
import {
    userGetCode
} from '../service';
import BaseCodeBox from '../../../base/components/BaseCodeBox';

class UserCodeBox extends BaseCodeBox {

}

const mapStateToProps = (state) => {
    return {
        languages: state.appSettings.data.languages,
        code: state.user.code
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCode: () => {
            return dispatch(userGetCode());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCodeBox);
