import {
    APP_GET,
    APP_INIT,
    APP_UPDATE_USERSETTING,
    SETTING_CONFIG,
    APP_SETTING_CHECK
} from './action-types';
import Transformer from '../../../../utils/Transformer';
import * as settingMock from '../settingMock';
import * as _ from 'lodash';

const initialState = {
    data: {},
    setting: {}
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case APP_SETTING_CHECK:
            return checkAppSetting(state);
        case APP_INIT:
            return initApp(state, payload);
        case APP_GET:
            return getApp(state);
        case APP_UPDATE_USERSETTING:
            return updateUserSettings(state, payload);
        case SETTING_CONFIG:
            return settingConfig(state, payload);
        default:
            return state;
    }
};

const checkAppSetting = (state) => {
    const pages = localStorage.getItem('_si');
    const languages = localStorage.getItem('_langs');
    const userSettings = localStorage.getItem('_s');
    const permissions = localStorage.getItem('_ps');
    const currentUser = localStorage.getItem('_u');

    const _userSettings = userSettings ? JSON.parse(userSettings) : settingMock.User;
    const _currentLanguage = _.find(_userSettings, (item) => item.key === 'LanguageId');
    const _currentBranch = _.find(_userSettings, (item) => item.key === 'BranchId');
    const _currentTheme = _.find(_userSettings, (item) => item.key === 'ThemeId');
    const _currentPageSize = _.find(_userSettings, (item) => item.key === 'PageSize');
    console.log(_currentLanguage, _currentBranch, _currentTheme, _currentPageSize);

    return {
        ...state,
        data: {
            pages: pages ? JSON.parse(pages) : settingMock.Pages,
            languages: languages ? JSON.parse(languages) : settingMock.Languages,
            userSettings: _userSettings,
            currentUser: currentUser ? JSON.parse(currentUser) : settingMock.CurrentUser,
            permissions: permissions ? JSON.parse(permissions) : settingMock.Permission
        },
        currentLanguage: _currentLanguage ? _currentLanguage.value : '',
        currentBranch: _currentBranch ? _currentBranch.value : '',
        currentTheme: _currentTheme ? _currentTheme.value : '',
        currentPageSize: _currentPageSize ? _currentPageSize.value : 0
    };
};

function initApp(state, payload) {
    const currentUser = { ...payload.currentUser, isAdmin: payload.isAdmin };
    const data = {
        pages: payload.pages || settingMock.Pages,
        userSettings: payload.userSettings || settingMock.User,
        languages: payload.languages || settingMock.Languages,
        currentUser: currentUser || settingMock.User,
        permissions: payload.permissions || null
    };
    localStorage.setItem('_si', JSON.stringify(payload.pages) || JSON.stringify(settingMock.Pages));
    localStorage.setItem('_s', JSON.stringify(payload.userSettings) || JSON.stringify(settingMock.User));
    localStorage.setItem('_langs', JSON.stringify(payload.languages) || JSON.stringify(settingMock.Languages));
    localStorage.setItem('_u', JSON.stringify(data.currentUser) || JSON.stringify(settingMock.User));
    localStorage.setItem('_ps', JSON.stringify(payload.permissions) || '');
    localStorage.setItem('mapId', payload.mapId);

    const _currentLanguage = _.find(data.userSettings, (item) => item.key === 'LanguageId');
    const _currentBranch = _.find(data.userSettings, (item) => item.key === 'BranchId');
    const _currentTheme = _.find(data.userSettings, (item) => item.key === 'ThemeId');

    state = Object.assign({}, state, {
        data,
        currentLanguage: _currentLanguage ? _currentLanguage.value : '',
        currentBranch: _currentBranch ? _currentBranch.value : '',
        currentTheme: _currentTheme ? _currentTheme.value : '',
        mapId: payload.mapId
    });
    return state;
}

function getApp(state) {
    var data = Transformer.fetch({
        pages: localStorage.getItem('_si') ? JSON.parse(localStorage.getItem('_si')) : null,
        userSettings: localStorage.getItem('_s') ? JSON.parse(localStorage.getItem('_s')) : null,
        languages: localStorage.getItem('_langs') ? JSON.parse(localStorage.getItem('_langs')) : null,
        currentUser: localStorage.getItem('_u') ? JSON.parse(localStorage.getItem('_u')) : null,
        permissions: localStorage.getItem('_ps') ? JSON.parse(localStorage.getItem('_ps')) : null
    });

    return {
        ...state, data: data
    };
}

function updateUserSettings(state, payload) {
    const userSettings = [...state.data.userSettings];
    const selectedSetting = _.findIndex(state.data.userSettings, { key: payload.key });

    if (selectedSetting === -1) {
        userSettings.push(payload);
    } else {
        userSettings[selectedSetting].value = payload.value;
    }

    var data = Transformer.fetch({
        pages: state.data.pages,
        userSettings: userSettings,
        languages: state.data.languages,
        currentUser: state.data.currentUser,
        permissions: state.data.permissions
    });

    localStorage.setItem('_s', JSON.stringify(data.userSettings) || JSON.stringify(settingMock.User));

    return {
        ...state, data: data
    };
}

function settingConfig(state, payload) {
    state = { ...state, setting: payload };
    return state;
}

export default reducer;
