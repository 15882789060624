import { spinnerLoading } from '../../../common/spinner/store/action';
import { CORE_URL } from '../../../constants/Config';
import Http from '../../../utils/Http';
import * as accountActions from './store/actions';

export function accountSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${CORE_URL}useraccounts?keyword=${payload.keyword
                }&type=${payload.type.toString()}`;
            // url += payload.type ? `&type=${payload.type}` : ''
            url +=
                payload.isActive !== null
                    ? `&isActive=${payload.isActive}`
                    : '';
            url += payload.page ? `&page=${payload.page}` : '';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '';

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(accountActions.list(res.data));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function accountInsertRequest(account) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}UserAccounts/${account.id}/roles`;
            Http.post(url, account.resourcePermission)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function accountUpdateRequest(account) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}useraccounts/${account.id}`;
            Http.put(url, account)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    account.concurrencyStamp = res.data.data;
                    dispatch(accountActions.update(account));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function accountDetailRequest(id) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            // dispatch(spinnerLoading(true))
            const url = `${CORE_URL}useraccounts/${id}`;
            Http.get(url)
                .then((res) => {
                    // dispatch(spinnerLoading(false))
                    dispatch(accountActions.detail(res.data.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function accountDeleteRequest(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}accounts/${id}`;
            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(accountActions.deletes(id));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function lockAccountRequest(payload) {
    // payload['type'] = 0;
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}useraccounts/lock-account/${payload.userName}/${payload.type}`;
            Http.put(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function unLockAccountRequest(payload) {
    // payload['type'] = 0;
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}useraccounts/reset-lock/${payload.userName}/${payload.type}`;
            Http.put(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function resetPasswordRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}useraccounts/reset-password/${payload.userName}/${payload.type}/${payload.password}`;

            Http.put(url, payload)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        });
}
export function changePasswordRequest(account) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}useraccounts/change-password`;
            Http.put(url, account)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(accountActions.changePassword(account));
                    resolve(res.data);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function accountGetAllEmployeeRequest() {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${CORE_URL}useraccounts/get-all-employee`;

            Http.get(url)
                .then((res) => {
                    dispatch(accountActions.getAllEmployee(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        })
    );
}
