import {PAYMENT_DELETE, PAYMENT_DETAIL, PAYMENT_INSERT, PAYMENT_LIST, PAYMENT_RESPONSE,
    PAYMENT_UPDATE,PAYMENT_GETALL} from "./action-types";


export function list(payload) {
    return {
        type: PAYMENT_LIST,
        payload
    }
}

export function detail(payload) {
    return {
        type: PAYMENT_DETAIL,
        payload
    }
}

export function update(payload) {
    return {
        type: PAYMENT_UPDATE,
        payload
    }
}

export function deletes(payload) {
    return {
        type: PAYMENT_DELETE,
        payload
    }
}

export function insert(payload) {
    return {
        type: PAYMENT_INSERT,
        payload
    }
}

export function responseError(payload) {
    return {
        type: PAYMENT_RESPONSE,
        payload
    }
}
export function suggestion(payload) {
    return {
        type: PAYMENT_GETALL,
        payload
    }
}
