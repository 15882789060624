export const enR = {
    religion: {
        list: { title: 'Danh sách Tôn Giáo' },
        form: {
            updateTitle: 'Chỉnh sửa Tôn Giáo',
            insertTitle: 'Thêm mới Tôn Giáo'
        }
    },
    resource: {
        list: { title: 'Danh sách Nguồn Khách' }
    },
    role: {
        list: { title: 'Danh sách Nhóm Quyền' },
        form: {
            groupname: 'Tên Nhóm Quyền',
            titleInfo: 'Thông tin Nhóm Quyền',
            edit: 'Chỉnh sửa nhóm quyền'
        }
    },
    request: {
        form: {
            details: 'Chi tiết phiếu',
            insertTitle: 'Add new order',
            updateTitle: 'Edit order',
            exportVAT: 'Export VAT invoice'
        },
        list: { edit: 'Chỉnh sửa/ Tạo phiếu yêu cầu' }
    },
    report: {
        reportCommissione: 'Employee commissions report',
        listCustomerServiceCard:
            'Customer service card revenue report',
        listTotal: 'Total revenue report',
        listTotalDetailService: 'Revenue of service',
        listTotalDetailProduct: 'Revenue of product',
        listTotalDetailCustomer: 'Revenue of customer',
        customerPoints: 'Customer points report',
        serviceAppointmentReport: 'Service Appointment Report',
        staffAppointment: 'Report Set appointment of staff',
        countServicesProducts: 'Revenue Services/ Products report',
        customerServiceDetail: 'Customer service detail report',
        listWarehouseCard: 'Warehouse card report',
        firstTimeCustomer: 'Reporting customers using the service for the first time',
        listImportExport: 'Import Export report',
        listProductInventory: 'Product invemtory report',
        listUser: 'User report',
        listUserDetail: 'Detail user report',
        listServiceProduct: 'Product service reports',
        listReport: 'Revenue',
        listCustomerCard: 'Customer card purchase report',
        listProductGroup: 'Product groups report',
        detail: 'Detail',
        Service: 'service',
        Product: 'product',
        Price: 'Total revenue ',
        Discount: 'Discout',
        Amount: 'Rure revenue',
        CustomerServiceCard: 'Collect prepaid card sales',
        Total: 'Total proceeds from',
        TotalUse: 'Money used',
        TotalBalance: 'Money balance',
        TotalPaid: 'Money paid',
        percent: 'Percent',
        commissioneMoney: 'Commissione money',
        joinedDate: 'Date ',
        serviceUsedTimes: 'Total Service use',
        productUsedTime: 'Total product purchase',
        totalAmountService: 'Total amount of services',
        totalAmountProduct: 'Total amount of products',
        exportExcel: 'Export',
        exportAll: 'Export all',
        totalPrice: 'Total price',
        totalAmount: 'Total amount',
        employee: 'Employees',
        customersReport: 'Customers Report',
        explain: 'Explain',
        error: {
            selectUser: 'Please select an user'
        },
        detailPayment: {
            listTitle: 'Detail Payment Report',
            customerCode: 'customerCode',
            fullName: 'fullName',
            birthday: 'birthday',
            phoneNumber: 'phoneNumber',
            type: 'type',
            creatorFullName: 'creatorFullName',
            pageUnit: 'records',
            cardType: 'CardType',
            paidCustomerCode: 'Paid Customer Code',
            paidCustomerName: 'Paid Customer Name',
            totalValue: 'Total Value',
            invoiceDate: 'Invoice Date'
        }
    }
};
