import { DropDownBox, TreeView } from 'devextreme-react';
import * as _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n';
import { newsCategoryTreeRequest } from '../../modules/website/category/service';
import Transformer from '../../utils/Transformer';

class NewsCategoryDropDownToolBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listData: {},
            treeData: {},
            tree: this.props.tree,
            treeBoxValue:
                this.props.selectionMode === 'multiple'
                    ? this.props.value
                    : [this.props.value],
            renderDone: false,
            search: {
                branchId: this.props.branchId,
                websiteId: this.props.websiteId,
                menuId: this.props.menuId
            }
        };

        this.treeViewRender = this.treeViewRender.bind(this);
        this.syncTreeViewSelection = this.syncTreeViewSelection.bind(this);
        this.treeView_itemSelectionChanged = this.treeView_itemSelectionChanged.bind(this);
    }

    componentDidMount() {
        this.getTreeData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!Transformer.compareTree(this.props.tree, nextProps.tree)) {
            console.log('UPDATE');
            this.mutilLanguageProcess(nextProps.tree);
        }

        if (nextProps.value === null) {
            this.setState({
                treeBoxValue:
                    this.props.selectionMode === 'multiple'
                        ? null
                        : []
            });
        }

        if (nextProps.websiteId !== this.props.websiteId) {
            this.setState({
                search: { ...this.state.search, websiteId: nextProps.websiteId }
            }, () => this.getTreeData());
        }
    }

    searchTree(tree, languageId) {
        const treeDataForLang = [];

        tree.forEach((element) => {
            treeDataForLang.push({
                id: element.id,
                text: Transformer.IsJsonString(element.text) ? JSON.parse(element.text)[languageId] : element.text,
                children: this.searchTree(element.children, languageId)
            });
        });

        return treeDataForLang;
    }

    syncTreeViewSelection(e) {
        const treeView =
            (e.component.selectItem && e.component) ||
            (this.treeView && this.treeView.instance);

        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
            } else {
                const values = e.value || this.state.treeBoxValue;
                values &&
                    values.forEach(function (value) {
                        treeView.selectItem(value);
                    });
            }
        }

        if (e.value !== undefined) {
            this.setState({
                treeBoxValue: e.value
            });

            this.props.updateData({
                [this.props.field]: e.value
            });
        }
    }

    mutilLanguageProcess(tree) {
        const { languages } = this.props;
        const list = Transformer.convertTreeToList(tree);

        let treeData = {};
        let listData = {};

        languages.forEach((language) => {
            const listDataForLang = [];

            treeData = {
                ...treeData,
                [language.languageId]: this.searchTree(
                    tree,
                    language.languageId
                )
            };

            list.forEach((element) => {
                listDataForLang.push({
                    id: element.id,
                    text: Transformer.IsJsonString(element.text) ? JSON.parse(element.text)[language.languageId] : element.text
                });
            });

            listData = { ...listData, [language.languageId]: listDataForLang };
        });

        this.setState({
            listData,
            treeData,
            renderDone: true
        });
    }

    /**
     * Get Tree Data.
     *
     * @inheritdoc
     */
    getTreeData() {
        const { search } = this.state;

        if (
            !this.props.tree ||
            this.props.tree.length === 0 ||
            (search.branchId && search.branchId !== this.props.tree[0].branchId)
        ) {
            this.props.getTree(search).then(() => {
                this.mutilLanguageProcess(this.props.tree);
            });
        } else {
            this.mutilLanguageProcess(this.props.tree);
        }
    }

    treeViewRender() {
        const { treeData, renderDone } = this.state;
        const { currentLanguageActive, selectionMode } = this.props;

        if (!renderDone) {
            return <div>Loading...</div>;
        }

        return (
            <TreeView
                searchEnabled={true}
                ref={(ref) => (this.treeView = ref)}
                dataSource={treeData[currentLanguageActive]}
                keyExpr={'id'}
                parentIdExpr={'parentId'}
                itemsExpr={'children'}
                displayExpr={'text'}
                showCheckBoxesMode="normal"
                selectNodesRecursive={false}
                selectionMode={selectionMode || 'single'}
                selectByClick={true}
                onContentReady={this.syncTreeViewSelection}
                onItemSelectionChanged={this.treeView_itemSelectionChanged}
            />
        );
    }

    treeView_itemSelectionChanged(e) {
        this.setState({
            treeBoxValue: e.component.getSelectedNodesKeys()
        });

        if (this.props.selectionMode === 'multiple') {
            const value = e.component.getSelectedNodesKeys();
            this.props.updateData({
                [this.props.field]: value
            });
        } else {
            const value = e.component.getSelectedNodesKeys();
            if (value.length === 0) {
                this.props.updateData({
                    [this.props.field]: null
                });
            } else {
                this.props.updateData({
                    [this.props.field]: value[0]
                });
            }
        }
    }

    render() {
        const { currentLanguageActive, i18nLabel } = this.props;
        const { listData, treeBoxValue } = this.state;

        return (
            <div className="form-group cm-mgr-5">
                <div className="dx-field">
                    <DropDownBox
                        dataSource={listData[currentLanguageActive]}
                        valueExpr={'id'}
                        parentIdExpr={'parentId'}
                        itemsExpr={'children'}
                        value={treeBoxValue}
                        displayExpr={'text'}
                        placeholder={i18n.t(i18nLabel)}
                        showClearButton={true}
                        contentRender={this.treeViewRender}
                        onValueChanged={this.syncTreeViewSelection}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { userSettings, languages } = state.appSettings.data;

    const currentLanguage = _.find(userSettings, (item) => item.key === 'LanguageId');
    const currentBranchId = _.find(state.appSettings.data.userSettings, (item) => item.key === 'BranchId');
    const { tree, totalRows } = state.newsCategory;

    return {
        languages,
        currentLanguage: currentLanguage.value,
        tree,
        totalRows,
        branchId: currentBranchId.value
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTree: (payload) => {
            return dispatch(newsCategoryTreeRequest(payload));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsCategoryDropDownToolBox);
