import { connect } from "react-redux"
import {
    jobGetAll
} from '../service'
import BaseSelectBox from '../../../../base/components/BaseSelectBox'

class JobSelectBox extends BaseSelectBox {
    
}

const mapStateToProps = (state) => {
    return {
        languages: state.appSettings.data.languages,
        listData: state.job.suggestion,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllData: () => {
            return dispatch(jobGetAll())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobSelectBox)