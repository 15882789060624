import React, { Component } from 'react';
import { Button } from 'devextreme-react';
import i18n from '../../i18n';
import PropTypes from 'prop-types';

class AddNewButton extends Component {
    render() {
        const { pagePermisions, addNewAction, role } = this.props;

        return (
            <div className={'dx-field'}>
                <Button
                    disabled={!pagePermisions[[role]]}
                    className={'dx-theme-accent-as-background-color'}
                    text={i18n.t('general.button.add')}
                    width={120}
                    onClick={addNewAction} />
            </div>
        );
    }
}

AddNewButton.propTypes = {
    pagePermisions: PropTypes.object,
    addNewAction: PropTypes.func,
    role: PropTypes.string
};

export default AddNewButton;
