export const OTHER_INCOME_LIST = 'OTHER_INCOME_LIST';
export const OTHER_INCOME_GETALL = 'OTHER_INCOME_GETALL';
export const OTHER_INCOME_DETAIL = 'OTHER_INCOME_DETAIL';
export const OTHER_INCOME_DELETE = 'OTHER_INCOME_DELETE';

export default {
    OTHER_INCOME_LIST,
    OTHER_INCOME_DETAIL,
    OTHER_INCOME_GETALL,
    OTHER_INCOME_DELETE
};
