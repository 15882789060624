import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PrivateLayout from './Private';
import PublicLayout from './Public';
import './layout.scss';

const Layout = ({ children, currentBranch, currentLanguage, currentTheme, currentWebsite, isAuthenticated, appSetting, ...rest }) => {
    if (isAuthenticated && currentBranch && currentLanguage && currentTheme && currentWebsite && appSetting && appSetting.pages && appSetting.languages) {
        return <PrivateLayout {...rest}>{children}</PrivateLayout>;
    }
    return <PublicLayout {...rest}>{children}</PublicLayout>;
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        listBranch: state.branch.suggestion,
        appSetting: state.appSettings.data,
        currentBranch: state.appSettings.currentBranch,
        currentLanguage: state.appSettings.currentLanguage,
        currentTheme: state.appSettings.currentTheme,
        currentWebsite: state.website.currentWebsite
    };
};

Layout.propTypes = {
    isAuthenticated: PropTypes.bool,
    children: PropTypes.any,
    currentBranch: PropTypes.string,
    currentLanguage: PropTypes.string,
    currentTheme: PropTypes.string,
    currentWebsite: PropTypes.string,
    appSetting: PropTypes.object
};

export default connect(mapStateToProps, null)(Layout);
