export const viF = {
    fileManagement: {
        list: {
            view: 'Xem chi tiết',
            edit: 'Chỉnh sửa',
            delete: 'Xóa',
            rename: 'Sửa tên',
            refresh: 'Làm mới'
        },
        toolbar: {
            addFolder: 'Thêm thư mục',
            chooseFile: 'Chọn tập tin',
            searchPlaceholder: 'Nhập tên tập tin'
        },
        popup: {
            addNewFolderTitle: 'THÊM MỚI THƯ MỤC',
            folderLabel: 'Thư mục',
            renameTitle: 'SỬA TÊN',
            editTitle: 'CHỈNH SỬA THƯ MỤC/ TẬP TIN',
            deleteTitle:
                'Bạn có chắc chắn muốn xóa tập tin/ thư mục này?',
            deleteNotice:
                'Sau khi xóa file/folder đó sẽ không sử dụng ở các mục liên quan'
        },
        error: {
            selectFileFolder: 'Hãy chọn 1 tập tin hoặc thư mục',
            folderContainFiles:
                'Bạn không thể xóa thư mục có chứa một hoặc nhiều thư mục/ tập tin'
        }
    }
};
