import React, { Component } from 'react';
import ColorBox from 'devextreme-react/color-box';
import ReeValidate from 'ree-validate';
import vi from 'ree-validate/dist/locale/vi';
import en from 'ree-validate/dist/locale/en';
import i18n from '../../i18n';
import PropTypes from 'prop-types';

class ColorPickerBox extends Component {
    constructor(props) {
        super(props);

        this.validator = new ReeValidate({
            color: this.props.validatorValue
        });

        if (this.props.currentLanguage === 'vi-VN') {
            this.validator.localize('vi', vi);
        } else {
            this.validator.localize('en', en);
        }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.validator.validate('color', nextProps.value).then(() => {
                this.checkValidate();
            });
        }
    }

    valueChanged = (e) => {
        const { field } = this.props;
        this.props.updateData({ [field]: e.value });

        if (e.event !== undefined && e.event !== null) {
            if (e.event.type !== 'dxclick') {
                const { errors } = this.validator;
                const value = e.event.target.value;

                errors.remove('color');
                this.props.updateData({
                    [this.props.field]: value
                });

                this.validator
                    .validate('color', value)
                    .then(() => {
                        this.checkValidate();
                    })
                    .catch(() => { });
            }
        }
    }

    checkValidate() {
        const { field } = this.props;

        const formValidate = !this.validator.errors.has('color');
        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    render() {
        const { value, i18nLabel, validatorValue, readOnly } = this.props;

        return (
            <div className={'' + (this.validator.errors.has('color') ? ' has-error' : '')}>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel) + ':'}
                        {!readOnly && validatorValue &&
                            validatorValue.includes('require') && (<span className="color-red"> *</span>)}
                    </div>

                    <div className={'dx-field-value'}>
                        <ColorBox
                            value={value}
                            readOnly={readOnly || false}
                            onValueChanged={this.valueChanged} />
                        {this.validator.errors.has('color') && (
                            <div className="help-block">
                                {this.validator.errors
                                    .first('color')
                                    .replace('color', i18n.t(i18nLabel))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

ColorPickerBox.propTypes = {
    value: PropTypes.string,
    i18nLabel: PropTypes.string,
    validatorValue: PropTypes.string,
    readOnly: PropTypes.bool,
    updateData: PropTypes.func,
    field: PropTypes.string,
    currentLanguage: PropTypes.string
};

export default ColorPickerBox;
