import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/config/general/pages',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./pages/list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/config/general/pages/edit/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./pages/form/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/config/general/pages/create',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./pages/form/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/config/general/tenants',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./tenant/list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/config/accounts',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./account/list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/accounts/change-password',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./account/change-password/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/accounts/reset-password',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./account/account-reset-password/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/config/accounts/role/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./account/account-detail-role/index'),
            loading: LoadingComponent
        })
    },

    {
        path: '/config/roles',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./role/list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/config/roles/edit/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./role/form/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/config/roles/create',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./role/form/index'),
            loading: LoadingComponent
        })
    }

];
