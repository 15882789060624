import {
    GROUPS_AREA_DETAIL, GROUPS_AREA_INSERT, GROUPS_AREA_LIST, GROUPS_AREA_TREE,
    GROUPS_AREA_DELETE, GROUPS_AREA_UPDATE
} from "./action-types"
import { PAGE_SIZE } from '../../../constants/Config'
import Transformer from "../../../utils/Transformer"

const initialState = {
    data: [],
    totalRows: 0,
    currentPAGE: 0,
    pageSize: PAGE_SIZE,
    tree: null,
    action: null,
    item: null
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GROUPS_AREA_LIST:
            return list(state, payload)
        case GROUPS_AREA_TREE:
            return tree(state, payload)
        case GROUPS_AREA_DETAIL:
            return detail(state, payload)
        case GROUPS_AREA_UPDATE:
            return update(state, payload)
        case GROUPS_AREA_INSERT:
            return insert(state, payload)
        case GROUPS_AREA_DELETE:
            return deletes(state, payload)
        default:
            return state
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload)
    return state
}

function tree(state, payload) {
    state = Object.assign({}, state, {
        tree: payload
    })
    return state
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    })
    return state
}

function insert(state, payload) {
    let insertItem = {
        id: parseInt(payload.id),
        parentId: payload.parentId,
        text: payload.name,
        children: []
    }

    const data = [...state.data, payload]

    if (payload.parentId === null) {
        state.tree = [...state.tree, insertItem]
    } else {
        Transformer.insertTree(state.tree, insertItem)
    }

    return Object.assign({}, state, { data }, { totalRows: state.totalRows + 1 })
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload }
        }
        return obj
    })
    let tree = Transformer.updateTree(state.tree, payload)

    const item = { ...state.item, ...payload }
    return Object.assign({}, state, { data, item, tree })
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload)
    
    let tree = null
    if (state.tree) {
        tree = Transformer.deleteTree(state.tree, payload)
    }

    return Object.assign({}, state, { data, tree }, { totalRows: state.totalRows - 1 })

}
export default reducer
