import React, { Component } from 'react';
import { TextBox } from 'devextreme-react';
import i18n from '../../i18n';
import ReeValidate from 'ree-validate';
import vi from 'ree-validate/dist/locale/vi';
import en from 'ree-validate/dist/locale/en';
import PropTypes from 'prop-types';

class PhoneNumberBox extends Component {
    constructor(props) {
        super(props);

        this.validator = new ReeValidate({
            phoneNumber: this.props.validatorValue
        });

        if (this.props.currentLanguage === 'vi-VN') {
            this.validator.localize('vi', vi);
        } else {
            this.validator.localize('en', en);
        }

        // this.validator.validate('phoneNumber', this.props.value)
        //     .then(() => {
        //         this.checkValidate();
        //     });

        this.valueChanged = this.valueChanged.bind(this);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.validator.validate('phoneNumber', nextProps.value).then(() => {
                this.checkValidate();
            });
        }
    }

    valueChanged = (e) => {
        if (e.event !== undefined && e.event !== null) {
            if (e.event.type !== 'dxclick') {
                const { errors } = this.validator;

                errors.remove(e.event.target.name);
                this.props.updateData({
                    [this.props.field]: e.event.target.value
                });

                this.validator.validate(e.event.target.name, e.event.target.value)
                    .then(() => {
                        this.checkValidate();
                    })
                    .catch(() => {
                    });
            }
        }
    }

    checkValidate() {
        const { field } = this.props;

        const formValidate = !this.validator.errors.has('phoneNumber');
        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    render() {
        const { value, i18nLabel, validatorValue, readOnly } = this.props;

        return (
            <div className={'' + (this.validator.errors.has('phoneNumber') ? ' has-error' : '')}>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'} >
                        {i18n.t(i18nLabel) + ':'}{!readOnly && validatorValue && validatorValue.includes('require') && <span className="color-red"> *</span>}
                    </div>
                    <div className={'dx-field-value'} >
                        <TextBox
                            name={'phoneNumber'}
                            value={value}
                            onValueChanged={this.valueChanged}
                            placeholder="Phone"
                            readOnly={readOnly || false}
                        />
                        {this.validator.errors.has('phoneNumber') &&
                            <div
                                className="help-block">{this.validator.errors.first('phoneNumber').replace('phoneNumber', i18n.t(i18nLabel))}
                            </div>}
                    </div>
                </div>
            </div>
        );
    }
}

PhoneNumberBox.propTypes = {
    value: PropTypes.any,
    i18nLabel: PropTypes.any,
    validatorValue: PropTypes.any,
    readOnly: PropTypes.any,
    updateData: PropTypes.any,
    field: PropTypes.any,
    currentLanguage: PropTypes.any
};

export default PhoneNumberBox;
