export const enGeneral = {
    general: {
        login: {
            loginSystem: 'Sign in',
            passwordretrieval: 'Password retrieval',
            return: 'Back',
            next: 'Next'
        },
        message: {
            rememberpassword:
                'We sent a password reset request to the registered email address',
            check: 'Please check your email for a verification code'
        },
        navbar: {
            logout: 'Logout',
            changepass: 'Change password',
            usercustomization: 'Customize users',
            username: 'Username',
            password: 'Password',
            forgotpassword: 'Forgot password',
            rememberpassword: 'Remember password',
            login: 'Login',
            changepasstitile: 'Change the password',
            oldpassword: 'Old password',
            newpassword: 'New password',
            Enterpassword: 'Enter the password again',
            Logoutdevices: 'Log out of other devices'
        },
        table: {
            promotionCardName: 'Promotion Card name',
            promotionCardDetailName: 'Promotion Card Detail Name',
            total: 'Total',
            totals: 'Total',
            sum: 'Sumary',
            dong: 'dong',
            denominations: 'Denominations',
            productAfterRequest: 'Product after request',
            productBeforeRequest: 'Product before request',
            page: 'Page',
            of: 'of',
            stt: 'No.',
            username: 'Employee name',
            codeuser: 'Employee code',
            position: 'Position',
            sdt: 'Phone number',
            note: 'Notes',
            edit: ' Edit ',
            email: ' Email ',
            add: ' House number? Street number',
            branchName: 'Branch name',
            branch: 'Branch',
            address: 'Address',
            default: 'Default',
            active: 'Active',
            action: 'Action',
            shiftname: 'Shift name',
            time: 'Time',
            des: 'Description',
            status: 'Status',
            order: 'Order',
            areaName: 'Area name',
            areaSelect: 'Select region',
            price: 'Price',
            useOn: 'Used On',
            unitTitle: 'Unit',
            quantity: 'Quantity',
            quantityShorten: 'Qty',
            printInvoice: 'Print invoice',
            System: 'System',
            domain: 'Domain',
            password: 'Password',

            promotionname: 'Promotion name',
            startdate: 'Start date',
            enddate: 'End date',
            Typeoffer: 'Offer type',
            Discount: 'Discount',
            promotion: 'Promotion',
            count: 'Discount',
            intomoney: 'Amount',
            Unitname: 'Unit name',
            Servicegroup: 'Service group',
            photo: 'Photos',
            product: 'Products',
            productcode: 'Product code',
            productCodeShorten: 'Code',
            productname: 'Product name',
            productgroup: 'Product group',
            Unit: 'Unit',
            servicecard: 'Service card',
            service: 'Service',
            del: 'Delete',

            cardcode: 'Card code',
            cardname: 'Card name',
            actualvalue: 'Actual value',
            promotionalvalue: 'Promotional value',
            cardvalue: 'Card value',
            servicecode: 'Service code',
            servicename: 'Service name',
            serviceprice: 'Service unit price (VND)',
            servicetime: 'Number of minutes taken',
            unitprice: 'Unit price',
            minutes: 'Number of minutes',
            conversionunits: 'Units of conversion',
            conversionnumbers: 'Number of conversions',
            mainunit: 'Main unit',
            postconversionunitprice: 'Unit price after conversion',
            timemakingvotes: 'Time to vote',

            fullname: 'Full name',
            department: 'Department',
            datebirth: 'Date of birth',
            dateentry: 'Start date',
            subject: 'Subject',
            title: 'Title',
            jobtitle: 'Job title',
            multiplechoice: 'Select multiple',
            departmentname: 'Department name',
            customename: 'Customer name',
            customercode: 'Customer code',
            accountname: 'Account name',
            customercodeShort: 'Customer code',
            lockstatus: 'Lock status',
            locktime: 'Lock time',
            passwordreset: 'Reset password',
            recentdays: 'Recent days',
            decentralization: 'Decentralization',
            votes: 'Number of votes',
            NumberVotesEntered: 'Number of tickets entered',
            NumberVotes: 'Number of vouchers',
            NumberEntered: 'Input quantity',
            ExportQuantity: 'Export quantity',
            QuantityStock: 'Quantity in stock',
            Beginning: 'Beginning of period',
            DuringPeriod: 'Enter in period',
            ExportedInPeriod: 'Export in period',
            EndingStocks: 'Ending Stock',
            import: 'Import',
            export: 'Export',
            instock: 'InStock',
            Critical: 'Critical',
            bills: 'Number of bill',
            customers: 'Customers',
            customerservicecard: 'Customer Service Cards',
            expirationDateCard: 'Expiry Date',
            totalBalanceUsed: 'Total Balance Used',
            balanceCard: 'Remain money',

            nameInvoices: 'Invoice name',
            totalbill: 'Total invoice',
            totalmoney: 'Total money',
            moneypaid: 'Money paid',
            moneyunpaid: 'Outstanding money',
            coupontype: 'Coupon type',
            cardTotalAmount: 'Card total value',
            cardPromotion: 'Promotion',
            cardPrice: 'Card price',
            cardPaid: 'Paid amount',
            cardUnpaid: 'UnPaid amount',
            cardUse: 'Used amount',
            prepaidCard: 'Prepaid Card',
            paidOn: 'Date',

            country: 'Country name',
            province: 'Province name',
            district: 'District name',
            ethnic: 'Ethnic name',
            religious: 'Ethnic name',
            paymentmethod: 'Payment method name',
            guestsource: 'Guest source name',
            occupationname: 'Occupation name',
            pagename: 'Page name',
            path: 'Path',
            permissiongroup: 'Right group',

            Collectors: 'Collectors',
            Consultants: 'Consultants',
            Sellers: 'Sellers',
            Creator: 'Creator',
            Pointsearned: 'Type of points',
            totalPoints: 'Total Points',
            Pointsearn: 'Points earned',
            percentOfAccumulatedPoints: 'Percent of Accumulated Points',
            Accumulator: 'Points earned',
            PurchaseDate: 'Date purchased',
            ExpiryDate: 'Expiry date',
            expiryDateShorten: 'Epr Date',
            Moneyused: 'Money used',
            Moneyremaining: 'Money left',
            Paid: 'Paid',
            Paymentinvoice: 'Bill of payment',
            Detailed: 'Details',
            Depositinvoice: 'Deposit invoice',
            statusBefore: 'Status before',
            statusAfter: 'Status after',
            saveStatus: 'Save status',
            saveStatusBefore: 'Save previous state',
            saveStatusAfter: 'Save status later',
            serviceInfo: 'Service information',
            statusService: 'Service status',
            reminderDate: 'Reminder Date',
            reminderNote: 'Reminder Note',
            isRemind: 'Remind',
            type: 'Type',
            noteCard: 'Note for cards',
            noteProduct: 'Note for products',
            noteService: 'Note for services',
            period: 'Validity period (days)',
            date: 'Date',
            month: 'month',
            year: 'year',
            report: 'Report',

            bookingServices: 'Booking services',
            totalPriceBooking: 'Total price booking',
            staff: 'Staff',
            actualServices: 'Actual services',
            totalPriceActual: 'Total price actual',
            serviceChanges: 'Service changes',
            totalPriceChange: 'Total price change',
            totalPriceReduce: 'Total price reduce',
            totalBalanceServiceCard: 'Total balance service card',
            inventory: 'Inventory',
            code: 'Code',
            dateCreate: 'Date create',
            totalAmountDocument: 'Total amount according to the number of books',
            totalAmountRealty: 'Actual total amount',
            counterUser1: 'The counter 1',
            counterUser2: 'The counter 2',
            counterUser3: 'The counter 3',
            lotId: 'Lot ID',
            content: 'Content',
            moneyAmount: 'Money',
            customerServiceCardCode: 'Customer service card code',

            titleDocument: 'Documents',
            titleReality: 'Reality',
            titleRedundant: 'Redundant',
            titleMissing: 'Missing',

            moneyShorten: 'Amount'
        },
        button: {
            add: 'Add new',
            save: 'Save',
            close: 'Close',
            copyschedule: 'Copy calendar',
            keepadding: 'Continue adding',
            copy: 'Copy',
            seeall: 'View all',
            info: 'View Info',
            uploadImg: 'Download image',
            accept: 'Accept',
            cancel: 'Cancel',
            edit: 'Edit',
            export: 'Export file',
            return: 'Back to list',
            schedule: 'Schedule',
            Createvotes: 'Create coupon',
            saveclose: 'Save & close',
            saverecharge: 'Save & Recharge',
            acceptrecharge: 'Accept & recharge',
            acceptexport: 'Accept & Print invoice',
            completed: 'Complete',
            approve: 'Approve',
            deny: 'Deny',
            deleteAll: 'Delete all',
            decentralization: 'Decentralize quickly',
            savedraft: 'Save draft',
            download: 'Download',
            activated: 'Activate',
            makeappointment: 'Make Appointment',
            pay: 'Pay',
            showAllRights: 'Show Given Permissions',
            saveprint: 'Save & Print invoice',
            viewInfo: 'View info',
            printInfo: 'Print info',
            saveSend: 'Save and send',
            cardHistory: 'Card history',
            print: 'Print',
            send: 'Send',
            selectBatches: 'Select batches',
            hideCcheckbox: 'Hide checkbox',
            update: 'Update'
        },
        info: {
            Smallheaderimage: 'Small header image',
            SmallFooterimage: 'Small footer image',
            Largeheaderimage: 'Large header image',
            LargeFooterimage: 'Large header image',
            Systemcolor: 'System color',
            Numberofrecords: 'PageSize',
            Country: 'Country',
            ProvinceCity: 'Province / City',
            DistrictDistrict: 'District / District',
            Ethnicity: 'Ethnicity',
            Religion: 'Religion',
            OccupationName: 'Occupation Name',
            Job: 'Occupation',
            Guestsource: 'Visitor source',
            Methodpay: 'Payment method',
            area: 'Region',
            CountryName: 'Country Name',
            DistrictName: 'District Name',
            ProvinceName: 'Province / City Name',
            gender: 'Gender',
            BrandName: 'Brand name'
        },
        form: {
            declineContent: 'Decline Content',
            InfoOfCustomer: 'Info of customer',
            Gender: 'Gender',
            serviceNote: {
                code: 'Invoice code',
                date: 'Invoice date',
                customer: 'Customer',
                phone: 'Phone number',
                customerType: 'Target audience',
                creator: 'Author'
            },
            branch: {
                insertTitle: 'Add new branch',
                updateTitle: 'Edit branch',
                code: 'Affiliate code',
                website: 'Website',
                hotline: 'Hotline',
                facebook: 'Facebook',
                active: 'Activate',
                default: 'Default'
            },
            country: {
                insertTitle: 'Add new country',
                updateTitle: 'Edit country'
            },
            province: {
                insertTitle: 'Add new provinces',
                updateTitle: 'Edit provinces'
            },
            nation: {
                insertTitle: 'Add new ethnic group',
                updateTitle: 'Edit ethnic group'
            },
            occupation: {
                insertTitle: 'Add new job',
                updateTitle: 'Edit job'
            },
            job: {
                insertTitle: 'Add new job',
                updateTitle: 'Edit job'
            },
            area: {
                insertTitle: 'Add new area',
                updateTitle: 'Edit area'
            },
            servicegroup: {
                insertTitle: 'Add new service group',
                updateTitle: 'Edit service group'
            },
            religion: {
                insertTitle: 'Add new religion',
                updateTitle: 'Edit religion'
            },
            district: {
                insertTitle: 'Add new district',
                updateTitle: 'Edit district'
            },
            unit: {
                insertTitle: 'Add new unit',
                updateTitle: 'Edit unit'
            },
            paymentMethod: {
                insertTitle: 'Add new payment method',
                updateTitle: 'Edit payment method'
            },
            productgroup: {
                insertTitle: 'Add new product group',
                updateTitle: 'Edit product group'
            },
            service: {
                insertTitle: 'Add new service',
                updateTitle: 'Edit service'
            },
            facebook: 'Facebook',
            active: 'Activate',
            customerPrePaid: 'Deposit',
            Offername: 'Offer name',
            Startdate: 'Start date',
            Enddate: 'End date',
            Typesoffers: 'Offer types',
            Someservices: 'Some services',
            Allservices: 'All services',
            Details: 'Details',
            Productlist: 'Product list',
            Selectedlist: 'Selected list',
            Incentives: 'Offers',
            discounts: 'Discounts',
            discount: 'Discounts',
            Moneydiscounts: 'Discounts in money',
            Sequencenumber: 'Order',
            Programcontent: 'Program content',
            Notes: 'Notes',
            Active: 'Active',
            UnitName: 'Unit name',
            Activate: 'Activate',
            Productgroup: 'Product group',
            Productgroupname: 'Product group name',
            Productname: 'Product name',
            Price: 'Price',
            Unit: 'Unit of measure',
            ProductSKU: 'Product code',
            Brand: 'Brand',
            Capacity: 'Capacity',
            Registrationnumber: 'Registration number',
            Origin: 'Origin',
            Order: 'Order',
            Ingredients: 'Ingredients',
            Uses: 'Uses',
            Instructionsuse: 'Instructions for use',
            Label: 'Label',
            UnitConversion: 'Unit conversion',
            CardName: 'Card name',
            Cardcode: 'Card code',
            Actualvalue: 'Actual value',
            Promotionalvalue: 'Promotional value',
            Cardvalue: 'Card value',
            Commission: 'Commission',
            Chooseservice: 'Choose service',
            Branching: 'Branches',
            Services: 'Services',
            Listcardservices: 'List of card services',
            Servicegroupname: 'Service group name',
            Selectparentservicegroup: 'Service group',
            SelectparentProductgroup: 'Product group',
            Servicecode: 'Service code',
            Servicename: 'Service name',
            unitprice: 'Unit price',
            Timetaken: 'Implementation time (minutes)',
            CommissionPrice: 'Commission',
            Des: 'Description',
            Organizationchart: 'Organization chart',
            Departmentinfo: 'Department information',
            Namedepartment: 'Department name',
            superiordepartment: 'Superior Department',
            titleposition: 'Name of position',
            Usedepartments: 'Used for departments',
            Allowedselectmany: "It's allowed to select multiple",
            Title: 'Title name',
            ServiceList: 'Service list',
            EmployeeInformation: 'Employee information',

            Normalstaff: 'Normal staff',
            Technician: 'Technician',
            Username: 'Username',
            EmployeeID: 'Employee ID',
            Fullname: 'Full name',
            CustomerCode: 'Customer Code',
            Selectgender: 'Select gender',
            DateBirth: 'Date of Birth',
            Department: 'Department',
            Position: 'Position',
            HeadUnit: 'Head position',
            Subject: 'Subject',
            Issuedate: 'Date of issue',
            Placeissue: 'Place of issue',
            Businessday: 'Business day',
            Leavedate: 'Business day',
            Country: 'Country',
            ProvinceCity: 'Province / City',
            District: 'County / District',
            Ethnicity: 'Ethnicity',
            Religion: 'Religion',
            Bankaccount: 'Bank account',
            Bankname: 'Bank name',
            BankBranch: 'Bank branch',
            Passport: 'Passport',
            AttendanceCode: 'Attendance code',
            Attendancecardcode: 'Attendance card code',
            Maritalstatus: 'Marital status',
            Contactinformation: 'Contact information',
            Phonenumber: 'Phone number',
            Ext: 'Extension',
            Contacts: 'Contacts',
            EmployeeList: 'List of employees',
            CouponCode: 'Coupon code',
            DateCreated: 'Date of creation',
            Customer: 'Customer',
            Preparer: 'Preparer',
            Servicedetails: 'Service details',
            Oldservicedetails: 'Old service details',
            Newservicedetails: 'New service details',
            ProductDetails: 'Product details',
            Serviceafterrequest: 'Service after request',
            Advanceservice: 'Service before request',
            Refundcustomers: 'Refund customers',

            Targetcustomers: 'Customer Objects',

            Totalamounttopaid: 'Amount paid by the customer',
            Quantity: 'Quantity',
            Totalamount: 'Total payment',
            Totalamountpaid: 'Total payment',
            Totalamounttobepaid: 'Total amount to be paid',
            TotalamountUnpaid: 'Total amount unpaid',
            Formpayment: 'Payment method',
            Payment: 'Payment',
            amountInWords: 'Amount in words',
            Employee: 'Employee',
            Area: 'Area',
            Promotion: 'Promotion',
            Money: 'Money',
            Reasondiscount: 'Reason for discounts',
            Serviceregistration: 'Service registration',
            Timeappointment: 'Time of appointment',
            Selectappointmentservice: 'Select an appointment service',
            Sellproducts: 'Sell products',
            Sourcesguests: 'Guest sources',
            Referrer: 'Referrer',
            Partner: 'Partner',
            Occupation: 'Occupation',
            BeVIP: 'As a VIP',
            Asemployee: 'As an employee',
            PersonalInformation: 'Personal information',
            Servicesperformed: 'Service performed',
            Productspurchased: 'Products purchased',
            Points: 'Earn points',
            Invoices: 'Invoices',
            invoiceDetail: 'Invoice Detail',
            AccumulatedPoints: 'Accumulated points',
            TransferredAmount: 'Transferred amount',
            ReceivedAmount: 'Received amount',
            PrepaidCardAmount: 'The amount of the prepaid card',
            declineReason: 'Reason for decline',
            cardBalance: 'Your balance in ',
            enterWithdrawal:
                'Please enter the amount you want to withdraw to prepaid card',

            AppointmentTime: 'Appointment time',
            ServiceCardRecharge: 'Service card topup',
            Prepaidcard: 'Prepaid card',
            Servicecardname: 'Name of service card',
            Actualservicecardvalue: 'Actual card value',
            Salesperson: 'Salesperson',
            Moneyinservicecard: 'Service card money',
            Rightsgroupname: 'Rights group name',
            Permissionsdetails: 'Permissions details',
            Adminaccount: 'Admin account',
            Pagename: 'Page name',
            Pagepath: 'Page path',
            Parentpage: 'Parent page',
            Backgroundcolor: 'Background color',
            Customeruses: 'Customers use',
            Selectregion: 'Select region',
            Regionname: 'Region name',
            Selectbranch: 'Select branch',
            Countryname: 'Country name',
            Selectcountry: 'Select country',
            Nameprovince: 'Name of province',
            Selectcity: 'Select province',
            Districtname: 'District name',
            Ethnicname: 'Ethnic name',
            Religiousname: 'Religious name',
            Namepaymentmethod: 'Payment method name',
            Customersourcename: 'Name of customer source',
            Occupationname: 'Occupation name',
            Counselor: 'Counselor',

            Starttime: 'Start time',
            Endtime: 'End time',
            Folderfilename: 'Folder / file name',

            receiver: 'Receiver',
            remitter: 'Remitter',
            error: {
                nameExisted: 'This name already exists in the language:',
                exceedTotalBalance:
                    'Service card money exceed Actual card value',
                exceedRemainBalance: 'Withdrawal exceed Card Balance',
                exceedMoreThan0: 'Withdrawal is higher than 0',
                serviceCardRequired: 'Service Card is required',
                serviceCardNotActive:
                    'This service card is not active. Withdrawal can not be made.',
                moneyCannotBeZero:
                    'Payment value can not be 0. Please enter payment value!',
                commissionLessThanZero: 'Commission must be greater than 0',
                dateRequired: 'Please enter date to search',
                commissionLessLessThanCommissioneRate: 'Commission must be less than service commission',
                totalCommissionLessLessThanCommissioneRate: 'Total commission must be less than service commission',
                oldPasswordValidate: 'The new password must not be the same as the old password',
                confirmPasswordValidate: 'The confirm password must be the same as the new password',
                customerNameRequired: 'Customer \' name is required',
                customerBirthdayRequired: 'Customer \' B.O.D. is required',
                customerPhoneRequired: 'Customer \' phone is required',
                customerGenderRequired: 'Customer \' gender is required',
                customerAddressRequired: 'Customer \' address is required'
            },
            CommissioneRate: 'Commission Rate',
            CommissionSeller: 'Commission Seller',
            Reasonapprove: 'Reason for disapprove',
            Reasonrefund: 'Reason for refund',
            expiredDay: 'Expired day',
            code: 'Code',
            date: 'Date',
            theCounter1: 'The counter 1',
            theCounter2: 'The counter 2',
            theCounter3: 'The counter 3',
            position: 'Position',
            note: 'Note',
            quantityRealty: 'Quantity realty',
            totalAmountRealty: 'Total amount realty',
            quantityDocument: 'Quantity document',
            totalAmountDocument: 'Total amount document',
            signature: 'Signature',
            printed: 'Times printed',
            AmountMoney: 'Amount of money',
            CardBalance: 'Card balance',
            ReceiverInformation: 'Recipient Information',
            InformationTransferer: 'Transferor information',
            signAndFullName: 'Sign and write your fullname',
            couponDetails: 'Coupon details',
            customerProfile: 'Customer profile',

            inventoryRecords: 'INVENTORY RECORDS',
            timeOfInventory: 'Time of inventory',
            inventoryCommittee: 'Inventory committee',
            grandParents: 'Grand parents',
            chiefAccountant: 'Chief Accountant',
            stocker: 'Stockkeeper',
            memberOfInventoryCommittee: 'Member of inventory committee'
        },
        title: {
            Add: 'Add new',
            Update: 'Information',
            Edit: 'Edit',
            Details: 'Details',
            account: 'account',
            region: 'region',
            country: 'country',
            province: 'province',
            district: 'district',
            ethnicity: 'ethnicity',
            religion: 'religion',
            brand: 'brand',
            paymentmethod: 'Payment method',
            guestsource: 'guest source',
            occupation: 'occupation',
            Actionforpage: 'Action for page',
            job: 'occupation',
            pages: 'page',
            loyaltycards: 'loyalty card',
            customers: 'Customer',
            Servicecard: 'Service card',
            Paymenthistory: 'Payment history',
            Tophistory: 'Top up history',
            topservicecards: 'Top up service card',
            Deposit: 'Deposit money',
            Buyservicecards: 'Buy service card',
            promotions: 'promotion',
            withdrawToPrepaidCard: 'Withdraw to Prepaid Card',

            rightsgroups: 'permission groups',
            permissions: 'permissions rights',
            Createcoupons: 'Create coupon',
            deals: 'deals',
            productgroups: 'product group',
            products: 'product',
            branch: 'branch',
            units: 'unit',
            servicegroups: 'service group',
            group: 'group',
            shift: 'shift',
            service: 'service',
            staff: 'employee',
            title: 'title',
            office: 'position',
            department: 'department',
            officedepartment: 'Position in a department',
            Listservicepersonnel: 'List of service performers',
            Consumables: 'Consumables',
            rights: 'rights',
            customerrequestsheets: 'customer request form',
            makepayments: 'payment',
            votes: 'votes',
            Accounttype: 'Account type',
            chooseposition: 'Choose a fatherhood',
            receipt: 'RECEIPT',
            receiptoutMoney: 'RECEIPT OUT MONEY',
            receiptTransfer: 'RECEIPT TRANSFER',
            receiptReceive: 'RECEIPT RECEIVE',
            receiptRefund: 'RECEIPT REFUND'
        },
        placeholder: {
            detailPayment: 'Select payment type',
            Searchname: 'Search first and last name',
            employeename: 'Enter employee name',
            searchkeyword: 'Enter search keyword',
            selectbranch: 'Select branch',
            Accounttype: 'Select Type of Account',
            Selectregion: 'Select region',
            Selectall: 'Select all',
            Selectcountry: 'Select country',
            selectprovince: 'Select province',
            Selectstatus: 'Select status',
            Enterdescription: 'Enter a description',
            groupname: 'Enter the permission group name',
            Fromdate: 'From date',
            Todate: 'To date',
            fromTime: 'from time',
            toTime: 'to time',
            Coupontype: 'Coupon type',
            language: 'Select language',
            Selectdepartments: 'Select departments',
            Searchservices: 'Search services',
            Selectproductgroups: 'Select product group',
            Finddeals: 'Search promotion',
            Cardcodesearch: 'Search card code',
            Billsearch: 'Search bill',
            rechargeSearch: 'Search invoice',
            Cardnamesearch: 'Search card name',
            billHistorySearch: 'Search for bill',
            Pointtype: 'Point type',
            Selectservicegroup: 'Select service group',
            Lookingsupplies: 'Looking for supplies',
            Lookingstaff: 'Looking for employees',
            view: 'Select view state',
            selectJob: ' Select job',
            chooseMonth: 'Choose month',
            prepaidCardStatus: 'Prepaid card status'
        },
        notification: {
            youHave: 'You have',
            notifications: 'notifications',
            notification: 'Notifications',
            markAsRead: 'Mark as Read',
            markAsunRead: 'Mark as unread',
            removeNotifications: 'Remove notifications',
            markAllAsRead: 'Mark all as read',
            noNotification: "You haven't notified yet!"
        }
    }
};
