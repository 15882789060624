export const Pages = [{
    id: 1,
    name: 'Cấu hình',
    bgColor: null,
    childCount: 0,
    icon: 'fa fa-cogs',
    idPath: '1',
    orderPath: null,
    url: '',
    parentId: null,
    children: [{
        id: 2,
        name: 'Trang',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-files-o',
        idPath: '1.2',
        orderPath: null,
        url: '/config/general/pages',
        parentId: 1,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 3,
        name: 'Quyền',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-user-secret',
        idPath: '1.3',
        orderPath: null,
        url: '/config/roles',
        parentId: 1,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 4,
        name: 'Client',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-users',
        idPath: '1.4',
        orderPath: null,
        url: '/config/general/clients',
        parentId: 1,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 5,
        name: 'Khách hàng',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-users',
        idPath: '1.5',
        orderPath: null,
        url: '/config/general/tenants',
        parentId: 1,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 6,
        name: 'Cấu hình hệ thống',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-cogs',
        idPath: '1.6',
        orderPath: null,
        url: '/config/systems',
        parentId: 1,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 7,
        name: 'Tài khoản',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-user',
        idPath: '1.7',
        orderPath: null,
        url: '/config/accounts',
        parentId: 1,
        children: [],
        isActive: false,
        isOpen: false
    }],
    isActive: false,
    isOpen: false
}, {
    id: 100,
    name: 'Khảo sát',
    bgColor: null,
    childCount: 0,
    icon: 'fa fa-rss',
    idPath: '100',
    orderPath: null,
    url: '',
    parentId: null,
    children: [{
        id: 101,
        name: 'Danh sách khảo sát',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-book',
        idPath: '100.101',
        orderPath: null,
        url: '/surveys',
        parentId: 100,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 102,
        name: 'Nhóm khảo sát',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-object-group',
        idPath: '100.102',
        orderPath: null,
        url: '/surveys/groups',
        parentId: 100,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 103,
        name: 'Ngân hàng câu hỏi',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-university',
        idPath: '100.103',
        orderPath: null,
        url: '/surveys/questions',
        parentId: 100,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 104,
        name: 'Nhóm câu hỏi',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-object-group',
        idPath: '100.104',
        orderPath: null,
        url: '/surveys/questions/groups',
        parentId: 100,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 106,
        name: 'Báo cáo khảo sát',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-bar-chart',
        idPath: '100.106',
        orderPath: null,
        url: '/surveys/reports',
        parentId: 100,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 114,
        name: 'Duyệt câu hỏi',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-check',
        idPath: '100.114',
        orderPath: null,
        url: '/surveys/questions/approves',
        parentId: 100,
        children: [],
        isActive: false,
        isOpen: false
    }],
    isActive: false,
    isOpen: false
}, {
    id: 1000,
    name: 'Mục tiêu',
    bgColor: '',
    childCount: 0,
    icon: 'fa fa-bullseye',
    idPath: '1000',
    orderPath: null,
    url: '',
    parentId: null,
    children: [{
        id: 1001,
        name: 'Quản trị',
        bgColor: null,
        childCount: 0,
        icon: '',
        idPath: '1000.1001',
        orderPath: null,
        url: '',
        parentId: 1000,
        children: [{
            id: 1002,
            name: 'Bảng mục tiêu',
            bgColor: '',
            childCount: 0,
            icon: '',
            idPath: '1000.1001.1002',
            orderPath: null,
            url: '/targets/configs/tables',
            parentId: 1001,
            children: [],
            isActive: false,
            isOpen: false
        }, {
            id: 1003,
            name: 'Thư viện mục tiêu mẫu',
            bgColor: null,
            childCount: 0,
            icon: null,
            idPath: '1000.1001.1003',
            orderPath: null,
            url: '/targets/configs/libraries',
            parentId: 1001,
            children: [],
            isActive: false,
            isOpen: false
        }],
        isActive: false,
        isOpen: false
    }, {
        id: 1005,
        name: 'Mục tiêu đơn vị',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-bullseye',
        idPath: '1000.1005',
        orderPath: null,
        url: '/targets/offices',
        parentId: 1000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 1006,
        name: 'Mục tiêu nhân viên',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-bullseye',
        idPath: '1000.1006',
        orderPath: null,
        url: '/targets/personals',
        parentId: 1000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 1007,
        name: 'Báo cáo mục tiêu',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-line-chart',
        idPath: '1000.1007',
        orderPath: null,
        url: '/targets/reports',
        parentId: 1000,
        children: [],
        isActive: false,
        isOpen: false
    }],
    isActive: false,
    isOpen: false
}, {
    id: 100000,
    name: 'Quản lý kho',
    bgColor: null,
    childCount: 0,
    icon: 'fa fa-database',
    idPath: '100000',
    orderPath: null,
    url: '',
    parentId: null,
    children: [{
        id: 100001,
        name: 'Sản phẩm',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-adjust',
        idPath: '100000.100001',
        orderPath: null,
        url: '/products',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100002,
        name: 'Thuộc tính sản phẩm',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-cubes',
        idPath: '100000.100002',
        orderPath: null,
        url: '/products/attributes',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100003,
        name: 'Nhóm sản phẩm',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-object-group',
        idPath: '100000.100003',
        orderPath: null,
        url: '/products/categories',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100004,
        name: 'Nhà cung cấp',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-building-o',
        idPath: '100000.100004',
        orderPath: null,
        url: '/products/configs/suppliers',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100006,
        name: 'Thương hiệu',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-square',
        idPath: '100000.100006',
        orderPath: null,
        url: '/products/configs/brands',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100007,
        name: 'Đơn vị',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-flask',
        idPath: '100000.100007',
        orderPath: null,
        url: '/products/configs/units',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100009,
        name: 'Kho',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-cube',
        idPath: '100000.100009',
        orderPath: null,
        url: '/warehouses',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100010,
        name: 'Nhập kho',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-arrow-down',
        idPath: '100000.100010',
        orderPath: null,
        url: '/goods/goods-receipt-notes',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100011,
        name: 'Xuất kho',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-arrow-up',
        idPath: '100000.100011',
        orderPath: null,
        url: '/goods/goods-delivery-notes',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100012,
        name: 'Cấu hình kho',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-cog',
        idPath: '100000.100012',
        orderPath: null,
        url: '/warehouses/configs',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100013,
        name: 'Báo cáo nhập xuất tồn',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-bar-chart',
        idPath: '100000.100013',
        orderPath: null,
        url: '/goods/inventories/report',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 100015,
        name: 'Kiểm kho',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-indent',
        idPath: '100000.100015',
        orderPath: null,
        url: '/goods/inventories',
        parentId: 100000,
        children: [],
        isActive: false,
        isOpen: false
    }],
    isActive: false,
    isOpen: false
}, {
    id: 2000,
    name: 'Công Việc',
    bgColor: '',
    childCount: 0,
    icon: 'fa fa-tasks',
    idPath: '2000',
    orderPath: null,
    url: '/tasks',
    parentId: null,
    children: [{
        id: 2004,
        name: 'Công việc cá nhân',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-tasks',
        idPath: '2000.2004',
        orderPath: null,
        url: '/tasks/list',
        parentId: 2000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 2005,
        name: 'Công việc nhóm',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-tasks',
        idPath: '2000.2005',
        orderPath: null,
        url: '/tasks/group',
        parentId: 2000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 2006,
        name: 'Báo cáo công việc',
        bgColor: null,
        childCount: 0,
        icon: '',
        idPath: '2000.2006',
        orderPath: null,
        url: '/tasks/reports',
        parentId: 2000,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 2099,
        name: 'Quản trị',
        bgColor: null,
        childCount: 0,
        icon: '',
        idPath: '2000.2099',
        orderPath: null,
        url: '',
        parentId: 2000,
        children: [{
            id: 2001,
            name: 'Vai trò công việc',
            bgColor: '',
            childCount: 0,
            icon: '',
            idPath: '2000.2099.2001',
            orderPath: null,
            url: '/tasks/configs/roles',
            parentId: 2099,
            children: [],
            isActive: false,
            isOpen: false
        }, {
            id: 2003,
            name: 'Thư viện công việc mẫu',
            bgColor: null,
            childCount: 0,
            icon: '',
            idPath: '2000.2099.2003',
            orderPath: null,
            url: '/tasks/configs/libraries',
            parentId: 2099,
            children: [],
            isActive: false,
            isOpen: false
        }],
        isActive: false,
        isOpen: false
    }],
    isActive: false,
    isOpen: false
}, {
    id: 217,
    name: 'Quản lý tài liệu',
    bgColor: null,
    childCount: 0,
    icon: 'fa fa-folder',
    idPath: '217',
    orderPath: null,
    url: '/folders',
    parentId: null,
    children: [],
    isActive: false,
    isOpen: false
}, {
    id: 300,
    name: 'Nhân sự',
    bgColor: null,
    childCount: 0,
    icon: 'fa fa-users',
    idPath: '300',
    orderPath: null,
    url: '',
    parentId: null,
    children: [{
        id: 303,
        name: 'Nhân viên',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-user',
        idPath: '300.303',
        orderPath: null,
        url: '/users',
        parentId: 300,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 305,
        name: 'Danh sách hợp đồng',
        bgColor: null,
        childCount: 0,
        icon: '',
        idPath: '300.305',
        orderPath: null,
        url: '/users/labor-contract/list',
        parentId: 300,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 314,
        name: 'Cấu hình quản lý',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-cogs',
        idPath: '300.314',
        orderPath: null,
        url: '/users/manager-config',
        parentId: 300,
        children: [],
        isActive: false,
        isOpen: false
    }, {
        id: 315,
        name: 'Đánh giá hiệu quả CV',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-tasks',
        idPath: '300.315',
        orderPath: null,
        url: '/assessment',
        parentId: 300,
        children: [{
            id: 316,
            name: 'Duyệt đánh giá',
            bgColor: '',
            childCount: 0,
            icon: 'fa fa-check',
            idPath: '300.315.316',
            orderPath: null,
            url: '/assessment/approve',
            parentId: 315,
            children: [],
            isActive: false,
            isOpen: false
        }, {
            id: 317,
            name: 'Thư viện tiêu chí',
            bgColor: '',
            childCount: 0,
            icon: 'fa fa-university',
            idPath: '300.315.317',
            orderPath: null,
            url: '/assessment/criteria',
            parentId: 315,
            children: [],
            isActive: false,
            isOpen: false
        }, {
            id: 318,
            name: 'Cấu hình tiêu chí',
            bgColor: '',
            childCount: 0,
            icon: 'fa fa-cogs',
            idPath: '300.315.318',
            orderPath: null,
            url: '/assessment/criteria-position',
            parentId: 315,
            children: [],
            isActive: false,
            isOpen: false
        }, {
            id: 319,
            name: 'Kỳ đánh giá của tôi',
            bgColor: '',
            childCount: 0,
            icon: 'fa fa-user',
            idPath: '300.315.319',
            orderPath: null,
            url: '/assessment/my-assessment',
            parentId: 315,
            children: [],
            isActive: false,
            isOpen: false
        }, {
            id: 320,
            name: 'Tổng hợp kết quả đánh giá',
            bgColor: '',
            childCount: 0,
            icon: 'fa fa-bookmark',
            idPath: '300.315.320',
            orderPath: null,
            url: '/assessment/result',
            parentId: 315,
            children: [],
            isActive: false,
            isOpen: false
        }, {
            id: 322,
            name: 'Cấu hình',
            bgColor: '',
            childCount: 0,
            icon: 'fa fa-cogs',
            idPath: '300.315.322',
            orderPath: null,
            url: '/assessment/config',
            parentId: 315,
            children: [],
            isActive: false,
            isOpen: false
        }, {
            id: 323,
            name: 'Nhóm tiêu chí',
            bgColor: null,
            childCount: 0,
            icon: 'fa fa-object-group',
            idPath: '300.315.323',
            orderPath: null,
            url: '/assessment/criteria/group',
            parentId: 315,
            children: [],
            isActive: false,
            isOpen: false
        }],
        isActive: false,
        isOpen: false
    }, {
        id: 321,
        name: 'Cơ cấu tổ chức',
        bgColor: '',
        childCount: 0,
        icon: 'fa fa-building',
        idPath: '300.321',
        orderPath: null,
        url: '/organization',
        parentId: 300,
        children: [{
            id: 301,
            name: 'Chức danh',
            bgColor: '',
            childCount: 0,
            icon: 'fa fa-graduation-cap ',
            idPath: '300.321.301',
            orderPath: null,
            url: '/organization/titles',
            parentId: 321,
            children: [],
            isActive: false,
            isOpen: false
        }, {
            id: 302,
            name: 'Chức vụ',
            bgColor: '',
            childCount: 0,
            icon: 'fa fa-graduation-cap ',
            idPath: '300.321.302',
            orderPath: null,
            url: '/organization/positions',
            parentId: 321,
            children: [],
            isActive: false,
            isOpen: false
        }, {
            id: 308,
            name: 'Phòng ban',
            bgColor: null,
            childCount: 0,
            icon: 'fa fa-building',
            idPath: '300.321.308',
            orderPath: null,
            url: '/organization/offices',
            parentId: 321,
            children: [],
            isActive: false,
            isOpen: false
        }],
        isActive: false,
        isOpen: false
    }, {
        id: 324,
        name: 'Mẫu hợp đồng',
        bgColor: null,
        childCount: 0,
        icon: 'fa fa-file-text-o',
        idPath: '300.324',
        orderPath: null,
        url: '/users/labor-contract/forms',
        parentId: 300,
        children: [],
        isActive: false,
        isOpen: false
    }],
    isActive: false,
    isOpen: false
}];

export const Languages = [{
    languageId: 'vi-VN',
    name: 'Tiếng Việt',
    description: null,
    isDefault: true,
    isActive: true
}, {
    languageId: 'en-US',
    name: 'English',
    description: 'Tiếng anh',
    isDefault: false
}];

export const User = [
    { key: 'BranchId', value: '43a72149-0f34-483a-a27c-f37de7d2931a' },
    { key: 'LanguageId', value: 'vi-VN' },
    { key: 'PageSize', value: '20' },
    { key: 'ThemeId', value: '14998e5c-27a3-44ff-92d8-19dfd1366ba1' },
    { key: 'WarehouseId', value: 'KT' }
];

export const WebsitesList = [];

export const CurrentUser = {};

export const Permission = [];

export default {
    User,
    Languages,
    Pages,
    WebsitesList,
    CurrentUser,
    Permission
};
