import Transformer from '../utils/Transformer';

export default class BaseViewModel {
    constructor(props, languageId = null, ...otherParams) {
        if (languageId === null) {
            this.initialize(props);
        } else {
            this.initializeWithLanguage(props, languageId, ...otherParams);
        }
    }

    /**
     * Initializes the app.
     *
     * @inheritdoc
     */
    initialize(props) {
        Object.keys(props).forEach(element => {
            if (props[element] && Transformer.IsJsonString(props[element])) {
                this[element] = JSON.parse(props[element]);
            } else {
                this[element] = props[element];
            }
        });
    }

    /**
     * Initializes the app.
     *
     * @inheritdoc
     */
    initializeWithLanguage(props, languageId) {
        Object.keys(props).forEach(element => {
            if (props[element] && Transformer.IsJsonString(props[element])) {
                // console.log('pares', element);
                if (JSON.parse(props[element])['vi-VN']) {
                    this[element] = Transformer.convertJSONString(props[element], languageId);
                } else {
                    this[element] = '';
                }
            } else {
                // console.log('no pares', element);
                this[element] = props[element];
            }
        });
    }
}
