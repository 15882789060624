import { PAGE_SIZE } from '../../../constants/Config';
import {
    PROMOTION_CARD_DETAIL_DELETE,
    PROMOTION_CARD_DETAIL_DETAIL,
    PROMOTION_CARD_DETAIL_INSERT,
    PROMOTION_CARD_DETAIL_LIST,
    PROMOTION_CARD_DETAIL_UPDATE,
    PROMOTION_CARD_DETAIL_GET_ALL,
    PROMOTION_CARD_DETAIL_SELECT,
    PROMOTION_CARD_DETAIL_LIST_RESET,
    PROMOTION_CARD_DETAIL_SEARCH
} from './action-types';
import * as _ from 'lodash';
const initialState = {
    data: [],
    dataSearch: [],
    totalRows: 0,
    totalRowsSearch: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {},
    suggestion: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PROMOTION_CARD_DETAIL_LIST:
            return list(state, payload);
        case PROMOTION_CARD_DETAIL_DETAIL:
            return detail(state, payload);
        case PROMOTION_CARD_DETAIL_UPDATE:
            return update(state, payload);
        case PROMOTION_CARD_DETAIL_INSERT:
            return insert(state, payload);
        case PROMOTION_CARD_DETAIL_DELETE:
            return deletes(state, payload);
        case PROMOTION_CARD_DETAIL_GET_ALL:
            return suggestion(state, payload);
        case PROMOTION_CARD_DETAIL_SELECT:
            return select(state, payload);
        case PROMOTION_CARD_DETAIL_LIST_RESET:
            return resetList(state);
        case PROMOTION_CARD_DETAIL_SEARCH:
            return search(state, payload);
        default:
            return state;
    }
};

function resetList(state) {
    state = Object.assign({}, state, {
        data: [],
        dataSearch: []
    });
    return state;
}

function select(state, payload) {
    state = Object.assign({}, state, {
        item: {
            id: payload
        }
    });
    return state;
}

function list(state, payload) {
    (_.isArray(payload))
        ? (state = Object.assign({}, state, { data: payload }))
        : (state = Object.assign({}, state, { data: payload.data }));

    return state;
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);
    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = state.data ? [...state.data, payload] : [payload];
    const item = Object.assign({}, state.item, payload);

    return Object.assign({}, state, { data, item });
}

function search(state, payload) {
    return Object.assign({ ...state, dataSearch: payload.data, totalRowsSearch: payload.totalRows });
}
export default reducer;
