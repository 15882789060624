export const USER_SERVICE_LIST = 'USER_SERVICE_LIST'
export const USER_SERVICE_DETAIL = 'USER_SERVICE_DETAIL'
export const USER_SERVICE_UPDATE = 'USER_SERVICE_UPDATE'
export const USER_SERVICE_INSERT = 'USER_SERVICE_INSERT'
export const USER_SERVICE_DELETE = 'USER_SERVICE_DELETE'
export const USER_SERVICE_GET_ALL = 'USER_SERVICE_GET_ALL'
export const USER_SERVICE_SELECT = 'USER_SERVICE_SELECT'
export const USER_SERVICE_USER_LIST = 'USER_SERVICE_USER_LIST'
export const USER_SERVICE_LIST_RESET = 'USER_SERVICE_LIST_RESET'
export const USER_SERVICE_TIMESHIFT = 'USER_SERVICE_TIMESHIFT'
export const USER_SERVICE_SERVICE_LIST = 'USER_SERVICE_SERVICE_LIST'
export const USER_SERVICE_GET_BY_USER_ID = 'USER_SERVICE_GET_BY_USER_ID'

export default {
    USER_SERVICE_DELETE,
    USER_SERVICE_DETAIL,
    USER_SERVICE_INSERT,
    USER_SERVICE_LIST,
    USER_SERVICE_UPDATE,
    USER_SERVICE_GET_ALL,
    USER_SERVICE_SELECT,
    USER_SERVICE_USER_LIST,
    USER_SERVICE_LIST_RESET,
    USER_SERVICE_TIMESHIFT,
    USER_SERVICE_SERVICE_LIST,
    USER_SERVICE_GET_BY_USER_ID
}