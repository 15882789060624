var moment = require('moment');

export default class FolderViewModel {
    constructor(foldersList, filesList) {
        this.initialize(foldersList, filesList)
    }
    initialize(foldersList, filesList) {
        this.data = [];

        let rootFolder = foldersList.filter(folder => !folder.parentId);
        let rootFile = filesList.filter(file => !file.folderId);

        rootFolder.forEach((item) => {
            this.data.push({
                "id": item.id,
                "key": item.id,
                "name": item.name,
                "isDirectory": true,
                "dateModified": moment(item.createTime),
                "items": this.getChildFolder(foldersList, filesList, item.id),
                "concurrencyStamp": item.concurrencyStamp,
                "parentId": item.parentId
            })
        })

        rootFile.forEach((item) => {
            this.data.push({
                "name": item.name,
                "id": item.id,
                "key": item.id,
                "dateModified": moment(item.createTime),
                "size": item.size,
                "type": item.type,
                "url": item.url,
                "isDirectory": false,
                "concurrencyStamp": item.concurrencyStamp,
                "folderId": item.folderId
            })
        })


    }

    getChildFolder(foldersList, filesList, folderId) {
        let childFolder = foldersList.filter(folder => folder.parentId === folderId);
        let childFile = filesList.filter(file => file.folderId === folderId);

        let _items = [];

        childFile.forEach((item) => {
            _items.push({
                "name": item.name,
                "id": item.id,
                "key": item.id,
                "dateModified": moment(item.createTime),
                "size": item.size,
                "type": item.type,
                "url": item.url,
                "isDirectory": false,
                "concurrencyStamp": item.concurrencyStamp,
                "folderId": item.folderId
            })
        })

        if (childFolder) {
            childFolder.forEach((item) => {
                _items.push({
                    "id": item.id,
                    "key": item.id,
                    "name": item.name,
                    "isDirectory": true,
                    "dateModified": moment(item.createTime),
                    "items": this.getChildFolder(foldersList, filesList, item.id),
                    "concurrencyStamp": item.concurrencyStamp,
                    "parentId": item.parentId
                })
            })
        }

        return _items;
    }
}
