import Loadable from "react-loadable";
import LoadingComponent from "../../common/loader";

export default [
  {
    path: "/report/revenue-by-service",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./RevenueByServiceReportContainer"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/report/revenue-by-customer",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./CustomerReportContainer"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/report/revenue-by-agents",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./RevenueByAgentsContainer"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/report/revenue-sale",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./SellingReportContainer"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/report/work-list",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./WorkShiftReportContainer"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/report/agent-work-time",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./AgentsWorkTimeReportContainer"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/report/summary-agent-work-time",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./SummaryAgentsWorkTimeContainer"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/report/refund-report",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./RefundReportContainer"),
      loading: LoadingComponent,
    }),
  },
];
