import { connect } from 'react-redux';
import {
    countryGetAll
} from '../service';
import BaseSelectBox from '../../../../base/components/BaseSelectBox';

class CountrySelectBox extends BaseSelectBox {

}

const mapStateToProps = (state) => {
    return {
        languages: state.appSettings.data.languages,
        listData: state.country.suggestion
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllData: () => {
            return dispatch(countryGetAll());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelectBox);
