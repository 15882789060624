import { ToastsStore } from 'react-toasts';
import * as _ from 'lodash';
import { FILE_URL } from '../../../constants/Config';

function handleFileUpload(fileMgrClass, event, filesList) {
    const currentFolder = fileMgrClass.fileMgr.getCurrentDirectory();

    const folderId = currentFolder.isRoot ? null : currentFolder.dataItem.id;
    const filesInFolder = _.filter(filesList, x => x.folderId === folderId);

    const uploadFiles = [];
    for (let i = 0; i < event.target.files.length; i++) {
        if (_.findIndex(filesInFolder, x => x.name === event.target.files[i].name) !== -1) {
            const fileExt = event.target.files[i].name.split('.').pop();
            const fileNameOnly = event.target.files[i].name.slice(0, event.target.files[i].name.length - fileExt.length - 1);

            console.log('aaa', `${fileNameOnly}_${Date.now()}.${fileExt}`);
            uploadFiles.push({
                file: event.target.files[i],
                name: `${fileNameOnly}_${Date.now()}.${fileExt}`
            });
        } else {
            uploadFiles.push({
                file: event.target.files[i],
                name: event.target.files[i].name
            });
        }
    }

    fileMgrClass.props.uploadFile(folderId, uploadFiles)
        .then((data) => {
            const currentFolder = fileMgrClass.fileMgr.getCurrentDirectory();

            fileMgrClass.refs.fileUploader.value = '';

            if (currentFolder.dataItem) {
                fileMgrClass.fileMgr.refresh();
            } else {
                fileMgrClass.updateFileProvider();

                fileMgrClass.fileMgr.refresh();
            }
        });
}

function downloadFile(fileMgrClass) {
    const selectedItems = fileMgrClass.fileMgr.getSelectedItems();
    let err = 0;
    selectedItems.forEach((element) => {
        if (!_.has(element, 'dataItem')) {
            err = 1;
        }
    });

    if (err === 0) {
        if (!selectedItems[0].dataItem.isDirectory) {
            const fileId = selectedItems[0].dataItem.id;
            const fileName = selectedItems[0].dataItem.name;

            fileMgrClass.props
                .downloadFile(fileId)
                .then((res) => {
                    fileMgrClass.setState({
                        imgDataURL: res,
                        imgName: fileName,
                        popupDownloadVisible: true
                    });
                })
                .catch((err) => {
                    ToastsStore.error(err);
                });
        }
    }
}

function download(fileMgrClass) {
    const selectedItems = fileMgrClass.fileMgr.getSelectedItems();
    let err = 0;
    selectedItems.forEach((element) => {
        if (!_.has(element, 'dataItem')) {
            err = 1;
        }
        if (err === 0) {
            if (!element.dataItem.isDirectory) {
                if (element.dataItem.type.match(/image\/.*/)) {
                    fileMgrClass.setState({
                        popupVisible: true,
                        imageItemToDisplay: {
                            name: element.dataItem.name,
                            url: FILE_URL + '/' + element.dataItem.url
                        }
                    });
                } else {
                    // fake server request, getting the file url as response
                    setTimeout(() => {
                        const response = {
                            file: FILE_URL + '/' + element.dataItem.url
                        };

                        window.open(response.file);
                    }, 100);
                }
            }
        }
    });
}

export {
    handleFileUpload,
    downloadFile,
    download
};
