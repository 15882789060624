export const ACCOUNT_LIST = 'ACCOUNT_LIST'
export const ACCOUNT_DETAIL = 'ACCOUNT_DETAIL'
export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE'
export const ACCOUNT_INSERT = 'ACCOUNT_INSERT'
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE'
export const ACCOUNT_RESPONSE = 'ACCOUNT_RESPONSE'
export const ACCOUNT_LOCK = 'ACCOUNT_LOCK'
export const ACCOUNT_CHANGE_PASSWORD = 'ACCOUNT_CHANGE_PASSWORD'
export const ACCOUNT_RESET_PASSWORD = 'ACCOUNT_RESET_PASSWORD'
export const ACCOUNT_GETALL_EMPLOYEE = 'ACCOUNT_GETALL_EMPLOYEE'

export default {
    ACCOUNT_DELETE,
    ACCOUNT_DETAIL,
    ACCOUNT_INSERT,
    ACCOUNT_LIST,
    ACCOUNT_UPDATE,
    ACCOUNT_RESPONSE,
    ACCOUNT_LOCK,
    ACCOUNT_CHANGE_PASSWORD,
    ACCOUNT_RESET_PASSWORD,
    ACCOUNT_GETALL_EMPLOYEE
}
