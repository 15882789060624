import React from 'react';
import { connect } from 'react-redux';
import BaseSelectBox from '../../../base/components/BaseSelectBox';
import i18n from '../../../i18n';
import Transformer from '../../../utils/Transformer';
import {
    userGetAllRequest
} from '../service';
import * as _ from 'lodash';
import { DropDownBox } from 'devextreme-react';
import DataGrid, { Selection, Paging, Scrolling, SearchPanel } from 'devextreme-react/data-grid';

class UserSelectBox extends BaseSelectBox {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            payload: {
                isAdmin: (this.props.isAdmin !== undefined) ? this.props.isAdmin : false
            }
        };

        this.dataGridRef = React.createRef();
    }

    get dataGrid() {
        return this.dataGridRef.current.instance;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.value) {
            return {
                renderDone: true
            };
        } else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        const { field, objectId } = this.props;
        if (prevProps.value || (this.props.value && this.props.value !== prevProps.value)) {
            if (objectId) {
                this.props.updateData({
                    formValidate: {
                        [objectId]: true
                    }
                });
            } else {
                this.props.updateData({
                    formValidate: {
                        [field]: true
                    }
                });
            }
        }
    }

    handleLanguage = () => {
        const { languages, listData } = this.props;

        let datas = [];

        languages.forEach((element) => {
            const newArray = [];

            for (const item of listData) {
                const i = Object.assign({}, item);
                const nameParse = Transformer.IsJsonString(i.name)
                    ? Transformer.convertJSONString(i.name, element.languageId)
                    : i.name;

                i.name = nameParse;
                newArray.push(i);
            }

            datas = { ...datas, [element.languageId]: newArray };
        });
        this.setState(
            {
                datas
            },
            () => {
                this.setState({ renderDone: true });
            }
        );
    };

    onCustomItemCreating = (e) => {
        const { field, listData, objectId, objectName, validatorValue } = this.props;
        const value = (e.selectedRowKeys.length && e.selectedRowKeys) || [];
        if (value.length > 0) {
            const selectedUser = _.find(listData, x => x.id === value[0]);

            if (objectId && objectName && selectedUser) {
                this.props.updateData({
                    [objectId]: value[0],
                    [objectName]: selectedUser ? selectedUser.fullName : ''
                });

                if (validatorValue && validatorValue.includes('required')) {
                    this.props.updateData({
                        formValidate: {
                            [objectId]: !!selectedUser
                        }
                    });
                }
            } else {
                this.props.updateData({
                    [field]: value[0]
                });
            }
            if (validatorValue && validatorValue.includes('required')) {
                this.props.updateData({
                    formValidate: {
                        [field]: !!selectedUser
                    }
                });
            }
        } else {
            this.props.updateData({
                [field]: null
            });
        }

        this.dataGrid.close();
    }

    onDataChanged(e) {
        const { objectId, objectName, listData, validatorValue } = this.props;
        if (objectId && objectName) {
            const selectedUser = _.find(listData, (x) => x.id === e.value);
            this.props.updateData({
                [objectId]: e.value,
                [objectName]: selectedUser ? selectedUser.fullName : ''
            });
            if (validatorValue && validatorValue.includes('required')) {
                this.props.updateData({
                    formValidate: {
                        [objectId]: !!selectedUser
                    }
                });
            }
        } else {
            super.onDataChanged(e);
        }
    }

    syncDataGridSelection = (e) => {
        const { field } = this.props;

        this.props.updateData({
            [field]: e.value || []
        });
    }

    dataGridRender = () => {
        const { currentLanguageActive, value } = this.props;
        const { datas } = this.state;
        const valueArr = [];
        value && valueArr.push(value);

        return (
            <DataGrid
                hoverStateEnabled={true}
                dataSource={datas[currentLanguageActive]}
                columns={[{ dataField: 'code', width: 100 }, { dataField: 'fullName' }]}
                keyExpr="id"
                allowColumnResizing={true}
                className="branch_custom"
                selectedRowKeys={valueArr}
                onSelectionChanged={this.onCustomItemCreating}
                height="100%"
            >
                <Selection mode="single" />
                <Scrolling mode="infinite" />
                <Paging enabled={true} pageSize={10} />
                <SearchPanel
                    visible={true}
                    width={300}
                    placeholder="Search..."
                />
            </DataGrid>
        );
    };

    render() {
        const { value, i18nLabel, disabled, isShowLabel, readOnly, validatorValue, currentLanguageActive, isShowClearButton } = this.props;
        const { datas } = this.state;

        return (
            <div className={'dx-field'}>
                {
                    isShowLabel && (
                        <div className={'dx-field-label'}>
                            {i18n.t(i18nLabel) + ':'}
                            {validatorValue &&
                                validatorValue.includes('require') && (<span style={{ color: 'red' }}> *</span>)}
                        </div>
                    )
                }

                <div className={'dx-field-value'}>
                    <DropDownBox
                        ref={this.dataGridRef}
                        value={value}
                        valueExpr="id"
                        deferRendering={false}
                        displayExpr="fullName"
                        showClearButton={isShowClearButton !== undefined ? isShowClearButton : true}
                        searchEnabled={true}
                        width={'100%'}
                        placeholder={i18n.t(i18nLabel)}
                        dataSource={datas[currentLanguageActive]}
                        onValueChanged={this.syncDataGridSelection}
                        contentRender={this.dataGridRender}
                        disabled={disabled}
                        readOnly={readOnly || false}
                    />
                    {/* <SelectBox
                        searchEnabled={true}
                        items={datas}
                        value={value}
                        valueExpr={'id'}
                        displayExpr={'fullName'}
                        placeholder={i18n.t(i18nLabel)}
                        showClearButton={true}
                        onValueChanged={this.onDataChanged}
                        disabled={disabled}
                        readOnly={readOnly || false}
                    /> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.appSettings.data.languages,
        listData: state.user.suggestion
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllData: (payload) => {
            return dispatch(userGetAllRequest(payload));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectBox);
