export const enI = {
    invoice: {
        payment: {
            title: 'Payment',
            code: 'Invoice ID',
            date: 'Invoice Date'
        },
        totalRefund: 'Total Refund',
        type: {
            servicenoteInvoice: 'Servicenote Invoice',
            goodnoteInvoice: 'Goodnote Invoice'
        },
        BillServiceCard: {
            code: 'Card code',
            name: 'Name Code'

        }
    },
    inventory: {
        insertTitle: 'Add New Inventory',
        updateTitle: 'Update Inventory',
        listTitle: 'List of Inventories',
        unit: 'inventories'
    }
};
