import { NAILSPA_URL } from "../../../constants/Config";
import Http from "../../../utils/Http";
import * as paymentActions from "./store/actions";
import { spinnerLoading } from "../../../common/spinner/store/action";

export function paymentSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            let url = `${NAILSPA_URL}categorys/methodOfPayment?keyword=${payload.keyword}`;
            url +=
                payload.isActive !== null
                    ? `&isActive=${payload.isActive}`
                    : "";
            url += payload.page ? `&page=${payload.page}` : "";
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : "";

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(paymentActions.list(res.data));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function paymentSearchValidNameRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}categorys/methodOfPayment?keyword=${payload.keyword}`;

            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
}

export function paymentInsertRequest(payment) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}categorys/methodOfPayment`;
            let paymentUpdate = payment;
            paymentUpdate.name = JSON.stringify(payment.name);
            paymentUpdate.description = JSON.stringify(payment.description);

            Http.post(url, paymentUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    payment.id = res.data.data;
                    dispatch(paymentActions.insert(payment));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function paymentUpdateRequest(payment) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}categorys/methodOfPayment/${payment.id}`;

            let paymentUpdate = payment;
            paymentUpdate.name = JSON.stringify(payment.name);
            paymentUpdate.description = JSON.stringify(payment.description);

            Http.put(url, paymentUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    payment.concurrencyStamp = res.data.data;
                    dispatch(paymentActions.update(payment));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function paymentDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}categorys/methodOfPayment/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    //dispatch(spinnerLoading(false))
                    dispatch(paymentActions.detail(res.data.data));
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
}

export function paymentDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}categorys/methodOfPayment/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(paymentActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function paymentGetAll() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}categorys/get-all/methodOfPayment`;
            Http.get(url)
                .then((res) => {
                    dispatch(paymentActions.suggestion(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
