export const JOB_LIST = 'JOB_LIST'
export const JOB_DETAIL = 'JOB_DETAIL'
export const JOB_UPDATE = 'JOB_UPDATE'
export const JOB_INSERT = 'JOB_INSERT'
export const JOB_DELETE = 'JOB_DELETE'
export const JOB_RESPONSE = 'JOB_RESPONSE'
export const JOB_GETALL = 'JOB_GETALL'
export default {
    JOB_DELETE,
    JOB_DETAIL,
    JOB_INSERT,
    JOB_LIST,
    JOB_UPDATE,
    JOB_GETALL
}
