export const SERVICE_PACKAGE_LIST = 'SERVICE_PACKAGE_LIST';
export const SERVICE_PACKAGE_DETAIL = 'SERVICE_PACKAGE_DETAIL';
export const SERVICE_PACKAGE_UPDATE = 'SERVICE_PACKAGE_UPDATE';
export const SERVICE_PACKAGE_INSERT = 'SERVICE_PACKAGE_INSERT';
export const SERVICE_PACKAGE_DELETE = 'SERVICE_PACKAGE_DELETE';
export const SERVICE_PACKAGE_GET_ALL = 'SERVICE_PACKAGE_GET_ALL';
export const SERVICE_PACKAGE_DETAILS_SET_ALL = 'SERVICE_PACKAGE_DETAILS_SET_ALL';
export const SERVICE_PACKAGE_DETAILS_GET_ALL = 'SERVICE_PACKAGE_DETAILS_GET_ALL';

export default {
    SERVICE_PACKAGE_LIST,
    SERVICE_PACKAGE_DETAIL,
    SERVICE_PACKAGE_UPDATE,
    SERVICE_PACKAGE_INSERT,
    SERVICE_PACKAGE_DELETE,
    SERVICE_PACKAGE_GET_ALL,
    SERVICE_PACKAGE_DETAILS_GET_ALL,
    SERVICE_PACKAGE_DETAILS_SET_ALL
};
