import BaseViewModel from '../../../base/BaseViewModel';
export default class ServiceViewModel extends BaseViewModel {
    initializeWithLanguage(props, languageId) {
        super.initializeWithLanguage(props, languageId);
        this.servicePackageDetails = [];
        if (props.servicePackageDetails) {
            props.servicePackageDetails.forEach(
                item => {
                    if (typeof item === 'object') {
                        this.servicePackageDetails.push(item.serviceId);
                    } else {
                        this.servicePackageDetails.push(item);
                    }
                }
            );
        }
        if (props.serviceMains) {
            props.serviceMains.forEach(item => {
                if (typeof item === 'object') {
                    this.serviceMains.push(item.serviceId);
                } else {
                    this.serviceMains.push(item);
                }
            });
        }
    }

    initialize(props) {
        super.initialize(props);
        this.servicePackageDetails = [];
        if (props.servicePackageDetails) {
            props.servicePackageDetails.forEach(item => {
                if (typeof item === 'object') {
                    this.servicePackageDetails.push(item.serviceId);
                } else {
                    this.servicePackageDetails.push(item);
                }
            });
        }
        this.serviceMains = [];
        if (props.serviceMains) {
            props.serviceMains.forEach(item => {
                if (typeof item === 'object') {
                    this.serviceMains.push(item.serviceId);
                } else {
                    this.serviceMains.push(item);
                }
            });
        }
    }
}
