import { THEME_LIST, CHANGE_THEME } from './action-types'

export function list(payload) {
    return {
        type: THEME_LIST,
        payload
    }
}

export function update(payload) {
    return {
        type: CHANGE_THEME,
        payload
    }
}
