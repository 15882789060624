import { NAILSPA_URL } from '../../constants/Config';
import Http from '../../utils/Http';
import { spinnerLoading } from '../../common/spinner/store/action';
import * as serviceNoteActions from './store/actions';
import ServiceNoteViewModel from './ServiceNoteViewModel';
import moment from 'moment';

export function selectServiveNote(serviceNoteId) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(serviceNoteActions.select(serviceNoteId));
            resolve();
        });
}

export function resetServiveNoteDetail() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(serviceNoteActions.resetDetail());
            resolve();
        });
}

export function serviceNoteSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}serviceNotes?branchId=${payload.branchId}&keyword=${payload.keyword}`;
            url += payload.page ? `&page=${payload.page}` : '';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '';
            url += payload.startDate ? `&startDate=${payload.startDate}` : '';
            url += payload.endDate ? `&endDate=${payload.endDate}` : '';
            url += payload.status !== null ? `&status=${payload.status}` : '';
            url += payload.type !== null ? `&type=${payload.type}` : '';
            url += payload.paymentStatus !== null ? `&paymentStatus=${payload.paymentStatus}` : '';

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(serviceNoteActions.list({ ...res.data, data: res.data.data ? res.data.data.map((card) => new ServiceNoteViewModel(card, payload.languageId)) : [] }));
                    resolve();
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function serviceNoteDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNotes/${payload.branchId}/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    dispatch(serviceNoteActions.detail(res.data.data));
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function serviceNoteInsertRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNotes/${payload.branchId}?type=${payload.type}`;
            const serviceNoteData = Object.assign({}, payload);
            serviceNoteData.workTime = payload.workTime;
            serviceNoteData.note = (typeof payload.note === 'object') ? JSON.stringify(payload.note) : payload.note;
            serviceNoteData.noteAdmin = (typeof payload.noteAdmin === 'object') ? JSON.stringify(payload.noteAdmin) : payload.noteAdmin;
            serviceNoteData.address = (typeof payload.address === 'object') ? JSON.stringify(payload.address) : payload.address;
            serviceNoteData.noteAddress = (typeof payload.noteAddress === 'object') ? JSON.stringify(payload.noteAddress) : payload.noteAddress;

            Http.post(url, serviceNoteData)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(serviceNoteActions.insert(new ServiceNoteViewModel({ ...payload, id: res.data.data })));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    if (error.response.data.code === -99) {
                        reject(error.response.data.code);
                    }
                    reject(error.response.data.message);
                });
        });
}

export function serviceNoteUpdateRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNotes/${payload.branchId}/${payload.id}`;
            const serviceNoteData = Object.assign({}, payload);
            serviceNoteData.workTime = payload.workTime;
            serviceNoteData.serviceNoteDetailId = serviceNoteData.serviceNoteDetails.length === 1 ? serviceNoteData.serviceNoteDetails[0].id : null;
            serviceNoteData.note = (typeof payload.note === 'object') ? JSON.stringify(payload.note) : payload.note;
            serviceNoteData.noteAdmin = (typeof payload.noteAdmin === 'object') ? JSON.stringify(payload.noteAdmin) : payload.noteAdmin;
            serviceNoteData.address = (typeof payload.address === 'object') ? JSON.stringify(payload.address) : payload.address;
            serviceNoteData.noteAddress = (typeof payload.noteAddress === 'object') ? JSON.stringify(payload.noteAddress) : payload.noteAddress;
            serviceNoteData.discountPrice = payload.discountPrice !== null ? payload.discountPrice : 0;

            serviceNoteData.serviceNoteDetailExtras = payload.serviceNoteDetailExtras.map(item => {
                return item.serviceId;
            });
            if (serviceNoteData.serviceNoteDetails) {
                serviceNoteData.serviceNoteDetails.forEach(item => {
                    item.serviceNoteDetailExtras = payload.serviceNoteDetailExtras.map(item => {
                        return item.serviceId;
                    });
                });
            }

            Http.put(url, serviceNoteData)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    // dispatch(serviceNoteActions.insert(new ServiceNoteViewModel({ ...payload, id: res.data.data })));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    if (error.response.data.code === -99) {
                        reject(error.response.data.code);
                    }
                    reject(error.response.data.message);
                });
        });
}

export function serviceNoteUpdateTimesRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNoteDetails/${payload.branchId}/${payload.serviceNoteId}`;
            const serviceNoteData = Object.assign({}, payload);
            serviceNoteData.startTime = (typeof payload.startTime !== 'string') ? moment(payload.startTime).format('YYYY-MM-DD[T]HH:mm:ss') : payload.startTime;
            serviceNoteData.startTime = (typeof payload.startTime !== 'string') ? moment(payload.startTime).format('YYYY-MM-DD[T]HH:mm:ss') : payload.startTime;
            Http.put(url, serviceNoteData)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    if (error.response.data.code === -99) {
                        reject(error.response.data.code);
                    }
                    reject(error.response.data.message);
                });
        });
}

export function deleteServiceNote(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}serviceNotes/${payload.branchId}/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function serviceNoteGetCodeRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}serviceNotes/code/${payload.branchId}`;

            Http.get(url)
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function serviceNotesUpdateNotesRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}ServiceNotes/update-note/${payload.branchId}/${payload.id}?note=${JSON.stringify(payload.note)}`;
            Http.put(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function serviceNoteUpdateStatusRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}ServiceNotes/status/${payload.branchId}/${payload.id}/${payload.status}`;

            Http.put(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        });
}

export function serviceNoteInsertStaff(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}ServiceNoteDetailStaffs/by-service-note/${payload.branchId}/${payload.serviceNoteId}/${payload.userId}`;

            Http.post(url)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        });
}

export function serviceNoteCheckInOut(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const checkData = Object.assign({}, payload);
            checkData.checkTime = (typeof payload.checkTime !== 'string' && payload.checkTime !== null) ? moment(payload.checkTime).format('YYYY-MM-DD[T]HH:mm:ss') : '';
            const url = `${NAILSPA_URL}ServiceNoteDetailStaffs/check-in-out/${checkData.branchId}/${checkData.serviceNoteDetailStaffId}/${checkData.isBefore}?checkTime=${checkData.checkTime}`;

            Http.put(url)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        });
}

export function saveEditingWorkDay(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            if (payload.joinedDate) payload.joinedDate = moment(payload.joinedDate).format('YYYY-MM-DD[T]HH:mm:ss');
            if (payload.startTime) payload.startTime = moment(payload.startTime).format('YYYY-MM-DD[T]HH:mm:ss');
            if (payload.endTime) payload.endTime = moment(payload.endTime).format('YYYY-MM-DD[T]HH:mm:ss');
            dispatch(serviceNoteActions.saveEditingWorkDay(payload));
            resolve();
        });
}

// export function getEditingWorkDay(payload) {
//     return (dispatch) =>
//         new Promise((resolve, reject) => {
//             dispatch(serviceNoteActions.getEditingWorkDay());
//             resolve();
//         });
// }
