import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/notify',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/notify/NotificationsContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/notify-timer',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/notify/NotificationTimerContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/notify-timer/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/notify/NotificationTimerDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/notify-timer/:notifyId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/notify/NotificationTimerDetailContainer'),
            loading: LoadingComponent
        })
    }

];
