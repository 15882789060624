import { TextBox } from 'devextreme-react';
import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import en from 'ree-validate/dist/locale/en';
import vi from 'ree-validate/dist/locale/vi';
import i18n from '../../i18n';
import PropTypes from 'prop-types';

class EmailBox extends Component {
    constructor(props) {
        super(props);

        this.validator = new ReeValidate({
            email: this.props.isRequired ? 'required|min:5|max:150|email' : '|min:5|max:150|email'
        });

        if (this.props.currentLanguage === 'vi-VN') {
            this.validator.localize('vi', vi);
        } else {
            this.validator.localize('en', en);
        }

        this.valueChanged = this.valueChanged.bind(this);
    }

    valueChanged = (e) => {
        if (e.event !== undefined && e.event !== null) {
            if (e.event.type !== 'dxclick') {
                const { errors } = this.validator;

                errors.remove(e.event.target.name);

                this.props.updateData({
                    [this.props.field]: e.event.target.value
                });

                this.validator.validate(e.event.target.name, e.event.target.value)
                    .then(() => {
                        this.checkValidate();
                    })
                    .catch(() => {
                    });
            }
        }
    }

    checkValidate() {
        const { field } = this.props;

        const formValidate = !this.validator.errors.has('email');
        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.validator.validate('email', nextProps.value).then(() => {
                this.checkValidate();
            });
        }
    }

    render() {
        const { value, i18nLabel, isRequired, readOnly } = this.props;

        return (
            <div className={'' + (this.validator.errors.has('email') ? ' has-error' : '')}>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'} >
                        {i18n.t(i18nLabel) + ':'}<span className="color-red">{isRequired && (<span style={{ color: 'red' }}> *</span>)}</span>
                    </div>
                    <div className={'dx-field-value'} >
                        <TextBox
                            name={'email'}
                            value={value}
                            onValueChanged={this.valueChanged}
                            placeholder="Email"
                            readOnly={readOnly || false}
                        />
                        {this.validator.errors.has('email') &&
                            <div
                                className="help-block">{this.validator.errors.first('email').replace('email', i18n.t(i18nLabel))}
                            </div>}
                    </div>
                </div>
            </div>
        );
    }
}

EmailBox.propTypes = {
    value: PropTypes.string,
    updateData: PropTypes.func,
    field: PropTypes.string,
    isRequired: PropTypes.bool,
    currentLanguage: PropTypes.string,
    i18nLabel: PropTypes.string,
    readOnly: PropTypes.bool
};

EmailBox.defaultProps = {
    isRequired: true
};

export default EmailBox;
