import * as ActionType from './action-types'
import * as _ from 'lodash';

const initialState = {
    files: [],
    folders: [],
    tree: [],
    totalFiles: null,
    totalFolders: null,
    breadcrumbs: []
}

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ActionType.FOLDER_LIST:
            return list(state, payload)
        case ActionType.FOLDER_INSERT:
            return folderInsert(state, payload)
        case ActionType.FOLDER_DELETE:
            return folderDelete(state, payload)
        case ActionType.FOLDER_EDIT:
            return folderEdit(state, payload)
        case ActionType.FOLDER_TREE:
            return folderTree(state, payload)
        case ActionType.FOLDER_SEARCH:
            return folderSearch(state, payload)
        case ActionType.FOLDER_RESET:
            return reset(state)

        case ActionType.FILE_UPLOAD:
            return fileUpload(state, payload)
        case ActionType.FILE_EDIT:
            return fileEdit(state, payload)
        case ActionType.FILE_DELETE:
            return fileDelete(state, payload)

        default:
            return state
    }
}

function reset(state, payload) {
    return initialState;
}

function list(state, payload) {
    const folders = _.unionBy(payload.folders, state.folders, 'id')
    const files = _.unionBy(payload.files, state.files, 'id')

    state = Object.assign({}, state, { folders }, { files })
    return state
}

function folderSearch(state, payload) {
    return Object.assign({}, payload)
}

function folderInsert(state, payload) {
    const folders = [...state.folders, payload]
    state = Object.assign({}, state, { folders })
    return state
}

function folderDelete(state, payload) {
    const folders = state.folders.filter(obj => obj.id !== payload.id)

    return Object.assign({}, state, { folders })
}

function folderEdit(state, payload) {
    const updateFolderIndex = state.folders.findIndex(obj => obj.id === payload.id)

    state.folders[updateFolderIndex].name = payload.name;
    state.folders[updateFolderIndex].concurrencyStamp = payload.concurrencyStamp;
    state.folders[updateFolderIndex].parentId = payload.parentId;

    return Object.assign({}, state)
}

function folderTree(state, payload) {
    state = Object.assign({}, state, {
        tree: payload
    })
    return state
}

function fileUpload(state, payload) {
    const files = [...state.files, ...payload]

    return Object.assign({}, state, { files })
}

function fileDelete(state, payload) {
    const files = state.files.filter(obj => obj.id !== payload.id)

    return Object.assign({}, state, { files })
}

function fileEdit(state, payload) {
    const updateFileIndex = state.files.findIndex(obj => obj.id === payload.id)

    state.files[updateFileIndex].name = payload.name;
    state.files[updateFileIndex].concurrencyStamp = payload.concurrencyStamp;
    state.files[updateFileIndex].folderId = payload.folderId;

    return Object.assign({}, state)
}

export default reducer