export const enA = {
    account: {
        form: {
            account: 'Account Name',
            newPassword: 'New Password',
            resetTitle: 'Reset Password'
        }
    },
    area: {
        list: { title: 'List of Areas', unit: 'area' },
        form: {
            name: 'Area name',
            select: 'Select Area',
            active: 'Active'
        }
    },
    absence: {
        list: {
            code: 'Order code',
            fullName: 'Customer name',
            joinedDate: 'Date',
            startTime: 'Start time',
            endTime: 'End time',
            userCode: 'Agent code',
            userFullName: 'Agent name',
            reason: 'Reason',
            approvedStatus: 'Approve status',
            isCancel: 'Canceled',
            isCancelAwait: 'Cancel pending',
            address: 'Address',
            servicePackageName: 'Service package'
        }
    }
};
