import React from 'react';
import FormPopup from './FormPopup';
import TabFormData from './TabFormData';

class FormData extends TabFormData {
    onShown = () => {
        if (!this.props.getDetail) return;
        this.initHandler();
    };

    render() {
        const { id, hideForm, otherProps } = this.props;

        return (
            <FormPopup
                otherProps={{
                    maxWidth: '600px',
                    maxHeight: '700px',
                    ...otherProps
                }}
                i18nLabel={
                    id ? this.initTxt.updateTitle : this.initTxt.insertTitle
                }
                hideForm={hideForm}
                onShown={this.onShown}
            >
                {super.render()}
            </FormPopup>
        );
    }
}

export default FormData;
