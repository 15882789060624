import Loadable from 'react-loadable';
import LoadingComponent from '../../../common/loader/index';

export default [
    {
        path: '/',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/DashboardContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/home',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/DashboardContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/system/info',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/SystemInfoContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/area',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./area'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/country',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/category/CountryContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/province',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/category/ProvinceContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/district',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../pages/category/DistrictContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/nation',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./category/nation'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/religion',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./category/religion'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/branch',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../modules/category/branch/list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/group',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../modules/category/group/list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/resource',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./category/resource'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/method-of-payment',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./category/methodOfPayment'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/job',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./category/job'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/brand',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../modules/category/brand/list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/category/supplier',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../../modules/category/supplier/list/index'),
            loading: LoadingComponent
        })
    }
];
