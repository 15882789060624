import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import Routes from './routes';
import { authCheck } from './modules/auth/store/actions';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import './common/assets/styles/app.scss';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import './common/assets/styles/theme/menu.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCogs, faCog } from '@fortawesome/free-solid-svg-icons';
import { checkWebsite } from './modules/website/website/store/actions';
import { checkAppSetting } from './modules/core/appSetting/store/actions';

library.add(faCogs, faCog);

store.dispatch(authCheck());
store.dispatch(checkAppSetting());
store.dispatch(checkWebsite());

if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then(function (registration) {
            console.log(
                'Registration successful, scope is:',
                registration.scope
            );
        })
        .catch(function (err) {
            console.log('Service worker registration failed, error:', err);
        });
}

const renderApp = (Component) => {
    ReactDOM.render(
        <Provider store={store}>
            <Component />
        </Provider>,
        document.getElementById('root')
    );
};

const importTheme = async () => {
    let themeId = localStorage.getItem('_theme');
    if (!themeId) {
        themeId = 'dx.dark.css';
        localStorage.setItem('_theme', themeId);
    }

    await import(`devextreme/dist/css/${themeId}`);
    renderApp(Routes);
};

importTheme();

if (module.hot) {
    module.hot.accept('./routes', () => {
        const Routes = require('./routes').default;
        renderApp(Routes);
    });
}

serviceWorker.unregister();
