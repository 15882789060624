import { PAGE_SIZE } from '../../../../constants/Config';
import {
    JOB_LIST,
    JOB_DETAIL,
    JOB_GETALL
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    item: null,
    suggestion: [],
    currentPage: 1,
    pageSize: PAGE_SIZE,
    categories: [],
    edit: false
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case JOB_LIST:
            return list(state, payload);
        case JOB_DETAIL:
            return detail(state, payload);
        case JOB_GETALL:
            return getAll(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

const detail = (state, payload) => {
    state = { ...state, item: payload };

    return state;
};

const getAll = (state, payload) => {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
};

export default reducer;
