import { connect } from "react-redux"
import {
    nationGetAll
} from '../service'
import BaseSelectBox from '../../../../base/components/BaseSelectBox'

class NationSelectBox extends BaseSelectBox {
    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            payload: {
                countryId: this.props.countryId
            },
        }
    }

    componentDidMount() {
        if (this.props.countryId) {
            this.getAllData()
        } else {
            this.setState({
                datas: {},
                renderDone: true
            })
        }
    }

    getAllData() {
        const { payload } = this.state
        const { listData } = this.props

        if (this.props.countryId === null) {
            this.setState({
                datas: {}
            })
        } else {
            if (listData && listData.length > 0 && listData[0].countryId === this.props.countryId) {
                this.handleLanguage()
            } else {
                this.props.getAllData(payload)
                    .then(() => {
                        this.handleLanguage()
                    })
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.countryId !== this.state.payload.countryId) {
            let payload = { ...this.state.payload, countryId: nextProps.countryId }

            this.setState({
                payload
            }, () => {
                this.getAllData()
            })

        }
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.appSettings.data.languages,
        listData: state.nation.suggestion,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllData: (payload) => {
            return dispatch(nationGetAll(payload))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NationSelectBox)