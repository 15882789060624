import { CORE_URL } from "../../../constants/Config"
import * as themeActions from './store/action'
import { updateUserSettings } from '../appSetting/store/actions'
import { spinnerLoading } from "../../../common/spinner/store/action"
import Http from "../../../utils/Http"

export function getThemesList() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const url = `${CORE_URL}themes`

            Http.get(url)
                .then(res => {
                    dispatch(themeActions.list(res))
                    resolve()
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
}

export function changeTheme(themeid) {
    return dispatch => {
        return new Promise((resolve, reject) => {

            dispatch(spinnerLoading(true))
            const url = `${CORE_URL}usersettings/change-theme/${themeid}`

            Http.get(url).then(res => {
                dispatch(spinnerLoading(false))
                dispatch(updateUserSettings({
                    "key": "ThemeId",
                    "value": themeid
                }))
                resolve()
            }).catch(() => {
            })
        })
    }
}