import { NAILSPA_URL } from "../../../constants/Config";
import Http from "../../../utils/Http";
import * as religionActions from "./store/actions";
import { spinnerLoading } from "../../../common/spinner/store/action";

export function religionSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}religions?keyword=${payload.keyword}`;
            url +=
                payload.isActive !== null
                    ? `&isActive=${payload.isActive}`
                    : "";
            url += payload.page ? `&page=${payload.page}` : "";
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : "";
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(religionActions.list(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function religionSearchValidNameRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            let url = `${NAILSPA_URL}religions?keyword=${payload.keyword}`;
            url +=
                payload.countryId !== null
                    ? `&countryId=${payload.countryId}`
                    : "";

            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function religionInsertRequest(religion) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}religions`;
            let religionUpdate = religion;
            religionUpdate.name = JSON.stringify(religion.name);
            religionUpdate.description = JSON.stringify(religion.description);

            Http.post(url, religionUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    religion.id = res.data.data;
                    dispatch(religionActions.insert(religion));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function religionUpdateRequest(religion) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}religions/${religion.id}`;
            let religionUpdate = religion;
            religionUpdate.name = JSON.stringify(religion.name);
            religionUpdate.description = JSON.stringify(religion.description);

            Http.put(url, religionUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    religion.concurrencyStamp = res.data.data;
                    dispatch(religionActions.update(religion));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function religionDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            //dispatch(spinnerLoading(true))
            const url = `${NAILSPA_URL}religions/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    //dispatch(spinnerLoading(false))
                    dispatch(religionActions.detail(res.data.data));
                    resolve();
                })
                .catch(() => {
                    //dispatch(spinnerLoading(false))
                    reject();
                });
        });
}

export function religionDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}religions/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(religionActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function religionGetAll(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}religions/get-all/${payload.countryId}`;

            Http.get(url)
                .then((res) => {
                    dispatch(religionActions.listByCountry(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
