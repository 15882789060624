import {
    OTHER_INCOME_DETAIL,
    OTHER_INCOME_GETALL,
    OTHER_INCOME_LIST,
    OTHER_INCOME_DELETE
} from './action-types';
import { PAGE_SIZE } from '../../../constants/Config';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {},
    suggestion: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case OTHER_INCOME_LIST:
            return list(state, payload);
        case OTHER_INCOME_DETAIL:
            return detail(state, payload);
        case OTHER_INCOME_GETALL:
            return getAll(state, payload);
        case OTHER_INCOME_DELETE:
            return deleteItem(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    console.log('state', { ...state, ...payload });
    return { ...state, ...payload };
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function getAll(state, payload) {
    state = Object.assign({}, state, { suggestion: payload });
    return state;
}
function deleteItem(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload);

    return Object.assign({}, state, { data });
}
export default reducer;
