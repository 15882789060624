import { NAILSPA_URL } from '../../../constants/Config';
import Http from '../../../utils/Http';
import * as countryActions from './store/actions';
import { spinnerLoading } from '../../../common/spinner/store/action';
import CountryViewModel from './CountryViewModel';

export function countrySearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}countrys?keyword=${payload.keyword}`;
            url += payload.isActive !== null ? `&isActive=${payload.isActive}` : '';
            url += payload.page ? `&page=${payload.page}` : '';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '';

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(countryActions.list({ ...res.data, data: res.data.data.map((country) => new CountryViewModel(country, payload.languageId)) }));
                    resolve();
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function countriesSearchNameValid(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}countrys?keyword=${payload.keyword}`;

            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function countryInsertRequest(country) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}countrys`;
            const countryUpdate = country;
            countryUpdate.name = JSON.stringify(country.name);
            countryUpdate.description = JSON.stringify(country.description);
            countryUpdate.unsignName = JSON.stringify(country.unsignName);

            Http.post(url, countryUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    country.id = res.data.data;
                    dispatch(countryActions.insert(country));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function countryUpdateRequest(country) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}countrys/${country.id}`;
            const countryUpdate = country;
            countryUpdate.name = JSON.stringify(country.name);
            countryUpdate.description = JSON.stringify(country.description);

            Http.put(url, countryUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    country.concurrencyStamp = res.data.data;
                    dispatch(countryActions.update(country));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function countryDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            // dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}countrys/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    // dispatch(spinnerLoading(false));
                    dispatch(countryActions.detail(res.data.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function countryDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}countrys/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(countryActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
    };
}

export function countryGetAll() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}countrys/get-all`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(countryActions.suggestion(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
