export const enN = {
    nation: {
        list: { title: 'List of Nations' }
    },
    notify: {
        title: 'Title',
        content: 'Content',
        isRead: 'isRead',
        createTime: 'Created Time',
        senderFullName: 'Sender',
        titleList: 'List of Notifications',
        unit: 'notify',
        insertTitle: 'Add New Notify',
        infoTitle: 'Notify Information',
        type: 'Type',
        subContent: 'Sub-Content',
        message: 'New Nofiy!',
        sendTime: 'Set send time',
        endTime: 'Set end time',
        isTimer: 'Set Timer',
        timerTime: 'Send time'
    }
};
