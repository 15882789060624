export const UNIT_LIST = 'UNIT_LIST'
export const UNIT_DETAIL = 'UNIT_DETAIL'
export const UNIT_UPDATE = 'UNIT_UPDATE'
export const UNIT_INSERT = 'UNIT_INSERT'
export const UNIT_DELETE = 'UNIT_DELETE'
export const UNIT_RESPONSE = 'UNIT_RESPONSE'
export const UNIT_GETALL = 'UNIT_GETALL'
export const UNIT_BYPRODUCT_GETALL = 'UNIT_BYPRODUCT_GETALL'
export default {
    UNIT_DELETE,
    UNIT_DETAIL,
    UNIT_INSERT,
    UNIT_LIST,
    UNIT_UPDATE,
    UNIT_RESPONSE,
    UNIT_GETALL,
    UNIT_BYPRODUCT_GETALL,
}
