import { PAGE_SIZE } from '../../../constants/Config';
import {
    PRODUCT_DELETE,
    PRODUCT_DETAIL,
    PRODUCT_INSERT,
    PRODUCT_LIST,
    PRODUCT_UPDATE,
    PRODUCT_GETALL,
    PRODUCT_SELECT,
    PRODUCT_GETCODE
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    items: [],
    item: {
        code: null
    },
    selectedProductId: null,
    suggestion: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PRODUCT_GETCODE:
            return getCode(state, payload);
        case PRODUCT_SELECT:
            return select(state, payload);
        case PRODUCT_LIST:
            return list(state, payload);
        case PRODUCT_DETAIL:
            return detail(state, payload);
        case PRODUCT_UPDATE:
            return update(state, payload);
        case PRODUCT_INSERT:
            return insert(state, payload);
        case PRODUCT_DELETE:
            return deletes(state, payload);
        case PRODUCT_GETALL:
            return getAll(state, payload);
        default:
            return state;
    }
};

function getCode(state, payload) {
    state = Object.assign({}, state, { item: { ...state.item, code: payload } });
    return state;
}

function select(state, payload) {
    state = Object.assign({}, state, { selectedProductId: payload });
    return state;
}

function list(state, payload) {
    return { ...state, ...payload };
}

function getAll(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

export default reducer;
