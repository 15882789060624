import BaseViewModel from '../../base/BaseViewModel';
import * as _ from 'lodash';
import Transformer from '../../utils/Transformer';
import { FORMAT_TIME_BYLANG, TYPE_SERVICE, SERVICE_NOTE_STATUS } from '../../constants/Config';
const moment = require('moment');

export default class ServiceNoteDetailViewModel extends BaseViewModel {
    initialize(props) {
        super.initialize(props);
        if (!props.totalAmount) {
            this.totalAmount = props.discountByPercent ? (props.price * (100 - props.discountPrice) / 100) : (props.price - props.discountPrice);
        } else {
            this.totalAmount = props.totalAmount;
        }

        this.discountPriceCal = this.price - this.totalAmount;
        this.serviceType = props.type
            ? JSON.parse(TYPE_SERVICE[props.type].name)
            : '';
        this.name = props.name ? Transformer.convertJSONString(props.name) : null;
        if (!props.comment) this.comment = {};
        if (props.serviceNoteDetails.length > 0) {
            this.startTime = props.serviceNoteDetails[0].startTime;
            this.endTime = props.serviceNoteDetails[0].endTime;
            this.serviceNoteDetailExtras = props.serviceNoteDetails[0].serviceNoteDetailExtras;
        }
    }

    initializeWithLanguage(props, languageId) {
        super.initializeWithLanguage(props, languageId);

        this.serviceNoteDetailStaffsName = _.map(
            props.serviceNoteDetailStaffs,
            (x) => x.fullName
        ).join(', ');
        if (!props.totalAmount) {
            this.totalAmount = props.discountByPercent ? (props.price * (100 - props.discountPrice) / 100) : (props.price - props.discountPrice);
        } else {
            this.totalAmount = props.totalAmount;
        }

        this.totalAmountTxt = Transformer.convertCurrency(props.totalAmount);
        this.priceTxt = Transformer.convertCurrency(props.price);

        this.discountPriceCal = Transformer.convertCurrency(props.price - this.totalAmount);
        this.joinedDate = moment(props.joinedDate).isValid()
            ? moment(props.joinedDate).format(FORMAT_TIME_BYLANG[languageId])
            : '';
        this.createTime = moment(props.createTime).isValid()
            ? moment(props.createTime).format(FORMAT_TIME_BYLANG[languageId])
            : '';

        this.name = props.name ? Transformer.convertJSONString(props.name, languageId) : null;
        if (props.name) {
            this.serviceName = Transformer.IsJsonString(props.name) ? Transformer.convertJSONString(props.name, languageId) : props.name;
        }

        if (props.serviceName) {
            this.serviceName = Transformer.IsJsonString(props.serviceName) ? Transformer.convertJSONString(props.serviceName, languageId) : props.serviceName;
        }
        this.discountPriceConvert = props.discountByPercent ? (props.totalPrice * (props.discountPrice / 100))
            : props.discountPrice;

        if (props.comment) {
            this.comment = Transformer.IsJsonString(props.comment) ? Transformer.convertJSONString(props.comment, languageId) : {};
        }

        this.formatStartTime = moment(props.startTime).format('DD/MM/YYYY HH:mm');
        this.formatEndTime = moment(props.endTime).format('DD/MM/YYYY HH:mm');

        // console.log('props', props);
        // if (props.serviceNoteDetails.length > 0) {
        //     this.startTime = props.serviceNoteDetails[0].startTime;
        //     this.endTime = props.serviceNoteDetails[0].endTime;
        //     this.serviceNoteDetailExtras = props.serviceNoteDetails[0].serviceNoteDetailExtras;
        //     this.joinedDate = props.joinedDate;
        // }
        if (_.isNumber(props.status)) {
            this.statusName = JSON.parse(SERVICE_NOTE_STATUS[props.status].name)[languageId];
        }

        this.agents = _.map(props.serviceNoteDetailStaffs, x => x.fullName).join(', ');
    }
}
