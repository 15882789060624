
import { spinnerLoading } from "../../../common/spinner/store/action"
import Http from '../../../utils/Http'
import { NAILSPA_URL, CORE_URL } from "../../../constants/Config"
import * as userInfoActions from './store/actions'
import {
    updateUserSettings
} from '../../core/appSetting/store/actions'

export function userInfoDetailRequest(payload) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true))
            const url = `${NAILSPA_URL}Users/${payload.id}`

            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false));
                dispatch(userInfoActions.detail(res.data.data));
                resolve()
            }).catch((error) => {
                dispatch(spinnerLoading(false))
                reject(error);
            })
        })
    )
}

export function userInfoUpdateRequest(userInfo) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true))
            const url = `${NAILSPA_URL}Users/${userInfo.id}`
            let userInfoUpdate = userInfo
            userInfoUpdate.officeName = JSON.stringify(userInfo.officeName)
            userInfoUpdate.titleName = JSON.stringify(userInfo.titleName)
            userInfoUpdate.positionName = JSON.stringify(userInfo.positionName)

            Http.put(url, userInfoUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false))
                    resolve(res.data.message)
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false))
                    reject(error.response.data.message)
                })
        })
    )
}

export function userSettingsGetRequest() {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true))
            const url = `${CORE_URL}UserSettings`

            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false));
                dispatch(userInfoActions.setting(res.data));
                resolve()
            }).catch((error) => {
                dispatch(spinnerLoading(false))
                reject(error);
            })
        })
    )
}

export function userSettingsChangePageSizeRequest(pageSize) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true))
            const url = `${CORE_URL}UserSettings/change-pagesize/${pageSize}`

            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false))
                dispatch(updateUserSettings({
                    "key": "PageSize",
                    "value": pageSize
                }))
                resolve()
            }).catch((error) => {
                dispatch(spinnerLoading(false))
                reject(error);
            })
        })
    )
}

export function userSettingsChangeLanguageRequest(languageId) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true))
            const url = `${CORE_URL}UserSettings/change-language/${languageId}`

            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false))
                dispatch(updateUserSettings({
                    "key": "LanguageId",
                    "value": languageId
                }))
                resolve()
            }).catch((error) => {
                dispatch(spinnerLoading(false))
                reject(error);
            })
        })
    )
}

export function changeWarehouse(warehouseId) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true))
            const url = `${CORE_URL}UserSettings/change-warehouse/${warehouseId}`

            Http.get(url).then(res => {
                dispatch(spinnerLoading(false))
                dispatch(updateUserSettings({
                    "key": "WarehouseId",
                    "value": warehouseId
                }))
                resolve()
            }).catch(() => {
            })
        })
    }
}