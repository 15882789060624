export const WEBSITE_LIST = 'WEBSITE_LIST';
export const WEBSITE_DETAIL = 'WEBSITE_DETAIL';
export const WEBSITE_UPDATE = 'WEBSITE_UPDATE';
export const WEBSITE_UPDATE_ACTIVE = 'WEBSITE_UPDATE_ACTIVE';
export const WEBSITE_INSERT = 'WEBSITE_INSERT';
export const WEBSITE_DELETE = 'WEBSITE_DELETE';
export const WEBSITE_RESPONSE = 'WEBSITE_RESPONSE';
export const WEBSITE_GETALL = 'WEBSITE_GETALL';
export const WEBSITE_GETALL_BYUSER = 'WEBSITE_GETALL_BYUSER';
export const WEBSITE_INSERT_USER = 'WEBSITE_INSERT_USER';
export const WEBSITE_DELETE_USER = 'WEBSITE_DELETE_USER';
export const WEBSITE_GET_USERS = 'WEBSITE_GET_USERS';
export const WEBSITE_SELECT = 'WEBSITE_SELECT';
export const WEBSITE_GET_LANGUAGES = 'WEBSITE_GET_LANGUAGES';
export const WEBSITE_INSERT_LANGUAGES = 'WEBSITE_INSERT_LANGUAGES';
export const WEBSITE_UPDATE_LANGUAGES = 'WEBSITE_UPDATE_LANGUAGES';
export const WEBSITE_DELETE_LANGUAGES = 'WEBSITE_DELETE_LANGUAGES';
export const WEBSITE_SETTING_CHECK = 'WEBSITE_SETTING_CHECK';

export default {
    WEBSITE_DELETE,
    WEBSITE_DETAIL,
    WEBSITE_INSERT,
    WEBSITE_LIST,
    WEBSITE_UPDATE,
    WEBSITE_GETALL,
    WEBSITE_GETALL_BYUSER,
    WEBSITE_SELECT,
    WEBSITE_GET_LANGUAGES,
    WEBSITE_INSERT_LANGUAGES,
    WEBSITE_UPDATE_LANGUAGES,
    WEBSITE_DELETE_LANGUAGES,
    WEBSITE_GET_USERS,
    WEBSITE_INSERT_USER,
    WEBSITE_DELETE_USER,
    WEBSITE_UPDATE_ACTIVE,
    WEBSITE_SETTING_CHECK
};
