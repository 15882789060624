export const AUTH_CHECK = 'AUTH_CHECK'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN'
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD'
export const AUTH_USER = 'AUTH_USER'
export const CHECK_USER_NAME = 'CHECK_USER_NAME'
export const SENT_MAIL_CODE = 'SENT_MAIL_CODE'
export const CONFIRM_CODE = 'CONFIRM_CODE'
export const AUTH_INIT_NEW_APP = 'AUTH_INIT_NEW_APP'

export default {
    AUTH_CHECK,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_REFRESH_TOKEN,
    AUTH_RESET_PASSWORD,
    AUTH_USER,
    CHECK_USER_NAME,
    SENT_MAIL_CODE,
    CONFIRM_CODE,
    AUTH_INIT_NEW_APP
}
