export const viC = {
    // C
    country: {
        list: { title: 'Danh sách quốc gia' },
        name: 'Tên quốc gia',
        description: 'Mô tả',
        order: 'Thứ tự',
        isActive: 'Kích hoạt',
        insertTitle: 'Thêm mới Quốc gia',
        updateTitle: 'Cập nhật Quốc gia'
    },
    customer: {
        list: {
            title: 'Danh sách Khách Hàng',
            appointment: 'Đặt lịch hẹn',
            delete: 'Xoá',
            placeOrder: 'Tạo đơn hàng',
            serviceHistory: 'Lịch sử sử dụng dịch vụ',
            canceledList: 'Danh sách đơn hủy'
        },
        form: {
            insertTitle: 'Thêm mới Khách Hàng',
            updateTitle: 'Chỉnh sửa Khách Hàng',
            registerServiceProduct: 'Đăng ký Dịch Vụ/Sản Phẩm',
            addPoint: 'Lịch sử tích điểm',
            registerAppointment: 'Đăng kí đặt lịch hẹn'
        },
        registerServiceProduct: {
            userSale: 'Nhân Viên bán',
            userAdvisory: 'Nhân viên tư vấn'
        },
        booking: {
            startTime: 'Thời gian bắt đầu',
            endTime: 'Thời gian kết thúc'
        },
        pregnant: 'Tiểu sử mang thai',
        error: {
            genderRequired: 'Giới tính không được để trống.'
        }
    },
    customerServiceCard: {
        title: 'Danh sách thẻ dịch vụ khách hàng',
        price: 'Mệnh giá',
        promotion: 'Khuyến mại',
        notice:
            '* Mỗi khách hàng sẽ được cấp một thẻ trả trước, thẻ này dùng để nạp tiền và thanh toán cho tất cả các dịch vụ tại spa (Kể cả việc mua thêm các thẻ dịch vụ khác).',
        formtabs: {
            cardInfo: 'Thông tin thẻ',
            serviceCard: 'Thẻ dịch vụ',
            billHistory: 'Lịch sử thanh toán',
            rechargeHistory: 'Lịch sử nạp tiền'
        },
        noteCard: 'Ghi chú thẻ',
        form: {
            gender: 'Giới tính',
            fullname: 'Họ và tên',
            phone: 'Số điện thoại',
            dob: 'Ngày sinh',
            branch: 'Chi nhánh'
        },
        list: {
            info: 'Thông tin thẻ',
            buyServiceCard: 'Mua thẻ dịch vụ',
            payCustomerCard: 'Nạp tiền thẻ trả trước',
            payServiceCard: 'Nạp tiền thẻ dịch vụ',
            changeStatus: 'Đổi trạng thái',
            withdrawPrepaid: 'Rút tiền thẻ về trả trước',
            usageHistory: 'Lịch sử sử dụng',
            paymentHistory: 'Lịch sử thanh toán',
            buyPreCard: 'Mua thẻ trả trước',
            customerCard: 'Thẻ trả trước',
            editCustomerCard: 'Chỉnh sửa thẻ khách hàng',
            transferBalance: 'Chuyển tiền thẻ trả trước'
        },
        totalBalanceUsed: 'Tổng số tiền đã sử dụng',
        totalBalance: 'Số tiền còn lại',
        totalAmount: 'Tổng tiền',
        totalPaid: 'Số tiền đã thanh toán',
        totalUnPaid: 'Số tiền chưa thanh toán',
        totalUse: 'Số tiền đã sử dụng',
        approveExpiryDate: 'Phê duyệt ngày hết hạn'
    },
    calendar: {
        home: {
            shift: 'Ca làm việc',
            workingday: 'Ngày làm việc',
            calendar: 'Lịch làm việc',
            homepage: 'Trang chủ',
            titlePopupCopy: 'Sao chép lịch làm việc',
            timeCopy: 'Thời gian sao chép',
            startCopydate: 'Ngày bắt đầu sao chép',
            destinationCopy: 'Đích sao chép',
            titlePopupAdd: 'Thêm mới lịch làm việc',
            titlePopupEdit: 'Chỉnh sửa lịch làm việc',
            titlePopupList: 'Danh sách nhân viên làm việc',
            deleteTitle: 'Bạn có chắc chắn xóa lịch làm việc này?',
            shiftRequired: 'Hãy chọn ca làm việc'
        },
        menu: {
            delete: 'Hủy lịch làm việc',
            edit: 'Chỉnh sửa',
            note: 'Ghi chú'
        }
    },
    category: {
        branch: {
            title: 'Danh sách chi nhánh',
            choose: 'Chọn chi nhánh'
        },
        supplier: {
            title: 'Danh sách Nhà Cung Cấp',
            unit: 'nhà cung cấp',
            name: 'Nhà cung cấp'
        },
        country: { title: 'Danh sách quốc gia' },
        province: { title: 'Danh sách tỉnh/thành' },
        district: { title: 'Danh sách quận/huyện' },
        nation: { title: 'Danh sách dân tộc' },
        religion: { title: 'Danh sách tôn giáo' },
        position: { title: 'Danh sách chức vụ' },
        title: { title: 'Danh sách chức danh' },
        office: { title: 'Danh sách phòng ban' },
        groupservice: { title: 'Danh sách nhóm dịch vụ' },
        productgroup: { title: 'Danh sách nhóm sản phẩm' },
        service: { title: 'Danh sách dịch vụ' },
        unit: { title: 'Danh sách đơn vị' },
        warehouse: { title: 'Danh sách kho' },
        resource: { title: 'Danh sách nguồn khách' },
        job: { title: 'Danh sách nghề nghiệp' },
        occupation: { title: 'Danh sách nghề nghiệp' },
        methodOfPayment: {
            title: 'Danh sách phương thức thanh toán'
        }
    },
    customerCare: {
        customerFeedback: {
            insertCustomerCare: 'Thêm mới thông tin chăm sóc khách hàng',
            updateCustomerCare: 'Cập nhật thông tin chăm sóc khách hàng',
            newPromotionInfo: 'Thông tin khuyến mại',
            reviewsFeedback: 'Phản hồi của khách hàng',
            consultationInfo: 'Thông tin tư vấn',
            exchangeTime: 'Thời gian trao đổi',
            salesConsultant: 'Tư vấn bán hàng',
            careNote: 'Ghi chú chăm sóc',
            workmanship: 'Tay nghề',
            productReview: 'Đánh giá sản phẩm',
            attitude: 'Thái độ',
            title: 'Phản hồi khách hàng',
            requestStatus: 'Tình trạng phiếu',
            serviceNoteProfile: 'Tình trạng Service Note',
            serviceNoteDetailList: 'Danh sách dịch vụ đã làm',
            newCustomerReport: 'Báo cáo khách hàng mới',
            customerBirthdayReport: 'Báo cáo theo sinh nhật khách hàng'
        },
        customerCardStatus: {
            title: 'Danh sách thẻ dịch vụ chăm sóc khách hàng'
        }
    }
};
