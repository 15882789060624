import React, { Component } from 'react';
import { DateBox } from 'devextreme-react';
import i18n from '../../i18n';
import ReeValidate from 'ree-validate';
import PropTypes from 'prop-types';

class AppointmentTimeBox extends Component {
    constructor(props) {
        super(props);

        this.validator = new ReeValidate({
            appointmentTime: this.props.validatorValue ? this.props.validatorValue : null
        });

        this.state = {
            errors: this.validator.errors
        };

        this.validator.validate('appointmentTime', this.props.value).then(() => {
            this.checkValidate();
        });

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    checkValidate() {
        const { field } = this.props;

        const formValidate = !this.validator.errors.has('appointmentTime');
        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.validator.validate('appointmentTime', nextProps.value).then(() => {
                this.checkValidate();
            });
        }
    }

    onValueChanged(e) {
        const { errors } = this.validator;
        errors.remove('appointmentTime');
        this.props.updateData({
            [this.props.field]: e.value
        });

        this.validator.validate('appointmentTime', e.value)
            .then(() => {
                this.checkValidate();
            })
            .catch(() => {
            });
        // if (e.event !== undefined && e.event !== null) {

        // }
    }

    render() {
        const { value, i18nLabel, width, max, min, readOnly, interval, validatorValue, hiddenError, displayFormat } = this.props;
        return (
            <div className={'' + (this.validator.errors.has('appointmentTime') ? ' has-error' : '')}>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel) + ':'}
                        {!readOnly && validatorValue &&
                            validatorValue.includes('require') && (<span style={{ color: 'red' }}> *</span>)}
                    </div>

                    <div className={'dx-field-value'}>
                        <DateBox
                            type={'time'}
                            pickerType="list"
                            onValueChanged={this.onValueChanged}
                            placeholder={i18n.t(i18nLabel)}
                            interval={interval || 30}
                            showClearButton={true}
                            displayFormat={displayFormat || 'HH:mm'}
                            value={value}
                            width={width || 120}
                            min={min || null}
                            max={max || null}
                            readOnly={readOnly || false}
                        />

                        {(this.validator.errors.has('appointmentTime') && !hiddenError) && (
                            <div className="help-block">
                                {this.validator.errors.first('appointmentTime').replace('appointmentTime', i18n.t(i18nLabel))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

AppointmentTimeBox.propTypes = {
    value: PropTypes.string,
    i18nLabel: PropTypes.string,
    width: PropTypes.number,
    currentLanguage: PropTypes.string,
    updateData: PropTypes.func,
    field: PropTypes.string
};

export default AppointmentTimeBox;
