import React from 'react';
import { SelectBox } from 'devextreme-react';
import { connect } from 'react-redux';
import BaseSelectBox from '../components/BaseSelectBox';
import i18n from '../../i18n';
import * as _ from 'lodash';
import Transformer from '../../utils/Transformer';

class ShiftSelectBox extends BaseSelectBox {
    handleLanguage = () => {
        const { listData, currentLanguage } = this.props;

        const result = _.map(listData, item => {
            const copyObj = Object.assign({}, item);
            copyObj.startTime = copyObj.startTime ? copyObj.startTime.substring(0, 5) : '';
            copyObj.endTime = copyObj.endTime ? copyObj.endTime.substring(0, 5) : '';
            copyObj.name = Transformer.IsJsonString(copyObj.name) ? JSON.parse(copyObj.name)[currentLanguage] : '';
            return copyObj;
        });
        this.setState({
            datas: result
        });
    }

    onDataChanged(e) {
        if (e.value) {
            const { field } = this.props;
            this.props.updateData({
                [field]: e.value
            });
        }

        // this.props.updateData({
        //     formValidate: {
        //         [field]: !!e.value
        //     }
        // });
    }

    // eslint-disable-next-line camelcase
    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (nextProps.productId !== this.props.productId ||
    //         nextProps.warehouseId !== this.props.warehouseId ||
    //         nextProps.entryDate !== this.props.entryDate) {
    //         if (nextProps.productId && nextProps.warehouseId) {
    //             const payload = {
    //                 productId: nextProps.productId,
    //                 warehouseId: nextProps.warehouseId,
    //                 entryDate: nextProps.entryDate
    //             };

    //             this.getLotIdList(payload);
    //         }
    //     }
    // }

    getAllShifts = (branchId) => {
        if (this.props.listData.length === 0) {
            this.props.getAllShifts(branchId)
                .then(() => {
                    const result = _.map(this.props.shiftByBranch, item => {
                        const copyObj = Object.assign({}, item);
                        copyObj.startTime = copyObj.startTime ? copyObj.startTime.substring(0, 5) : '';
                        copyObj.endTime = copyObj.endTime ? copyObj.endTime.substring(0, 5) : '';
                        copyObj.name = Transformer.IsJsonString(copyObj.name) ? JSON.parse(copyObj.name) : '';
                        return copyObj;
                    });
                    this.setState({
                        datas: result
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            const result = _.map(this.props.listData, item => {
                const copyObj = Object.assign({}, item);
                copyObj.startTime = copyObj.startTime ? copyObj.startTime.substring(0, 5) : '';
                copyObj.endTime = copyObj.endTime ? copyObj.endTime.substring(0, 5) : '';
                copyObj.name = Transformer.IsJsonString(copyObj.name) ? JSON.parse(copyObj.name) : '';
                return copyObj;
            });
            this.setState({
                datas: result
            });
        }
    }

    render() {
        const { i18nLabel, disabled, isShowLabel, readOnly, validatorValue, value } = this.props;
        const { datas } = this.state;
        return (
            <div className={'dx-field'}>
                {
                    isShowLabel && (
                        <div className={'dx-field-label'}>
                            {i18n.t(i18nLabel) + ':'}
                            {validatorValue &&
                                validatorValue.includes('require') && (<span style={{ color: 'red' }}> *</span>)}
                        </div>
                    )
                }

                <div className={'dx-field-value'}>
                    <SelectBox
                        searchEnabled={true}
                        items={datas}
                        valueExpr={'id'}
                        displayExpr={'name'}
                        placeholder={i18n.t(i18nLabel)}
                        showClearButton={false}
                        onValueChanged={this.onDataChanged}
                        disabled={disabled}
                        readOnly={readOnly || false}
                        value={value || ''}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { languages } = state.appSettings.data;
    return {
        languages,
        listData: state.coreShift.byBranch
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftSelectBox);
