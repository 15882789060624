let AUTH_URL,
  CORE_URL,
  NAILSPA_URL,
  NOTIFICATION_URL,
  WEBSITE_API_URL,
  FILE_URL_API,
  FILE_URL,
  WEBSITE_URL,
  FIREBASE_URL,
  NOTIFICATION_LISTERNER_URL;

const hName = window.location.hostname;
// console.log(hName, hName.includes("crm-test.ghmsoft"))

if (hName.includes("localhost") || hName.includes("mori-crm-test.ghmsoft")) {
  AUTH_URL = "https://moriauth.ghmsoft.vn";
  CORE_URL = "https://moricore.ghmsoft.vn/api/v1/";
  NAILSPA_URL = "https://moriapi.ghmsoft.vn/api/v1/";
  NOTIFICATION_URL = "https://morinotification.ghmsoft.vn/api/v1/";
  FILE_URL_API = "https://morifile.ghmsoft.vn/api/v1/";
  FILE_URL = "https://morifile.ghmsoft.vn";
  WEBSITE_URL = "https://moriwebsite.ghmsoft.vn/api/v1/";
  FIREBASE_URL = "https://morifirebase.ghmsoft.vn/api/v1/";
  NOTIFICATION_LISTERNER_URL =
    "https://morinotification.ghmsoft.vn/Notifications";
  WEBSITE_API_URL = "https://moriwebsiteapi.ghmsoft.vn/api/v1/";
} else if (
  hName.includes("crm.ghmsoft") ||
  hName.includes("mori-mori.ghmsoft.vn")
) {
  AUTH_URL = "https://auth.moricare.vn";
  CORE_URL = "https://core.moricare.vn/api/v1/";
  NAILSPA_URL = "https://api.moricare.vn/api/v1/";
  NOTIFICATION_URL = "https://notification.moricare.vn/api/v1/";
  FILE_URL_API = "https://file.moricare.vn/api/v1/";
  FILE_URL = "https://file.moricare.vn";
  WEBSITE_URL = "https://website.moricare.vn/api/v1/";
  FIREBASE_URL = "https://firebase.moricare.vn/api/v1/";
  NOTIFICATION_LISTERNER_URL =
    "https://notification.moricare.vn/Notifications";
  WEBSITE_API_URL = "https://websiteapi.moricare.vn/api/v1/";
}

export {
  AUTH_URL,
  CORE_URL,
  NAILSPA_URL,
  NOTIFICATION_URL,
  WEBSITE_API_URL,
  FILE_URL_API,
  FILE_URL,
  WEBSITE_URL,
  FIREBASE_URL,
  NOTIFICATION_LISTERNER_URL,
};

export const THEMES_LIST = [
  { value: "dx.dark.css", name: "Dark" },
  { value: "dx.light.css", name: "Light" },
  { value: "dx.darkviolet.css", name: "DarkViolet" },
  { value: "dx.carmine.css", name: "Carmine" },
  { value: "dx.darkmoon.css", name: "Darkmoon" },
  { value: "dx.greenmist.css", name: "GreenMist" },
  { value: "dx.contrast.css", name: "Contrast" },
  { value: "dx.softblue.css", name: "SoftBlue" },
];

export const DEFAULT_USERSETTING = {
  BRANCH: "dae13df6-6720-4bda-a61c-61e5e948017e",
  PAGESIZE: 20,
  THEME: "14998e5c-27a3-44ff-92d8-19dfd1366ba1",
  LANGUAGE: "vi-VN",
};

export const LANGUAGES = ["vi-VN", "en-US", "ja-JP"]

export const DEFAULT_AVATAR =
  "https://www.allianceplast.com/wp-content/uploads/2017/11/no-image.png";
export const DEFAULT_IMG =
  "https://www.allianceplast.com/wp-content/uploads/2017/11/no-image.png";

export const PAGE_SIZE = 10;
export const RECORDS_SELECT = [5, 10, 20, 50, 100, 500];

export const CLIENT_ID = "GHMSOFTCLIENT";
export const SCOPES =
  "GHM_Core GHM_Gateway GHM_Notification GHM_FileManagement GHM_FireBase GHM_Mori GHM_Website offline_access openid profile email";
// export const DOMAIN = 'vj.soft.comm'
// export const DOMAIN = 'quanlyspa.jadespa.vn'; // admin của spa
export const DOMAIN = "quanlyspa.mori.vn"; // admin cao nhất

export const DOMAIN_TYPE = "0";
export const CLIENT_SECRET = "GHMSOFT";

export const DEVEXTREME_BASEURL =
  "https://unpkg.com/devextreme@21.2-next/dist/css/";

// khach hang gioi thieu || nhan vien gioi thieu
export const KHGT = "KHGT";
export const NVGT = "NVGT";

export const FORMAT_TIME_BYLANG = {
  "vi-VN": "DD/MM/yyyy",
  "en-US": "MM/DD/yyyy",
};
export const FORMAT_TIMEDATE_BYLANG = {
  "vi-VN": "HH:mm DD/MM/YYYY",
  "en-US": "HH:mm MM/DD/yyyy",
};
export const DATETIME_FORMAT = "YYYY-MM-DD[T]HH:mm:ss";
// id the tra truoc va the dich vu
export const TTT = "TTT";
export const TDV = "TDV";
export const D = "D";
export const TM = "TM";
export const TTTOTHER = "TTTOTHER";
export const TDVOTHER = "TDVOTHER";
export const CK = "CK";

export const GoogleMapsAPI = "AIzaSyBNlGkJ7v2YZDnU-eHTePqvV8eDiokRrQ0";

export const DEFAULT_NO_IMG = "/uploads/images/no-image.png";

export const EXCEPT_ROUTER = [
  "/config/usercustom",
  "/login",
  "/report",
  "/report-customerServiceCard",
  "/report-commissionne",
  "/report-total-revenue",
  "/warehouse",
  "/category/brand",
  "/customer/point",
  "/promotion-card",
  "/",
  "/website/:websiteId",
  "/album/:albumId",
  "/menu/:menuId",
  "/subscribe/:subscribeId",
  "/customerFeedback/add",
  "/customerFeedback/:customerFeedbackId",
  "/partner/add",
  "/partner/:partnerId",
  "/feedback/:feedbackId",
  "/service-website/:serviceId",
  "/product-website/add",
  "/booking/edit/:id",
  "/news/:id",
  "/news/add",
  "/project/add",
  "/project",
  "/project/:id",
  "/job/add",
  "/job/:jobId",
  "/recruitment/add",
  "/recruitment/:recruitmentId",
  "/project-category/add",
  "/project-category/:id",
  "/serviceNote/:id",
  "/serviceNote/add",
  "/holiday/:id",
  "/testPage",
  "/testPage/:id",
  "/work-assignment",
  "/work-assignment/:id",
  "/serviceNote/add",
  "/serviceNote/:id",
  "/holiday",
  "/holiday/add",
  "/holiday/:id",
  "/customer/:customerId",
  "/customer/add",
  "/promotion/add",
  "/promotion/:id",
  "/serviceNote/add1",
  "/news/category/:categoryId",
  "/news/category/add",
  "/user-add/:isAdmin",
  "/user/:userId/:isAdmin",
  "/banner/add",
  "/banner/:bannerId",
  "/hardContent/add",
  "/hardContent/:hardContentId",
  "/faq/add",
  "/faq/:faqId",
  "/faq-group/add",
  "/faq-group/:faqGroupId",
  "/branchContact/add",
  "/branchContact/:branchContactId",
  "/socialNetwork/add",
  "/socialNetwork/:socialNetworkId",
  "/notify-timer/:notifyId",
  "/notify-timer/add",
  "/login",
  "/service/add",
  "/service/:id",
  "/service-package/add",
  "/service-package/:id",
];

export const PAYMENT_RECEIVER = [
  "Khách hàng",
  "Nhân viên Công ty",
  "Trợ lý gia đình",
];

export const NOTIFY_RECEIVER_TYPE = [
  { id: 0, name: '{"vi-VN":"Gửi tất cả Admin","en-US":"Send All Admins"}' },
  { id: 1, name: '{"vi-VN":"Gửi tất cả Trợ lý","en-US":"Send All Agents"}' },
  {
    id: 2,
    name: '{"vi-VN":"Gửi tất cả Khách hàng","en-US":"Send All Customers"}',
  },
  { id: null, name: '{"vi-VN":"Gửi riêng","en-US":"Send Individual"}' },
];

export const ABSENT_APPROVE_STATUS = [
  { id: 1, name: '{"vi-VN":"Chờ xác nhận","en-US":"Waiting"}' },
  { id: 2, name: '{"vi-VN":"Phê duyệt","en-US":"Approved"}' },
  { id: 3, name: '{"vi-VN":"Từ chối","en-US":"Rejected"}' },
];

export const CUSTOMER_SERVICECARD_USEHISTORY_TYPE = [
  { type: 1, name: '{"vi-VN":"Lịch sử dịch vụ","en-US":"Service History"}' },
  { type: 2, name: '{"vi-VN":"Lịch sử sản phẩm","en-US":"Product History"}' },
  { type: 3, name: '{"vi-VN":"Lịch sử rút tiền","en-US":"Withdraw History"}' },
];
export const BILL_TYPE_NAME = [
  {
    type: 0,
    name: '{"vi-VN":"Hóa đơn thẻ trả trước","en-US":"Prepaid card bill"}',
  },
  { type: 1, name: '{"vi-VN":"Hóa đơn dịch vụ","en-US":"Service bill"}' },
  { type: 2, name: '{"vi-VN":"Hóa đơn sản phẩm","en-US":"Product bill"}' },
  { type: 3, name: '{"vi-VN":"Hóa đơn rút tiền","en-US":"Withdrawal bill"}' },
  {
    type: 4,
    name: '{"vi-VN":"Hóa đơn yêu cầu dịch vụ","en-US":"Service request bill"}',
  },
  {
    type: 5,
    name: '{"vi-VN":"Hóa đơn yêu cầu sản phẩm","en-US":"Product requirements bill"}',
  },
  {
    type: 6,
    name: [
      '{"vi-VN":"Hóa đơn chuyển tiền","en-US":"Transfers bill"}',
      '{"vi-VN":"Hóa đơn nhận tiền","en-US":"Receive bill"}',
    ],
  },
  { type: 7, name: '{"vi-VN":"Hóa đơn thẻ dịch vụ","en-US":"Pay bill"}' },
];

export const VIDEO_TYPE = [
  { id: 0, name: '{"vi-VN":"Youtube","en-US":"Youtube"}' },
  { id: 1, name: '{"vi-VN":"Vimeo","en-US":"Vimeo"}' },
];

export const ALBUM_TYPE = [
  { id: 0, name: '{"vi-VN":"Ảnh","en-US":"Photo"}' },
  { id: 1, name: '{"vi-VN":"Video","en-US":"Video"}' },
];

export const CONTACT_TYPE = [
  { id: 0, name: '{"vi-VN":"Email","en-US":"Email"}' },
  { id: 1, name: '{"vi-VN":"Số điện thoại","en-US":"Phone Number"}' },
  { id: 2, name: '{"vi-VN":"Mã số thuế","en-US":"TIN"}' },
  { id: 3, name: '{"vi-VN":"KvK","en-US":"KvK"}' },
];

export const ACCOUNT_TYPE = [
  { id: 0, name: '{"vi-VN":"Admin/ Quản lý","en-US":"Admin"}' },
  { id: 1, name: '{"vi-VN":"Trợ lý","en-US":"Customer"}' },
  { id: 2, name: '{"vi-VN":"Khách hàng","en-US":"Customer"}' },
];

export const GENDER = [
  {
    id: 0,
    name: '{"vi-VN":"Nữ","en-US":"Female"}',
    "name-vi-VN": "Nữ",
    "name-en-US": "Female",
  },
  {
    id: 1,
    name: '{"vi-VN":"Nam","en-US":"Male"}',
    "name-vi-VN": "Nam",
    "name-en-US": "Male",
  },
  {
    id: 2,
    name: '{"vi-VN":"Khác","en-US":"Other"}',
    "name-vi-VN": "Khác",
    "name-en-US": "Other",
  },
];

export const DISCOUNT = [
  { id: 0, name: "giảm giá bằng tiền" },
  { id: 1, name: "% giảm giá" },
];

export const USER_TYPE = [
  { id: 0, name: '{"vi-VN":"Nhân viên","en-US":"Staff"}' },
  { id: 1, name: '{"vi-VN":"Trưởng đơn vị","en-US":"Leader"}' },
  { id: 2, name: '{"vi-VN":"Phó đơn vị","en-US":"ViceLeader"}' },
];

export const IS_TECH = [
  { id: 0, name: '{"vi-VN":"Nhân viên thường","en-US":"Staff"}' },
  { id: 1, name: '{"vi-VN":"Kỹ thuật viên","en-US":"Technicians"}' },
];

export const IS_ALL_CUSTOMER = [
  {
    id: 0,
    name: '{"vi-VN":"Một số khách hàng","en-US":"Customers"}',
    value: false,
  },
  { id: 1, name: '{"vi-VN":"Tất cả","en-US":"ALL customers"}', value: true },
];

export const IS_ALL_PACKAGE = [
  {
    id: 0,
    name: '{"vi-VN":"Một số gói dịch vụ","en-US":"Service packages"}',
    value: false,
  },
  {
    id: 1,
    name: '{"vi-VN":"Tất cả","en-US":"ALL service packages"}',
    value: true,
  },
];

export const CUSTOMER_TYPE = [
  {
    id: 0,
    name: '{"vi-VN":"Khách hàng mới","en-US":"New customer"}',
    value: 0,
  },
  { id: 1, name: '{"vi-VN":"Khách hàng cũ","en-US":"Old Customer"}', value: 1 },
  { id: 2, name: '{"vi-VN":"Tất cả ","en-US":"All"}', value: 2 },
];

export const IS_DISCOUNT = [
  { id: 0, name: '{"vi-VN":"Giảm giá bằng tiền","en-US":"discount"}' },
  { id: 1, name: '{"vi-VN":"% giảm giá","en-US":"% discount"}' },
];

export const CATEGORIES = [
  { id: 0, name: '{"vi-VN":"Nhân viên","en-US":"Staff"}' },
  { id: 1, name: '{"vi-VN":"Trưởng đơn vị","en-US":"Leader"}' },
  { id: 2, name: '{"vi-VN":"Phó đơn vị","en-US":"ViceLeader"}' },
];

export const STATUS = [
  { id: 0, name: '{"vi-VN":"Học việc","en-US":"Apprentice"}' },
  { id: 1, name: '{"vi-VN":"Thử việc","en-US":"Probation"}' },
  { id: 2, name: '{"vi-VN":"Chính thức","en-US":"Official"}' },
  { id: 3, name: '{"vi-VN":"Nghỉ thai sản","en-US":"Maternity"}' },
  { id: 4, name: '{"vi-VN":"Thôi việc","en-US":"Discontinue"}' },
  { id: 5, name: '{"vi-VN":"Nghỉ hưu","en-US":"Retirement"}' },
];

export const RECRUIMENT_POSITION = [
  { id: 0, name: '{"vi-VN":"Nhân viên","en-US":"Staff"}' },
  { id: 1, name: '{"vi-VN":"PM","en-US":"PM"}' },
];

export const MARRIED_STATUS = [
  { id: 0, name: '{"vi-VN":"Độc thân","en-US":"Single"}' },
  { id: 1, name: '{"vi-VN":"Đã kết hôn","en-US":"Married"}' },
  { id: 2, name: '{"vi-VN":"Ly thân","en-US":"Separated"}' },
  { id: 3, name: '{"vi-VN":"Ly hôn","en-US":"Divorce"}' },
];

export const FORMAT_TIME = "YYYY-MM-DD[T]HH:mm:ss[Z]";
export const FORMAT_DAY = "DD-MM-YYYY";
export const FORMAT_DATE_TIME_BYLANG = {
  "vi-VN": "DD/MM/yyyy[-]HH:mm:ss",
  "en-US": "MM/DD/yyyy[-]HH:mm:ss",
};
export const FORMAT_DAY_BYLANG = {
  "vi-VN": "DD/MM/yyyy",
  "en-US": "MM/DD/yyyy",
};

export const FORMAT_HOUR = "HH:mm";

export const RESOURCE_DATA = [
  {
    name: '{"vi-VN": "Chờ xác nhận", "en-US": "Pending"}',
    id: 1,
    color: "#427bf5",
  },
  {
    name: '{"vi-VN": "Đã phê duyệt", "en-US": "Approved"}',
    id: 2,
    color: "#28A745",
  },
  {
    name: '{"vi-VN": "Từ chối", "en-US": "Declined"}',
    id: 3,
    color: "#DC3545",
  },
];

export const SHIFT_RESOURCE_DATA = [
  {
    id: 0,
    color: "#1e5ae6",
  },
  {
    id: 1,
    color: "#DC3545",
  },
  {
    id: 2,
    color: "#723ccf",
  },
  {
    id: 3,
    color: "#0dadfc",
  },
];

export const QUICK_SEARCH = [
  {
    name: "Theo ngày",
    id: 0,
  },
  {
    name: "Theo tuần",
    id: 1,
  },
  {
    name: "Theo tháng",
    id: 2,
  },
];

export const STATUS_SERVICE_NOTE = [
  { id: 0, name: '{"vi-VN":"Chưa thanh toán","en-US":"Not Paid"}' },
  { id: 1, name: '{"vi-VN":"Đã thanh toán","en-US":"Paid"}' },
  { id: 2, name: '{"vi-VN":"Đã thanh toán","en-US":"Paid"}' },
  { id: 10, name: '{"vi-VN":"Đang thanh toán","en-US":"Paid"}' },
];
export const PROJECT_STATUS = [
  { id: 1, name: '{"vi-VN":"Kích hoạt","en-US":"InActive"}' },
  { id: 0, name: '{"vi-VN":"Không kích hoạt","en-US":"Active"}' },
];

export const RECUITMENT_IS_VIEW = [
  { id: 1, name: '{"vi-VN":"Đã xem","en-US":"Viewed"}' },
  { id: 0, name: '{"vi-VN":"Chưa xem","en-US":"View"}' },
];

export const STATUS_SERVICE_REQUEST_CHANGE = [
  { id: 0, name: '{"vi-VN":"Thu thêm khách hàng","en-US":"Need Add Pay"}' },
  { id: 1, name: '{"vi-VN":"Không phát sinh","en-US":"No Fee Change"}' },
  { id: 2, name: '{"vi-VN":"Hoàn tiền khách hàng","en-US":"Cashback"}' },
];

export const TYPE_SERVICE = [
  { id: 0, name: '{"vi-VN":"Hoàn tiền","en-US":"Cashback"}' },
  { id: 1, name: '{"vi-VN":"Thu thêm","en-US":"Addition"}' },
  { id: 2, name: '{"vi-VN":"Không thay đổi","en-US":"No Change"}' },
  { id: 3, name: '{"vi-VN":"Hoàn tiền tất cả","en-US":"Cashback all"}' },
];

export const STATUS_NOTE = [
  { id: 0, name: '{"vi-VN":"Đang chờ","en-US":"Waiting"}' },
  { id: 1, name: '{"vi-VN":"Phê duyệt","en-US":"Approved"}' },
  { id: 2, name: '{"vi-VN":"Từ chối","en-US":"Decline"}' },
];

export const PROMOTION_NOTE = [
  { id: 0, name: '{"vi-VN":"Nháp","en-US":"Draft"}' },
  { id: 1, name: '{"vi-VN":"Đang chờ","en-US":"Pending"}' },
  { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' },
  { id: 3, name: '{"vi-VN":"Từ chối","en-US":"Rejected"}' },
];

export const OTHERINCOME_STATUS = [
  // { id: 0, name: '{"vi-VN":"Nháp","en-US":"Draft"}' },
  { id: 1, name: '{"vi-VN":"Đang chờ","en-US":"Pending"}' },
  { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' },
  { id: 3, name: '{"vi-VN":"Từ chối","en-US":"Rejected"}' },
];

export const BOOKING_STATUS = [
  { id: 1, name: '{"vi-VN":"Đang chờ","en-US":"Pending"}' },
  { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' },
  { id: 3, name: '{"vi-VN":"Từ chối","en-US":"Rejected"}' },
];

export const SERVICE_NOTE_TYPE = [
  { id: 0, name: '{"vi-VN":"Gói lẻ theo ngày","en-US":"Reatail package"}' },
  {
    id: 1,
    name: '{"vi-VN":"Tổng vệ sinh","en-US":"Total satination package"}',
  },
  { id: 2, name: '{"vi-VN":"Gói định kỳ","en-US":"Periodic package"}' },
];

export const SERVICE_NOTE_STATUS = [
  {
    id: 0,
    name: '{"vi-VN":"Tiếp nhận","en-US":"Receive order"}',
    "name-vi-VN": "Tiếp nhận",
    "name-en-US": "Receive order",
  },
  {
    id: 1,
    name: '{"vi-VN":"Chờ phân công","en-US":"Waiting for assignment"}',
    "name-vi-VN": "Chờ phân công",
    "name-en-US": "Waiting for assignment",
  },
  {
    id: 2,
    name: '{"vi-VN":"Đã phân công","en-US":"Assign successfully"}',
    "name-vi-VN": "Đã phân công",
    "name-en-US": "Assign successfully",
  },
  {
    id: 3,
    name: '{"vi-VN":"Bắt đầu làm","en-US":"Started"}',
    "name-vi-VN": "Bắt đầu làm",
    "name-en-US": "Started",
  },
  {
    id: 4,
    name: '{"vi-VN":"Hoàn thành","en-US":"Finished"}',
    "name-vi-VN": "Hoàn thành",
    "name-en-US": "Finished",
  },
  {
    id: 5,
    name: '{"vi-VN":"Đã hủy","en-US":"Refund Canceled"}',
    "name-vi-VN": "Đã hủy",
    "name-en-US": "Canceled",
  },
  {
    id: 6,
    name: '{"vi-VN":"Phân công thủ công","en-US":"Manual assignment"}',
    "name-vi-VN": "Phân công thủ công",
    "name-en-US": "Manual assignment",
  },
  {
    id: 7,
    name: '{"vi-VN":"Đã hủy - Bảo lưu","en-US":"Reserved Canceled"}',
    "name-vi-VN": "Đã hủy - Bảo lưu",
    "name-en-US": "Reserved Canceled",
  },
  {
    id: 8,
    name: '{"vi-VN":"Đã hủy - Hoàn tiền","en-US":"Canceled - Refund"}',
    "name-vi-VN": "Đã hủy - Hoàn tiền",
    "name-en-US": "Canceled - Refund",
  },
];

export const WORK_STATUS = [
  {
    id: 1,
    name: '{"vi-VN":"Chờ phân công nhân sự","en-US":"Waiting for assignment"}',
  },
  {
    id: 2,
    name: '{"vi-VN":"Phân công nhân sự thành công","en-US":"Assign successfully"}',
  },
  { id: 3, name: '{"vi-VN":"Đơn hàng bắt đầu thực hiện","en-US":"Started"}' },
  { id: 4, name: '{"vi-VN":"Đơn hàng hoàn thành","en-US":"Finished"}' },
  { id: 5, name: '{"vi-VN":"Đơn hàng đã hủy","en-US":"Canceled"}' },
  { id: 6, name: '{"vi-VN":"Phân công thủ công","en-US":"Manual assignment"}' },
];

export const PAYMENT_STATUS = [
  { id: 0, name: '{"vi-VN":"Chưa thanh toán","en-US":"Unpaid"}' },
  { id: 1, name: '{"vi-VN":"Đã thanh toán","en-US":"Paid"}' },
  { id: 2, name: '{"vi-VN":"Chờ xác nhận","en-US":"Approve penđing"}' },
  { id: 10, name: '{"vi-VN":"Đang thanh toán","en-US":"Not fully paid"}' },
];

export const CELL_WIDTH = {
  isActive: 120,
  isSystem: 120,
  isDefault: 120,
  code: 120,
  thumbnail: 100,
  order: 120,
  endTime: 120,
  startTime: 120,
  dayAgo: 120,
  fullName: 220,
  // 'userName': 100,
  isMultiple: 120,
  gender: 90,
  phoneNumber: 150,
  birthday: 120,
  joinedDate: 120,
  statusName: 120,
  email: 150,
  description: 400,
  // 'serviceGroupsName': 400
};

export const ACTIVE_STATE = [
  { id: 0, name: '{"vi-VN":"Không hoạt động","en-US":"InActive"}' },
  { id: 1, name: '{"vi-VN":"Đang hoạt động","en-US":"Active"}' },
];

export const VIEW_STATE = [
  { id: 0, name: '{"vi-VN":"Chưa xem","en-US":"Not View"}' },
  { id: 1, name: '{"vi-VN":"Đã xem","en-US":"Viewed"}' },
];

export const TYPE_PROMOTION = [
  { id: 0, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
  { id: 1, name: '{"vi-VN":"Sản phẩm","en-US":"Product"}' },
  // { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' }
];

export const TYPE_PROMOTION_READONLY = [
  { id: 0, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
  { id: 1, name: '{"vi-VN":"Sản phẩm","en-US":"Product"}' },
];

export const TYPE_COMMISSIONNE_REPORT = [
  { id: 0, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
  { id: 1, name: '{"vi-VN":"Sản phẩm","en-US":"Product"}' },
  {
    id: 2,
    name: '{"vi-VN":"Thẻ dịch vụ khách hàng","en-US":"CustomerServiceCard"}',
  },
];

export const TYPE_REVENUE_REPORT = [
  { id: 0, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
  { id: 1, name: '{"vi-VN":"Sản phẩm","en-US":"Product"}' },
  { id: 2, name: '{"vi-VN":"Khách hàng","en-US":"Customer"}' },
];

export const TYPESTAFF_COMMISSIONNE_PRODUCT = [
  { id: 0, name: '{"vi-VN":"Nhân viên tư vấn","en-US":"Counselor"}' },
  { id: 1, name: '{"vi-VN":"Nhân viên bán","en-US":"Salesperson"}' },
  { id: 10, name: '{"vi-VN":"Tất cả","en-US":"All"}' },
];

export const MANAGE_CONFIG = [
  { id: 0, name: '{"vi-VN":"Thủ kho","en-US":"Stocker"}' },
  { id: 1, name: '{"vi-VN":"Kế toán trưởng","en-US":"Chief accountant"}' },
];

export const BANNER_POSITION = [
  { id: 0, name: '{"vi-VN":"Trên","en-US":"Top"}' },
  { id: 1, name: '{"vi-VN":"Dưới","en-US":"Bottom"}' },
  { id: 2, name: '{"vi-VN":"Trái","en-US":"Left"}' },
  { id: 3, name: '{"vi-VN":"Phải","en-US":"Right"}' },
  { id: 4, name: '{"vi-VN":"Giữa","en-US":"Center"}' },
];

export const BANNER_TYPE = [
  { id: 0, name: '{"vi-VN":"Ảnh","en-US":"Image"}' },
  { id: 1, name: '{"vi-VN":"Video", "en-US":"Video"}' },
];

export const MENU_POSITION = [
  { id: 0, name: '{"vi-VN":"Trên","en-US":"Top"}' },
  { id: 1, name: '{"vi-VN":"Dưới","en-US":"Bottom"}' },
  { id: 2, name: '{"vi-VN":"Trái","en-US":"Left"}' },
  { id: 3, name: '{"vi-VN":"Phải","en-US":"Right"}' },
  { id: 4, name: '{"vi-VN":"Giữa","en-US":"Center"}' },
];

export const MENU_OBJECT_TYPES = [
  { id: 0, name: '{"vi-VN":"Tùy chỉnh", "en-US":"Custom"}' },
  { id: 1, name: '{"vi-VN":"Chuyên mục tin tức", "en-US":"Category News"}' },
  { id: 2, name: '{"vi-VN":"Tin tức", "en-US":"News"}' },
  {
    id: 3,
    name: '{"vi-VN":"Chuyên mục sản phẩm", "en-US":"Category Product"}',
  },
  { id: 4, name: '{"vi-VN":"Sản phẩm", "en-US":"Product"}' },
  { id: 5, name: '{"vi-VN":"Dịch vụ", "en-US":"Service"}' },
  {
    id: 6,
    name: '{"vi-VN":"Phản hồi khách hàng", "en-US":"Customer feedback"}',
  },
  { id: 7, name: '{"vi-VN":"Nhóm FAQ", "en-US":"FAQ group"}' },
  { id: 8, name: '{"vi-VN":"Album", "en-US":"Album"}' },
];

export const NEWS_STATUS = [
  { id: 0, name: '{"vi-VN":"Nháp","en-US":"Draft"}' },
  { id: 1, name: '{"vi-VN":"Đang chờ","en-US":"Pending"}' },
  { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' },
  { id: 3, name: '{"vi-VN":"Từ chối","en-US":"Declined"}' },
];

export const NEWS_TYPES = [
  { id: 0, name: '{"vi-VN":"Tin tức", "en-US":"News","ja-JP": "ニュース" }' },
  { id: 1, name: '{"vi-VN":"Sự kiện", "en-US":"Event", "ja-JP": "イベント" }' },
];

export const ISCUSTOMER_TYPES = [
  {
    id: null,
    name: '{"vi-VN":"Dành cho cả KH và Trợ Lý", "en-US":"For both Customer and Agent" }',
  },
  {
    id: true,
    name: '{"vi-VN":"Chỉ hiển thị cho KH", "en-US":"Only Customer" }',
  },
  {
    id: false,
    name: '{"vi-VN":"Chỉ hiển thị cho Trợ Lý", "en-US":"Only Agent" }',
  },
];

export const JOB_TYPES = [
  {
    id: 0,
    name: '{"vi-VN":"Toàn thời gian","en-US":" Fulltime", "ja-JP": "フルタイム"}',
  },
  {
    id: 1,
    name: '{"vi-VN":"Bán thời gian ","en-US":"Parttime", "ja-JP": "パートタイム"}',
  },
];

export const REVIEW_RATE = [
  {
    id: 0,
    name: '{"vi-VN":"Rất hài lòng", "en-US":"Excellent","ja-JP": "優秀な" }',
  },
  { id: 1, name: '{"vi-VN":"Hài lòng", "en-US":"Good","ja-JP": "良い" }' },
  { id: 2, name: '{"vi-VN":"Bình thường", "en-US":"Ok","ja-JP": "Ok" }' },
  {
    id: 3,
    name: '{"vi-VN":"Chưa hài lòng", "en-US":"Poor","ja-JP": "貧しい" }',
  },
];

export const REMINDER_TYPE = [
  {
    id: 0,
    name: '{"vi-VN":"Dịch vụ", "en-US":"Service","ja-JP": "サービス" }',
  },
  { id: 1, name: '{"vi-VN":"Sản phẩm", "en-US":"Products","ja-JP": "製品" }' },
  {
    id: 2,
    name: '{"vi-VN":"Thẻ dịch vụ", "en-US":"Customer service card","ja-JP": "カスタマーサービスカード" }',
  },
];

export const PREPAID_CARD_PAYMENT = [
  {
    id: 0,
    name: '{"vi-VN":"Nạp tiền", "en-US":"Recharge","ja-JP": "充電する" }',
  },
  {
    id: 3,
    name: '{"vi-VN":"Rút tiền từ thẻ DV", "en-US":"Withdraw from service card","ja-JP": "サービスカードから撤退する" }',
  },
  {
    id: 6,
    name: '{"vi-VN":"Chuyển tiền giữa các thẻ", "en-US":"Transfer money between cards","ja-JP": "カード間で送金する" }',
  },
];

export const PREPAID_CARD_STATUS = [
  { id: 0, name: '{"vi-VN":"Chưa thanh toán","en-US":"Unpaid"}' },
  { id: 1, name: '{"vi-VN":"Đã thanh toán","en-US":"Paid"}' },
  { id: 10, name: '{"vi-VN":"Đang thanh toán","en-US":"Not fully paid"}' },
];

export const REPORT_STATUS = [
  { id: 3, name: '{"vi-VN":"Hủy","en-US":"Cancel"}' },
  { id: 4, name: '{"vi-VN":"Hoàn thành","en-US":"Sucess"}' },
];

export const REPORT_CUSTOMER_TYPE = [
  { id: 0, name: '{"vi-VN":"Khách hàng mới","en-US":"New customer"}' },
  {
    id: 1,
    name: '{"vi-VN":"Ngày khách hàng làm dịch vụ","en-US":"Customer service date"}',
  },
];

const newLocal =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export const URL_PATTERN = newLocal;

export const FILE_IMAGE_EXT = [".jpg", ".png", ".JPEG"];

export const DETAIL_PAYMENT_TYPE = [
  { id: 0, name: '{"vi-VN":"Thẻ trả trước","en-US":"Prepaid_Cards"}' },
  { id: 1, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
  { id: 2, name: '{"vi-VN":"Sản Phẩm","en-US":"Product"}' },
  { id: 3, name: '{"vi-VN":"Rút tiền","en-US":"Withdrawal"}' },
  { id: 4, name: '{"vi-VN":"Yêu cầu dịch vụ","en-US":"RequestService"}' },
  { id: 5, name: '{"vi-VN":"Yêu cầu sản phẩm","en-US":"RequestProduct"}' },
  {
    id: 6,
    name: '{"vi-VN":"Chuyển tiền từ thẻ trả trước này sang trả trước khác","en-US":"Transfers"}',
  },
  { id: 7, name: '{"vi-VN":"Thanh toán thẻ dịch vụ","en-US":"Service Card"}' },
];

export const ADMIN_ACCOUNT_TYPE = [
  { id: 0, name: '{"vi-VN":"Tài khoản thường","en-US":"Standard account"}' },
  {
    id: 1,
    name: '{"vi-VN":"Tài khoản administrator","en-US":"Administrator account"}',
  },
];

export const WEEKDAYS = [
  { id: 0, name: '{"vi-VN":"Thứ 2","en-US":"Mon."}', code: "monday" },
  { id: 1, name: '{"vi-VN":"Thứ 3","en-US":"Tue."}', code: "tuesday" },
  { id: 2, name: '{"vi-VN":"Thứ 4","en-US":"Wed."}', code: "wednesday" },
  { id: 3, name: '{"vi-VN":"Thứ 5","en-US":"Thu."}', code: "thursday" },
  { id: 4, name: '{"vi-VN":"Thứ 6","en-US":"Fri."}', code: "friday" },
  { id: 5, name: '{"vi-VN":"Thứ 7","en-US":"Sat."}', code: "saturday" },
  { id: 6, name: '{"vi-VN":"CN","en-US":"Sun."}', code: "sunday" },
];

export const MONTHS_OF_CONTRACT = [
  { id: 0, name: '{"vi-VN":"2 tuần","en-US":"2 weeks"}', value: 0.5 },
  { id: 1, name: '{"vi-VN":"1 tháng","en-US":"1 month"}', value: 1 },
  { id: 2, name: '{"vi-VN":"2 tháng","en-US":"2 month"}', value: 2 },
  { id: 3, name: '{"vi-VN":"3 tháng","en-US":"3 month"}', value: 3 },
  { id: 4, name: '{"vi-VN":"4 tháng","en-US":"4 month"}', value: 4 },
  { id: 5, name: '{"vi-VN":"5 tháng","en-US":"5 month"}', value: 5 },
  { id: 6, name: '{"vi-VN":"6 tháng","en-US":"6 month"}', value: 6 },
  { id: 7, name: '{"vi-VN":"7 tháng","en-US":"7 month"}', value: 7 },
  { id: 8, name: '{"vi-VN":"8 tháng","en-US":"8 month"}', value: 8 },
  { id: 9, name: '{"vi-VN":"9 tháng","en-US":"9 month"}', value: 9 },
  { id: 10, name: '{"vi-VN":"10 tháng","en-US":"10 month"}', value: 10 },
  { id: 11, name: '{"vi-VN":"11 tháng","en-US":"11 month"}', value: 11 },
  { id: 12, name: '{"vi-VN":"12 tháng","en-US":"12 month"}', value: 12 },
];

export const NOTIFICATION_TYPE = [
  { id: 0, name: '{"vi-VN":"Warning","en-US":"Warning"}' },
  { id: 1, name: '{"vi-VN":"Info","en-US":"Info"}' },
  { id: 2, name: '{"vi-VN":"Danger","en-US":"Danger"}' },
  { id: 3, name: '{"vi-VN":"Thông báo cho nhân viên","en-US":"User"}' },
];

export const NOTIFICATION_REDIRECT_URL = [
  { id: 0, url: "/serviceNote" },
  { id: 1, url: "/promotion" },
  { id: 2, url: "/absence-calendar" },
  { id: 3, url: "/serviceNoteDetail/cancelOrder" },
  { id: 4, url: "/serviceNote" },
  { id: 5, url: "/serviceNote" },
  { id: 6, url: "/feedback" },
];

export const CONTRACT_TYPE = [
  { id: 0, value: 0, name: '{"vi-VN": "Cố định", "en-US": "Fixed contract"}' },
  { id: 1, value: 1, name: '{"vi-VN": "CTV", "en-US": "Collaborators"}' },
];

export const WEEK_DAYS = {
  monday: {
    id: 0,
    key: "monday",
    value: "Thứ 2",
    check: false,
    startTime: null,
    endTime: null,
  },
  tuesday: {
    id: 1,
    key: "tuesday",
    value: "Thứ 3",
    check: false,
    startTime: null,
    endTime: null,
  },
  wednesday: {
    id: 2,
    key: "wednesday",
    value: "Thứ 4",
    check: false,
    startTime: null,
    endTime: null,
  },
  thursday: {
    id: 3,
    key: "thursday",
    value: "Thứ 5",
    check: false,
    startTime: null,
    endTime: null,
  },
  friday: {
    id: 4,
    key: "friday",
    value: "Thứ 6",
    check: false,
    startTime: null,
    endTime: null,
  },
  saturday: {
    id: 5,
    key: "saturday",
    value: "Thứ 7",
    check: false,
    startTime: null,
    endTime: null,
  },
  sunday: {
    id: 6,
    key: "sunday",
    value: "Chủ nhật",
    check: false,
    startTime: null,
    endTime: null,
  },
};

export const HOUSE_TYPES = [
  { id: 0, "name-vi-VN": "Nhà tầng/ mặt đất", "name-en-US": "Townhouse" },
  { id: 1, "name-vi-VN": "Nhà chung cư", "name-en-US": "Apartment" },
];

export const ESTIMATE_OPTIONS = [
  {
    id: 0,
    "name-vi-VN": "Từ 50-80m / 2 trợ lý / 3h",
    "name-en-US": "50-80m / 2 agents / 3h",
  },
  {
    id: 1,
    "name-vi-VN": "Từ 80-120m / 2 trợ lý / 4h",
    "name-en-US": " 80-120m / 2 agents / 4h",
  },
  {
    id: 2,
    "name-vi-VN": "Từ 120-180m / 3 trợ lý / 3h",
    "name-en-US": "120-180m / 3 agents / 3h",
  },
  {
    id: 3,
    "name-vi-VN": "Từ 180-220m / 3 trợ lý / 4h",
    "name-en-US": "180-220m / 3 agents / 4h",
  },
  {
    id: 4,
    "name-vi-VN": "Từ 220-300m / 4 trợ lý / 5h",
    "name-en-US": " 220-300m / 4 agents / 5h",
  },
];
