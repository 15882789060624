import { PAGE_SIZE } from "../../../constants/Config"
import {
    BOOKING_DELETE,
    BOOKING_DETAIL,
    BOOKING_INSERT,
    BOOKING_LIST,
    BOOKING_UPDATE,
    BOOKING_GET_ALL,
} from "./action-types"

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: null,
    suggestion: [],
}

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case BOOKING_LIST:
            return list(state, payload)
        case BOOKING_DETAIL:
            return detail(state, payload)
        case BOOKING_UPDATE:
            return update(state, payload)
        case BOOKING_INSERT:
            return insert(state, payload)
        case BOOKING_DELETE:
            return deletes(state, payload)
        case BOOKING_GET_ALL:
            return suggestion(state, payload)
        default:
            return state
    }
}

function list(state, payload) {
   return { ...state, data: payload.data, totalRows: payload.totalRows }
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    })
    return state;
}
function detail(state, payload) {
   return Object.assign({}, state, { item: payload })
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload);
    return Object.assign({}, state, { data })
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload }
        }
        return obj
    })

    const item = {...state.item, concurrencyStamp: payload.concurrencyStamp };

    return Object.assign({}, state, { data }, { item })
}

function insert(state, payload) {
    const data = [...state.data, payload]
    return Object.assign({}, state, { data })
}

export default reducer
