export const enG = {
    goodNoteDetail: {
        form: {
            insertTitle: 'Add New Product',
            updateTitle: 'Edit Product',
            quantity: 'Quantity',
            unit: 'Unit',
            barcode: 'Barcode',
            unitPrice: 'Unit price',
            lotId: 'Lot Id',
            expiredDate: 'Expired date',
            inStock: 'In stock'
        },
        table: {
            supplier: 'Supplier',
            warehouse: 'Warehouse',
            deliver: 'Deliver',
            invoiceDate: 'Invoice date',
            invoiceNo: 'Invoice No.'
        }
    },
    groupProduct: {
        list: {
            title: 'List of Product Groups',
            placeholder: 'Choose Product Group'
        }
    },
    groupService: {
        list: { title: 'List of Service Groups', order: 'Order' },
        form: {
            name: 'Service Group Name',
            parent: 'Choose Service Group Parent'
        }
    },
    goodsNote: {
        code: 'Number of votes',
        entryDate: 'Date',
        customerCode: 'Customer\'s code',
        customerName: 'Customer',
        objectName: 'Object',
        reason: 'Reason',
        address: 'Address',
        deliver: 'Deliver',
        staff: 'Staff',
        totalAmount: 'Total amount',
        totalTax: 'Tax',
        discount: 'Discounts',
        totalPrice: 'Total price',
        invoiceDate: 'Invoice date',
        invoiceNo: 'Invoice no',
        ct: 'Invoice',
        note: 'Note',
        title: 'Product list',
        unit: 'good note(s)',
        supplier: 'Supplier',
        placeOfUse: 'Place of use',
        info: 'Info',
        delete: 'Delete',
        print: 'Print',
        canceler: 'Canceler',
        deliverySupply: { title: 'List of Delivery Giving', insertTitle: 'Add new delivery giving', updateTitle: 'Update delivery giving', printTitle: 'Goods delivery note of giving' },
        deliveryOther: { title: 'List of Delivery Other', insertTitle: 'Add new delivery other', updateTitle: 'Update delivery other', printTitle: 'Goods delivery note of other purposes' },
        deliveryTransfer: { title: 'List of Delivery Transfer', insertTitle: 'Add new delivery transfer', updateTitle: 'Update delivery transfer', printTitle: 'Goods delivery note of warehouse transfer' },
        deliverySupplier: { title: 'List of Delivery Suppliers', insertTitle: 'Add new delivery supplier', updateTitle: 'Update delivery supplier', printTitle: 'Goods delivery note of supplier return' },
        deliverySale: { title: 'List of Delivery Sale', insertTitle: 'Add new delivery sale', updateTitle: 'Update delivery sale', printTitle: 'Goods delivery note of sale' },
        deliveryUse: { title: 'List of Delivery Uses', insertTitle: 'Add new delivery use', updateTitle: 'Update delivery use', printTitle: 'Goods delivery note of usage' },
        deliveryCancel: { title: 'List of Delivery Cancel', insertTitle: 'Add new delivery cancel', updateTitle: 'Update delivery cancel', printTitle: 'Goods delivery note of canceling' },
        receiptOther: { title: 'List of Receipt Other', insertTitle: 'Add new receipt other', updateTitle: 'Update receipt other', printTitle: 'Goods received note of other purposes' },
        receiptReturn: { title: 'List of Returned Receipts', insertTitle: 'Add new return receipt', updateTitle: 'Update return receipts', printTitle: 'Goods received note of customer return' },
        receiptNew: { title: 'List of New Receipts', insertTitle: 'Add new receipt', updateTitle: 'Update new receipts', printTitle: 'Goods received note' },
        transfer: {
            warehouseExportId: 'Warehouse export id',
            warehouseExportName: 'Warehouse export',
            warehouseExportObject: 'Exporter',
            warehouseImportId: 'Warehouse import id',
            warehouseImportName: 'Warehouse import',
            warehouseImportObject: 'Importer'
        },
        errorAlert: {
            errWarehouseConfig: 'Have not selected the warehouse configuration',
            errUpdateWarehouse: 'Do not choose the same warehouse as the export warehouse'
        }
    }
};
