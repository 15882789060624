export const SUBSCRIBE_LIST = 'SUBSCRIBE_LIST'
export const SUBSCRIBE_DETAIL = 'SUBSCRIBE_DETAIL'
export const SUBSCRIBE_UPDATE = 'SUBSCRIBE_UPDATE'
export const SUBSCRIBE_INSERT = 'SUBSCRIBE_INSERT'
export const SUBSCRIBE_DELETE = 'SUBSCRIBE_DELETE'
export const SUBSCRIBE_RESPONSE = 'SUBSCRIBE_RESPONSE'
export const SUBSCRIBE_GETALL = 'SUBSCRIBE_GETALL'

export default {
    SUBSCRIBE_DELETE,
    SUBSCRIBE_DETAIL,
    SUBSCRIBE_INSERT,
    SUBSCRIBE_LIST,
    SUBSCRIBE_UPDATE,
    SUBSCRIBE_GETALL
}
