import React, { Component } from 'react';
import i18n from '../../i18n';
import { SelectBox } from 'devextreme-react';
import PropTypes from 'prop-types';

class BaseConstantSelectBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: {},
            payload: {}
        };

        this.initData = [];

        this.onDataChanged = this.onDataChanged.bind(this);
    }

    componentDidMount() {
        const { languages } = this.props;
        this.handleLanguage(languages);
    }

    handleLanguage = (languages) => {
        let datas = [];

        languages.forEach(element => {
            const newArray = [];

            for (const item of this.initData) {
                const i = Object.assign({}, item);
                const nameParse = (i.name ? JSON.parse(i.name) : '');

                i.name = nameParse[element.languageId];
                newArray.push(i);
            }

            datas = { ...datas, [element.languageId]: newArray };
        });

        this.setState({
            datas
        });
    }

    /**
     * Handle Data Change.
     *
     * @inheritdoc
     */
    onDataChanged(e) {
        if (e.value !== null) {
            const { field } = this.props;

            console.log(field, e.value);
            this.props.updateData({
                [field]: e.value
            });
        }
    }

    render() {
        const { currentLanguageActive, value, i18nLabel, validatorValue, showClearButton, disabled } = this.props;
        const { datas } = this.state;

        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>{i18n.t(i18nLabel) + ':'}{validatorValue && validatorValue.includes('require') && <span style={{ color: 'red' }}> *</span>}</div>
                <div className={'dx-field-value'}>
                    <SelectBox
                        searchEnabled={true}
                        items={datas[currentLanguageActive]}
                        value={value}
                        valueExpr={'id'}
                        displayExpr={'name'}
                        readOnly={disabled !== undefined ? disabled : false}
                        placeholder={i18n.t(i18nLabel)}
                        showClearButton={showClearButton !== undefined ? showClearButton : true}
                        onValueChanged={this.onDataChanged}
                    />
                </div>
            </div>
        );
    }
}

BaseConstantSelectBox.propTypes = {
    currentLanguageActive: PropTypes.string,
    validatorValue: PropTypes.string,
    value: PropTypes.any,
    i18nLabel: PropTypes.string,
    showClearButton: PropTypes.bool,
    disabled: PropTypes.bool,
    updateData: PropTypes.func,
    field: PropTypes.string,
    languages: PropTypes.array
};

export default BaseConstantSelectBox;
