import { CheckBox, Popup } from 'devextreme-react';
import Button from 'devextreme-react/button';
import * as _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastsStore } from 'react-toasts';
import ReeValidate from 'ree-validate';
import { PasswordBox } from '../../../../components';
import { FILE_URL } from '../../../../constants/Config';
import i18n from '../../../../i18n';
import { logout } from '../../../auth/service.js';
import { notificationDisconnectRequest } from '../../../notification/service';
import { changePasswordRequest } from '../service';
import './style.scss';

class ChangePasswordForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new ReeValidate({
            // userName: 'required|min:5|max:150|regex:^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*$',
            oldPassword: 'required|min:6|max:150',
            newPassword: 'required|min:6|max:150',
            confirmNewPassword: 'required|min:6|max:150'
        });
        this.state = {
            model: {
                // id: this.props.id,
                // userName: this.props.account.userName,
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
                isResetPassword: true
            },
            logOutinOthers: false,
            passwordOld: 'password',
            passwordnew1: 'password',
            passwordnew2: 'password',
            urrentPageActive: 1,
            errors: this.validator.errors,
            errorResponse: [],
            popupVisible: true,
            id: null,
            isShowForm: false,
            currentLanguageActive: this.props.currentLanguage,
            currentLanguage: '',
            disabled: false
        };
        // this.onValueChanged = this.onValueChanged.bind(this);
        this.passwordBtnOld = {
            icon: '/assets/images/eye.png',
            type: 'default',
            onClick: () => {
                this.setState({
                    passwordOld: (this.state.passwordOld === 'text' ? 'password' : 'text')
                });
            }
        };
        this.passwordBtn1 = {
            icon: '/assets/images/eye.png',
            type: 'default',
            onClick: () => {
                this.setState({
                    passwordnew1: (this.state.passwordnew1 === 'text' ? 'password' : 'text')
                });
            }
        };
        this.passwordBtn2 = {
            icon: '/assets/images/eye.png',
            type: 'default',
            onClick: () => {
                this.setState({
                    passwordnew2: (this.state.passwordnew2 === 'text' ? 'password' : 'text')
                });
            }
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
        this.handlechangePassword = this.handlechangePassword.bind(this);
        this.hidePopup = this.hidePopup.bind(this);
        this.hideForm = this.hideForm.bind(this);
    }

    valueChanged(e) {
        var model = {
            ...this.state.model
        };
        if (e.oldPassword || e.newPassword || e.confirmNewPassword) {
            model = { ...this.state.model, ...e };
        }

        this.setState({
            ...this.state,
            model,
            errors: this.validator.errors,
            disabled: false
        });
        // if (e.event !== undefined && e.event !== null) {
        //     if (e.event.type !== 'dxclick') {
        //         // const { errors } = this.validator;

        //         // errors.remove(e.event.target.name);
        //         // errors.remove('confirmPasswordValidate');
        //         // errors.remove('oldPasswordValidate');
        //         // this.validator.validate(e.event.target.name, e.event.target.value).then(() => {
        //         //     this.setState({ errors });
        //         // });
        //     }
        // }
    }

    hideForm() {
        this.setState({
            isShowForm: !this.state.isShowForm,
            id: null
        });
    }

    handleSubmit() {
        const account = this.state.model;
        const { errors } = this.validator;
        if (account.oldPassword === account.newPassword && account.oldPassword !== '') {
            errors.add('oldPasswordValidate', i18n.t('general.form.error.oldPasswordValidate'));
            this.setState({
                errors,
                disabled: true
            });
        } else if (account.newPassword !== account.confirmNewPassword) {
            errors.add('confirmPasswordValidate', i18n.t('general.form.error.confirmPasswordValidate'));
            this.setState({
                errors,
                disabled: true
            });
        } else {
            this.validator.validateAll(account).then((success) => {
                if (success) {
                    this.handlechangePassword(account);
                } else {
                    this.setState({
                        errors,
                        disabled: true
                    });
                }
            });
        }
    }

    handlechangePassword = (account) => {
        this.props.changePassword(account)
            .then((data) => {
                if (this.state.logOutinOthers) {
                    this.props.disconnect();
                }

                ToastsStore.success(data.message);
                setTimeout(() => {
                    this.logout();
                }, 1500);
            }).catch((error, status) => {
                ToastsStore.error(error.response.data.message);
            });
    }

    logout = () => {
        return this.props.logout();
    }

    hidePopup() {
        this.setState({
            popupVisible: false

        });
    }

    isHiddenForm = () => {
        this.props.hideForm();
    }

    render() {
        const { iShowFormResetPassword, currentLanguage } = this.props;
        const { errors, logOutinOthers, currentLanguageActive, disabled } = this.state;
        console.log('CURRENT LANG: ', currentLanguage);
        console.log('CURRENT LANG ACTIVE: ', currentLanguageActive);
        if (!iShowFormResetPassword && !this.state.model) {
            return null;
        } else {
            return (
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={() => this.hidePopup()}
                    onHidden={() => this.isHiddenForm()}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title={i18n.t('general.navbar.changepasstitile')}
                    maxWidth={750}
                    height={600}
                >
                    <div className={'dx-fieldset my-2 m-auto'} onKeyPress={event => {
                        if (event.key === 'Enter') {
                            this.handleSubmit();
                        }
                    }}>
                        <div className={'dx-field'}>
                            <div className={'dx-field-label text-right'}>
                                <div className={'ml-auto'} >
                                    <img alt="Copyright Of GHMSoft" className={'ml-auto'} src={FILE_URL + '/' + this.props.initNewData.logo} height='90' />
                                </div>
                            </div>
                            <div className={'dx-field-value'}> <h2 className="title-changepass">{i18n.t('general.navbar.changepass')}</h2></div>
                        </div>
                        <div className={'dx-field' + (errors.has('oldPassword') ? ' has-error' : '')}>
                            {/* <div className={'dx-field-label text-right'}>{i18n.t('general.navbar.oldpassword')} :<span className={'color-red'}>*</span></div> */}
                            <div className={'dx-field-value'}>

                                {/* <TextBox
                                    name={'oldPassword'}
                                    valueChangeEvent={'keyup'}
                                    value={this.state.model.oldPassword || ''}
                                    onValueChanged={this.valueChanged}
                                    mode={this.state.passwordOld}>
                                    <TextBoxButton
                                        name={'oldPassword'}
                                        location={'after'}
                                        options={this.passwordBtnOld}
                                    />
                                </TextBox>
                                 */}
                                <PasswordBox
                                    i18nLabel={'general.navbar.oldpassword'}
                                    updateData={this.valueChanged}
                                    field={'oldPassword'}
                                    validatorValue={'required|min:6|max:150'}
                                    currentLanguage={currentLanguage}
                                    currentLanguageActive={currentLanguageActive}
                                    value={this.state.model.oldPassword || ''}
                                    showEye={true}
                                />
                                {errors.has('oldPassword') &&
                                    <div
                                        className="help-block">{errors.first('oldPassword')}
                                    </div>}
                                {errors.has('confirmOldPassword') &&
                                    <div
                                        className="help-block">{errors.first('confirmOldPassword')}
                                    </div>}
                            </div>

                        </div>
                        <div className={'dx-field' + (errors.has('newPassword') ? ' has-error' : '') + (errors.has('oldPasswordValidate') ? ' has-error' : '')}>
                            {/* <div className={'dx-field-label text-right'}>{i18n.t('general.navbar.newpassword')} :<span className={'color-red'}>*</span></div> */}
                            <div className={'dx-field-value'}>
                                {/* <TextBox
                                    name={'newPassword'}
                                    valueChangeEvent={'keyup'}
                                    value={this.state.model.newPassword || ''}
                                    onValueChanged={this.valueChanged}
                                    mode={this.state.passwordnew1}>
                                    <TextBoxButton
                                        name={'newPassword'}
                                        location={'after'}
                                        options={this.passwordBtn1}
                                    />
                                </TextBox>
                                 */}
                                <PasswordBox
                                    i18nLabel={'general.navbar.newpassword'}
                                    updateData={this.valueChanged}
                                    field={'newPassword'}
                                    validatorValue={'required|min:6|max:150'}
                                    currentLanguage={currentLanguage}
                                    currentLanguageActive={currentLanguageActive}
                                    value={this.state.model.newPassword || ''}
                                    showEye={true}
                                />
                                {errors.has('newPassword') &&
                                    <div
                                        className="help-block">{errors.first('newPassword')}
                                    </div>}
                                {errors.has('oldPasswordValidate') &&
                                    <div
                                        className="help-block">{errors.first('oldPasswordValidate')}
                                    </div>}
                            </div>
                        </div>
                        <div className={'dx-field' + (errors.has('confirmNewPassword') ? ' has-error' : '') + (errors.has('confirmPasswordValidate') ? ' has-error' : '')}>
                            {/* <div className={'dx-field-label text-right'}>{i18n.t('general.navbar.Enterpassword')} :<span className={'color-red'}>*</span></div> */}
                            <div className={'dx-field-value'}>
                                <PasswordBox
                                    i18nLabel={'general.navbar.Enterpassword'}
                                    updateData={this.valueChanged}
                                    field={'confirmNewPassword'}
                                    validatorValue={'required|min:6|max:150'}
                                    currentLanguage={currentLanguage}
                                    currentLanguageActive={currentLanguageActive}
                                    value={this.state.model.confirmNewPassword || ''}
                                    showEye={true}
                                />
                                {errors.has('confirmNewPassword') &&
                                    <div
                                        className="help-block">{errors.first('confirmNewPassword')}
                                    </div>}
                                {errors.has('confirmPasswordValidate') &&
                                    <div
                                        className="help-block">{errors.first('confirmPasswordValidate')}
                                    </div>}
                                {/* <TextBox
                                    name={'confirmNewPassword'}
                                    valueChangeEvent={'keyup'}
                                    value={this.state.model.confirmNewPassword || ''}
                                    mode={this.state.passwordnew2}
                                    onValueChanged={this.valueChanged}>
                                    <TextBoxButton
                                        name={'confirmNewPassword'}
                                        location={'after'}
                                        options={this.passwordBtn2}
                                    />
                                </TextBox>
                                 */}
                            </div>
                        </div>
                        <div className={'dx-field'}>
                            <div className={'dx-field-label text-right'}>
                                <CheckBox value={logOutinOthers} onValueChanged={() => this.setState({ logOutinOthers: !this.state.logOutinOthers })} />
                            </div>
                            <div className={'dx-field-value'}>{i18n.t('general.navbar.Logoutdevices')}</div>
                        </div>
                        <div className={'filter-container text-center'}>
                            <Button className={'mr-1 dx-theme-accent-as-background-color'}
                                width={120}
                                text={i18n.t('general.button.save')}
                                stylingMode={'contained'}
                                disabled={disabled}
                                onClick={() => this.handleSubmit()} />
                            <Button className={'ml-1'}
                                width={120}
                                text={i18n.t('general.button.cancel')}
                                stylingMode={'contained'}
                                onClick={this.props.hideForm} />
                        </div>
                    </div>
                </Popup>

            );
        }
    }
}

const mapStateToProps = (state) => {
    const { languages, userSettings } = state.appSettings.data;
    const currentLanguage = _.find(userSettings, (item) => item.key === 'LanguageId');

    return {
        languages: languages,
        currentLanguage: currentLanguage.value,
        account: state.account.data,
        initNewData: state.auth.initNewApp
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (account) => {
            return dispatch(changePasswordRequest(account));
        },
        logout: () => {
            return dispatch(logout());
        },
        disconnect: () => {
            return dispatch(notificationDisconnectRequest());
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm)
    ;
