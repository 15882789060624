export const enH = {
    holiday: {
        serviceId: 'Main service',
        price: 'Price',
        priceWeekend: 'Weekend price',
        description: 'Description',
        form: {
            insertTitle: 'Add new holiday',
            updateTitle: 'Update holiday infomation'
        }
    }
};
