import { spinnerLoading } from '../../common/spinner/store/action';
import { FILE_URL_API } from '../../constants/Config';
import Http from '../../utils/Http';
import * as folderActions from './store/actions';

export function folderChildrenRequest(folderId) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${FILE_URL_API}folders/current-directory/${folderId}`;
            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false));
                dispatch(folderActions.list(res.data));
                resolve(res.data);
            }).catch(err => {
                dispatch(spinnerLoading(false));
                reject(err);
            });
        })
    );
}

export function folderSearchRequest(keyword = null) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${FILE_URL_API}folders`;
            url += keyword ? '?keyword=' + keyword : '';
            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false));
                const payload = res.data;
                for (let index = 0; index < payload.files.length; index++) {
                    payload.files[index].folderId = null;
                }
                for (let index = 0; index < payload.folders.length; index++) {
                    payload.folders[index].parentId = null;
                }

                dispatch(folderActions.folderSearch(payload));
                resolve();
            }).catch(err => {
                dispatch(spinnerLoading(false));
                reject(err);
            });
        })
    );
}

export function folderResetRequest() {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            dispatch(folderActions.reset());
            const url = `${FILE_URL_API}folders/current-directory/0`;
            Http.get(url).then((res) => {
                dispatch(spinnerLoading(false));
                dispatch(folderActions.list(res.data));

                resolve();
            }).catch(err => {
                dispatch(spinnerLoading(false));
                reject(err);
            });
        })
    );
}

export function folderInsertRequest(folder) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${FILE_URL_API}folders`;
            const folderUpdate = folder;

            Http.post(url, folderUpdate).then(res => {
                dispatch(spinnerLoading(false));
                const newFolder = {
                    id: parseInt(res.data.data),
                    name: folder.name,
                    parentId: folder.parentId,
                    isDirectory: true
                };
                dispatch(folderActions.folderInsert(newFolder));
                return resolve(res.data);
            }).catch(err => {
                dispatch(spinnerLoading(false));
                return reject(err);
            });
        })
    );
}

export function folderDeleteRequest(folderId) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${FILE_URL_API}folders/` + folderId;

            Http.delete(url).then(res => {
                dispatch(spinnerLoading(false));
                dispatch(folderActions.folderDelete({
                    id: folderId
                }));
                return resolve(res.data);
            }).catch(err => {
                dispatch(spinnerLoading(false));
                return reject(err);
            });
        })
    );
}

export function folderEditRequest(folderId, folderMeta) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${FILE_URL_API}folders/` + folderId;

            const folderUpdate = folderMeta;

            Http.put(url, folderUpdate).then(res => {
                dispatch(spinnerLoading(false));
                dispatch(folderActions.folderEdit({
                    id: folderId,
                    name: folderMeta.name,
                    parentId: folderMeta.parentId,
                    concurrencyStamp: res.data.data
                }));
                return resolve(res.data);
            }).catch(err => {
                dispatch(spinnerLoading(false));
                return reject(err);
            });
        })
    );
}

export function folderNameEditRequest(folderId, folderMeta) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${FILE_URL_API}folders/${folderId}/name`;
            url += `?name=${folderMeta.name}`;
            url += `&concurrencyStamp=${folderMeta.concurrencyStamp}`;

            const folderUpdate = folderMeta;

            Http.put(url, folderUpdate).then(res => {
                dispatch(spinnerLoading(false));
                dispatch(folderActions.folderEdit({
                    id: folderId,
                    name: folderMeta.name,
                    parentId: folderMeta.parentId,
                    concurrencyStamp: res.data.data
                }));
                return resolve(res.data.data);
            }).catch(err => {
                dispatch(spinnerLoading(false));
                return reject(err);
            });
        })
    );
}

export function folderTreeRequest() {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${FILE_URL_API}folders/trees`;

            Http.get(url).then(res => {
                dispatch(spinnerLoading(false));
                const foldersList = getFoldersList(res.data);
                const listData = {
                    folders: foldersList,
                    files: []
                };
                dispatch(folderActions.list(listData));
                dispatch(folderActions.folderTree(res.data));
                return resolve();
            }).catch(err => {
                dispatch(spinnerLoading(false));
                return reject(err);
            });
        })
    );
}

function getFoldersList(data) {
    let _folders = [];

    data.forEach(element => {
        _folders.push({
            id: element.id,
            parentId: element.parentId,
            name: element.text,
            idPath: element.idPath,
            childCount: element.childCount,
            concurrencyStamp: element.data.concurrencyStamp
        });

        const _childFolders = getFoldersList(element.children);
        _folders = [..._folders, ..._childFolders];
    });
    return _folders;
}

export function fileUploadRequest(folderId, files) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${FILE_URL_API}files/uploads`;
            const data = new FormData();

            const addFiles = new Promise((resolve, reject) => {
                for (let i = 0; i < files.length; i++) {
                    data.append('formFileCollection', files[i].file, files[i].name);
                }
                resolve();
            });

            addFiles
                .then(() => {
                    if (folderId) { data.set('folderId', folderId); }
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Access-Control-Allow-Origin': '*'
                        }
                    };

                    Http.post(url, data, axiosConfig).then((res) => {
                        dispatch(spinnerLoading(false));
                        dispatch(folderActions.fileUpload(res.data.data));
                        return resolve(res.data.data);
                    }).catch(err => {
                        dispatch(spinnerLoading(false));
                        return reject(err);
                    });
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    );
}

export function fileDeleteRequest(fileId) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${FILE_URL_API}files/` + fileId;
            Http.delete(url).then(res => {
                dispatch(spinnerLoading(false));
                dispatch(folderActions.fileDelete({
                    id: fileId
                }));
                return resolve();
            }).catch(err => {
                dispatch(spinnerLoading(false));
                return reject(err);
            });
        })
    );
}

export function fileEditRequest(fileId, fileMeta) {
    return dispatch => (
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${FILE_URL_API}files/` + fileId;

            const fileUpdate = fileMeta;

            Http.put(url, fileUpdate).then(res => {
                dispatch(spinnerLoading(false));
                dispatch(folderActions.fileEdit({
                    id: fileId,
                    name: fileMeta.name,
                    folderId: fileMeta.folderId,
                    concurrencyStamp: res.data.data
                }));
                return resolve(res.data);
            }).catch(err => {
                dispatch(spinnerLoading(false));
                return reject(err);
            });
        })
    );
}

export function fileDownloadRequest(fileId) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const url = `${FILE_URL_API}Files/downloads/${fileId}`;

            Http.get(url, { responseType: 'blob' })
                .then((response) => {
                    const reader = new window.FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onload = function () {
                        const imageDataUrl = reader.result;
                        resolve(imageDataUrl);
                    };
                })
                .catch((err) => {
                    reject(err);
                });
        })
    );
}
