import {
    FAQGROUP_LIST,
    FAQGROUP_UPDATE,
    FAQGROUP_DELETE,
    FAQGROUP_GETALL,
    FAQGROUP_DETAIL,
    FAQGROUP_INSERT
} from "./action-types";

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    items: [],
    item: null,
    suggestion: []
}

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case FAQGROUP_LIST:
            return list(state, payload)
        case FAQGROUP_DETAIL:
            return detail(state, payload)
        case FAQGROUP_UPDATE:
            return update(state, payload)
        case FAQGROUP_INSERT:
            return insert(state, payload)
        case FAQGROUP_DELETE:
            return deletes(state, payload)
        case FAQGROUP_GETALL:
            return suggestion(state, payload)
        default:
            return state
    }
}

function list(state, payload) {
    state = Object.assign({}, state, payload)
    return state
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    })
    return state;
}
function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    })
    return state
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload)

    return Object.assign({}, state, { data })
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload }
        }
        return obj
    })

    return Object.assign({}, state, { data })
}

function insert(state, payload) {
    const data = [...state.data, payload]
    return Object.assign({}, state, { data })
}

export default reducer
