import React, { Component } from 'react';
import { NumberBox } from 'devextreme-react';
import i18n from '../../i18n';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { locale } from 'devextreme/localization';

class CurrencyBox extends Component {
    constructor(props) {
        super(props);

        this.onChangeValue = this.onChangeValue.bind(this);

        locale('en-US');
    }

    onChangeValue(e) {
        this.props.updateData({
            [this.props.field]: e.value
        });
    }

    onContentReady = (e) => {
        if (this.props.focus) this.focus(e);
    };

    focus = (e) => {
        e.component.focus();
        e.element.className += ' dx-state-focused';
    };

    render() {
        const { value, i18nLabel, max, disabled, validatorValue, readOnly } = this.props;

        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>
                    {i18n.t(i18nLabel)}:
                    {validatorValue && validatorValue.includes('require') && (
                        <span style={{ color: 'red' }}> *</span>
                    )}
                </div>
                <div className={'dx-field-value'}>
                    <NumberBox
                        value={value}
                        format="#,##0.## ₫"
                        min={0}
                        max={_.has(this.props, 'max') ? max : null}
                        onContentReady={this.onContentReady}
                        onValueChanged={this.onChangeValue}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
            </div>
        );
    }
}

CurrencyBox.propTypes = {
    value: PropTypes.any,
    i18nLabel: PropTypes.string,
    max: PropTypes.number,
    disabled: PropTypes.bool,
    field: PropTypes.string,
    updateData: PropTypes.func,
    focus: PropTypes.bool,
    validatorValue: PropTypes.any,
    readOnly: PropTypes.any
};

export default CurrencyBox;
