import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/customer',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/CustomerContainer'),
            loading: LoadingComponent
        })
    }, {
        path: '/customer/info',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./info/CustomerInfo'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer/point',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./customerPoint/index'),
            loading: LoadingComponent
        })
    },

    {
        path: '/customer/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/CustomerDetailContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer/:customerId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/CustomerDetailContainer'),
            loading: LoadingComponent
        })
    }

];
