import {
    WEBSITE_MENU_SEARCH,
    WEBSITE_MENU_DETAIL,
    WEBSITE_MENU_ITEM_GETALL,
    WEBSITE_MENU_ITEM_SEARCH,
    WEBSITE_MENU_ITEM_TREE,
    WEBSITE_MENU_ITEM_DETAIL,
    WEBSITE_MENU_ITEM_UPDATE_ACTIVE
} from './action-types';

const initialState = {
    data: [],
    item: null,
    items_suggestion: [],
    items_data: [],
    menu_item: null,
    items_totalRows: 0,
    totalRows: 0,
    tree: null
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case WEBSITE_MENU_SEARCH:
            return search(state, payload);
        case WEBSITE_MENU_DETAIL:
            return detail(state, payload);
        case WEBSITE_MENU_ITEM_SEARCH:
            return itemsSearch(state, payload);
        case WEBSITE_MENU_ITEM_TREE:
            return itemsTree(state, payload);
        case WEBSITE_MENU_ITEM_DETAIL:
            return itemDetail(state, payload);
        case WEBSITE_MENU_ITEM_GETALL:
            return itemsGetAll(state, payload);
        case WEBSITE_MENU_ITEM_UPDATE_ACTIVE:
            return menuUpdateActive(state, payload);
        default:
            return state;
    }
};

function menuUpdateActive(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function search(state, payload) {
    state = Object.assign({}, state, { data: payload.data, totalRows: payload.totalRows });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, { item: payload });
    return state;
}

function itemsGetAll(state, payload) {
    state = Object.assign({}, state, { items_suggestion: payload.data });
    return state;
}

function itemsSearch(state, payload) {
    state = Object.assign({}, state, { items_data: payload.data, items_totalRows: payload.totalRows });
    return state;
}

function itemsTree(state, payload) {
    state = Object.assign({}, state, { tree: payload });
    return state;
}

function itemDetail(state, payload) {
    state = Object.assign({}, state, { menu_item: payload });
    return state;
}

export default reducer;
