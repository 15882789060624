import HTTP from '../../../utils/Http';

import {
    AUTH_CHECK,
    AUTH_LOGIN,
    AUTH_REFRESH_TOKEN,
    AUTH_LOGOUT,
    AUTH_RESET_PASSWORD,
    CHECK_USER_NAME,
    SENT_MAIL_CODE,
    CONFIRM_CODE,
    AUTH_INIT_NEW_APP
} from './action-types';

const initialState = {
    isAuthenticated: false,
    data: {},
    initNewApp: null
};

const reducer = (state = initialState, { type, payLoad = null }) => {
    switch (type) {
        case AUTH_REFRESH_TOKEN:
        case AUTH_LOGIN:
            return login(state, payLoad);
        case AUTH_CHECK:
            return checkAuth(state);
        case AUTH_LOGOUT:
            return logout(state);
        case AUTH_RESET_PASSWORD:
            return resetPassword(state);
        case CHECK_USER_NAME:
            return checkUserName(state, payLoad);
        case SENT_MAIL_CODE:
            return sentCode(state);
        case CONFIRM_CODE:
            return confirmCode(state, payLoad);
        case AUTH_INIT_NEW_APP:
            return authInitNewApp(state, payLoad);
        default:
            return state;
    }
};

function authInitNewApp(state, payLoad) {
    return {
        ...state, initNewApp: payLoad
    };
}

function login(state, payLoad) {
    localStorage.setItem('access_token', payLoad);
    HTTP.defaults.headers.common['Authorization'] = `Bearer ${payLoad}`;
    return {
        ...state, isAuthenticated: true, accessToken: payLoad
    };
}

function checkAuth(state) {
    state = Object.assign({}, state, {
        isAuthenticated: !!localStorage.getItem('access_token') && !!localStorage.getItem('username') && !!localStorage.getItem('password')
    });

    if (state.isAuthenticated) {
        HTTP.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    }
    return { ...state, accessToken: localStorage.getItem('access_token') || '' };
}

function logout(state) {
    localStorage.clear();
    return {
        ...state, isAuthenticated: false
    };
}

function resetPassword(state) {
    return {
        ...state, resetPassword: true
    };
}
function checkUserName(state, payload) {
    state = Object.assign({}, state, { data: payload });
    return state;
}
function sentCode(state, payload) {
    const data = { ...state.data, payload };

    return Object.assign({}, state, { data });
}

function confirmCode(state, payload) {
    return Object.assign({}, state);
}

export default reducer;
