export const HOLIDAY_LIST = 'HOLIDAY_LIST';
export const HOLIDAY_GETTALL = 'HOLIDAY_GETTALL';
export const HOLIDAY_DETAIL = 'HOLIDAY_DETAIL';
export const HOLIDAY_SHIFT_GETALL = 'HOLIDAY_SHIFT_GETALL';
export const HOLIDAY_SHIFT_BY_DATE = 'HOLIDAY_SHIFT_BY_DATE';

export default {
    HOLIDAY_LIST,
    HOLIDAY_GETTALL,
    HOLIDAY_DETAIL,
    HOLIDAY_SHIFT_GETALL,
    HOLIDAY_SHIFT_BY_DATE
};
