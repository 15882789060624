export const HARD_CONTENT_LIST = 'HARD_CONTENT_LIST'
export const HARD_CONTENT_DETAIL = 'HARD_CONTENT_DETAIL'
export const HARD_CONTENT_UPDATE = 'HARD_CONTENT_UPDATE'
export const HARD_CONTENT_INSERT = 'HARD_CONTENT_INSERT'
export const HARD_CONTENT_DELETE = 'HARD_CONTENT_DELETE'
export const HARD_CONTENT_RESPONSE = 'HARD_CONTENT_RESPONSE'
export const HARD_CONTENT_GETALL = 'HARD_CONTENT_GETALL'
export const HARD_CONTENT_DETAIL_GETALL = 'HARD_CONTENT_DETAIL_GETALL'
export const HARD_CONTENT_DETAIL_INSERT = 'HARD_CONTENT_DETAIL_INSERT'
export const HARD_CONTENT_DETAIL_UPDATE = 'HARD_CONTENT_DETAIL_UPDATE'
export const HARD_CONTENT_DETAIL_DELETE = 'HARD_CONTENT_DETAIL_DELETE'
export const HARD_CONTENT_DETAIL_DETAIL = 'HARD_CONTENT_DETAIL_DETAIL'

export default {
    HARD_CONTENT_DELETE,
    HARD_CONTENT_DETAIL,
    HARD_CONTENT_INSERT,
    HARD_CONTENT_LIST,
    HARD_CONTENT_UPDATE,
    HARD_CONTENT_GETALL,
    HARD_CONTENT_DETAIL_GETALL,
    HARD_CONTENT_DETAIL_INSERT,
    HARD_CONTENT_DETAIL_UPDATE,
    HARD_CONTENT_DETAIL_DELETE,
    HARD_CONTENT_DETAIL_DETAIL,
}
