import React, { Component } from 'react';
import { Button, TreeView, DropDownBox } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';
import i18n from '../../../i18n';
import PropTypes from 'prop-types';
class EditPopup extends Component {
    constructor(props) {
        super(props);

        const { selectedItem } = this.props;

        this.state = {
            newFolderName: selectedItem.name,
            selectedParentFolderId: selectedItem.isDirectory ? selectedItem.parentId : selectedItem.folderId,
            foldersList: []
        };

        this.dropdownBoxRef = React.createRef();

        // this.onFolderNameValueChanged = this.onFolderNameValueChanged.bind(this);
        this.editFolder = this.editFolder.bind(this);
        this.hideEditPopup = this.hideEditPopup.bind(this);
        this.treeViewRender = this.treeViewRender.bind(this);
        this.onItemSelectionChanged = this.onItemSelectionChanged.bind(this);
        this.treeViewOnContentReady = this.treeViewOnContentReady.bind(this);
    }

    componentDidMount() {
        const { folderTrees } = this.props;
        const foldersList = this.createFoldersList(folderTrees);

        this.setState({
            foldersList
        });
    }

    get dropdownBox() {
        return this.dropdownBoxRef.current.instance;
    }

    createFoldersList(children) {
        let foldersList = [];

        children.forEach(element => {
            foldersList.push({
                id: element.id,
                text: element.text,
                parentId: element.parentId
            });

            const childFolders = this.createFoldersList(element.children);
            foldersList = [...foldersList, ...childFolders];
        });

        return foldersList;
    }

    treeViewRender() {
        return (
            <TreeView
                searchEnabled={true}
                dataSource={this.props.folderTrees}
                keyExpr={'id'}
                parentIdExpr={'parentId'}
                itemsExpr={'children'}
                displayExpr={'text'}
                selectionMode={'single'}
                selectByClick={true}
                onItemClick={this.treeViewOnItemClick}
                onItemSelectionChanged={this.onItemSelectionChanged}
                onContentReady={this.treeViewOnContentReady}
            />
        );
    }

    treeViewOnItemClick = () => {
        this.dropdownBox.close();
    }

    treeViewOnContentReady(e) {
        e.component.selectItem(this.state.selectedParentFolderId);
    }

    syncTreeViewSelection = (e) => {
        this.setState({
            selectedParentFolderId: e.value
        });
    }

    onItemSelectionChanged(e) {
        this.setState({
            selectedParentFolderId: e.itemData.id
        });
    }

    // onFolderNameValueChanged(e) {
    //     if (e.event !== undefined && e.event !== null) {
    //         this.setState({
    //             newFolderName: e.value
    //         })
    //     }
    // }

    editFolder() {
        const { newFolderName, selectedParentFolderId } = this.state;
        this.props.editFolder(newFolderName, selectedParentFolderId);
    }

    hideEditPopup() {
        this.props.hideEditPopup();
    }

    render() {
        const { isShowEditPopup } = this.props;
        const { selectedParentFolderId, foldersList } = this.state;
        const { currentLanguage } = this.props;
        return (
            <Popup
                maxHeight={300}
                maxWidth={'500'}
                closeOnOutsideClick={true}
                title={i18n.t('fileManagement.popup.editTitle')}
                visible={isShowEditPopup}
                onHiding={() => console.log('onHidding')}
                onHidden={this.hideEditPopup}
                className={'photo-popup-content'}>

                {/* <div className={'dx-field'}>
                    <div className={'dx-field-label'}>{i18n.t('general.form.Folderfilename', {currentLanguage})}:<span className="color-red"> *</span></div>
                    <div className={'dx-field-value'}>
                        <TextBox
                            className={'multi-language'}
                            name={'name'}
                            valueChangeEvent={'keyup'}
                            value={newFolderName || ''}
                            onValueChanged={this.onFolderNameValueChanged}
                        />
                    </div>
                </div> */}

                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>{i18n.t('fileManagement.popup.folderLabel')}:</div>
                    <div className={'dx-field-value'}>
                        <DropDownBox
                            ref={this.dropdownBoxRef}
                            dataSource={foldersList}
                            valueExpr={'id'}
                            parentIdExpr={'parentId'}
                            itemsExpr={'children'}
                            value={selectedParentFolderId}
                            displayExpr={'text'}
                            showClearButton={true}
                            contentRender={this.treeViewRender}
                            onValueChanged={this.syncTreeViewSelection}
                        />
                    </div>
                </div>

                <div className={'mt-3 '} style={{ textAlign: 'right' }}>
                    <Button
                        className={'button-info mx-1 dx-theme-accent-as-background-color'}
                        text={i18n.t('general.button.save', { currentLanguage })} width={'120'}
                        onClick={this.editFolder} />
                    <Button
                        className={'button-info'}
                        text={i18n.t('general.button.cancel', { currentLanguage })} width={'120'}
                        onClick={this.hideEditPopup} />
                </div>
            </Popup>
        );
    }
}

EditPopup.propTypes = {
    selectedItem: PropTypes.object.isRequired,
    isShowEditPopup: PropTypes.bool,
    editFolder: PropTypes.func,
    hideEditPopup: PropTypes.func,
    currentLanguage: PropTypes.string,
    folderTrees: PropTypes.object
};

export default EditPopup;
