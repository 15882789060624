import BaseViewModel from '../../base/BaseViewModel';
import { FORMAT_DATE_TIME_BYLANG, NOTIFICATION_TYPE } from '../../constants/Config';

var moment = require('moment');
export default class NotificationViewModel extends BaseViewModel {
    initializeWithLanguage(props, languageId) {
        super.initializeWithLanguage(props, languageId);

        this.createTimeTxt = moment(props.createTime).format(FORMAT_DATE_TIME_BYLANG[languageId]);
        this.typeName = JSON.parse(NOTIFICATION_TYPE[props.type].name)[languageId];
    }
}
