export const PRODUCTCONVERTUNIT_LIST = 'PRODUCTCONVERTUNIT_LIST'
export const PRODUCTCONVERTUNIT_TREE = 'PRODUCTCONVERTUNIT_TREE'
export const PRODUCTCONVERTUNIT_DETAIL = 'PRODUCTCONVERTUNIT_DETAIL'
export const PRODUCTCONVERTUNIT_INSERT = 'PRODUCTCONVERTUNIT_INSERT'
export const PRODUCTCONVERTUNIT_UPDATE = 'PRODUCTCONVERTUNIT_UPDATE'
export const PRODUCTCONVERTUNIT_DELETE = 'PRODUCTCONVERTUNIT_DELETE'

export default {
    PRODUCTCONVERTUNIT_LIST,
    PRODUCTCONVERTUNIT_TREE,
    PRODUCTCONVERTUNIT_DETAIL,
    PRODUCTCONVERTUNIT_INSERT,
    PRODUCTCONVERTUNIT_UPDATE,
    PRODUCTCONVERTUNIT_DELETE,
}
