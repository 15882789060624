import React, { Component } from 'react';
import { TextBox } from 'devextreme-react';
import i18n from '../../i18n';
import ReeValidate from 'ree-validate';
import vi from 'ree-validate/dist/locale/vi';
import en from 'ree-validate/dist/locale/en';
import Transformer from '../../utils/Transformer';

class SEOLinkBox extends Component {
    constructor(props) {
        super(props);

        this.validator = new ReeValidate({
            seoLink: this.props.validatorValue
        });

        if (this.props.currentLanguage === 'vi-VN') {
            this.validator.localize('vi', vi);
        } else {
            this.validator.localize('en', en);
        }

        this.validator.validate('seoLink', this.props.value).then(() => {
            this.checkValidate();
        });

        this.valueChanged = this.valueChanged.bind(this);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { id } = this.props;
        if (nextProps.value !== this.props.value) {
            this.validator.validate('seoLink', nextProps.value).then(() => {
                this.checkValidate();
            });
        }

        if (nextProps.suggestion !== this.props.suggestion) {
            if (!id) {
                this.props.updateData({
                    [this.props.field]: Transformer.convertUnsignedChars(nextProps.suggestion)
                });
            }
        }
    }

    valueChanged = (e) => {
        if (e.event !== undefined && e.event !== null) {
            if (e.event.type !== 'dxclick') {
                const { errors } = this.validator;
                const value = e.event.target.value;

                errors.remove(e.event.target.name);
                this.props.updateData({
                    [this.props.field]: value
                });

                this.validator
                    .validate(e.event.target.name, value)
                    .then(() => {
                        this.checkValidate();
                    })
                    .catch(() => { });
            }
        }
    };

    checkValidate() {
        const { field } = this.props;

        const formValidate = !this.validator.errors.has('seoLink');
        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    render() {
        const { value, i18nLabel, validatorValue, readOnly } = this.props;

        return (
            <div
                className={
                    '' + (this.validator.errors.has('seoLink') ? ' has-error' : '')
                }
            >
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel) + ':'}
                        {validatorValue &&
                            validatorValue.includes('require') && (
                                <span className="color-red"> *</span>
                            )}
                    </div>

                    <div className={'dx-field-value'}>
                        <TextBox
                            className={'multi-language'}
                            name={'seoLink'}
                            value={value}
                            readOnly={readOnly || false}
                            onValueChanged={this.valueChanged}
                        />

                        {this.validator.errors.has('seoLink') && (
                            <div className="help-block">
                                {this.validator.errors
                                    .first('seoLink')
                                    .replace('seoLink', i18n.t(i18nLabel))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default SEOLinkBox;
