import {
    BRANCHS_DETAIL, BRANCHS_INSERT, BRANCHS_LIST,
    BRANCHS_DELETE, BRANCHS_UPDATE, BRANCHS_GETALL, BRANCHS_USER_LIST
} from "./action-types";
import { PAGE_SIZE } from '../../../../constants/Config'
import Transformer from "../../../../utils/Transformer";
const initialState = {
    data: [],
    totalRows: 0,
    currentPAGE: 0,
    pageSize: PAGE_SIZE,
    tree: [],
    action: null,
    item: null,
    suggestion: [],
    userList: {
        totalRows: 0,
        data: []
    }
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case BRANCHS_LIST:
            return list(state, payload)
        case BRANCHS_DETAIL:
            return detail(state, payload)
        case BRANCHS_UPDATE:
            return update(state, payload)
        case BRANCHS_INSERT:
            return insert(state, payload)
        case BRANCHS_DELETE:
            return deletes(state, payload)
        case BRANCHS_GETALL:
            return suggestion(state, payload)
        case BRANCHS_USER_LIST:
            return userList(state, payload)
        default:
            return state
    }
}

function userList(state, payload) {
    state = Object.assign({}, state, { userList: payload })
    return state
}

function list(state, payload) {
    state = Object.assign({}, state, payload)
    return state
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    })
    return state
}

function insert(state, payload) {
    const data = [...state.data, payload]

    let suggestion = Transformer.insertList(state.suggestion, payload)

    return Object.assign({}, state, { data, suggestion })
}

function update(state, payload) {
    const data = state.data.map(obj => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload }
        }
        return obj;
    })
    const item = { ...state.item, ...payload }
    let suggestion = Transformer.updateList(state.suggestion, payload)

    return Object.assign({}, state, { data, item, suggestion })
}

function deletes(state, payload) {
    const data = state.data.filter(obj => obj.id !== payload)

    return Object.assign({}, state, { data })

}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    })
    return state
}
export default reducer
